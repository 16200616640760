
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { VueDraggableNext } from "vue-draggable-next";
import { useStore } from "vuex";
import { props } from "@syncfusion/ej2-vue-richtexteditor/src/rich-text-editor/richtexteditor.component";

export default defineComponent({
  name: "VesselRoutesTE",
  components: {
    draggable: VueDraggableNext,
  },
  props: {
    tableData: {
      type: Array,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement", "updateElement"],

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: update"
        ) !== -1
      );
    });

    const locationData = computed(() => store.getters.Locations);
    const firstUE = ref(false);
    const activeNames = ref(["1"]);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "ilocationid", prop: "location_id" },
      {
        label: "iarrivaldate",
        prop: "arrival_date",
      },
      {
        label: "ideparturedate",
        prop: "departure_date",
      },
      { label: "iDescription", prop: "descriptions" },
    ]);
    const headerTable = ref(headers.value);

    const isUE = (item, index) => {
      const location_item = elements.value.options.find(
        (x: Record<string, undefined>) => {
          return x.id === item.location_id;
        }
      );
      const v = location_item ? location_item["country"] : null;
      const eu = v ? v["is_eu"] : null;
      const payload = {
        index: index,
        is_ue: eu,
      };
      if (eu && !firstUE.value) {
        store.commit("editVesselRoute", payload);
        firstUE.value = true;
      }
    };

    const getElements = () => {
      if (!locationData.value.length) {
        ApiService.query(`/api/location/lists`, {
          params: { per_page: 10, is_maritime: true },
        }).then(({ data }) => {
          const temp = data.locations.filter((x) => x.maritime === 1);
          store.commit("setLocation", temp);
          elements.value.list = temp;
          elements.value.options = temp;
        });
      } else {
        elements.value.list = locationData.value;
        elements.value.options = locationData.value;
      }
    };
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        setTimeout(() => {
          elements.value.loading = false;
          elements.value.options = elements.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.full_name.toLowerCase().indexOf(query.toLowerCase()) > -1
              );
            }
          );
          if (!elements.value.options.length) {
            ApiService.query(`/api/location/lists`, {
              params: { per_page: 10, name: query, is_maritime: true },
            }).then(({ data }) => {
              data.locations.forEach((item) => {
                store.commit("addLocation", item);
              });
              elements.value.list = locationData.value;
              elements.value.options = data.locations;
            });
          }
        }, 200);
      } else {
        elements.value.options = locationData.value;
      }
    };

    onMounted(() => {
      if (props.mounted) {
        getElements();
      } else {
        elements.value.list = locationData.value;
        elements.value.options = locationData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      headers,
      headerTable,
      elements,
      activeNames,
      selectElements,
      isUE,
    };
  },
});
