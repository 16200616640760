
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import {useI18n} from "vue-i18n";
import customers from "@/core/data/customers";
import InputNumber from "@/components/inputs/InputNumber.vue";
export default defineComponent({
  name: "BillImportTE",
  components: { InputNumber },
  props: {
    tableData: {
      type: Array as any,
      default: [] as any,
    },
    currencies: {
      type: Array as any,
    },
    tax_id: {
      type: Number,
    },
    cost_type: {
      type: Number,
      default: 1,
    },
    office_id: {
      type: Number,
    },
    billing_status: {
      type: String,
      default: "N",
    },
    origin: {
      type: String,
      default: "global",
    },
    disable: {
      type: Boolean,
      default: false,
    },
    is_only_read: {
      type: Boolean,
      default: false,
    },
    bl: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "changeTotal"],

  setup(props, { emit }) {
    const store = useStore();
    const {t} = useI18n();
    const activeNames = ref(["1"]);
    const goodTableData = !props.bl
      ? computed(() => store.getters.Goods)
      : computed(() => store.getters.GoodsBL);
    const equipmentTypeData = computed(() => store.getters.RegisterEquipments);
    const equipmentData = computed(() => store.getters.EquipmentsMF);
    const tempBilling = computed(() => store.getters.tempBilling);
    const equipmentType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const equipments = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const taxes_detail = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const providers = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const customers = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const billingConcepts = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headers =
      props.origin === "bl"
        ? ref([
            { label: t("billing_concept_id"), prop: "billing_concept_id" },
            { label: t("iBilling_concepts"), prop: "billing_concept" },
            { label: t("fushion_concept"), prop: "fusion_concept" },
            { label: t("provider_id"), prop: "provider_id" },
            { label: t("currency_id"), prop: "currency_id" },
            { label: t("iunits"), prop: "units" },
            { label: t("iunitamount"), prop: "unit_amount" },
            { label: t("taxes_detail_id"), prop: "taxes_detail_id" },
          ])
        : ref([
            { label: t("billing_concept_id"), prop: "billing_concept_id" },
            { label: t("iBilling_concepts"), prop: "billing_concept" },
            { label: t("fushion_concept"), prop: "fusion_concept" },
            { label: t("provider_id"), prop: "provider_id" },
            { label: t("customer_id"), prop: "customer_id" },
            { label: t("currency_id"), prop: "currency_id" },
            { label: t("iunits"), prop: "units" },
            { label: t("iunitamount"), prop: "unit_amount" },
            { label: t("taxes_detail_id"), prop: "taxes_detail_id" },
          ]);
    const headerTable = ref(headers.value);
    const cost_type_id = ref(props.cost_type);
    watch(
      () => props.tax_id,
      (first) => {
        if (first) {
          loadTaxesDetails();
        }
      },
      { deep: true }
    );
    watch(
      () => props.office_id,
      (first) => {
        if (first) {
          loadTaxesDetails();
        }
      },
      { deep: true }
    );
    watch(
      () => props.cost_type,
      (first) => {
        if (first) {
          cost_type_id.value = props.cost_type;
        }
      },
      { deep: true }
    );

    watch(
      () => props.tableData,
      (first) => {
        if (props.tableData.length) {
          props.tableData.forEach((x) => {
            if (x.isNew === undefined) {
              if (x.id !== undefined && x.id !== null) {
                if (billingConcepts.value.list.length > 0) {
                  if (
                    billingConcepts.value.list.find(
                      (y) => y.id === x.billing_concept_info.id
                    ) === undefined
                  ) {
                    if (x.billing_concept_info) {
                      billingConcepts.value.list.push(x.billing_concept_info);
                      billingConcepts.value.options.push(
                        x.billing_concept_info
                      );
                    }
                  }
                  if (
                    taxes_detail.value.list.find(
                      (y) => y.id === x.taxes_detail_id
                    ) === undefined
                  ) {
                    if (x.taxes_detail) {
                      x.taxes_detail.taxes_detail_id = x.taxes_detail_id;
                      taxes_detail.value.list.push(x.taxes_detail);
                      taxes_detail.value.options.push(x.taxes_detail);
                    }
                  }
                  if (
                    customers.value.list.find((y) => y.id === x.customer_id) ===
                    undefined
                  ) {
                    if (x.customer) {
                      customers.value.list.push(x.customer);
                      customers.value.options.push(x.customer);
                    }
                  }
                  if (
                    providers.value.list.find((y) => y.id === x.provider_id) ===
                    undefined
                  ) {
                    if (x.provider) {
                      providers.value.list.push(x.provider);
                      providers.value.options.push(x.provider);
                    }
                  }
                } else {
                  billingConcepts.value.list.push(x.billing_concept_info);
                  billingConcepts.value.options.push(x.billing_concept_info);
                  if (x.customer) {
                    customers.value.list.push(x.customer);
                    customers.value.options.push(x.customer);
                  }
                  if (x.taxes_detail) {
                    x.taxes_detail.taxes_detail_id = x.taxes_detail_id;
                    taxes_detail.value.list.push(x.taxes_detail);
                    taxes_detail.value.options.push(x.taxes_detail);
                  }
                  if (x.provider) {
                    providers.value.list.push(x.provider);
                    providers.value.options.push(x.provider);
                  }
                }
              } else {
                if (billingConcepts.value.list.length > 0) {
                  if (
                    billingConcepts.value.list.find(
                      (y) => y.id === x.billing_concept_info.id
                    ) === undefined
                  ) {
                    if (x.billing_concept_info) {
                      billingConcepts.value.list.push(x.billing_concept_info);
                      billingConcepts.value.options.push(
                        x.billing_concept_info
                      );
                    }
                  }
                  if (
                    taxes_detail.value.list.find(
                      (y) => y.id === x.taxes_detail_id
                    ) === undefined
                  ) {
                    if (x.taxes_detail) {
                      x.taxes_detail.taxes_detail_id = x.taxes_detail_id;
                      taxes_detail.value.list.push(x.taxes_detail);
                      taxes_detail.value.options.push(x.taxes_detail);
                    }
                  }
                  if (
                    customers.value.list.find((y) => y.id === x.customer_id) ===
                    undefined
                  ) {
                    if (x.customer) {
                      customers.value.list.push(x.customer);
                      customers.value.options.push(x.customer);
                    }
                  }
                  if (
                    providers.value.list.find((y) => y.id === x.provider_id) ===
                    undefined
                  ) {
                    if (x.provider) {
                      customers.value.list.push(x.provider);
                      customers.value.options.push(x.provider);
                    }
                  }
                } else {
                  billingConcepts.value.list.push(x.billing_concept_info);
                  billingConcepts.value.options.push(x.billing_concept_info);
                  if (x.customer) {
                    customers.value.list.push(x.customer);
                    customers.value.options.push(x.customer);
                  }
                  if (x.provider) {
                    customers.value.list.push(x.provider);
                    customers.value.options.push(x.provider);
                  }
                  if (x.taxes_detail) {
                    x.taxes_detail.taxes_detail_id = x.taxes_detail_id;
                    taxes_detail.value.list.push(x.taxes_detail);
                    taxes_detail.value.options.push(x.taxes_detail);
                  }
                }
              }
            }
          });
        }
      },
      { deep: true }
    );

    const onChangeCheck = () => {
      const checkElements = props.tableData.filter((x) => x.select === "1");
      const ids = checkElements.map((x) => x.id);
      if (!ids.length) {
        const reset_name = props.bl
          ? "resetOverdimensionsBL"
          : "resetOverdimensions";
        store.commit(`${reset_name}`);
      } else {
        const filter_name = props.bl
          ? "filterOverdimensionsBL"
          : "filterOverdimensions";
        store.commit(`${filter_name}`, ids);
      }
    };

    const getProviders = () => {
      const provider_ids = [] as any;
      tempBilling.value.forEach((x) => {
        provider_ids.push(x.provider_id);
      });
      store.commit("setLoading", false);
      ApiService.query("/api/entities", {
        params: {
          per_page: 30,
          type_entity_ids: [2],
          entity_id: provider_ids,
        },
      }).then(({ data }) => {
        providers.value.list = data.entities;
        providers.value.options = data.entities;
      });
    };
    const getCustomers = () => {
      const customer_ids = [] as any;
      tempBilling.value.forEach((x) => {
        customer_ids.push(x.customer_id);
      });
      store.commit("setLoading", false);
      ApiService.query("/api/entities", {
        params: {
          per_page: 30,
          offices: [props.office_id],
          type_entity_ids: [1, 13],
        },
      }).then(({ data }) => {
        customers.value.list = data.entities;
        customers.value.options = data.entities;
      });
    };
    const selectEquipmentType = (query) => {
      if (query !== "") {
        equipmentType.value.loading = true;
        setTimeout(() => {
          equipmentType.value.loading = false;
          equipmentType.value.options = equipmentType.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.registration_number
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) > -1
              );
            }
          );
          if (!equipmentType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/registered-equipment/lists`, {
              params: { per_page: 25, registration_number: query },
            }).then(({ data }) => {
              data.registered_equipment.forEach((element) => {
                store.commit("addEquipmentType", element);
              });
              equipmentType.value.list = equipmentTypeData.value;
              equipmentType.value.options = data.registered_equipment;
            });
          }
        }, 200);
      } else {
        equipmentType.value.options = equipmentTypeData.value;
      }
    };

    const loadTaxesDetails = () => {
      store.commit("setLoading", false);
      ApiService.query(`/api/taxes_detail`, {
        params: {
          page: 1,
          per_page: 100,
          offices: [props.office_id],
          taxes: props.tax_id === undefined ? [] : [props.tax_id],
        },
      }).then(function (response) {
        taxes_detail.value.options = response.data.taxes_details;
      });
    };

    const getBillingConcepts = async () => {
      const { data } = await ApiService.query("/api/billingConcepts/lists", {
        params: {
          page: 1,
          per_page: 100,
          offices: [props.office_id],
          cost_type_id: [cost_type_id.value],
        },
      });
      store.commit("setEquipment", data);
      billingConcepts.value.list = data.billing_concepts;
      billingConcepts.value.options = data.billing_concepts;
      // for (const x of props.tableData) {
      //   if (x.billing_concept_id) {
      //     const val = data.billing_concepts.find(
      //       (y) => y.id === x.billing_concept_id
      //     );
      //     console.log("getBillingConcepts val", val);
      //     if (val === undefined) {
      //       store.commit("setLoading", false);
      //       const { data: bill } = await ApiService.get(
      //         "api/billingConcepts/" + x.billing_concept_id
      //       );
      //       billingConcepts.value.list.push(bill);
      //       billingConcepts.value.options.push(bill);
      //     }
      //   }
      // }
    };

    const searchBillingConcepts = async (query) => {
      if (query !== "") {
        setTimeout(async () => {
          billingConcepts.value.options = billingConcepts.value.options.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          const { data } = await ApiService.query(`api/billingConcepts/lists`, {
            params: {
              per_page: 5,
              offices: [props.office_id],
              name: query,
              cost_type_id: [cost_type_id.value],
            },
          });
          billingConcepts.value.list = data.billing_concepts;
          billingConcepts.value.options = data.billing_concepts;
        }, 200);
      }
    };
    const selectEquipment = (query) => {
      if (query !== "") {
        equipments.value.loading = true;
        setTimeout(() => {
          equipments.value.loading = false;
          equipments.value.options = equipments.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!equipments.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/equipments/lists`, {
              params: { per_page: 25, offices: [props.office_id], name: query },
            }).then(({ data }) => {
              data.equipments.forEach((element) => {
                store.commit("addEquipment", element);
              });
              equipments.value.list = equipmentData.value;
              equipments.value.options = data.equipments;
            });
          }
        }, 200);
      } else {
        equipments.value.options = equipmentData.value;
      }
    };
    const selectCustomer = (query) => {
      if (query !== "") {
        setTimeout(() => {
          customers.value.options = customers.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!customers.value.options.length) {
            ApiService.query(`/api/entities/lists`, {
              params: {
                per_page: 25,
                offices: [props.office_id],
                name: query,
                type_entity_ids: [1, 13],
              },
            }).then(({ data }) => {
              customers.value.list = data.entities;
              customers.value.options = data.entities;
            });
          }
        }, 200);
      }
    };
    const selectProvider = (query) => {
      if (query !== "") {
        setTimeout(() => {
          providers.value.options = providers.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!providers.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/entities/lists`, {
              params: {
                per_page: 25,
                offices: [props.office_id],
                name: query,
                type_entity_ids: [2],
              },
            }).then(({ data }) => {
              providers.value.list = data.entities;
              providers.value.options = data.entities;
            });
          }
        }, 200);
      }
    };

    const activePrebill = () => {
      const tmp = [] as any;
      tempBilling.value.forEach(function (value: any) {
        if (value.active) {
          tmp.push(value);
        }
      });
      return tmp;
    };
    const changeUnitAmount = (value, index, item) => {
      const datas = props.tableData;
      datas[index].unit_amount = parseFloat(value);
      let total = 0;
      datas.forEach((x) => {
        total += parseFloat(x.unit_amount);
      });
      emit("changeTotal", total.toFixed(2));
    };
    onMounted(() => {
      getProviders();
    });

    return {
      activeNames,
      tempBilling,
      billingConcepts,
      taxes_detail,
      providers,
      customers,
      headers,
      headerTable,
      equipmentType,
      equipments,
      goodTableData,
      onChangeCheck,
      selectEquipmentType,
      selectEquipment,
      selectCustomer,
      changeUnitAmount,
      getBillingConcepts,
      searchBillingConcepts,
      getCustomers,
      selectProvider,
      loadTaxesDetails,
      activePrebill,
    };
  },
});
