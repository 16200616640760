
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "CardBasicNew",
  props: {
    item: Object,
    title: String,
    date: String,
    showPermission: Boolean,
    duplicatePermission: Boolean,
    updatePermission: Boolean,
    deletePermission: Boolean,
    badgeText: String,
    showCheck: Boolean,
    sendEmail: Boolean,
    exportEquipments: Boolean,
    itemKeysLink: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    backgroundClass: {
      type: String,
      default: "bg-primary",
    },
  },
  emits: [
    "duplicateElement",
    "editElement",
    "seeDetail",
    "deleteElement",
    "onLinkClick",
    "onChangeCheck",
    'sendEmail',
    'exportEquipments'
  ],

  setup(props, { emit }) {
    const store = useStore();
    const dialogremovevisible = ref(false);
    const check = ref(false);
    const permissions = computed(() => store.getters.permissions);
    const reset = computed(() => store.getters.ResetSelectedCard);
    const canShow = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "companies: show") !== -1
      );
    });
    const deleteElement = (id, force) => {
      dialogremovevisible.value = false;
      emit("deleteElement", { id: id, force: force });
    };

    watch(
      () => reset.value,
      (first) => {
        check.value = false;
      },
      { deep: true }
    );

    return {
      dialogremovevisible,
      check,
      deleteElement,
    };
  },
};
