
import { ref } from "vue";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    class: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "searchRemote"],
  setup(props, { emit }) {
    const onSearch = (query) => {
      emit("searchRemote", query);
    };
    const m_val = ref("");
    return {
      m_val,
      onSearch,
    };
  },
};
