
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import store from "@/store";

export default {
  name: "OtParticipantList",
  props: {
    tableData: {
      type: Array,
    },
    cedidoAdd: {
      type: Boolean,
      default: false,
    },
    propioAdd: {
      type: Boolean,
      default: false,
    },
    activeOT: {
      type: Number,
    },
  },

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const operationTableData = computed(() => store.getters.MooringOperations);
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: update"
        ) !== -1
      );
    });

    const loading = ref(false);
    const ParticipantList = computed(() => store.getters.ParticipantList);
    const element_booking = computed(() => store.getters.BookingElement);
    const element_bl = computed(() => store.getters.BLElement);
    const bookingTableData = computed(() => store.getters.Bookings);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const elements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const addressData = ref({
      loading: false,
      options: [],
      list: [],
    });
    const participant_type = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;
    const contactByKey = ref([]) as any;
    const contactData = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "ito_participant_type_id", prop: "to_participant_type_id" },
      { label: "observations", prop: "observations" },
      { label: "ientity_id", prop: "entity_id" },
      { label: "iftx_entity", prop: "ftx_entity" },
      { label: "iaddress_id", prop: "address_id" },
      { label: "iftx_address", prop: "ftx_address" },
      { label: "icontact_id", prop: "contact_id" },
      { label: "ftx_contact", prop: "ftx_contact" },
    ]);
    const headerTable = ref(headers.value);

    const getElements = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/to_participant_type/lists?per_page=100").then(
        function (response) {
          participant_type.value.options = response.data.to_participant_type;
        }
      );
      store.commit("setLoading", false);
      ApiService.query("/api/entities/lists", {
        params: {
          per_page: 25,
          office_id: [
            maritimeFile.value.office.id ?? maritimeFile.value.office.id,
          ],
        },
      }).then(({ data }) => {
        elements.value.list = data.entities;
        elements.value.options = data.entities;
        data.entities.forEach(function (value) {
          contactByKey.value.push({
            e_id: value.id,
            c_list: value.contacts,
            a_list: value.addresses,
          });
        });
      });
      if (ParticipantList.value.length > 0) {
        ApiService.query("/api/entities/lists", {
          params: {
            per_page: ParticipantList.value.length,
            entity_ids: ParticipantList.value.map((x) => x.entity_id),
          },
        }).then(({ data }) => {
          data.entities.forEach(function (value) {
            elements.value.options.push(value);
            contactByKey.value.push({
              e_id: value.id,
              c_list: value.contacts,
              a_list: value.addresses,
            });
          });
        });
      }
      store.commit("setLoading", false);
    };
    const contactArray = (entity_id) => {
      const data = contactByKey.value.find((c) => c.e_id === entity_id);
      return data ? data : { c_list: [] };
    };
    const addressArray = (entity_id) => {
      const data = contactByKey.value.find((c) => c.e_id === entity_id);
      return data ? data : { a_list: [] };
    };
    const getEntityData = (id, i) => {
      store.commit("setLoading", false);
      ApiService.get("/api/entities/" + id).then(function (response) {
        contactData.value.list = response.data.contacts;
        contactData.value.options = response.data.contacts;
        contactByKey.value.forEach(function (v) {
          if (v.e_id === id) {
            v.c_list = response.data.contacts;
            v.a_list = response.data.addresses;
          }
        });
        ParticipantList.value[i].ftx_entity = response.data.legal_name;
        ParticipantList.value[i].ftx_contact =
          response.data.contacts.length > 0
            ? response.data.contacts[0].name +
              " " +
              response.data.contacts[0].last_name
            : "";
        ParticipantList.value[i].contact_id =
          response.data.contacts.length > 0
            ? response.data.contacts[0].id
            : undefined;
        response.data.contacts.length > 0
          ? response.data.contacts[0].id
          : undefined;
        ParticipantList.value[i].address_id =
          response.data.addresses.length > 0
            ? response.data.addresses[0].id
            : undefined;
        ParticipantList.value[i].ftx_address =
          response.data.addresses.length > 0
            ? response.data.addresses[0].number +
              " " +
              response.data.addresses[0].street
            : "";
        if (
          !elements.value.options.map((x) => x.id).includes(response.data.id)
        ) {
          elements.value.options.push(response.data);
          contactByKey.value.push({
            e_id: response.data.id,
            c_list: response.data.contacts,
            a_list: response.data.addresses,
          });
        }
      });
    };
    const selectElements = (query) => {
      if (query.length > 2) {
        elements.value.loading = true;
        ApiService.query(`/api/entities/lists`, {
          params: {
            per_page: 25,
            name: query,
            office_id: [
              maritimeFile.value.office.id ?? maritimeFile.value.office.id,
            ],
          },
        }).then(({ data }) => {
          elements.value.list = data.entities;
          elements.value.options = data.entities;
          data.entities.forEach(function (value) {
            contactByKey.value.push({
              e_id: value.id,
              c_list: value.contacts,
              a_list: value.addresses,
            });
          });
        });
        elements.value.loading = false;
        return true;
      } else {
        elements.value.options = elements.value.list;
      }
    };

    watch(
      () => props.cedidoAdd,
      (first) => {
        if (first) {
          let entity_id = element_bl.value.customer_id;
          store.commit(
            "setParticipantList",
            ParticipantList.value.filter((x) => x.to_participant_type_id !== 2)
          );
          store.commit("addParticipantList", {
            to_participant_type_id: 5,
            entity_id: entity_id,
            ftx_entity: "",
            address_id: "",
            ftx_address: "",
            contact_id: "",
            ftx_contact: "",
            observations: "",
          });
          if (entity_id) {
            getEntityData(entity_id, 0);
          }
        }
      }
    );

    watch(
      () => props.propioAdd,
      (first) => {
        if (first) {
          store.commit(
            "setParticipantList",
            ParticipantList.value.filter((x) => x.to_participant_type_id !== 5)
          );
          store.commit("addParticipantList", {
            to_participant_type_id: 2,
            entity_id: "",
            ftx_entity: "",
            address_id: "",
            ftx_address: "",
            contact_id: "",
            ftx_contact: "",
            observations: "",
          });
        }
      }
    );

    onMounted(() => {
      getElements();
      if (ParticipantList.value.length == 0) {
        let entity_id = undefined;
        operationTableData.value.forEach((item) => {
          if (
            item.operation_type_id === 1 &&
            element_booking.value.trip_type_id === 2
          ) {
            entity_id = item.station_id;
          }
          if (
            item.operation_type_id === 3 &&
            element_booking.value.trip_type_id === 1
          ) {
            entity_id = item.station_id;
          }
        });
        store.commit("addParticipantList", {
          to_participant_type_id:
            element_booking.value.trip_type_id === 2 ? 1 : 3,
          entity_id: entity_id,
          ftx_entity: "",
          address_id: "",
          ftx_address: "",
          contact_id: "",
          ftx_contact: "",
          observations: "",
        });
        store.commit("addParticipantList", {
          to_participant_type_id:
            element_booking.value.trip_type_id === 2 ? 3 : 1,
          entity_id: "",
          ftx_entity: "",
          address_id: "",
          ftx_address: "",
          contact_id: "",
          ftx_contact: "",
          observations: "",
        });
        store.commit("addParticipantList", {
          to_participant_type_id: 4,
          entity_id: element_booking.value.maritime_voyage.shipowner_id,
          ftx_entity: "",
          address_id: "",
          ftx_address: "",
          contact_id: "",
          ftx_contact: "",
          observations: "",
        });
        store.commit("addParticipantList", {
          to_participant_type_id: 2,
          entity_id: "",
          ftx_entity: "",
          address_id: "",
          ftx_address: "",
          contact_id: "",
          ftx_contact: "",
          observations: "",
        });
        if (entity_id) {
          getEntityData(entity_id, 0);
        }
        getEntityData(element_booking.value.maritime_voyage.shipowner_id, 2);
      }
    });

    return {
      canCreate,
      canEdit,
      participant_type,
      ParticipantList,
      loading,
      bookingTableData,
      elements,
      contactData,
      addressData,
      headers,
      headerTable,
      element_booking,
      element_bl,
      selectElements,
      getEntityData,
      contactArray,
      addressArray,
    };
  },
};
