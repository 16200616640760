
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import TableBasic from "@/components/tables/TableBasic.vue";
import BillImportTE from "@/components/maritime-file/tables-editable/BillImportTE.vue";
import BillImportExpirationDatesTE from "@/components/maritime-file/tables-editable/BillImportExpirationDatesTE.vue";
import TemplateTypeSelect from "@/components/catalogs-select/TemplateTypeSelect.vue";
import { VueEditor } from "vue3-editor";
import Billing_documents from "@/components/maritime-file/tabs/billing-tabs/Billing_documents.vue";
import BillingPaymentsTE from "@/components/maritime-file/tables-editable/BillingPaymentsTE.vue";
import moment from "moment";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
import MaritimeFileSelect from "@/components/catalogs-select/MaritimeFileSelect.vue";
import SPSelect from "@/components/catalogs-select/SPSelect.vue";
import { useI18n } from "vue-i18n";
import InputNumber from "@/components/inputs/InputNumber.vue";
import currencies from "@/views/catalog/currencies/currencies.vue";
import i18n from "@/core/plugins/i18n";
import { Field } from "vee-validate";
import { ElNotification } from "element-plus";
import { GeneralUseSelect } from "@/components/catalogs-select";
import { GeneralSelect } from "@/components/catalogs-select";

export default {
  name: "BillingRegister",
  components: {
    InputNumber,
    MaritimeFileSelect,
    EntitySelect,
    BillImportTE,
    BillImportExpirationDatesTE,
    TemplateTypeSelect,
    VueEditor,
    SPSelect,
    Billing_documents,
    BillingPaymentsTE,
    GeneralUseSelect,
    GeneralSelect,
    Field,
  },
  props: {
    showBack: {
      type: Boolean,
      default: false,
    },
    isBilling: {
      type: Boolean,
      default: false,
    },
    billing: {
      type: Object,
      default: undefined,
    },
    prebillings: {
      type: Array,
      default: [] as any,
    },
    bl_id: {
      type: Number,
      default: undefined,
    },
    bl: {
      type: Object,
      default: null as any,
    },
    maritime_file: {
      type: Object,
      default: null as any,
    },
    maritime_file_id: {
      type: Number,
      default: null,
    },
    origin: {
      type: String,
      default: "global",
    },
  },
  emits: ["actionBack"],
  setup: function (props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const router = useRouter();
    const route = useRoute();

    const currenciesChangeList = ref([] as any);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const errorMessageBillingSerie = ref("");
    const errorMessageCustomer = ref("");
    const errorMessageProvider = ref("");
    const dialogValRefused = ref("");
    const errorMessageReference = ref("");
    const errorMessagePaymentDetail = ref("");
    const billing_dates = computed(() => store.getters.BillingPaymentsData);
    const officesList = computed(() => store.getters.Office);
    const paymentsDetails = computed(() => store.getters.PaymentDetails);
    const billing_lines = computed(() => store.getters.tempBilling);
    const mFiles = computed(() => store.getters.MaritimeFiles);
    const bl_list = computed(() => store.getters.BLS);
    const customerList = computed(() => store.getters.Customers);
    const providerList = computed(() => store.getters.Providers);
    const sendEmailForm = ref(false);
    const currency_exchanges = computed(() => store.getters.CurrenciesExchange);
    const billing_expire = computed(
      () => store.getters.billTempExpirationDates
    );
    const currenciesData = computed(() => store.getters.Currencies);
    const dataObject = computed(() => store.getters.tempBilling);

    const office_loading = ref(false);
    const showPendingForm = ref(false);
    const templates = ref([] as any);

    const filterByUserOffice = ref({ users: [user.value.id], id: null });
    const searchPaymentDetails = ref<{
      entities: number[];
      type_economic_data: any;
    }>({ entities: [], type_economic_data: "C" });
    const searchOffice = ref<{
      cost_type_id: number[];
    }>({
      cost_type_id: [1],
    });
    const providerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [2],
      offices_id: [],
    });
    const customerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [1, 13],
      offices_id: [],
    });
    const shipOwnerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [7],
      offices_id: [],
    });
    const blMfSearch = ref<{
      office_id: number[];
    }>({
      office_id: [],
    });

    const activeName = ref("general");

    const catalogs = ref({
      companiesGroup: [],
      entities: [],
      loadingCompaniesGroup: false,
      loadingEntities: false,
    });

    const payload = ref({
      email: [],
      subject: "",
      bodymail: "",
      edi_paths_id: [],
      action_files_id: [],
    });

    const company = ref({
      accounting_plan_id: "",
    });
    const reload = ref(true);

    const confirm_cancel = ref(false);

    const header = ref([
      { column: "select", key: "active" },
      {
        column: "iconcept",
        key: "billing_concept_id",
      },
      { column: "tibillingconceptname", key: "billing_concept" },
      { column: "icustomer", key: "customer_id" },
      { column: "tiprovider", key: "provider_id" },
      { column: "freightpaymenttype", key: "freight_payment_type_id" },
      { column: "location_abroad", key: "location_abroad_id" },
      { column: "iamounts", key: "amounts" },
      { column: "icurrency", key: "currency" },
    ]);

    const list = ref({
      bill_sense: [
        {
          id: "E",
          name: i18n.global.t("iFacturaEmitida"),
        },
        {
          id: "R",
          name: i18n.global.t("iFacturaRecibida"),
        },
        {
          id: "A",
          name: i18n.global.t("iAbono"),
        },
        {
          id: "FR",
          name: i18n.global.t("iFacturaRectificada"),
        },
      ],
      type_traffic: [
        {
          id: "I",
          name: "IMPORT",
        },
        {
          id: "E",
          name: "EXPORT",
        },
      ],
      billing_status: [
        {
          id: "A",
          label: "bill_A",
        },
        {
          id: "C",
          label: "bill_C", //*
        },
        {
          id: "E",
          label: "bill_E", //*
        },
        {
          id: "H",
          label: "bill_H", // Solo el admin puede poner este estado
        },
        {
          id: "I",
          label: "bill_I", //*
        },
        {
          id: "P",
          label: "bill_P",
        },
        {
          id: "R",
          label: "bill_R",
        },
        {
          id: "V",
          label: "bill_V",
        },
        {
          id: "X",
          label: "bill_X",
        },
      ],
      bill_sense_loading: false,
      payment_type_detail: [] as any,
      offices: [] as any,
      taxes: [] as any,
      address: [] as any,
      pagination_taxes: {
        per_page: 5,
        page: 1,
      },
      pagination_office: {
        per_page: 5,
        page: 1,
      },
      pagination_payment_type: {
        per_page: 5,
        page: 1,
      },
      customer: [] as any,
      pagination_customer: {
        per_page: 5,
        page: 1,
      },
      currencies: [] as any,
      pagination_currencies: {
        per_page: 5,
        page: 1,
      },
      taxesDetails: [] as any,
      pagination_taxesDetails: {
        per_page: 5,
        page: 1,
      },
      billing_serie: [] as any,
      pagination_serie: {
        per_page: 5,
        page: 1,
      },
      bls: [] as any,
      pagination_bls: {
        per_page: 5,
        page: 1,
      },
      bookins: [] as any,
      pagination_bookins: {
        per_page: 5,
        page: 1,
      },
      payment_type_detail_loading: false,
      customer_loading: false,
      billing_serie_loading: false,
      offices_loading: false,
    });

    const logs = ref([]) as any;
    const show = ref({
      show_emit_button: true,
      show_draft_button: true,
      show_discard_button: true,
      show_contab_button: false,
      show_null_button: false,
      show_print_button: true,
      show_correct_button: false,
    });

    const copy_tempplate = ref({
      header_observations: "",
      footer_observations: "",
      var_footer_observations: null as any,
      var_header_observations: null as any,
    });

    const getBillingExercices = () => {
      const date = new Date();
      return date.getFullYear();
    };

    const form = ref({
      id: "",
      all_data: {
        edi_paths: [],
      },
      bl_id: null as any,
      maritime_file_id: null as any,
      bills_of_lading: null as any,
      company_id: null as any,
      currency_name: "",
      correct_billing_id: "",
      bill_sense: "E",
      cost_type_id: 1,
      type_traffic: "",
      currency_exchange_id: "",
      payment_type_detail_id: "",
      companies: null as any,
      customer: "",
      provider: "",
      billing_exercise: getBillingExercices(),
      billing_serie_id: "",
      bill_number: "",
      bill_number_provider: "",
      billing_status: "I",
      bill_date: "",
      accouting_date: "",
      expiration_date: "",
      reference: "",
      bill_total: 0,
      office_id: "",
      office_name: "",
      office: "" as any,
      currency_id: 3,
      taxes_detail_id: "",
      tax_id: "",
      observations: "",
      bill_address: "",
      header_observations: "",
      footer_observations: "",
      var_footer_observations: null as any,
      var_header_observations: null as any,
      deleted_at: "",
      created_at: "",
      updated_at: "",
      template_id: "",
      billing_lines: [] as any,
      expiration_dates: [] as any,
      billing_payments: [] as any,
      bills_of_lading_date: "",
      bills_of_lading_number: "",
      booking_number: "",
      group_by_concepts: true,
    });

    const getStatusName = (value: any) => {
      const data = list.value.billing_status.find((s) => s.id === value) as any;
      return data.label ? data.label : "";
    };

    const onOfficeChange = async (event) => {
      const office_id = event;
      if (form.value.bl_id !== undefined) {
        await changeOffice(office_id);
      } else {
        await changeOffice(office_id);
      }
    };

    const loadValues = () => {
      let variables = [] as any;
      form.value.var_header_observations =
        copy_tempplate.value.var_header_observations;
      form.value.var_footer_observations =
        copy_tempplate.value.var_footer_observations;
      if (
        copy_tempplate.value.header_observations !== null &&
        copy_tempplate.value.header_observations !== ""
      ) {
        form.value.header_observations =
          copy_tempplate.value.header_observations;

        const data_body = JSON.parse(form.value.var_header_observations);
        const var_keys = Object.keys(data_body);
        var_keys.forEach((x) => {
          form.value.header_observations =
            form.value.header_observations.replaceAll(
              `@${x.toString()}`,
              x === "billing_serie"
                ? form.value[x]["code"]
                : x === "currency"
                ? form.value["currency_name"]
                : x === "payment_type_detail"
                ? form.value[x]["name"]
                : x === "customer_name"
                ? form.value["customer_info"]["legal_name"]
                : x === "customer_identification"
                ? form.value["customer_info"]["identifications"].filter(
                    (x: any) => x.identification_type_id === 1
                  )[0].value
                : x === "contact_name"
                ? form.value["customer_info"]["contacts"].filter(
                    (x: any) => x.contact_type_id === 5
                  ).length > 0
                  ? form.value["customer_info"]["contacts"].filter(
                      (x: any) => x.contact_type_id === 5
                    )[0].name
                  : ""
                : x === "contact_last_name"
                ? form.value["customer_info"]["contacts"].filter(
                    (x: any) => x.contact_type_id === 5
                  ).length > 0
                  ? form.value["customer_info"]["contacts"].filter(
                      (x: any) => x.contact_type_id === 5
                    )[0].last_name
                  : ""
                : x === "customer_email"
                ? form.value["customer_info"]["email"]
                : x === "trip_type"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["maritime_voyages"] &&
                  form.value["maritime_file"]["maritime_voyages"].length > 0
                  ? form.value["maritime_file"]["maritime_voyages"][0].trip_type
                  : ""
                : x === "maritime_voyage_code"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["maritime_voyages"] &&
                  form.value["maritime_file"]["maritime_voyages"].length > 0
                  ? form.value["maritime_file"]["maritime_voyages"][0].code
                  : ""
                : x === "maritime_file_code"
                ? form.value["maritime_file"]
                  ? form.value["maritime_file"].code
                  : ""
                : x === "maritime_file_type"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["type"]
                  ? form.value["maritime_file"]["type"].name
                  : ""
                : x === "mf_type_code"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["type"]
                  ? form.value["maritime_file"]["type"].code
                  : ""
                : x === "port_name"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["port"]
                  ? form.value["maritime_file"]["port"].name
                  : ""
                : x === "port_prefix"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["port"]
                  ? form.value["maritime_file"]["port"].prefix
                  : ""
                : x === "vessel"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["vessel"]
                  ? form.value["maritime_file"]["vessel"].name
                  : ""
                : x === "lloyds_iso"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["vessel"]
                  ? form.value["maritime_file"]["vessel"].lloyds_iso
                  : ""
                : x === "ship_consignee_name"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["ship_consignee"]
                  ? form.value["maritime_file"]["ship_consignee"].legal_name
                  : ""
                : x === "ship_consignee_email"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["ship_consignee"]
                  ? form.value["maritime_file"]["ship_consignee"].email
                  : ""
                : x === "company_name"
                ? form.value["office"]["company"].name
                : x === "company_website"
                ? form.value["office"]["company"].website
                : x === "company_cif"
                ? form.value["office"]["company"].cif
                : form.value[x]
            );
        });
      }
      if (copy_tempplate.value.footer_observations) {
        form.value.footer_observations =
          copy_tempplate.value.footer_observations;
        const data_body = JSON.parse(form.value.var_footer_observations);
        const var_keys = Object.keys(data_body);
        var_keys.forEach((x) => {
          form.value.footer_observations =
            form.value.footer_observations.replaceAll(
              `@${x.toString()}`,
              x === "billing_serie"
                ? form.value[x]["code"]
                : x === "currency"
                ? form.value["currency_name"]
                : x === "payment_type_detail"
                ? form.value[x]["name"]
                : x === "customer_name"
                ? form.value["customer_info"]["legal_name"]
                : x === "customer_identification"
                ? form.value["customer_info"]["identifications"].filter(
                    (x: any) => x.identification_type_id === 1
                  )[0].value
                : x === "contact_name"
                ? form.value["customer_info"]["contacts"].filter(
                    (x: any) => x.contact_type_id === 5
                  ).length > 0
                  ? form.value["customer_info"]["contacts"].filter(
                      (x: any) => x.contact_type_id === 5
                    )[0].name
                  : ""
                : x === "contact_last_name"
                ? form.value["customer_info"]["contacts"].filter(
                    (x: any) => x.contact_type_id === 5
                  ).length > 0
                  ? form.value["customer_info"]["contacts"].filter(
                      (x: any) => x.contact_type_id === 5
                    )[0].last_name
                  : ""
                : x === "customer_email"
                ? form.value["customer_info"]["email"]
                : x === "trip_type"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["maritime_voyages"] &&
                  form.value["maritime_file"]["maritime_voyages"].length > 0
                  ? form.value["maritime_file"]["maritime_voyages"][0].trip_type
                  : ""
                : x === "maritime_voyage_code"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["maritime_voyages"] &&
                  form.value["maritime_file"]["maritime_voyages"].length > 0
                  ? form.value["maritime_file"]["maritime_voyages"][0].code
                  : ""
                : x === "maritime_file_code"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"].code
                : x === "maritime_file_type"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["type"].name
                : x === "mf_type_code"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["type"].code
                : x === "port_name"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["port"].name
                : x === "port_prefix"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["port"].prefix
                : x === "vessel"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["vessel"].name
                : x === "lloyds_iso"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["vessel"].lloyds_iso
                : x === "ship_consignee_name"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["ship_consignee"].legal_name
                : x === "ship_consignee_email"
                ? form.value["maritime_file"] &&
                  form.value["maritime_file"]["ship_consignee"].email
                : x === "company_name"
                ? form.value["office"]["company"].name
                : x === "company_website"
                ? form.value["office"]["company"].website
                : x === "company_cif"
                ? form.value["office"]["company"].cif
                : form.value[x]
            );
        });
      }
    };

    const sendEmail = (dta) => {
      if (form.value) {
        const element = dta && dta.length > 0 ? dta : null;
        const customers_data = form.value;
        const customers_data_info = customers_data["customer_info"]["contacts"];
        const formated_date = moment(customers_data.bill_date).format(
          "DD/MM/YYYY"
        );
        let emails = [] as any;

        // Mapear las solicitudes a promesas
        const promises = customers_data_info.map((customer: any) => {
          return ApiService.query("/api/contacts/", {
            params: {
              entity_id: [customer.pivot.entity_id],
              contact_type_id: [5],
            },
          })
            .then(({ data }) => {
              data.contacts.forEach((x: any) => {
                const communications = x.communications.filter(
                  (y: any) => y.media.name === "Email"
                );
                communications.forEach((y: any) => {
                  if (y.value && y.value !== "" && !emails.includes(y.value)) {
                    emails.push(y.value);
                  }
                });
              });
            })
            .catch((error) => {
              console.error("Error fetching contacts:", error);
            });
        });

        Promise.all(promises)
          .then(() => {
            // Verificar si hay correos electrónicos antes de continuar
            if (emails.length > 0) {
              payload.value.email = emails;
              payload.value.subject = "Envio de la Factura " + element.filename;

              const emailBody = `
                <p>Hola,</p>
                <p>Adjunto encontrarás la factura correspondiente con los siguientes detalles:</p>
                <table border='1' style='border-collapse: collapse; width: 100%;'>
                  <tr><td>Fecha de Factura</td><td>${formated_date}</td></tr>
                  <tr><td>Dirección de Facturación</td><td>${
                    customers_data.bill_address
                  }</td></tr>
                  <tr><td>Total de Factura</td><td>${
                    customers_data.bill_total
                  }</td></tr>

                </table>
                <p>Otras observaciones: ${
                  customers_data.footer_observations || "N/A"
                }</p>
              `;
              payload.value.bodymail = emailBody;
              payload.value.action_files_id = [];
              payload.value.edi_paths_id = element.map((e) => e.id) as never[];
              store.commit("setEmailItem", element);
              const payloadValue = payload.value;

              // Hago una llamada para generar el mensaje
              ApiService.post("/api/edifile/mail/send", payloadValue)
                .then(({ data }) => {
                  ElNotification({
                    title: "Success",
                    message: t("rsend_email_success"),
                    type: "success",
                  });
                  store.commit("setSendBillingDialog", false);
                })
                .catch((error) => {
                  console.error("Error sending mail:", error);
                });
            } else {
              // Manejar la situación donde no hay correos electrónicos
              ElNotification({
                title: "Error",
                message:
                  "No hay emails asociados en los contactos para enviar la factura",
                type: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error processing promises:", error);
          });
      }
    };

    const onChangeIdHeader = (id) => {};
    const onChangeIdFooter = (id) => {};
    const contabilizeFacture = () => {
      form.value.billing_status = "C";
      if (!props.showBack && route.params.id) {
        ApiService.patch("/api/billing/" + route.params.id, {
          billing_status: "C",
        }).then(function (response) {
          getBillingStatus(response.data.billing_status);
        });
      } else {
        ApiService.patch("/api/billing/" + form.value.id, {
          billing_status: "C",
        }).then(function (response) {
          getBillingStatus(response.data.billing_status);
        });
      }
    };
    const devolution = () => {
      confirm_cancel.value = true;
    };

    const generate_pdf = async () => {
      store.commit("setLoadingStatus", true);

      const billingId = props.showBack ? form.value.id : route.params.id;

      ApiService.query(
        "/api/billing/generateBillings/?billing_ids%5B%5D=" + billingId,
        {
          params: {
            groupByConcepts: form.value.group_by_concepts,
          },
        }
      ).then(async (response) => {
        store.commit("setLoadingStatus", false);
        if (!response.data[0].error) {
          ElNotification({
            title: "Success",
            message: i18n.global.t("bill_generated"),
            type: "success",
          });
          const emailData = [response.data[0]];
          store.commit("addFileStoredData", response.data[0]);
          if (
            form.value.office &&
            form.value.office.billing_equipments_type_attachment ===
              "additional_document"
          ) {
            store.commit("setLoadingStatus", true);
            const responseAttach = await generateAttachmentEquipmentInvolves(
              form.value.id
            );
            store.commit("setLoadingStatus", false);
            if (!responseAttach.data[0].error) {
              ElNotification({
                title: "Success",
                message: i18n.global.t("bill_equipments_generated"),
                type: "success",
              });
              emailData.push(responseAttach.data[0]);
              store.commit("addFileStoredData", responseAttach.data[0]);
            }
          }
          if (sendEmailForm.value) {
            sendEmail(emailData);
          }
        } else {
          response.data.forEach(function (value) {
            let errorKeys = Object.keys(value.detail.errors);
            // Para la pestaña de linea de facturas que esta hecho distinto
            errorKeys.forEach(function (key) {
              var index = key.indexOf("concepts");
              if (index != -1) {
                var splitted = key.split(".", 3);
                key = splitted[2];
              }
              ElNotification({
                title: "Error",
                message: i18n.global.t(key + "_required"),
                type: "error",
              });
            });
          });
        }
      });
    };
    const devolution_ok = () => {
      if (!props.showBack && route.params.id) {
        ApiService.patch("/api/billing/" + route.params.id, {
          billing_status: "A",
        }).then(function ({ data }) {
          form.value.bill_total = form.value.bill_total * -1;
          form.value.billing_lines.forEach(function (valu: any, key: any) {
            if (form.value.billing_lines[key].unit_amount > 0) {
              form.value.billing_lines[key].unit_amount =
                parseFloat(valu.unit_amount) * -1;
            }
          });
          confirm_cancel.value = false;
          getBillingStatus(data.billing_status);
          form.value.billing_status = "I";
          form.value.bill_number = "";
          ApiService.post("/api/billing", form.value).then(function (response) {
            router.push({ name: "billing_management" });
          });
        });
      } else {
        ApiService.patch("/api/billing/" + form.value.id, {
          billing_status: "A",
        }).then(function ({ data }) {
          form.value.bill_total = form.value.bill_total * -1;
          form.value.billing_lines.forEach(function (valu: any, key: any) {
            if (form.value.billing_lines[key].unit_amount > 0) {
              form.value.billing_lines[key].unit_amount =
                parseFloat(valu.unit_amount) * -1;
            }
          });
          confirm_cancel.value = false;
          getBillingStatus(data.billing_status);
          form.value.billing_status = "I";
          form.value.bill_number = "";
          ApiService.post("/api/billing", form.value).then(function (response) {
            // router.push({ name: "billing_management" });
            getBillingStatus(data.billing_status);
          });
        });
      }
    };

    const onClickTaxes = async () => {
      if (list.value.taxes.length === 0) {
        const { data } = await ApiService.query(`/api/taxes`, {
          params: {
            page: list.value.pagination_taxesDetails.page,
            per_page: 5,
            offices: [form.value.office_id],
          },
        });
        list.value.taxes = [];
        data.taxes.forEach(function (value) {
          list.value.taxes.push({
            id: value.tax_id,
            name: value.code,
            taxe_detail_id: value.taxes_detail_id,
          });
        });
        list.value.pagination_taxes.page = data.pagination.page;
      }
    };
    const getTaxes = async () => {
      const { data } = await ApiService.query(`/api/taxes`, {
        params: {
          page: list.value.pagination_taxesDetails.page,
          per_page: 5,
          offices: [form.value.office_id],
        },
      });
      list.value.taxes = [];
      data.taxes.forEach(function (value) {
        list.value.taxes.push({
          id: value.tax_id,
          name: value.code,
          taxe_detail_id: value.taxes_detail_id,
        });
      });
      list.value.pagination_taxes.page = data.pagination.page;
    };
    const loadTaxes = async () => {
      if (
        form.value.tax_id === null ||
        form.value.tax_id === "" ||
        form.value.tax_id === undefined
      ) {
        const { data } = await ApiService.query(`/api/taxes`, {
          params: {
            page: list.value.pagination_taxesDetails.page,
            per_page: 5,
            offices: [form.value.office_id],
          },
        });
        list.value.taxes = [];
        data.taxes.forEach(function (value) {
          list.value.taxes.push({
            id: value.tax_id,
            name: value.code,
            taxe_detail_id: value.taxes_detail_id,
          });
        });
        list.value.pagination_taxes.page = data.pagination.page;
      }
    };
    const searchTaxes = async (query) => {
      if (query !== "") {
        office_loading.value = true;
        setTimeout(() => {
          office_loading.value = false;
          list.value.taxes = list.value.taxes.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          ApiService.query(`/api/taxes`, {
            params: {
              per_page: 5,
              name: query,
              page: list.value.pagination_taxes.page,
            },
          }).then(({ data }) => {
            list.value.taxes = [];
            data.taxes.forEach(function (value) {
              list.value.taxes.push({
                id: value.tax_id,
                name: value.code,
                taxe_detail_id: value.taxes_detail_id,
              });
            });
            list.value.pagination_taxes.page = data.pagination.page;
          });
        }, 200);
      }
    };
    const loadTaxesDetails = (taxes_details_id) => {
      ApiService.query(`/api/taxes_detail`, {
        params: {
          page: 1,
          per_page: 100,
          offices: [form.value.office_id],
          taxes: [taxes_details_id],
        },
      })
        .then(({ data }) => {
          data.taxes_details.forEach(function (value) {
            list.value.taxesDetails.push({
              id: value.tax_id,
              name: value.code,
            });
          });
          loadValues();
        })
        .catch((e) => {
          console.error("loadTaxes error", e);
        });
    };

    const loadCurrenciesChanges = async (maritime_file_id) => {
      /*if (currency_exchanges.value.length > 0) {
        const val = currency_exchanges.value.find(
          (x) => x.id === form.value.currency_exchange_id
        );
        if (val === undefined || !val) {
          if (
            form.value.currency_exchange_id !== undefined &&
            form.value.currency_exchange_id
          ) {
            const { data: current } = await ApiService.get(
              `/api/currency_exchanges/${form.value.currency_exchange_id}`
            );
            currenciesChangeList.value.push(current);
            store.commit("setCurrencieEchanges", currenciesChangeList.value);
            currenciesChangeList.value = currenciesChangeList.value.filter(
              (x) => x.origin_currency_id === form.value.currency_id
            );
            currenciesChangeList.value.forEach((x) => {
              if (x.origin_currency_id === form.value.currency_id) {
                form.value.currency_name = x.origin_currency.name;
                return;
              }
            });
          } else {
            currenciesChangeList.value = currency_exchanges.value;
            currenciesChangeList.value = currenciesChangeList.value.filter(
              (x) => x.origin_currency_id === form.value.currency_id
            );
            currenciesChangeList.value.forEach((x) => {
              if (x.origin_currency_id === form.value.currency_id) {
                form.value.currency_name = x.origin_currency.name;
                return;
              }
            });
          }
        } else {
          currenciesChangeList.value = currency_exchanges.value;
          currenciesChangeList.value = currenciesChangeList.value.filter(
            (x) => x.origin_currency_id === form.value.currency_id
          );
          currenciesChangeList.value = currency_exchanges.value;
          currenciesChangeList.value.forEach((x) => {
            if (x.origin_currency_id === form.value.currency_id) {
              form.value.currency_name = x.origin_currency.name;
              return;
            }
          });
        }
      } else {*/
      const { data } = await ApiService.query(`/api/currency_exchanges/`, {
        params: {
          maritime_files: [maritime_file_id],
          order_by_id: "DESC",
          page: 1,
          per_page: 100,
        },
      });
      currenciesChangeList.value = data.currency_exchanges;
      if (data.currency_exchanges.length === 0) {
        return;
      }
      if (
        form.value.currency_exchange_id === "" ||
        !form.value.currency_exchange_id
      ) {
        await loadValues();
        return;
      }
      const val = data.currency_exchanges.find(
        (x) => x.id === form.value.currency_exchange_id
      );

      if (val === undefined || !val) {
        const { data: current } = await ApiService.get(
          `/api/currency_exchanges/${form.value.currency_exchange_id}`
        );
        currenciesChangeList.value.push(current);
        store.commit("setCurrencieEchanges", currenciesChangeList.value);
        currenciesChangeList.value = currenciesChangeList.value.filter(
          (x) => x.origin_currency_id === form.value.currency_id
        );
        currenciesChangeList.value.push(current);
        currenciesChangeList.value.forEach((x) => {
          if (x.origin_currency_id === form.value.currency_id) {
            form.value.currency_name = x.origin_currency.name;
            return;
          }
        });
      } else {
        store.commit("setCurrencieEchanges", data.currency_exchanges);
      }
      // }
      loadValues();
    };
    const loadCurrencies = async () => {
      if (list.value.currencies.length < 5) {
        const { data } = await ApiService.get(
          `/api/currencies/lists?page=1&per_page=5`
        );
        list.value.currencies = data.currencies;
        store.commit("setCurrency", data.currencies);
        const val = list.value.currencies.find(
          (y) => y.id === form.value.currency_id
        );
        if (val === undefined || !val) {
          const { data: currency } = await ApiService.get(
            `/api/currencies/${form.value.currency_id}`
          );
          list.value.currencies.push(currency);
          store.commit("setCurrency", list.value.currencies);
        }
      }
    };
    const loadBillingSerie = async () => {
      store.commit("setLoading", false);
      list.value.billing_serie = [];
      form.value.billing_serie_id = "";
      const { data } = await ApiService.query(`/api/billing_serie/lists`, {
        params: {
          page: 1,
          per_page: 5,
          companies: [form.value.company_id],
        },
      });
      const dta = [] as any;
      data.billing_serie.forEach(function (value) {
        dta.push({
          name: value.code,
          id: value.id,
        });
      });
      list.value.billing_serie = dta;
      if (list.value.billing_serie.length === 1) {
        form.value.billing_serie_id = list.value.billing_serie[0].id;
      }
    };

    const searchBillingSerie = (query) => {
      if (query !== "") {
        setTimeout(() => {
          list.value.billing_serie = list.value.billing_serie.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );

          ApiService.query(`/api/billing_serie/lists`, {
            params: {
              per_page: 5,
              name: query,
              companies: [form.value.company_id],
              page: list.value.pagination_serie.page,
            },
          }).then(({ data }) => {
            const dta = [] as any;
            data.billing_serie.forEach(function (value) {
              dta.push({
                name: value.code,
                id: value.id,
              });
            });
            list.value.billing_serie = dta;
            list.value.pagination_serie.page = data.pagination.page;
          });
        }, 200);
      }
    };

    const loadItems = async () => {
      if (props.billing === undefined) {
        const { data } = await ApiService.get(
          `/api/billing/` + route.params.id
        );
        form.value = {
          ...data,
          all_data: {
            edi_paths: data.edi_paths,
          },
        };
        form.value.office_name = data.office.name;
        form.value.office_id = data.office.id;
        form.value.group_by_concepts = true;
        form.value.currency_id =
          data.billing_lines.length > 0 ? data.billing_lines[0].currency_id : 3;
        form.value.currency_name =
          data.billing_lines.length > 0
            ? data.billing_lines[0].currency.name
            : "Euro";
        form.value.maritime_file_id = data.maritime_file_id;
        form.value.customer = data.customer_info.id;
        form.value["customer_info"] = data.customer_info;
        form.value.header_observations = data.header_observations;
        form.value.footer_observations = data.footer_observations;
        list.value.currencies.push(data.billing_lines[0].currency);
        store.commit("addOffice", data.office);
        store.commit("addCustomer", data.customer_info);
        form.value.company_id = data.office.company_id;
        store.commit("addPaymentDetail", data.payment_type_detail);
        if (data.bl_id) {
          form.value.bl_id = data.bl_id;
          store.commit("updateBls", data.bills_of_lading);
          form.value.type_traffic = data.type_traffic
            ? data.type_traffic
            : data.bills_of_lading.bookings[0].maritime_voyage.trip_type;
          data.bills_of_lading.bookings.forEach((x) => {
            list.value.bookins.push(x);
          });
        }
        if (data.currency_exchange) {
          currency_exchanges.value.push(data.currency_exchange);
        }
        list.value.customer.push(data.customer_info);
        list.value.billing_serie.push(data.billing_serie);
        list.value.taxes.push(data.tax);
        if (data.maritime_file_id) {
          store.commit("updateMaritimeFiles", data.maritime_file);
          form.value.type_traffic = data.type_traffic
            ? data.type_traffic
            : data.maritime_file.maritime_voyages.length
            ? data.maritime_file.maritime_voyages[0].trip_type
            : "";
          data.maritime_file.maritime_voyages.forEach((x) => {
            x.bookings.forEach((y) => {
              y.bills_of_lading.forEach((z) => {
                store.commit("updateBls", z);
              });
            });
          });
          loadCurrenciesChanges(data.maritime_file_id);
        } else {
          loadCurrenciesChanges(
            data.bills_of_lading.bookings[0].maritime_voyage.maritime_file_id
          );
        }
        store.commit("setExpirationDates", form.value.expiration_dates);
        store.commit("setPaymentDate", form.value.billing_payments);
        if (
          data.customer_info !== undefined &&
          data.customer_info !== null &&
          data.customer_info.addresses.length > 0
        ) {
          data.customer_info.addresses.forEach((x) => {
            const address = `${x.street}, ${x.number}, ${x.zip_code}`
              .replace("null,", "")
              .replace("null", "");
            const id = list.value.address.length + 1;
            list.value.address.push({
              id: id,
              name: address,
            });
          });
        }
        getBillingStatus(data.billing_status);
      } else {
        const data = props.billing.all_data;
        form.value = data;
        form.value.header_observations = data.header_observations;
        form.value.footer_observations = data.footer_observations;
        form.value.currency_id =
          props.billing.all_data.billing_lines[0].currency_id;
        store.commit("setExpirationDates", form.value.expiration_dates);
        store.commit("setPaymentDate", form.value.billing_payments);
        list.value.customer.push();
        list.value.currencies.push(
          props.billing.all_data.billing_lines[0].currency
        );
        list.value.billing_serie.push(data.billing_serie);
        list.value.payment_type_detail.push(data.payment_type_detail);
        store.commit("addPaymentDetail", data.payment_type_detail);
        list.value.taxes.push(data.tax);
        store.commit("addCustomer", data.customer_info);
        if (
          data.customer_info !== undefined &&
          data.customer_info !== null &&
          data.customer_info.addresses.length > 0
        ) {
          data.customer_info.addresses.forEach((x) => {
            const address = `${x.street}, ${x.number}, ${x.zip_code}`
              .replace("null,", "")
              .replace("null", "");
            const id = list.value.address.length + 1;
            list.value.address.push({
              id: id,
              name: address,
            });
          });
        }
        if (data.maritime_file_id) {
          loadCurrenciesChanges(data.maritime_file_id);
        } else {
          loadCurrenciesChanges(
            data.bills_of_lading.bookings[0].maritime_voyage.maritime_file_id
          );
        }
        getBillingStatus(data.billing_status);
      }
    };

    const getTemplates = async () => {
      const { data } = await ApiService.query("/api/template/lists", {
        params: {
          office_ids: [form.value.office_id],
        },
      });
      templates.value = data.templates;
      await reloadObservations();
    };

    const separateTemplates = () => {
      if (templates.value.length === 0) {
        form.value.header_observations = "";
        form.value.var_header_observations = null;
        form.value.footer_observations = "";
        form.value.var_footer_observations = null;
        copy_tempplate.value.var_header_observations = null;
        copy_tempplate.value.var_footer_observations = null;
        copy_tempplate.value.header_observations = "";
        copy_tempplate.value.footer_observations = "";
      } else {
        templates.value.forEach((x) => {
          if (form.value.bl_id) {
            if (
              x.template_type.name ==
              "Observaciones de cabecera de factura con bl"
            ) {
              form.value.header_observations = x.body;
              copy_tempplate.value.header_observations = x.body;
              form.value.var_header_observations = x.template_type.vars;
              copy_tempplate.value.var_header_observations =
                x.template_type.vars;
            } else if (
              x.template_type.name == "Observaciones de pie de factura con bl"
            ) {
              form.value.footer_observations = x.body;
              copy_tempplate.value.footer_observations = x.body;
              form.value.var_footer_observations = x.template_type.vars;
              copy_tempplate.value.var_footer_observations =
                x.template_type.vars;
            }
          } else {
            if (
              x.template_type.name ==
              "Observaciones de cabecera de factura sin bl"
            ) {
              form.value.header_observations = x.body;
              copy_tempplate.value.header_observations = x.body;
              form.value.var_header_observations = x.template_type.vars;
              copy_tempplate.value.var_header_observations =
                x.template_type.vars;
            } else if (
              x.template_type.name == "Observaciones de pie de factura sin bl"
            ) {
              form.value.footer_observations = x.body;
              copy_tempplate.value.footer_observations = x.body;
              form.value.var_footer_observations = x.template_type.vars;
              copy_tempplate.value.var_footer_observations =
                x.template_type.vars;
            }
          }
        });
      }
    };
    const reloadObservations = async (bl = null) => {
      separateTemplates();
      if (form.value.bl_id) {
        await getBlFormValues(bl);
      }
      loadValues();
    };
    const getBlFormValues = async (bl = null) => {
      let current_bl = null as any;
      if (bl) {
        current_bl = bl;
      } else {
        const { data } = await ApiService.get(
          "api/bills_of_lading/" + form.value.bl_id
        );
        current_bl = data;
      }
      if (current_bl) {
        let booking = current_bl.bookings[0];
        form.value.bills_of_lading_number = current_bl.bl_number;
        form.value.bills_of_lading_date = current_bl.bl_date;
        form.value.booking_number = booking.booking_number;
      }
    };
    const createExpirationDate = () => {
      store.commit("addExpirationDate", {
        expiration_date: form.value.expiration_date,
        bill_total: form.value.bill_total,
      });
    };
    const createPaymentDate = () => {
      const payment = {
        payment_date: form.value.bill_date,
        amount: form.value.bill_total,
        currency: "",
        currency_id: form.value.currency_id,
        remarks: "",
      };
      store.commit("addPaymentDate", payment);
    };

    const createConcept = () => {
      reload.value = false;
      form.value.billing_lines.push({
        isNew: true,
        prebilling_id: undefined,
        currency_id: form.value.currency_id,
        fusion_concept: "",
        billing_concept_id: "",
        billing_concept: "",
        provider_id: undefined,
        customer_id: undefined,
        units: undefined,
        unit_amount: undefined,
        total_amount: undefined,
        taxes_detail_id: undefined,
        tax_id: undefined,
      });
      reload.value = true;
    };

    const changeBillingSerie = (value) => {
      if (value) {
        errorMessageBillingSerie.value = "";
      }
    };

    const isValidAllLines = (lines) => {
      let isValid = true;
      lines.forEach((x) => {
        if (
          x.billing_concept_id === "" ||
          x.units === undefined ||
          x.unit_amount === undefined ||
          x.billing_concept === ""
        ) {
          isValid = false;
        }
      });
      return isValid;
    };

    const onSubmit = (status) => {
      if (form.value.billing_lines.length === 0) {
        showPendingForm.value = true;
        dialogValRefused.value = t("iconPendinLines");
        return;
      }
      if (!isValidAllLines(form.value.billing_lines)) {
        showPendingForm.value = true;
        dialogValRefused.value = t("iconPendinLines2");
      }
      if (
        form.value.billing_serie_id === null ||
        form.value.billing_serie_id === ""
      ) {
        errorMessageBillingSerie.value = t("ibilling_series_required");
        return;
      }
      if (form.value.customer === null || form.value.customer === "") {
        errorMessageCustomer.value = t("billing_customer_required");
        return;
      }
      if (
        form.value.reference === "" ||
        form.value.reference === null ||
        form.value.reference === undefined
      ) {
        errorMessageReference.value = t("billing_reference_required");
        return;
      }
      if (form.value.payment_type_detail_id === "") {
        errorMessagePaymentDetail.value = t("payment_details_required");
        return;
      }
      form.value.billing_status = status;
      form.value.billing_payments = billing_dates.value;
      form.value.expiration_dates = billing_expire.value;
      if (!props.showBack && route.params.id) {
        store.commit("setLoadingStatus", true);
        ApiService.put("/api/billing/" + route.params.id, form.value).then(
          function (response) {
            if (status === "E") {
              form.value.bill_number = response.data.bill_number;
            }
            store.dispatch("GET_LOGACTIVITIES_API");
            if (!props.showBack) {
              getBillingStatus(response.data.billing_status);
              // router.push({ name: "billing_management" });
            } else {
              form.value.bill_total = response.data.bill_total;
              getBillingStatus(response.data.billing_status);
            }
            store.commit("setLoadingStatus", false);
          }
        );
      } else {
        // loadValues();
        store.commit("setLoadingStatus", true);
        if (form.value.id !== "") {
          ApiService.put("/api/billing/" + form.value.id, form.value).then(
            function (response) {
              store.dispatch("GET_LOGACTIVITIES_API");
              if (!props.showBack) {
                getBillingStatus(response.data.billing_status);
              } else {
                form.value.bill_number = response.data.format_bill_number;
                form.value.bill_total = response.data.bill_total;
                getBillingStatus(response.data.billing_status);
              }
              store.commit("setLoadingStatus", false);
            }
          );
        } else {
          if (props.showBack && props.billing !== undefined) {
            form.value.bl_id = props.billing.bl_id;
          } else if (props.showBack) {
            form.value.bl_id = props.bl_id;
          }
          ApiService.post("/api/billing", form.value).then(function (response) {
            form.value.id = response.data.id;
            form.value.bill_number = response.data.format_bill_number;
            getBillingStatus(response.data.billing_status);
            store.commit("setLoadingStatus", false);
            // router.push({ name: `bill_register`, params: { id: response.data.id } })
          });
        }
      }
    };

    const getBillingStatus = (status) => {
      form.value.billing_status = status;
      switch (status) {
        case "I":
          show.value = {
            show_emit_button: true,
            show_draft_button: true,
            show_contab_button: false,
            show_null_button: false,
            show_print_button: true,
            show_discard_button: true,
            show_correct_button: false,
          };
          break;
        case "E":
          show.value = {
            show_emit_button: false,
            show_draft_button: false,
            show_contab_button: true,
            show_null_button: true,
            show_print_button: true,
            show_discard_button: false,
            show_correct_button: true,
          };
          break;
        case "C":
          show.value = {
            show_emit_button: false,
            show_draft_button: false,
            show_contab_button: false,
            show_null_button: false,
            show_print_button: true,
            show_discard_button: false,
            show_correct_button: true,
          };
          break;
        case "A":
          show.value = {
            show_emit_button: false,
            show_draft_button: false,
            show_contab_button: false,
            show_null_button: false,
            show_print_button: true,
            show_discard_button: false,
            show_correct_button: false,
          };
          break;
      }
    };

    onMounted(async () => {
      store.commit("setLoadingStatus", true);
      store.commit("emptyPaymentDate");
      store.commit("clearExpirationDate");
      store.commit("clearFileStored");
      await setCurrentPageBreadcrumbs("bill_register", []);
      const date = new Date();
      const hoy = moment(date).format("YYYY-MM-DD");
      form.value.accouting_date = hoy;
      form.value.bill_date = hoy;
      if (route.params.id && (!props.showBack || props.billing)) {
        try {
          await loadItems();
        } catch (e) {
          store.commit("setLoadingStatus", false);
          console.error(e);
        }
      } else if (billing_lines.value.length > 0) {
        try {
          await loadCurrencies();
          form.value.reference = !props.bl
            ? ""
            : props.bl.reference
            ? props.bl.reference
            : props.bl.contractor_refrence
            ? props.bl.contractor_refrence
            : props.bl.bl_number;
          form.value.office_id = props.maritime_file.office.id;
          form.value.office_name = props.maritime_file.office.name;
          form.value.company_id = props.maritime_file.office.company_id;
          store.commit("addOffice", props.maritime_file.office);
          let find_taxes_detail = undefined as any;
          billing_lines.value.forEach((x) => {
            find_taxes_detail = x.prebilling_amounts.find(
              (y) => y.taxes_detail !== null
            );
            if (find_taxes_detail !== undefined) {
              return;
            }
          });
          billing_lines.value.forEach((x) => {
            const index = list.value.currencies.findIndex(
              (y) => x.id === x.prebilling_amounts[0].currency_id
            );
            if (index === -1) {
              list.value.currencies.push(x.prebilling_amounts[0].currency);
            }
          });

          if (find_taxes_detail !== undefined) {
            form.value.tax_id = find_taxes_detail.taxes_detail.tax_id;
            list.value.taxes.push({
              id: find_taxes_detail.taxes_detail.tax.id,
              name: find_taxes_detail.taxes_detail.tax.code,
              taxe_detail_id: find_taxes_detail.taxes_detail.id,
            });
          }
          await loadCurrenciesChanges(
            props.maritime_file_id !== null
              ? props.maritime_file_id
              : maritimeFile.value.id
          );
          let address = "";
          let total = 0;

          form.value.currency_id =
            billing_lines.value[0].prebilling_amounts[0].currency_id;

          currenciesData.value.push(
            billing_lines.value[0].prebilling_amounts[0].currency
          );
          if (is_diferent_currency(billing_lines.value)) {
            billing_lines.value.forEach(function (value: any) {
              const currenci_exchange = currenciesChangeList.value.find(
                (x) =>
                  x.origin_currency_id ===
                  value.prebilling_amounts[0].currency_id
              );
              let sub_total_amount = 0;
              value.prebilling_amounts.forEach((x) => {
                if (x.cost_type_id === 1) {
                  sub_total_amount =
                    sub_total_amount + parseFloat(x.total_amount);
                }
              });
              if (currenci_exchange) {
                total =
                  total +
                  sub_total_amount *
                    parseFloat(currenci_exchange.exchange_sale);
              } else {
                total = total + sub_total_amount;
              }
              if (
                value.prebilling_concept.cost_type_id === 1 ||
                value.prebilling_concept.cost_type_id === 4
              ) {
                form.value.billing_lines.push({
                  billing_concept_id: value.prebilling_concept.id,
                  prebilling_id: value.id,
                  billing_concept: value.billing_concept,
                  billing_concept_info: value.prebilling_concept,
                  fusion_concept: value.fusion_concept,
                  provider_id: value.provider_id,
                  customer_id: value.customer_id,
                  customer: value.customer,
                  currency_id: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].currency_id
                    : 3,
                  units: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].units
                    : 1,
                  unit_amount: value.prebilling_amounts.length
                    ? sub_total_amount / value.prebilling_amounts[0].units
                    : sub_total_amount,
                  total_amount: sub_total_amount,
                  taxes_detail: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].taxes_detail
                    : null,
                  taxes_detail_id: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].taxes_detail_id
                    : 1,
                });
              }
            });
          } else {
            billing_lines.value.forEach(function (value: any) {
              const currenci_exchange = currenciesChangeList.value.find(
                (x) =>
                  x.origin_currency_id ===
                  value.prebilling_amounts[0].currency_id
              );
              let sub_total_amount = 0;
              value.prebilling_amounts.forEach((x) => {
                if (x.cost_type_id === 1) {
                  sub_total_amount =
                    sub_total_amount + parseFloat(x.total_amount);
                }
              });
              if (currenci_exchange) {
                total =
                  total +
                  sub_total_amount *
                    parseFloat(currenci_exchange.exchange_sale);
              } else {
                total = total + sub_total_amount;
              }
              if (
                value.prebilling_concept.cost_type_id === 1 ||
                value.prebilling_concept.cost_type_id === 4
              ) {
                form.value.billing_lines.push({
                  billing_concept_id: value.prebilling_concept.id,
                  prebilling_id: value.id,
                  billing_concept: value.billing_concept,
                  billing_concept_info: value.prebilling_concept,
                  fusion_concept: value.fusion_concept,
                  provider_id: value.provider_id,
                  customer_id: value.customer_id,
                  customer: value.customer,
                  currency_id: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].currency_id
                    : 3,
                  units: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].units
                    : 1,
                  unit_amount: value.prebilling_amounts.length
                    ? sub_total_amount / value.prebilling_amounts[0].units
                    : sub_total_amount,
                  total_amount: sub_total_amount,
                  taxes_detail: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].taxes_detail
                    : null,
                  taxes_detail_id: value.prebilling_amounts.length
                    ? value.prebilling_amounts[0].taxes_detail_id
                    : 1,
                });
              }
            });
          }
          form.value.bill_total = total;
          form.value.bl_id = billing_lines.value[0].bl_id
            ? billing_lines.value[0].bl_id
            : null;
          form.value.maritime_file_id = billing_lines.value[0].maritime_file_id
            ? billing_lines.value[0].maritime_file_id
            : null;
          if (form.value.maritime_file_id) {
            store.commit(
              "updateMaritimeFiles",
              billing_lines.value[0].maritime_file
            );
            form.value.type_traffic =
              billing_lines.value[0].maritime_file.maritime_voyages[0].trip_type;
            form.value.customer =
              billing_lines.value[0].maritime_file.customer_id;
            store.commit(
              "addCustomer",
              billing_lines.value[0].maritime_file.customer
            );
            changeCustomer(form.value.customer);
          }
          if (form.value.bl_id) {
            store.commit("updateBls", billing_lines.value[0].bills_of_lading);
            billing_lines.value[0].bills_of_lading.bookings.forEach((x) => {
              list.value.bookins.push(x);
            });
            form.value.type_traffic =
              billing_lines.value[0].bills_of_lading.bookings[0].maritime_voyage.trip_type;
            if (props.bl.customer_id) {
              form.value.customer = props.bl.customer_id;
              store.commit("addCustomer", props.bl.customer);
            }
            changeCustomer(form.value.customer);
          }

          const payment_detail = paymentsDetails.value.find(
            (x) => (x.id = form.value.payment_type_detail_id)
          );
          if (payment_detail) {
            date.setDate(date.getDate() + payment_detail.payment_number);
            form.value.expiration_date = moment(date).format("YYYY-MM-DD");
          }
          show.value = {
            show_emit_button: false,
            show_draft_button: true,
            show_contab_button: false,
            show_null_button: false,
            show_print_button: false,
            show_discard_button: false,
            show_correct_button: false,
          };
        } catch (e) {
          store.commit("setLoadingStatus", false);
          console.error(e);
        }
      } else if (props.prebillings.length > 0) {
        try {
          form.value.office_id = props.maritime_file
            ? props.maritime_file.office_id
            : "";

          form.value.office_name = props.maritime_file
            ? props.maritime_file.office.name
            : "";
          store.commit("addOffice", props.maritime_file.office);
          form.value.company_id = props.maritime_file.office.company_id;
          form.value.customer = props.maritime_file
            ? props.maritime_file.customer_id
            : "";
          store.commit("addCustomer", props.maritime_file.customer);
          if (form.value.customer && form.value.customer !== "") {
            changeCustomer(form.value.customer);
          }
          let address = "";
          let total = 0;
          props.prebillings.forEach((x) => {
            for (let i = 0; i < x.prebilling_amounts.length; i++) {
              if (x.prebilling_amounts[i].taxes_detail) {
                form.value.tax_id = x.prebilling_amounts[i].taxes_detail.tax_id;
                list.value.taxes.push({
                  id: x.prebilling_amounts[i].taxes_detail.tax.id,
                  name: x.prebilling_amounts[i].taxes_detail.tax.code,
                  taxe_detail_id:
                    x.prebilling_amounts[i].taxes_detail.tax.taxes_detail_id,
                });
                return;
              }
            }
          });
          form.value.currency_id =
            props.prebillings[0].prebilling_amounts[0].currency_id;
          props.prebillings.forEach(function (value: any) {
            let sub_total_amount = 0;
            value.prebilling_amounts.forEach((x) => {
              if (x.cost_type_id === 1) {
                sub_total_amount =
                  sub_total_amount + parseFloat(x.total_amount);
              }
            });
            total = total + sub_total_amount;
            if (
              value.prebilling_concept.cost_type_id === 1 ||
              value.prebilling_concept.cost_type_id === 4
            ) {
              form.value.billing_lines.push({
                billing_concept_id: value.prebilling_concept.id,
                prebilling_id: value.id,
                billing_concept: value.prebilling_concept.name,
                billing_concept_info: value.prebilling_concept,
                provider_id: value.provider_id,
                fusion_concept: value.fusion_concept,
                customer_id: value.customer_id,
                customer: value.customer,
                currency_id: value.prebilling_amounts.length
                  ? value.prebilling_amounts[0].currency_id
                  : 3,
                units: value.prebilling_amounts.length
                  ? value.prebilling_amounts[0].units
                  : 1,
                unit_amount: value.prebilling_amounts.length
                  ? sub_total_amount / value.prebilling_amounts[0].units
                  : sub_total_amount,
                total_amount: sub_total_amount,
                taxes_detail: value.prebilling_amounts.length
                  ? value.prebilling_amounts[0].taxes_detail
                  : null,
                taxes_detail_id: value.prebilling_amounts.length
                  ? value.prebilling_amounts[0].taxes_detail_id
                  : 1,
              });
            }
          });
          form.value.bill_total = total;
          const payment_detail = paymentsDetails.value.find(
            (x) => (x.id = form.value.payment_type_detail_id)
          );
          if (payment_detail) {
            date.setDate(date.getDate() + payment_detail.payment_number);
            form.value.expiration_date = moment(date).format("YYYY-MM-DD");
          }
          await loadCurrencies();
          show.value = {
            show_emit_button: false,
            show_draft_button: true,
            show_contab_button: false,
            show_null_button: false,
            show_print_button: false,
            show_discard_button: false,
            show_correct_button: false,
          };
        } catch (e) {
          store.commit("setLoadingStatus", false);
          console.error(e);
        }
      } else {
        await getCurrencybyId();
        show.value = {
          show_emit_button: false,
          show_draft_button: true,
          show_contab_button: false,
          show_null_button: false,
          show_print_button: false,
          show_discard_button: false,
          show_correct_button: false,
        };
      }
      try {
        getSearchs();
        await getTemplates();
        store.commit("setLoadingStatus", false);
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    });

    const getSearchs = () => {
      searchPaymentDetails.value.entities = [parseInt(form.value.customer)];
      providerSearch.value.offices_id = [parseInt(form.value.office_id)];
      customerSearch.value.offices_id = [parseInt(form.value.office_id)];
      shipOwnerSearch.value.offices_id = [parseInt(form.value.office_id)];
      blMfSearch.value.office_id = [parseInt(form.value.office_id)];
    };

    const is_diferent_currency = (dta) => {
      const element = dta[0].prebilling_amounts[0];
      let val = false;
      dta.forEach((x) => {
        if (
          x.prebilling_amounts[0].currency_id !== null &&
          x.prebilling_amounts[0].currency_id !== element.currency_id
        ) {
          val = true;
        }
      });
      return val;
    };
    const removeExpirationDate = (index) => {
      billing_expire.value.splice(index, 1);
    };
    const removeBillingLines = (index) => {
      form.value.billing_lines.splice(index, 1);
    };
    onUnmounted(() => {
      store.commit("clearCurrencieEchanges");
      store.commit("clearExpirationDate");
      store.commit("emptyPaymentDate");
      store.commit("clearTempBill");
    });
    const discard = () => {
      if (!props.showBack && route.params.id) {
        if (form.value.id !== undefined) {
          ApiService.delete(
            "/api/billing/" + form.value.id + "?force_delete=false"
          ).then(function (response) {
            router.push({ name: "billing_management" });
          });
        }
      } else {
        if (form.value.id !== undefined) {
          ApiService.delete(
            "/api/billing/" + form.value.id + "?force_delete=false"
          ).then(function (response) {
            emit("actionBack");
          });
        } else {
          emit("actionBack");
        }
      }
    };

    const changeCustomer = (value) => {
      if (value) {
        form.value.bill_address = "";
        list.value.address = [];
        form.value.customer = value;
        searchPaymentDetails.value.entities = [value];
        store.commit("clearPaymentDetails");
        form.value.payment_type_detail_id = "";
        const val = Array.isArray(customerList.value)
          ? customerList.value.find((x) => x.id === value)
          : customerList.value;
        if (val) {
          form.value["customer_info"] = val;
          let address = "";
          if (val.addresses && val.addresses.length > 0) {
            let addres_customer = null as any;
            val.addresses.forEach((x) => {
              const z = x.address_types.find((y) => y.id === 4);
              if (z) {
                addres_customer = x;
              }
              if (addres_customer) {
                if (addres_customer.street) {
                  address += `${addres_customer.street}`;
                }
                if (addres_customer.number) {
                  address += `, ${addres_customer.number}`;
                }
                if (addres_customer.zip_code) {
                  address += `, ${addres_customer.zip_code}`;
                }
              }
              if (address !== "") {
                const id = list.value.address.length + 1;
                list.value.address.push({
                  id: id,
                  name: address.replace("null,", "").replace("null", ""),
                });
              }
            });
            if (list.value.address.length === 1) {
              form.value.bill_address = list.value.address[0].name;
            }
          } else {
            list.value.address = [];
          }
          loadValues();
        }
      } else {
        form.value.customer = value;
        loadValues();
      }
    };

    const onChangeCurrency = async (value) => {
      const current = currenciesChangeList.value.find((x) => x.id === value);
      const inverse = currency_exchanges.value.find(
        (x) =>
          x.origin_currency_id === current.exchange_currency_id &&
          x.exchange_currency_id === current.origin_currency_id
      );
      form.value.currency_exchange_id = value;
      form.value.currency_id = current.exchange_currency_id;
      form.value.currency_name = current.exchange_currency.name;
      form.value.bill_total = 0;
      form.value.billing_lines.forEach((x) => {
        x.unit_amount = x.unit_amount * parseFloat(current.exchange_sale);
        x.unit_amount = parseFloat(x.unit_amount.toFixed(2));
        x.total_amount = x.total_amount * parseFloat(current.exchange_sale);
        x.total_amount = parseFloat(x.total_amount.toFixed(2));
        x.currency_id = current.exchange_currency_id;
        form.value.bill_total += x.total_amount;
      });
      form.value.bill_total = parseFloat(form.value.bill_total.toFixed(2));
      currenciesChangeList.value = currency_exchanges.value.filter(
        (x) => x.origin_currency_id === current.exchange_currency_id
      );
      currenciesChangeList.value.push(current);
      const val = currenciesData.value.find(
        (x) => x.id === form.value.currency_id
      );
      if (val === undefined) {
        const { data } = await ApiService.get(
          `/api/currencies/${form.value.currency_id}`
        );
        list.value.currencies.push(data);
        store.commit("setCurrency", list.value.currencies);
      }
      loadValues();
    };
    const changeTotalLine = (value) => {
      form.value.bill_total = value;
      loadValues();
    };

    const getCurrencybyId = async (id = 3) => {
      const { data } = await ApiService.get(
        `/api/currencies/${form.value.currency_id}`
      );
      list.value.currencies.push(data);
    };
    const changeOffice = async (office_id: any) => {
      form.value.office_id = office_id;
      if (office_id !== undefined || office_id !== null) {
        const office_selected = officesList.value;
        form.value.office_name = office_selected.name;
        form.value.company_id = office_selected.company_id;
        await loadBillingSerie();
        await getTaxes();
        await getTemplates();
        getSearchs();
      }
    };
    const correctFacture = async () => {
      if (!props.showBack && route.params.id) {
        await ApiService.patch("/api/billing/" + route.params.id, {
          billing_status: "A",
        });
        form.value.bill_total = form.value.bill_total * -1;
        const new_list = [] as any;
        const aux = form.value.billing_lines;
        aux.forEach(function (valu: any, key: any) {
          aux[key].unit_amount = parseFloat(valu.unit_amount) * -1;
        });
        aux.forEach((x) => {
          let y = Object.assign({}, x);
          y.unit_amount = 0;
          y.id = "";
          new_list.push(y);
        });
        form.value.billing_lines = aux.concat(new_list);
        form.value.billing_status = "I";
        form.value.bill_number = "";
        form.value.correct_billing_id = form.value.id;
        ApiService.post("/api/billing", form.value).then(function (response) {
          router.push({ name: "billing_management" });
        });
      } else {
        await ApiService.patch("/api/billing/" + form.value.id, {
          billing_status: "A",
        });
        form.value.bill_total = form.value.bill_total * -1;
        const new_list = [] as any;
        const aux = form.value.billing_lines;
        aux.forEach(function (valu: any, key: any) {
          aux[key].unit_amount = parseFloat(valu.unit_amount) * -1;
        });
        aux.forEach((x) => {
          let y = Object.assign({}, x);
          y.unit_amount = 0;
          y.id = "";
          new_list.push(y);
        });
        form.value.billing_lines = aux.concat(new_list);
        form.value.billing_status = "I";
        form.value.bill_number = "";
        form.value.correct_billing_id = form.value.id;
        ApiService.post("/api/billing", form.value).then(function (response) {
          getBillingStatus(form.value.billing_status);
          // router.push({ name: "billing_management" });
        });
      }
    };
    const onChangePaymentDetail = async (val) => {
      if (val) {
        form.value.payment_type_detail_id = val;
        const payment_detail = paymentsDetails.value.find((x) => x.id === val);
        if (payment_detail && form.value.customer) {
          const { data } = await ApiService.get(
            `/api/entities/${form.value.customer}/economicData`
          );
          let economic_data = null as any;
          if (form.value.maritime_file_id) {
            data.economic_data.forEach((x) => {
              if (x.applies_to.filter((y) => y.applies_to === "E").length > 0) {
                economic_data = x;
              }
            });
          } else if (form.value.bl_id) {
            const current_bl = props.bl ? props.bl : form.value.bills_of_lading;
            const current_booking = current_bl.bookings[0];
            data.economic_data.forEach((x) => {
              if (
                x.applies_to.filter(
                  (y) =>
                    y.applies_to === "BLE" &&
                    current_booking.trip_type.code === "E"
                ).length > 0
              ) {
                economic_data = x;
              } else if (
                x.applies_to.filter(
                  (y) =>
                    y.applies_to === "BLI" &&
                    current_booking.trip_type.code === "I"
                ).length > 0
              ) {
                economic_data = x;
              }
            });
          }
          if (economic_data) {
            const date = new Date();
            const date_number_expire =
              date.getDate() + economic_data.payment_type_detail.payment_number;
            const mayores = economic_data.payment_days.filter(
              (x) => parseInt(x.day) > date_number_expire
            );
            const menores = economic_data.payment_days.filter(
              (x) => parseInt(x.day) < date_number_expire
            );
            if (mayores.length > 0) {
              mayores.sort((a, b) => parseFloat(a.day) - parseFloat(b.day));
              date.setDate(parseInt(mayores[0].day));
              form.value.expiration_date = moment(date).format("YYYY-MM-DD");
            } else if (menores.length > 0) {
              menores.sort((a, b) => parseFloat(a.day) - parseFloat(b.day));
              date.setMonth(date.getMonth() + 1, parseInt(menores[0].day));
              form.value.expiration_date = moment(date).format("YYYY-MM-DD");
            }
          } else {
            const date = new Date();
            const date_number_expire =
              date.getDate() + payment_detail.payment_number;
            date.setDate(date_number_expire);
            form.value.expiration_date = moment(date).format("YYYY-MM-DD");
          }

          loadValues();
        }
      } else {
        form.value.payment_type_detail_id = val;
      }
    };

    const changeMFile = async (val) => {
      if (val) {
        form.value.maritime_file_id = val;
        form.value.currency_exchange_id = "";
        form.value.bl_id = null;
        const { data } = await ApiService.get("api/maritimefiles/" + val);
        const mf = data;
        if (mf !== null && mf !== undefined && mf.maritime_voyages.length) {
          form.value.type_traffic = mf.maritime_voyages[0].trip_type;
        }

        store.commit("clearCurrencieEchanges");
        await loadCurrenciesChanges(val);
        loadValues();
      } else {
        form.value.maritime_file_id = val;
      }
    };

    const changeTaxes = async (val) => {
      await loadTaxesDetails(val);
    };

    const searchCurrencies = async (query) => {
      ///api/currencies/lists
      if (query !== "") {
        setTimeout(() => {
          list.value.currencies = list.value.currencies.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          ApiService.query(`api/currencies/lists`, {
            params: {
              per_page: 5,
              name: query,
            },
          }).then(({ data }) => {
            list.value.currencies = data.currencies;
            list.value.pagination_currencies.page = data.pagination.page + 1;
          });
        }, 200);
      }
    };

    const changeBls = async (value) => {
      if (value) {
        form.value.maritime_file_id = null;
        form.value.currency_exchange_id = "";
        form.value.bl_id = value;
        store.commit("clearCurrencieEchanges");
        const { data } = await ApiService.get("api/bills_of_lading/" + value);
        const booking = data.bookings[0];
        if (booking) {
          form.value.type_traffic = booking.maritime_voyage.trip_type;
          await loadCurrenciesChanges(booking.maritime_voyage.maritime_file_id);
        }
        await reloadObservations(data);
      } else {
        form.value.bl_id = value;
      }
    };

    /*watch(form.value["bill_date"], () => {
      alert('actualizando valores')
      loadValues();
    });*/
    const changeSense = (value) => {
      form.value.bill_sense = value;
      store.commit("clearCustomer");
      if (value === "E") {
        form.value.bill_number_provider = "";
        form.value.cost_type_id = 1;
      } else {
        form.value.cost_type_id = 3;
      }
      loadValues();
    };

    const generateAttachmentEquipmentInvolves = async (billingID) => {
      return await ApiService.query(
        "/api/billing/attachment_equipments_involves",
        {
          params: {
            billing_id: billingID,
          },
        }
      );
    };

    return {
      form,
      logs,
      show,
      route,
      router,
      list,
      reload,
      dataObject,
      activeName,
      confirm_cancel,
      billing_expire,
      billing_dates,
      office_loading,
      currenciesChangeList,
      bl_list,
      showPendingForm,
      templates,
      store,
      errorMessageBillingSerie,
      errorMessageCustomer,
      errorMessagePaymentDetail,
      errorMessageReference,
      searchPaymentDetails,
      customerSearch,
      providerSearch,
      dialogValRefused,
      user,
      currency_exchanges,
      customerList,
      filterByUserOffice,
      blMfSearch,
      errorMessageProvider,
      billing_lines,
      getStatusName,
      createPaymentDate,
      createExpirationDate,
      createConcept,
      onChangeIdHeader,
      onChangeIdFooter,
      onSubmit,
      contabilizeFacture,
      devolution,
      devolution_ok,
      generate_pdf,
      onOfficeChange,
      removeExpirationDate,
      removeBillingLines,
      discard,
      changeCustomer,
      onChangeCurrency,
      changeTotalLine,
      changeOffice,
      correctFacture,
      onChangePaymentDetail,
      changeMFile,
      loadBillingSerie,
      searchBillingSerie,
      searchTaxes,
      loadTaxes,
      changeTaxes,
      onClickTaxes,
      loadCurrenciesChanges,
      searchCurrencies,
      changeBls,
      changeBillingSerie,
      changeSense,
      loadValues,
      sendEmail,
      sendEmailForm,
      generateAttachmentEquipmentInvolves,
    };
  },
};
