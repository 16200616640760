
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import BookingSelect from "@/components/catalogs-select/BookingSelect.vue";
import BLsSelect from "@/components/catalogs-select/BLsSelect.vue";
import EquipmentsSelect from "@/components/catalogs-select/EquipmentsSelect.vue";
import ShiploadSelect from "@/components/catalogs-select/ShiploadSelect.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default {
  name: "Dispatches",
  components: {
    OfficeSelect,
    MaritimeVoyageSelect,
    BookingSelect,
    BLsSelect,
    EquipmentsSelect,
    ShiploadSelect,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const search = ref("");
    const routeId = ref(route.params.id);
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const bookingTableData = computed(() => store.getters.Bookings);
    const shiploadTableData = computed(() => store.getters.BookingsBLTable);
    const summary = ref(undefined) as any;
    const dataObject = ref([]) as any;
    const filters = ref({
      customs_document_number: "",
      maritime_voyage_id: [] as any,
      office_id: [] as any,
      booking_id: [] as any,
      bl_id: [] as any,
      equipment_id: [] as any,
      shipload_id: [] as any,
    });
    const tableButtons = ref([]);
    const header = ref([
      { column: "dispatch_date", key: "dispatch_date", size: "extra_small" },
      { column: "iregistration_number", key: "registration_number" },
      { column: "igoods", key: "goods_description" },
      { column: "filesTypeAss", key: "associated_doc_type" },
      { column: "customs_situation_document", key: "customs_situation" },
      { column: "customs_document_number", key: "customs_document_number" },
      {
        column: "description_document",
        key: "doc_description",
        size: "extra_large",
      },
      { column: "mask", key: "mask", size: "extra_large" },
    ]);
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.user_preferences.items_per_page,
      last_page: 1,
      total: 1,
    });

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const updateBookings = (value) => {
      filters.value.booking_id = value;
    };

    const updateBLs = (value) => {
      filters.value.bl_id = value;
    };

    const onSearch = () => {
      serverSearch();
    };

    const exportAll = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/dispatches`, {
        params: {
          page: 1,
          per_page: 0,
          maritime_files: [maritimeFileId.value],
        },
      }).then(({ data }) => {
        let dataExport = [] as any;
        data.dispatches.forEach((x) => {
          dataExport.push({
            id: x.id,
            dispatch_date: x.dispatch_date,
            registration_number:
              x.shipload.equipment_involved.registered_equipment
                .registration_number,
            goods_description: x.shipload.goods.description,
            associated_doc_type: x.customs_situation_document_id
              ? x.customs_situation_document.associated_doc_type
              : "",
            customs_situation: x.customs_situation_document_id
              ? x.customs_situation_document.customs_situation
              : "",
            customs_document_number: x.customs_document_number,
            doc_description: x.customs_situation_document_id
              ? x.customs_situation_document.doc_description
              : "",
            mask: x.customs_situation_document_id
              ? x.customs_situation_document.mask
              : "",
          });
        });

        ApiService.setResponseTypeBlob();
        let headersNames: string[] = [];
        let headersKeys: string[] = [];
        header.value.forEach((element) => {
          headersNames.push(t(element.column));
          headersKeys.push(element.key);
        });
        ApiService.post(`/api/export`, {
          params: {
            thsNames: headersNames,
            ths: headersKeys,
            data: dataExport,
          },
        })
          .then((data) => {
            let fileUrl = window.URL.createObjectURL(data);
            let fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", t("tdispatches") + `.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
            store.commit("setLoadingStatus", false);
          })
          .catch((error) => {
            console.log(error);
            store.commit("setLoadingStatus", false);
          });
        ApiService.setResponseTypeJson();
      });
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(
        `/api/dispatches?page=${pagination.value.page}&per_page=${pagination.value.rowsPerPage}`,
        {
          params: {
            page: pagination.value.page,
            per_page: pagination.value.rowsPerPage,
            maritime_files: [maritimeFileId.value],
            customs_document_number: filters.value.customs_document_number,
            offices: filters.value.office_id,
            maritime_voyages: filters.value.maritime_voyage_id,
            bookings: filters.value.booking_id,
            bls: filters.value.bl_id,
            equipment_involveds: filters.value.equipment_id,
            shiploads: filters.value.shipload_id,
          },
        }
      ).then(({ data }) => {
        pagination.value.total = data.pagination.total;
        pagination.value.page = data.pagination.current_page;
        pagination.value.last_page = data.pagination.last_page;

        while (dataObject.value.length > 0) {
          dataObject.value.pop();
        }
        data.dispatches.forEach((x) => {
          dataObject.value.push({
            id: x.id,
            dispatch_date: x.dispatch_date,
            registration_number:
              x.shipload.equipment_involved.registered_equipment
                .registration_number,
            goods_description: x.shipload.goods.description,
            associated_doc_type: x.customs_situation_document_id
              ? x.customs_situation_document.associated_doc_type
              : "",
            customs_situation: x.customs_situation_document_id
              ? x.customs_situation_document.customs_situation
              : "",
            customs_document_number: x.customs_document_number,
            doc_description: x.customs_situation_document_id
              ? x.customs_situation_document.doc_description
              : "",
            mask: x.customs_situation_document_id
              ? x.customs_situation_document.mask
              : "",
          });
        });
        store.commit("setLoadingStatus", false);
      });
    };

    onMounted(() => {
      //console.clear();
      if (maritimeFileId.value === undefined) {
        store.commit("setMaritimeFileId", routeId);
      }
      serverSearch();
    });

    return {
      filters,
      search,
      header,
      pagination,
      tableButtons,
      dataObject,
      bookingTableData,
      shiploadTableData,
      summary,
      onSearch,
      serverSearch,
      setItemsPerPage,
      currentPageChange,
      updateBookings,
      updateBLs,
      user,
      exportAll,
    };
  },
};
