
import { computed, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "ParticipantsTE",
  props: {
    tableData: {
      type: Array,
      default: [] as any,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement", "updateElement"],

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "bookings: create") !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "bookings: update") !== -1
      );
    });

    const activeNames = ref(["1"]);
    const bookingTableData = computed(() => store.getters.Bookings);
    const entityData = computed(() => store.getters.Entity);
    const participantTypeData = computed(() => store.getters.ParticipantType);
    const elements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const participantType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "ientity", prop: "entity_id" },
      { label: "iparticipantType", prop: "participant_type_id" },
      { label: "ientitydata", prop: "entity_data" },
    ]);
    const headerTable = ref(headers.value);

    const getElements = () => {
      if (!entityData.value.length) {
        ApiService.query("/api/entities/", {
          params: {
            per_page: 10,
          },
        }).then(({ data }) => {
          store.commit("setEntity", data);
          elements.value.list = data;
          elements.value.options = data;
        });
      } else {
        elements.value.list = entityData.value;
        elements.value.options = entityData.value;
      }
      props.tableData.forEach((x) => {
        const value = elements.value.options.find((y) => y.id === x.entity_id);
        if (!value && x.entity_id !== undefined) {
          ApiService.get(`/api/entities/${x.entity_id}`).then(({ data }) => {
            elements.value.list.push(data);
            elements.value.options.push(data);
          });
        }
      });
    };
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        setTimeout(() => {
          elements.value.loading = false;
          ApiService.query(`/api/entities/lists`, {
            params: { per_page: 10, name: query },
          }).then(({ data }) => {
            store.commit("setEntity", []);
            data.entities.forEach((item) => {
              store.commit("addEntity", item);
            });
            elements.value.list = entityData.value;
            elements.value.options = entityData.value;
          });
        }, 200);
      } else {
        elements.value.options = entityData.value;
      }
    };

    const getParticipantType = () => {
      if (!participantTypeData.value.length) {
        ApiService.get("/api/resource/participantsType").then(({ data }) => {
          data.participant_type.forEach((item) => {
            store.commit("addParticipantType", item);
          });
          participantType.value.list = data;
          participantType.value.options = data;
        });
      } else {
        participantType.value.list = participantTypeData.value;
        participantType.value.options = participantTypeData.value;
      }
    };
    const selectParticipantType = (query) => {
      if (query !== "") {
        participantType.value.loading = true;
        setTimeout(() => {
          participantType.value.loading = false;
          ApiService.query(`/api/participant-type/lists`, {
            params: { per_page: 10, name: query },
          }).then(({ data }) => {
            store.commit("setParticipantType", data.participant_type);
            getParticipantType();
            participantType.value.list = participantTypeData.value;
            participantType.value.options = participantTypeData.value;
          });
        }, 200);
      } else {
        participantType.value.options = participantTypeData.value;
      }
    };

    onMounted(() => {
      if (props.mounted) {
        getElements();
        getParticipantType();
      } else {
        elements.value.list = entityData.value;
        elements.value.options = entityData.value;
        participantType.value.list = participantTypeData.value;
        participantType.value.options = participantTypeData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      activeNames,
      bookingTableData,
      elements,
      participantType,
      headers,
      headerTable,
      selectElements,
      selectParticipantType,
    };
  },
};
