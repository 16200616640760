
import moment from "moment";
import {
  defineComponent,
  ref,
  computed,
  watch,
  onUpdated,
  onMounted,
  onUnmounted,
} from "vue";
import { Form, Field } from "vee-validate";
import BookingRoutesTE from "@/components/maritime-file/tables-editable/BookingRoutesTE.vue";
import ParticipantsTE from "@/components/maritime-file/tables-editable/ParticipantsTE.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import BookingSelect from "@/components/catalogs-select/BookingSelect.vue";
import MaritimeTripTypesSelect from "@/components/catalogs-select/MaritimeTripTypesSelect.vue";
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import EquipmentBLSection from "@/components/maritime-file/sections/EquipmentBLSection.vue";
import Documents from "@/components/maritime-file/tabs/Documents.vue";
import Dispatches from "@/components/maritime-file/tabs/booking-tabs/Dispatches.vue";
import BillOfLandingOT from "@/views/maritime_file/BillOfLandingOT.vue";
import SPSelect from "@/components/catalogs-select/SPSelect.vue";
import i18n from "@/core/plugins/i18n";
import TariffHeadingsDialog from "@/components/dialogs/TariffHeadingsDialog.vue";
import CustomerEntityOfficeSelect from "@/components/catalogs-select/CustomerEntityOfficeSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "Booking",
  components: {
    CustomerEntityOfficeSelect,
    TariffHeadingsDialog,
    SPSelect,
    BillOfLandingOT,
    Dispatches,
    MaritimeVoyageSelect,
    MaritimeTripTypesSelect,
    TableBasicSelect,
    ParticipantsTE,
    BookingRoutesTE,
    EquipmentBLSection,
    Documents,
    BookingSelect,
    Field,
  },

  setup() {
    const activeName = ref("first");
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bookings: create")
            .length > 0
        );
      }
      return false;
    });
    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bookings: show").length >
          0
        );
      }
      return false;
    });
    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bookings: update")
            .length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bookings: delete")
            .length > 0
        );
      }
      return false;
    });
    const showBookingRoutes = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "booking routes: index")
            .length > 0
        );
      }
      return false;
    });
    const showEquipmentsInvolveds = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter(
            (x) => x.name === "equipment involved: index"
          ).length > 0
        );
      }
      return false;
    });
    const showOT = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "transport order: index")
            .length > 0
        );
      }
      return false;
    });

    const isFirst = ref(true);
    const isCancel = ref(false);
    const withBL = ref(false);
    const search = ref("");
    const selectCustomerNew = ref(false);
    const client_id_new = ref(null) as any;
    const participants_id = ref([]) as any;
    const customers_id = ref([]) as any;

    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 1,
      last_page: 1,
    });
    const selectMaritimeVoyageNew = ref(false);
    const maritime_voyage_id_new = ref(null) as any;
    const selected_booking = ref({} as any);
    const showDuplicate = ref(false);
    const duplicateId = ref(null) as any;
    const table_selected_booking = ref([] as any);
    const principal_booking_id = ref({} as any);
    const group_booking_id = ref([] as any);
    const booking_table_select = computed(() => store.getters.TableSelectList);
    const bookingPosition = computed(() => store.getters.getBookingPosition);
    const editBooking = ref(false);
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const bookingTableData = computed(() => store.getters.Bookings);
    const maritimeFileTab = computed(() => store.getters.maritimeFileTabItem);
    const dataObject = computed(() => store.getters.BookingsTable);
    const voyagesTableData = computed(() => store.getters.MaritimeTrips);
    const transshipmentTableData = computed(() => store.getters.Transshipments);
    const routeTableData = computed(() => store.getters.BookingRoutes);
    const participantTableData = computed(() => store.getters.Participants);
    const billOfLandingTableData = computed(() => store.getters.BillsOfLanding);
    const shiploadTableData = computed(() => store.getters.ShiploadsBL);
    const goodTableData = computed(() => store.getters.GoodsBL);
    const goodMeasureTableData = computed(() => store.getters.GoodsMeasuresBL);
    const viewTranshipment = computed(() => form.value.trip_type_id === 3);

    const locationData = computed(() => store.getters.Locations);
    const routeTypeData = computed(() => store.getters.RouteType);
    const entityData = computed(() => store.getters.Entity);
    const participantTypeData = computed(() => store.getters.ParticipantType);
    const equipmentData = computed(() => store.getters.Equipments);
    const overdimensionsTableData = computed(
      () => store.getters.OverdimensionsBL
    );
    const shiploads = computed(() =>
      equipmentData.value.filter((x) => !("new_item" in x))
    );
    const goodImoData = computed(() => store.getters.GoodsImoBL);
    const goodImoMeasureData = computed(() => store.getters.GoodsImoMeasuresBL);
    const bookingBls = ref("");
    const originalBooking = ref({
      id: undefined,
      maritime_voyage_id: undefined,
      trip_type_id: undefined,
      booking_number: "",
      booking_date: "",
      edi_path_id: undefined,
      reference: "",
    });
    const voyageType = ref("");
    const filters = ref({
      maritime_voyage_id: undefined,
      trip_type_id: undefined,
    });
    const form = ref({
      id: undefined,
      maritime_voyage_id: null as any,
      trip_type_id: null as any,
      booking_number: "",
      booking_date: "",
      edi_path_id: undefined,
      reference: "",
    });
    const transshipment = ref({
      maritime_voyage_id: undefined,
      remarks: "",
    });
    const originalTransshipment = ref({
      maritime_voyage_id: undefined,
      remarks: "",
    });
    const header = ref([
      {
        column: "iimo",
        key: "has_imo",
        type: "icon",
        icon: "las la-exclamation-triangle",
        color: "warning",
      },
      {
        column: "ivgm",
        key: "has_vgm",
        type: "icon",
        icon: "las la-check",
        color: "gray-900",
      },
      {
        column: "tmaritimevoyage",
        key: "maritime_voyage_id",
        size: "small",
      },
      {
        column: "ttripType",
        key: "trip_type_id",
        size: "small",
      },
      {
        column: "isecondaryvoyage",
        key: "transshipment",
        size: "small",
      },
      { column: "tbookingNumber", key: "booking_number", size: "small" },
      { column: "idate", key: "booking_date", size: "small" },
      { column: "routes", key: "booking_route" },
      // { column: "tparticipants", key: "participants" },
      { column: "tshipsloads", key: "shiploads" },
      { column: t("customer_id"), key: "customer_id", size: "small" },
      { column: t("ishipper"), key: "ishipper" },
      { column: t("iconsignne"), key: "iconsignne" },
      { column: t("notify"), key: "notify" },
    ]);
    const isLoading = ref(false);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Duplicar" as never,
        type: "DUPLICATE-2" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Rolar" as never,
        type: "ROLAR" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);
    const dialogaprove = ref(false);
    const dialoggroup = ref(false);
    const extra = ref("");

    const setItemsPerPage = (event) => {
      store.commit(
        "updateBookingsPagination",
        typeof event === "object" ? parseInt(event.target.value) : event
      );
      serverSearch();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    watch(
      () => form.value.trip_type_id,
      (first) => {
        if (first) {
          if (
            (voyageType.value === "E" && first === 2) ||
            (voyageType.value === "I" && first === 1)
          ) {
            form.value.trip_type_id = undefined;
            ElNotification({
              title: "Error",
              message: t("rbookingvoyagetype"),
              type: "error",
            });
          }
        }
      }
    );

    watch(
      () => bookingTableData.value,
      (first) => {
        if (first) {
          if (bookingTableData.value.length > 0) {
            if ("tab" in maritimeFileTab.value) {
              if ("bookings" === maritimeFileTab.value.tab) {
                store.commit("setLoadingStatus", true);
                setTimeout(() => {
                  actionsButton(maritimeFileTab.value.item_id, "EDIT", null);
                }, 5500);
              }
            }
          }
        }
      },
      { deep: true }
    );

    watch(
      () => form.value.maritime_voyage_id,
      (first) => {
        if (first) {
          setTimeout(() => {
            const voyage = voyagesTableData.value.find((v) => v.id === first);
            if (voyageType.value && voyageType.value !== voyage.trip_type) {
              form.value.trip_type_id = undefined;
              ElNotification({
                title: "Error",
                message: t("rbookingvoyagetype"),
                type: "error",
              });
            }
          }, 200);
        }
      }
    );

    const handleClick = (value) => {
      if (value === "docs") {
        store.commit("setReload", true);
      }
      if (value === "dispatches") {
        store.commit("setLoading", false);
        ApiService.query(`/api/shiploads`, {
          params: {
            page: pagination.value.page,
            per_page: pagination.value.rowsPerPage,
            goods: shiploads.value.map((x) => x.good_id),
          },
        })
          .then(({ data }) => {
            if ("shiploads" in data) {
              data.shiploads.forEach((x) => {
                store.commit("setDispatches", x);
              });
            }
          })
          .catch(() => {
            console.error("error submit");
          });
      }
    };

    const onChangeForm = () => {
      // store.commit("setsaveForm", true);
    };

    const onSelectionChange = (values) => {
      table_selected_booking.value = values;
    };

    const openGroupDialog = () => {
      dialoggroup.value = true;
      principal_booking_id.value = null;
      group_booking_id.value = [];
    };

    const closeGroupDialog = () => {
      dialoggroup.value = false;
      principal_booking_id.value = null;
      group_booking_id.value = [];
    };

    const onGroupClick = () => {
      if (group_booking_id.value.length && principal_booking_id.value !== {}) {
        let sameType = true;
        const hasBls =
          table_selected_booking.value.findIndex((x) => x.has_bls) > -1;
        const item = table_selected_booking.value.find(
          (x) => x.id === principal_booking_id.value
        );
        group_booking_id.value.forEach((x) => {
          const other = table_selected_booking.value.find((y) => y.id === x);
          if (other.trip_type_id !== item.trip_type_id) {
            sameType = false;
          }
        });
        if (!sameType || hasBls) {
          ElNotification({
            title: "Error",
            message: t("rbookinggrouperror"),
            type: "error",
          });
        } else {
          store.commit("cleanBookingTable");
          store.commit("cleanBLTable");
          const payload = {
            bookings: group_booking_id.value,
          };
          ApiService.post(
            `/api/bookings/${principal_booking_id.value}/group_bookings`,
            payload
          ).then(({ data }) => {
            ApiService.get(
              `/api/maritimefile/${maritimeFileId.value}/bookings`
            ).then(({ data: bookingInfo }) => {
              store.commit("setLoading", true);
              store.commit("setBookingsPagination", {
                current_page: bookingInfo.pagination.current_page,
                last_page: bookingInfo.pagination.last_page,
                per_page: bookingInfo.pagination.per_page,
                total: bookingInfo.pagination.total,
              });
              bookingInfo.bookings.forEach((z) => {
                store.commit("setBooking", z);
                if (z.bills_of_lading.length) {
                  store.commit("setBlPagination", {
                    current_page: 1,
                    last_page: z.bills_of_lading.length % 10,
                    per_page: 10,
                    total: z.bills_of_lading.length,
                  });
                  z.bills_of_lading.forEach((b) => {
                    // const routes = z.routes.map((x) => {
                    //   return x.location ? x.location.full_name : "";
                    // });
                    const routes = z.routes.map((x) => x.location?.full_name);
                    const participants = z.participants.map(
                      (x) => x.entity?.name
                    );
                    const shiploads = z.equipment_involveds.map(
                      (x) => x.equipment?.name
                    );
                    const has_imo = z.has_dangerous_goods;
                    const has_vgm =
                      z.equipment_involveds.findIndex((w) => w.has_vgm) > -1;
                    // store.commit("setBLTable", {
                    //   id: b.id,
                    //   bl_date: b.bl_date,
                    //   bl_number: b.bl_number,
                    //   bl_customer: b.customer_id,
                    //   booking_id: z.id,
                    //   maritime_voyage_id: z.maritime_voyage.code,
                    //   trip_type_id: z.trip_type.name,
                    //   booking_number: z.booking_number,
                    //   booking_date: z.booking_date
                    //     ? moment(z.booking_date).format("DD/MM/YYYY")
                    //     : "",
                    //   edi_path_id: z.edi_path,
                    //   reference: z.reference,
                    //   has_imo: has_imo,
                    //   has_vgm: has_vgm,
                    //   booking_route: routes
                    //     .filter((x, index) => {
                    //       return routes.indexOf(x) === index;
                    //     })
                    //     .join(", "),
                    //   participants1: participants
                    //     .filter((x, index) => {
                    //       return participants.indexOf(x) === index;
                    //     })
                    //     .join(", "),
                    //   shiploads: shiploads
                    //     .filter((x, index) => {
                    //       return shiploads.indexOf(x) === index;
                    //     })
                    //     .join(", "),
                    //   buttons: [
                    //     { type: "EDIT", id: z.id },
                    //     { type: "REMOVE", id: z.id },
                    //   ],
                    // });
                  });
                }
              });

              bookingInfo.bookings.forEach((z) => {
                const routes = z.routes.map((x) => {
                  return x.location?.full_name;
                });
                const participants = z.participants.map((x) => x.entity?.name);
                const shiploads = z.equipment_involveds.map(
                  (x) => x.equipment?.name
                );
                const has_imo = z.has_dangerous_goods;
                const has_vgm =
                  z.equipment_involveds.findIndex((w) => w.has_vgm) > -1;
                const has_bls = z.bills_of_lading.length > 0;
                store.commit("setBookingTable", {
                  id: z.id,
                  maritime_voyage_id: z.maritime_voyage.code,
                  trip_type_id: z.trip_type.name,
                  booking_number: z.booking_number,
                  booking_date: z.booking_date
                    ? moment(z.booking_date).format("DD/MM/YYYY")
                    : "",
                  edi_path_id: z.edi_path_id,
                  reference: z.reference,
                  edi_path: z.edi_path,
                  has_imo: !has_imo,
                  has_vgm: !has_vgm,
                  has_bls: has_bls,
                  booking_route: routes
                    .filter((x, index) => {
                      return routes.indexOf(x) === index;
                    })
                    .join(", "),
                  participants: participants
                    .filter((x, index) => {
                      return participants.indexOf(x) === index;
                    })
                    .join(", "),
                  shiploads: shiploads
                    .filter((x, index) => {
                      return shiploads.indexOf(x) === index;
                    })
                    .join(", "),
                  buttons: [
                    { type: "EDIT", id: z.id },
                    { type: "REMOVE", id: z.id },
                  ],
                });
              });
              dialoggroup.value = false;
              table_selected_booking.value = [];
              principal_booking_id.value = [];
              principal_booking_id.value = null;
              group_booking_id.value = [];
            });
          });
        }
      }
    };

    const onNewClick = () => {
      editBooking.value = !editBooking.value;
      withBL.value = false;
      const position = bookingTableData.value.length;
      store.commit("setBLElement", undefined);
      store.commit("setBooking", {
        maritime_voyage_id: -1,
        trip_type_id: "",
        booking_number: "",
        booking_date: "",
        reference: "",
      });
      form.value = {
        id: undefined,
        maritime_voyage_id: undefined,
        trip_type_id: undefined,
        booking_number: "",
        booking_date: "",
        edi_path_id: undefined,
        reference: "",
      };
      store.commit("setBookingTable", {
        maritime_voyage_id: -1,
        trip_type_id: "",
        booking_number: "",
        booking_date: "",
        reference: "",
      });
      store.commit("clearMaritimeVoyage");
      store.commit("setBookingPosition", position);
    };

    const updateVoyage = (value) => {
      form.value.trip_type_id = value;
      store.commit("clearMaritimeVoyage");
      // voyageType.value = value;
      // store.commit("setsaveForm", true);
    };

    const updateTripType = async (value) => {
      form.value.maritime_voyage_id = value;
      form.value.trip_type_id =
        (await ApiService.get(`/api/maritimevoyages/${value}`)).data
          .trip_type == "I"
          ? 2
          : 1;
    };

    const actionsButton = async (param1, param2, param3) => {
      if (param2 === "EDIT") {
        store.commit("setLoadingStatus", true);
        ApiService.get(`/api/bookings/${param1}`)
          .then(({ data }) => {
            selected_booking.value = { ...data };
            store.commit("setBLElement", data);
            form.value.id = data.id;
            form.value.maritime_voyage_id = data.maritime_voyage_id;
            form.value.trip_type_id = data.trip_type_id;
            form.value.booking_number = data.booking_number;
            form.value.booking_date = data.booking_date
              ? moment(data.booking_date).format("YYYY-MM-DD hh:mm:ss")
              : "";
            form.value.edi_path_id = data.edi_path_id;
            form.value.reference = data.reference;
            originalBooking.value = { ...data };
            withBL.value =
              "bills_of_lading" in data && data.bills_of_lading.length > 0;

            const currentElement = {
              name: "ibookingNumber",
              value: form.value.booking_number,
            };
            store.commit("addTripType", data.trip_type);
            store.commit("setEditItem", currentElement);
            store.commit("setBookingOpen", currentElement);
            store.commit("setCurrentElement", currentElement);

            if ("bills_of_lading" in data && data.bills_of_lading?.length) {
              data.bills_of_lading.forEach((e, index) => {
                bookingBls.value +=
                  index < data.bills_of_lading.length - 1
                    ? `${e.bl_number},`
                    : `${e.bl_number}`;
              });
            }

            if (data.transshipment) {
              transshipment.value.maritime_voyage_id =
                data.transshipment.maritime_voyage_id;
              transshipment.value.remarks = data.transshipment.remarks;
              originalTransshipment.value = { ...transshipment.value };
              store.commit(
                "addMaritimeVoyage",
                data.transshipment.maritime_trip
              );
            }

            const setBookingRoute = [] as any;
            if ("routes" in data && data.routes.length) {
              data.routes.forEach(async (x, index) => {
                if (
                  x.route_type_id &&
                  routeTypeData.value.findIndex(
                    (y) => y.id === x.route_type_id
                  ) === -1
                ) {
                  store.commit("addRouteType", {
                    id: x.route_type.id,
                    name: x.route_type.name,
                    description: x.route_type.description,
                  });
                }
                if (
                  x.location_id &&
                  locationData.value.findIndex(
                    (y) => y.id === x.location_id
                  ) === -1
                ) {
                  store.commit("addLocation", {
                    id: x.location.id,
                    country_id: x.location.country_id,
                    subdivision_id: x.location.subdivision_id,
                    code: x.location.code,
                    full_code: x.location.full_code,
                    name: x.location.name,
                    full_name: x.location.full_name,
                  });
                }
                setBookingRoute.push({
                  id: x.id,
                  ue: x.location ? x.location.country.is_eu : false,
                  booking_id: x.booking_id,
                  route_type_id: x.route_type_id,
                  location_id: x.location_id,
                  order: x.order,
                  remarks: x.remarks,
                });
              });
              store.commit("updateBookingRoute", setBookingRoute);
            }

            const addEntity = [] as any;
            const addParticipantType = [] as any;
            const setParticipant = [] as any;
            if ("participants" in data && data.participants.length) {
              data.participants.forEach((x) => {
                if (
                  entityData.value.findIndex((y) => y.id === x.entity_id) === -1
                ) {
                  const entity = {
                    id: x.entity_id,
                    name: x.entity ? x.entity.name : "",
                    legal_name: x.entity ? x.entity.legal_name : "",
                    identification: x.entity ? x.entity.identification : "",
                    identification_type_id: x.entity
                      ? x.entity.identification_type_id
                      : "",
                  };
                  addEntity.push(entity);
                  store.commit("addEntity", entity);
                }
                if (
                  participantTypeData.value.findIndex(
                    (y) => y.id === x.participant_type_id
                  ) === -1
                ) {
                  const participant_type = {
                    id: x.participant_type_id,
                    name: x.participant_type.name,
                    code: x.participant_type.code,
                  };
                  addParticipantType.push(participant_type);
                  store.commit("addParticipantType", participant_type);
                }
                setParticipant.push({
                  id: x.id,
                  booking_id: x.booking_id,
                  entity_id: x.entity_id ? x.entity_id : "",
                  participant_type_id: x.participant_type_id,
                  entity_data: x.entity_data,
                });
              });
              store.commit("updateParticipant", setParticipant);
            }

            store.commit("setBookingElement", data);
            const setBillOfLanding = [] as any;
            data.bills_of_lading?.forEach((x) => {
              setBillOfLanding.push("setBillOfLanding", {
                bl_date: moment(x.bl_date).format("YYYY-MM-DD hh:mm:ss"),
              });
            });
            store.commit("setAllBillOfLanding", setBillOfLanding);
            const ids = [] as any;
            if (
              "equipment_involveds" in data &&
              data.equipment_involveds.length
            ) {
              data.equipment_involveds.forEach((x) => {
                ids.push(x.equipment_id);
              });
            }

            if (data.goods.length) {
              data.goods.forEach((x) => {
                if (x.shiploads.length) {
                  const valsOt = [] as any;
                  const vals = [] as any;
                  x.shiploads.forEach((y) => {
                    if (y.has_transportation_order) {
                      valsOt.push(y);
                    } else {
                      vals.push(y);
                    }
                  });
                  store.commit("setShiploadBlOT", valsOt);
                  store.commit("setShiploadBlUOT", vals);
                }
              });
            }

            ApiService.query(`/api/registered-equipment/lists`, {
              params: { per_page: 25, name: "", equipment_id: ids },
            })
              .then(({ data }) => {
                data.registered_equipment.forEach((e) => {
                  store.commit("addRegisterEquipment", e);
                });
                editBooking.value = true;
              })
              .catch(() => {
                store.commit("setLoadingStatus", false);
              });
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
          });
      }
      if (param2 == "DUPLICATE") {
        duplicateDialog(param1);
        /*const item = bookingTableData.value.find((x) => x.id === param1);
        let payload = {
          maritime_voyage_id: param3,
          trip_type_id: item.trip_type_id,
          booking_number: item.booking_number,
          booking_date: item.booking_date,
          edi_path_id: item.edi_path_id,
          reference: item.reference,
        };
        if (item.transshipment !== null) {
          payload["transshipment"] = item.transshipment;
        }
        payload["booking_routes"] = item.routes;
        payload["participants"] = item.participants;
        payload["equipment_involveds"] = item.equipment_involveds;
        payload["goods"] = item.goods;

        ApiService.post("/api/bookings", payload)
          .then(({ data }) => {
            store.commit("setLoadingStatus", true);
            store.commit("setBooking", data);
            const routes = data.routes.map((x) => x.location.full_name);
            const participants = data.participants.map((x) => x.entity?.name);
            const shiploads = data.equipment_involveds.map(
              (x) => x.equipment?.name
            );
            store.commit("setBookingTable", {
              id: data.id,
              maritime_voyage_id: data.maritime_voyage.code,
              trip_type_id: data.trip_type.name,
              booking_number: data.booking_number,
              booking_date: data.booking_date,
              edi_path_id: data.edi_path_id,
              edi_path: data.edi_path,
              booking_route: routes
                .filter((x, index) => {
                  return routes.indexOf(x) === index;
                })
                .join(", "),
              participants: participants
                .filter((x, index) => {
                  return participants.indexOf(x) === index;
                })
                .join(", "),
              shiploads: shiploads
                .filter((x, index) => {
                  return shiploads.indexOf(x) === index;
                })
                .join(", "),
              buttons: [
                { type: "EDIT", id: data.id },
                { type: "REMOVE", id: data.id },
              ],
            });
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
          });*/
      }
      if (param2 === "MOVE") {
        const item = (await ApiService.get(`/api/bookings/${param1}`)).data;
        maritime_voyage_id_new.value = param3;
        rolar(item);
      }
      if (param2 === "DOWNLOAD") {
        const url = `${param1.edi_path?.path}`;
        window.open(url);
      }
      if (param2 == "REMOVE") {
        if (param1 === undefined) {
          const pos = dataObject.value.findIndex((x) => x.id === undefined);
          const item = dataObject.value[pos];
          if (item.has_transportation_order) {
            ElNotification({
              title: "Error",
              message: t("rhas_transportation_order"),
              type: "error",
            });
            return;
          }
          store.commit("removeBookingTable", pos);
          store.commit("removeBooking", pos);
          store.commit("removeBookingPosition");
        } else {
          const item = dataObject.value.find(
            (x: any) => x.id === param1
          ) as any;
          if (item.has_transportation_order) {
            ElNotification({
              title: "Error",
              message: t("rhas_transportation_order"),
              type: "error",
            });
            return;
          }
          ApiService.delete(`/api/bookings/${param1}?force_delete=true`)
            .then(({ data }) => {
              store.dispatch("GET_LOGACTIVITIES_API");
              const index = dataObject.value.findIndex((x) => x.id === param1);
              store.commit("removeBooking", index);
              store.commit("removeBookingTable", index);
              const bls = store.getters.BookingsBLTable;
              store.commit(
                "filterBLTable",
                bls.filter((x) => x.booking_id !== param1)
              );
            })
            .catch(() => {});
        }
      }
    };
    const duplicateDialog = (value) => {
      duplicateId.value = value;
      showDuplicate.value = true;
    };

    const rolar = (data) => {
      store.commit("setLoadingStatus", true);
      selectMaritimeVoyageNew.value = false;
      let flag = true;
      let payload = {
        bookings: [] as any,
      };

      if (!Array.isArray(data)) {
        const aux = {
          id: data.id,
          maritime_voyage_id: maritime_voyage_id_new.value,
          transshipment: null,
        };
        if (data.transshipment !== null) {
          if (data.transshipment.maritime_trip) {
            delete data.transshipment.maritime_trip;
          }
          delete data.transshipment.created_at;
          delete data.transshipment.updated_at;
          delete data.transshipment.deleted_at;
          aux["transshipment"] = data.transshipment;
        }
        payload.bookings.push(aux);
      } else {
        const resultData = data.reduce((arrGroup, current) => {
          let thatItem = arrGroup.find(
            (item) =>
              item.all_data.trip_type_id == current.all_data.trip_type_id
          );
          if (thatItem == undefined) arrGroup = [...arrGroup, { ...current }];
          return arrGroup;
        }, []);

        flag = resultData.length == 1;

        data.forEach((x) => {
          const aux = {
            id: x.all_data.id,
            maritime_voyage_id: maritime_voyage_id_new.value,
            transshipment: null,
          };
          if (x.all_data.transshipment !== null) {
            if (x.all_data.transshipment.maritime_trip) {
              delete x.all_data.transshipment.maritime_trip;
            }
            delete x.all_data.transshipment.created_at;
            delete x.all_data.transshipment.updated_at;
            delete x.all_data.transshipment.deleted_at;
            aux["transshipment"] = x.all_data.transshipment;
          }
          payload.bookings.push(aux);
        });
      }

      if (flag) {
        ApiService.patch(`/api/bookings/change_maritime_voyages`, payload)
          .then(({ data }) => {
            serverSearch();
          })
          .catch(() => {
            maritime_voyage_id_new.value = "";
            store.commit("setLoadingStatus", false);
          });
      } else {
        ElNotification({
          title: "Error",
          message: i18n.global.t("onlyOneTripType"),
          type: "error",
        });
        maritime_voyage_id_new.value = "";
        store.commit("setLoadingStatus", false);
      }
    };

    const addCustomer = (data) => {
      store.commit("setLoadingStatus", true);
      selectCustomerNew.value = false;
      let flag = true;
      let payload = {} as any;

      if (!Array.isArray(data)) {
        payload = {
          customer_id: client_id_new.value,
          bls_id: [data.id],
        };
      } else {
        payload = {
          customer_id: client_id_new.value,
          bls_id: data.map((x) => x.id),
        };
      }

      ApiService.patch(`api/bills_of_lading/set_customer`, payload)
          .then(({ data }) => {
            client_id_new.value = "";
            serverSearch();
          })
          .catch(() => {
            client_id_new.value = "";
            store.commit("setLoadingStatus", false);
          });
    };

    const createTSElement = () => {
      if (transshipmentTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        transshipmentTableData.value.forEach((item, index) => {
          if (
            item.maritime_voyage_id === "" ||
            item.booking_id === "" ||
            item.remarks === ""
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addTSLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rtseditabletable"),
            type: "error",
          });
        }
      } else {
        addTSLine();
      }
    };

    const addTSLine = () => {
      store.commit("setTransshipment", {
        maritime_voyage_id: "",
        remarks: "",
      });
    };

    const removeTSItem = (index) => {
      store.commit("removeTransshipment", index);
    };

    const createBRElement = () => {
      if (routeTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        routeTableData.value.forEach((item, index) => {
          if (item.route_type_id === "" || item.location_id === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addBRLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rbreditabletable"),
            type: "error",
          });
        }
      } else {
        addBRLine();
      }
    };

    const addBRLine = () => {
      store.commit("setBookingRoute", {
        route_type_id: "",
        location_id: "",
        order: "",
        remarks: "",
      });
      // store.commit("setsaveForm", true);
    };

    const removeBRItem = (index) => {
      if (routeTableData.value[index].id === undefined) {
        store.commit("setsaveForm", false);
      } else {
        // store.commit("setsaveForm", true);
      }
      store.commit("removeBookingRoute", index);
    };

    const createPElement = () => {
      if (participantTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        participantTableData.value.forEach((item, index) => {
          if (item.participant_type_id === "" || item.booking_id === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addPLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rpeditabletable"),
            type: "error",
          });
        }
      } else {
        addPLine();
      }
    };

    const addPLine = () => {
      store.commit("setParticipant", {
        entity_id: "",
        participant_type_id: "",
        entity_data: "",
      });
      // store.commit("setsaveForm", true);
    };

    const removePItem = (index) => {
      if (participantTableData.value[index].id === undefined) {
        store.commit("setsaveForm", false);
      } else {
        // store.commit("setsaveForm", true);
      }
      store.commit("removeParticipant", index);
    };

    const createBLElement = () => {
      if (billOfLandingTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        billOfLandingTableData.value.forEach((item, index) => {
          if (item.bl_date === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addBLLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rbleditabletable"),
            type: "error",
          });
        }
      } else {
        addBLLine();
      }
    };

    const addBLLine = () => {
      store.commit("setBillOfLanding", {
        bl_date: "",
      });
    };

    const removeBLItem = (index) => {
      store.commit("removeBillOfLanding", index);
    };

    const resetFields = async () => {
      await store.commit("resetBLTab");
      store.commit("resetBookingTab");
      form.value.id = undefined;
      form.value.maritime_voyage_id = undefined;
      form.value.trip_type_id = undefined;
      form.value.booking_number = "";
      form.value.booking_date = "";
      form.value.reference = "";
      originalBooking.value = { ...form.value };
      bookingBls.value = "";
      transshipment.value.maritime_voyage_id = undefined;
      transshipment.value.remarks = "";
      isCancel.value = true;
      isFirst.value = true;
      store.commit("setsaveForm", false);
      store.commit("setsaveForm", false);
      store.commit("setIsActive", false);
    };

    const checkMaritimeVotage = () => {
      if (
        form.value.id !== undefined &&
        form.value.maritime_voyage_id !==
          originalBooking.value.maritime_voyage_id
      ) {
        const item = voyagesTableData.value.find(
          (v) => v.id === form.value.maritime_voyage_id
        );
        if (item.maritime_file.office_id !== maritimeFile.value.office_id) {
          dialogaprove.value = true;
          return;
        }
        onSubmit();
      } else {
        onSubmit();
      }
    };

    const serverSearch = () => {
      store.commit("cleanBookingTable");
      store.commit("setLoadingStatus", true);
      ApiService.query(`api/maritimefile/${maritimeFileId.value}/bookings`, {
        params: {
          booking_number: search.value,
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          maritime_voyage: filters.value.maritime_voyage_id,
          participants: participants_id.value,
          customers: customers_id.value,
          trip_types: filters.value.trip_type_id,
        },
      }).then(({ data }) => {
        store.commit("cleanBookingTable");
        store.commit("setBookingsPagination", {
          current_page: data.pagination.current_page,
          last_page: data.pagination.last_page,
          per_page: data.pagination.per_page,
          total: data.pagination.total,
        });
        pagination.value.rowsPerPage = data.pagination.per_page;
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
        pagination.value.page = data.pagination.current_page;
        data.bookings.forEach((z) => {
          const routes = z.routes.map((x) => x.location?.full_name);
          const participants = z.participants.map((x) => x.entity?.name);
          const shiploads = z.equipment_involveds.map((x) => x.equipment?.name);
          store.commit("setBookingTable", {
            all_data: z,
            id: z.id,
            has_imo: z.has_dangerous_goods,
            has_vgm: z.has_dangerous_goods,
            can_it_be_deleted: z.can_it_be_deleted,
            maritime_voyage_id: z.maritime_voyage.code,
            trip_type_id: z.trip_type.name,
            transshipment: z.transshipment
              ? `${z.transshipment.maritime_trip.trip_type} - ${z.transshipment.maritime_trip.code}`
              : "-",
            booking_number: z.booking_number,
            booking_date: z.booking_date,
            edi_path_id: z.edi_path_id,
            reference: z.reference,
            edi_path: z.edi_path,
            booking_route: routes
              .filter((x, index) => {
                return routes.indexOf(x) === index;
              })
              .join(", "),
            participants: participants
              .filter((x, index) => {
                return participants.indexOf(x) === index;
              })
              .join(", "),
            shiploads: shiploads
              .filter((x, index) => {
                return shiploads.indexOf(x) === index;
              })
              .join(", "),
            customer_id:
                z.bills_of_lading[0] && z.bills_of_lading[0].customer
                    ? z.bills_of_lading[0].customer.name
                    : "-",
            ishipper:
                z.participants.length > 0
                    ? z.participants
                        .filter((x) => x.participant_type.code === "CZ")
                        .map((y) => y.entity_data.split("|")[0])
                        .toString()
                    : "-",
            iconsignne:
                z.participants.length > 0
                    ? z.participants
                        .filter((x) => x.participant_type.code === "CN")
                        .map((y) => y.entity_data.split("|")[0])
                        .toString()
                    : "-",
            notify:
                z.participants.length > 0
                    ? z.participants
                        .filter((x) => x.participant_type.code === "N1")
                        .map((y) => y.entity_data.split("|")[0])
                        .toString()
                    : "-",
            buttons: [
              { type: "EDIT", id: z.id },
              { type: "REMOVE", id: z.id },
            ],
          });
        });
        store.commit("setLoadingStatus", false);
      });
    };

    const exportAll = () => {
      store.commit("setLoadingStatus", true);
      let dataExport = [] as any;
      ApiService.query(`api/maritimefile/${maritimeFileId.value}/bookings`, {
        params: {
          page: 1,
          per_page: 0,
        },
      }).then(({ data }) => {
        data.bookings.forEach((z) => {
          const routes = z.routes.map((x) => x.location?.full_name);
          const participants = z.participants.map((x) => x.entity?.name);
          const shiploads = z.equipment_involveds.map((x) => x.equipment?.name);
          dataExport.push({
            all_data: z,
            id: z.id,
            has_imo: z.has_dangerous_goods,
            has_vgm: z.has_dangerous_goods,
            can_it_be_deleted: z.can_it_be_deleted,
            maritime_voyage_id: z.maritime_voyage.code,
            trip_type_id: z.trip_type.name,
            transshipment: z.transshipment
              ? `${z.transshipment.maritime_trip.trip_type} - ${z.transshipment.maritime_trip.code}`
              : "-",
            booking_number: z.booking_number,
            booking_date: z.booking_date,
            edi_path_id: z.edi_path_id,
            reference: z.reference,
            edi_path: z.edi_path,
            booking_route: routes
              .filter((x, index) => {
                return routes.indexOf(x) === index;
              })
              .join(", "),
            participants: participants
              .filter((x, index) => {
                return participants.indexOf(x) === index;
              })
              .join(", "),
            shiploads: shiploads
              .filter((x, index) => {
                return shiploads.indexOf(x) === index;
              })
              .join(", "),
            buttons: [
              { type: "EDIT", id: z.id },
              { type: "REMOVE", id: z.id },
            ],
          });
        });

        ApiService.setResponseTypeBlob();
        let headersNames: string[] = [];
        let headersKeys: string[] = [];
        header.value.forEach((element) => {
          headersNames.push(t(element.column));
          headersKeys.push(element.key);
        });
        ApiService.post(`/api/export`, {
          params: {
            thsNames: headersNames,
            ths: headersKeys,
            data: dataExport,
          },
        })
          .then((data) => {
            let fileUrl = window.URL.createObjectURL(data);
            let fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", t("tibookings") + `.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
            store.commit("setLoadingStatus", false);
          })
          .catch((error) => {
            console.log(error);
            store.commit("setLoadingStatus", false);
          });
        ApiService.setResponseTypeJson();
      });
    };

    const onSubmit = () => {
      dialogaprove.value = false;
      if (form.value.id === undefined) {
        let payload = { ...form.value };
        if (
          transshipment.value.maritime_voyage_id !== undefined &&
          transshipment.value.remarks !== ""
        ) {
          payload["transshipment"] = transshipment.value;
        }
        payload["booking_routes"] = routeTableData.value;
        payload["participants"] = participantTableData.value;
        payload["booking_routes"].forEach((x, index) => {
          x.order = index + 1;
        });

        shiploadTableData.value.forEach((x) => {
          x.goods_measures = overdimensionsTableData.value.filter(
            (o) => o.equipment_id === x.id
          );
        });
        const equip_envold = shiploadTableData.value;
        equip_envold.forEach((e) => {
          const equipmentsGoodMeasures = overdimensionsTableData.value.filter(
            (o) => o.equipment_id === e.id
          );
          if ("new_item" in e) {
            delete e["good_id"];
            delete e["id"];
          }
          if (e.identification === "") {
            e.identification = "ZZZ";
          }
          e.goods_measures = equipmentsGoodMeasures;
        });
        payload["equipment_involveds"] = equip_envold;

        let finalGoods = [] as any;
        // Creo un map para asociar los dangerous_goods a un good
        const dangerousGoodsMap = new Map();
        // Lleno el map con los dangerous_goods asociados a los good
        goodImoData.value.forEach((value) => {
          const goodId = value.good_id;
          if (!dangerousGoodsMap.has(goodId)) {
            dangerousGoodsMap.set(goodId, []);
          }
          dangerousGoodsMap.get(goodId).push({
            dangerous_goods_type_id: value.dangerous_goods_type_id,
            name: value.name,
            packaging_type_id: value.packaging_type_id,
            shipper: null,
            sea_pollutant: value.sea_pollutant,
            flash_point: value.flash_point,
            goods_measures: goodImoMeasureData.value.filter(
              (m) => m.dangerous_goods_id === value.id
            ),
          });
        });
        goodTableData.value.forEach((x) => {
          const goodsMeasure = goodMeasureTableData.value.filter(
            (g) => g.good_id === x.id
          );
          const equipments = equipmentData.value.filter(
            (g) => g.good_id === x.id
          );

          const dangerous_goods = dangerousGoodsMap.get(x.id) || [];
          /*const dangerous_goods = [] as any;
          goodImoData.value.forEach(function (value) {
            if (value.good_id === x.id) {
              dangerous_goods.push({
                dangerous_goods_type_id: value.p_s_n,
                name: value.name,
                packaging_type_id: value.packaging_type_id,
                shipper: null,
                number_onu: value.dangerous_goods_type
                  ? value.dangerous_goods_type.number_onu
                  : "",
                class: value.dangerous_goods_type ? value.class : "",
                packaged_group: value.dangerous_goods_type
                  ? value.packaged_group
                  : "",
                emergency_tag: value.dangerous_goods_type
                  ? value.emergency_tag
                  : "",
                goods_measures: goodImoMeasureData.value.filter(
                  (m) => m.dangerous_goods_id === value.id
                ),
              });
            }
          });*/
          goodsMeasure.forEach((y) => {
            if ("new_item" in y) {
              delete y["good_id"];
            }
          });
          equipments.forEach((y) => {
            const equipmentsGoodMeasures = overdimensionsTableData.value.filter(
              (o) => o.equipment_id === y.id
            );
            if ("new_item" in y) {
              delete y["good_id"];
              delete y["id"];
            }
            if (y.identification === "") {
              y.identification = "ZZZ";
            }
            y.goods_measures = equipmentsGoodMeasures;
          });
          const good = { ...x };
          good.goods_measures = goodsMeasure;
          good.equipments = equipments;
          good.dangerous_goods = dangerous_goods;
          finalGoods.push(good);
        });
        payload["goods"] = finalGoods;
        ApiService.post("/api/bookings", payload)
          .then(({ data }) => {
            store.commit("setBooking", data);
            updateInfo(data);
            store.commit("setLoadingStatus", false);
            store.dispatch("GET_LOGACTIVITIES_API");
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
          });
      } else {
        let payload = { ...form.value };
        if (
          transshipment.value.maritime_voyage_id !== undefined &&
          transshipment.value.remarks !== ""
        ) {
          payload["transshipment"] = transshipment.value;
        }
        payload["booking_routes"] = routeTableData.value;
        payload["participants"] = participantTableData.value;
        payload["booking_routes"].forEach((x, index) => {
          x.order = index + 1;
        });
        const equip_envold = shiploadTableData.value;
        equip_envold.forEach((e) => {
          const equipmentsGoodMeasures = overdimensionsTableData.value.filter(
            (o) => o.equipment_id === e.id
          );
          if ("new_item" in e) {
            delete e["good_id"];
            delete e["id"];
          }
          if (e.identification === "") {
            e.identification = "ZZZ";
          }
          e.goods_measures = equipmentsGoodMeasures;
        });
        payload["equipment_involveds"] = equip_envold;
        let finalGoods = [] as any;

        // Creo un map para asociar los dangerous_goods a un good
        const dangerousGoodsMap = new Map();
        // Lleno el map con los dangerous_goods asociados a los good
        goodImoData.value.forEach((value) => {
          const goodId = value.good_id;
          if (!dangerousGoodsMap.has(goodId)) {
            dangerousGoodsMap.set(goodId, []);
          }
          dangerousGoodsMap.get(goodId).push({
            dangerous_goods_type_id: value.dangerous_goods_type_id,
            name: value.name,
            packaging_type_id: value.packaging_type_id,
            shipper: null,
            sea_pollutant: value.sea_pollutant,
            flash_point: value.flash_point,
            goods_measures: goodImoMeasureData.value.filter(
              (m) => m.dangerous_goods_id === value.id
            ),
          });
        });
        goodTableData.value.forEach((x) => {
          const goodsMeasure = goodMeasureTableData.value.filter(
            (g) => g.good_id === x.id
          );
          const equipments = equipmentData.value.filter(
            (g) => g.good_id === x.id
          );
          const dangerous_goods = dangerousGoodsMap.get(x.id) || [];
          /*const dangerous_goods = [] as any;
          goodImoData.value.forEach(function (value) {
            if (value.good_id === x.id) {
              dangerous_goods.push({
                dangerous_goods_type_id: value.p_s_n,
                name: value.name,
                packaging_type_id: value.packaging_type_id,
                shipper: null,
                number_onu: value.dangerous_goods_type
                  ? value.dangerous_goods_type.number_onu
                  : "",
                class: value.dangerous_goods_type ? value.class : "",
                packaged_group: value.dangerous_goods_type
                  ? value.packaged_group
                  : "",
                emergency_tag: value.dangerous_goods_type
                  ? value.emergency_tag
                  : "",
                goods_measures: goodImoMeasureData.value.filter(
                  (m) => m.dangerous_goods_id === value.id
                ),
              });
            }
          });*/
          goodsMeasure.forEach((y) => {
            if ("new_item" in y) {
              delete y["good_id"];
            }
          });
          equipments.forEach((y) => {
            const equipmentsGoodMeasures = overdimensionsTableData.value.filter(
              (o) => o.equipment_involved_id === y.id
            );
            if ("new_item" in y) {
              delete y["good_id"];
              delete y["id"];
            }
            if (y.identification === "") {
              y.identification = "ZZZ";
            }
            y.goods_measures = equipmentsGoodMeasures;
          });
          const good = { ...x };
          good.goods_measures = goodsMeasure;
          good.equipments = equipments;
          good.dangerous_goods = dangerous_goods;
          finalGoods.push(good);
        });
        payload["goods"] = finalGoods;
        ApiService.put(`/api/bookings/${form.value.id}`, payload)
          .then(({ data }) => {
            store.commit("updateBooking", data);
            store.commit("setsaveForm", false);
            updateInfo(data);
            store.commit("setLoadingStatus", false);
            store.dispatch("GET_LOGACTIVITIES_API");
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
          });
      }
    };

    const updateInfo = (data) => {
      let booking_routes = [] as any;
      let participants = [] as any;
      let equip_inv = [] as any;
      let g = [] as any;
      let m = [] as any;
      let e = [] as any;
      let o = [] as any;
      let d = [] as any;
      let dm = [] as any;
      form.value.id = data.id;
      data.routes.forEach((x) => {
        booking_routes.push({
          id: x.id,
          booking_id: x.booking_id,
          route_type_id: x.route_type_id,
          location_id: x.location_id,
          order: x.order,
          remarks: x.remarks,
        });
      });
      data.participants.forEach((x) => {
        participants.push({
          id: x.id,
          booking_id: x.booking_id,
          entity_id: x.entity_id,
          participant_type_id: x.participant_type_id,
          entity_data: x.entity_data,
        });
      });
      data.equipment_involveds.forEach((x) => {
        equip_inv.push({
          id: x.id,
          bl_id: x.bl_id,
          registered_equipment_id: x.registered_equipment_id,
          equipment_id: x.equipment_id,
          connected_onboard: x.connected_onboard === 1 ? "1" : "0",
          connected_in_station: x.connected_in_station === 1 ? "1" : "0",
          connected_transport: x.connected_transport === 1 ? "1" : "0",
          equipment_supplier: x.equipment_supplier,
          seal_number: x.seal_number,
          is_empty: x.is_empty === 1 ? "1" : "0",
        });
        x.goods_measures.forEach((z) => {
          o.push({
            id: z.id,
            equipment_id: x.equipment_id,
            measure_type_id: z.measure_type_id,
            measure_unit_id: z.measure_unit_id,
            measure: z.measure,
            remarks: z.remarks,
          });
        });
      });

      data.goods.forEach((x) => {
        g.push({
          id: x.id,
          bl_id: x.bl_id,
          customs_tariff_code_id: x.customs_tariff_code_id,
          packaging_type_id: x.packaging_type_id,
          description: x.description,
          additional_id: x.additional_id,
          imo: x.imo === 1 ? "1" : "0",
          shipment_type: x.shipment_type,
          customs_status: x.customs_status,
          bl_type_mmpp: x.bl_type_mmpp,
        });
        x.goods_measures.forEach((y) => {
          m.push({
            id: y.id,
            good_id: y.goods_id,
            measure_type_id: y.measure_type_id,
            measure_unit_id: y.measure_unit_id,
            measure: y.measure,
            remarks: y.remarks,
          });
        });
        x.dangerous_goods.forEach((y) => {
          d.push({
            id: y.id,
            good_id: y.good_id,
            name: y.name,
            packaging_type_id: y.packaging_type_id,
            p_s_n: y.dangerous_goods_type_id,
            number_onu: y.dangerous_goods_type
              ? y.dangerous_goods_type.number_onu
              : "",
            class: y.dangerous_goods_type ? y.class : "",
            packaged_group: y.dangerous_goods_type ? y.packaged_group : "",
            emergency_tag: y.dangerous_goods_type ? y.emergency_tag : "",
          });
          y.goods_measures.forEach((z) => {
            dm.push({
              id: z.id,
              dangerous_goods_id: y.id,
              measure_type_id: z.measure_type_id,
              measure_unit_id: z.measure_unit_id,
              measure: z.measure,
              remarks: z.remarks,
            });
          });
        });
        x.shiploads.forEach((y) => {
          e.push({
            id: y.id,
            good_id: y.good_id,
            identification:
              y.equipment_involved_id &&
              y.equipment_involved.registered_equipment
                ? y.equipment_involved.registered_equipment.registration_number
                : "",
            equipment_type: y.equipment_involved_id
              ? y.equipment_involved.equipment_id
              : "",
          });
        });
      });
      store.commit("updateBookingRoute", booking_routes);
      store.commit("updateParticipant", participants);
      store.commit("updateShipload", equip_inv);
      store.commit("updateGood", g);
      store.commit("updateGoodMeasure", m);
      store.commit("updateEquipments", e);
      store.commit("updateOverdimensions", o);
      store.commit("updateImoGood", d);
      store.commit("updateGoodImoMeasure", dm);
    };

    const onSearch = () => {
      if (search.value !== "") {
        const filtered = dataObject.value.filter(
          (x) =>
            JSON.stringify(x)
              .toLowerCase()
              .indexOf(search.value.toLowerCase()) !== -1
        );
        store.commit("filterBookingTable", filtered);
      } else {
        serverSearch();
      }
    };

    onMounted(async () => {
      //console.clear();
      const booking_id = localStorage.getItem("booking_id");
      const paramsRoute = route.params;
      if (Object.keys(paramsRoute).length && paramsRoute.id) {
        store.commit("setMaritimeFileId", paramsRoute.id);
        if (booking_id) {
          await actionsButton(booking_id, "EDIT", null);
          editBooking.value = true;
          localStorage.removeItem("booking_id");
        } else {
          serverSearch();
        }
      }
      if (
        store.getters.blBoTabItem.tab !== null &&
        store.getters.blBoTabItem.tab !== undefined &&
        store.getters.blBoTabItem.tab !== ""
      ) {
        activeName.value = store.getters.blBoTabItem.tab;
      }
    });

    onUnmounted(() => {
      editBooking.value = false;
      const pos = bookingTableData.value.findIndex((x) => x.id === undefined);
      if (bookingPosition.value === pos) {
        store.commit("removeBookingPosition");
        store.commit("cancelBooking");
      }
      store.commit("setEditItem", undefined);
      store.commit("setBookingOpen", undefined);
      store.commit("setCurrentElement", undefined);
      store.commit("setBookingElement", undefined);
      store.commit("setBLElement", undefined);
      resetFields();
    });

    onUpdated(() => {
      //console.clear();
    });

    const onCancel = () => {
      editBooking.value = false;
      const pos = bookingTableData.value.findIndex((x) => x.id === undefined);
      if (bookingPosition.value === pos) {
        store.commit("removeBookingPosition");
        store.commit("cancelBooking");
      }
      store.commit("setEditItem", undefined);
      store.commit("setBookingOpen", undefined);
      store.commit("setCurrentElement", undefined);
      store.commit("setBookingElement", undefined);
      resetFields();
      serverSearch();
    };

    const onupdateElement = (action) => {
      switch (action) {
        case "BookingRoute":
          routeTableData.value.forEach((r) => {
            if (locationData.value.length) {
              r.location = locationData.value.find(
                (l) => l.id === r.location_id
              );
              if (r.location) {
                r.location.name = r.location.full_name;
                r.location.code = r.location.full_code;
                r.route_type = routeTypeData.value.find(
                  (t) => t.id === r.route_type_id
                );
              }
            }
          });
          break;
        case "Participants":
          participantTableData.value.forEach((p) => {
            p.participant_type = participantTypeData.value.find(
              (x) => x.id === p.participant_type_id
            );
            p.entity = entityData.value.find((x) => x.id === p.entity_id);
          });
          break;
      }
    };

    const duplicateE = (id) => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/bookings/${duplicateId.value}`)
        .then(async ({ data }) => {
          const booking_routes = [] as any;
          const participants = [] as any;
          const equipment_involveds = [] as any;
          const goods = [] as any;
          if (data.routes) {
            data.routes.forEach((x) => {
              booking_routes.push({
                route_type_id: x.route_type_id,
                location_id: x.location_id,
                order: x.order,
                remarks: x.remarks,
              });
            });
          }
          if (data.participants) {
            data.participants.forEach((x) => {
              participants.push({
                entity_id: x.entity_id,
                participant_type_id: x.participant_type_id,
                entity_data: x.entity_data,
              });
            });
          }
          if (data.equipment_involveds) {
            data.equipment_involveds.forEach((x) => {
              equipment_involveds.push({
                bl_id: x.bl_id,
                registered_equipment_id: x.registered_equipment_id,
                equipment_id: x.equipment_id,
                connected_onboard: x.connected_onboard,
                connected_in_station: x.connected_in_station,
                connected_transport: x.connected_transport,
                equipment_supplier: x.equipment_supplier,
                seal_number: x.seal_number,
                is_empty: x.is_empty,
              });
            });
          }
          if (data.goods) {
            data.goods.forEach((x) => {
              const goods_measures = [] as any;
              const equipments = [] as any;
              const dangerous_goods = [] as any;
              if (x.goods_measures) {
                x.goods_measures.forEach((y) => {
                  goods_measures.push({
                    measure_type_id: y.measure_type_id,
                    measure_unit_id: y.measure_unit_id,
                    measure: y.measure,
                    remarks: y.remarks,
                  });
                });
              }
              if (x.equipments) {
                x.equipments.forEach((y) => {
                  const goods_measurese = [] as any;
                  if (y.goods_measures) {
                    y.goods_measures.forEach((z) => {
                      goods_measurese.push({
                        measure_type_id: z.measure_type_id,
                        measure_unit_id: z.measure_unit_id,
                        measure: z.measure,
                        remarks: z.remarks,
                      });
                    });
                  }
                  equipments.push({
                    identification: y.identification,
                    equipment_type: y.equipment_type,
                    measure: y.measure,
                    remarks: y.remarks,
                    goods_measures: goods_measurese,
                  });
                });
              }
              if (x.dangerous_goods) {
                x.dangerous_goods.forEach((y) => {
                  const goods_measurese = [] as any;
                  if (y.goods_measures) {
                    y.goods_measures.forEach((z) => {
                      goods_measurese.push({
                        measure_type_id: z.measure_type_id,
                        measure_unit_id: z.measure_unit_id,
                        measure: z.measure,
                        remarks: z.remarks,
                      });
                    });
                  }
                  dangerous_goods.push({
                    dangerous_goods_type_id: y.dangerous_goods_type_id,
                    name: y.name,
                    packaging_type_id: y.packaging_type_id,
                    shipper: y.shipper,
                    goods_measures: goods_measurese,
                  });
                });
              }
              goods.push({
                bl_id: x.bl_id,
                customs_tariff_code_id: x.customs_tariff_code_id,
                packaging_type_id: x.packaging_type_id,
                description: x.description,
                additional_id: x.additional_id,
                imo: x.imo,
                shipment_type: x.shipment_type,
                customs_status: x.customs_status,
                bl_type_mmpp: x.bl_type_mmpp,
                goods_measures: goods_measures,
                equipments: equipments,
                dangerous_goods: dangerous_goods,
              });
            });
          }
          const new_item = {
            maritime_voyage_id: data.maritime_voyage_id,
            trip_type_id: data.trip_type_id,
            booking_number: data.booking_number,
            booking_date: data.booking_date,
            reference: data.reference,
            transshipment: data.transshipment,
            booking_routes: booking_routes,
            participants: participants,
            equipment_involveds: equipment_involveds,
            goods: goods,
          };
          duplicateId.value = null;
          showDuplicate.value = false;
          await ApiService.post("/api/bookings", new_item);
          serverSearch();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    return {
      canCreate,
      canShow,
      canEdit,
      canDelete,
      showBookingRoutes,
      showEquipmentsInvolveds,
      showOT,
      activeName,
      form,
      extra,
      originalBooking,
      originalTransshipment,
      search,
      bookingBls,
      maritimeFileId,
      maritimeFile,
      viewTranshipment,
      transshipment,
      withBL,
      editBooking,
      header,
      isLoading,
      tableButtons,
      dataObject,
      bookingTableData,
      transshipmentTableData,
      routeTableData,
      participantTableData,
      billOfLandingTableData,
      shiploadTableData,
      goodTableData,
      filters,
      dialogaprove,
      dialoggroup,
      voyagesTableData,
      pagination,
      table_selected_booking,
      principal_booking_id,
      group_booking_id,
      booking_table_select,
      closeGroupDialog,
      openGroupDialog,
      onSelectionChange,
      setItemsPerPage,
      currentPageChange,
      handleClick,
      onSearch,
      serverSearch,
      onNewClick,
      onGroupClick,
      updateVoyage,
      onCancel,
      actionsButton,
      createTSElement,
      removeTSItem,
      createBRElement,
      removeBRItem,
      createPElement,
      removePItem,
      createBLElement,
      removeBLItem,
      checkMaritimeVotage,
      onSubmit,
      onChangeForm,
      onupdateElement,
      updateTripType,
      rolar,
      maritime_voyage_id_new,
      selectMaritimeVoyageNew,
      user,
      selected_booking,
      showDuplicate,
      goodImoData,
      duplicateE,
      exportAll,
      addCustomer,
      client_id_new,
      selectCustomerNew,
      participants_id,
      customers_id,

    };
  },
});
