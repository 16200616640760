import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row mb-5" }
const _hoisted_2 = { class: "col-md-6" }
const _hoisted_3 = { class: "required" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { style: {"text-align":"right","margin":"0"} }
const _hoisted_7 = { class: "svg-icon svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.dialogvisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.dialogvisible) = $event)),
    title: _ctx.$t('iimportdocument'),
    "custom-class": "dialog-container",
    onOpened: $setup.onOpen,
    onClose: $setup.onClose,
    width: "60%"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t(`${$setup.select_label}`)), 1),
          _createVNode(_component_el_select, {
            modelValue: $setup.booking_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.booking_id) = $event)),
            class: "custom-select w-100 p-0",
            size: "small",
            placeholder: `${_ctx.$t('iselect')}`,
            readonly: $setup.disable_booking
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.elements, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.id,
                  label: `${item.id} - ${item.value}`,
                  value: item.id
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("iobservations")), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.observations) = $event)),
            type: "text",
            autocomplete: "off",
            class: "w-100"
          }, null, 512), [
            [_vModelText, $setup.form.observations]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          type: "button",
          class: "custom-btn",
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_subscriptions_export_modal",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.file.click()))
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr078.svg" })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("timport")), 1)
        ]),
        _createElementVNode("input", {
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.uploadFile && $setup.uploadFile(...args))),
          ref: "file",
          type: "file",
          multiple: "",
          style: {"display":"none"}
        }, null, 544)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onOpened", "onClose"]))
}