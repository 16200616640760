
import { useStore } from "vuex";
import { computed, ref } from "vue";

import Prebillings from "@/views/maritime_file/Prebillings.vue";
import PrebillingForm from "@/views/maritime_file/PrebillingForm.vue";
import BillingRegister from "@/views/facturation_data/bill_register.vue";

export default {
  name: "bill_E",
  components: {
    BillingRegister,
    Prebillings,
    PrebillingForm,
  },

  setup() {
    const store = useStore();
    const select_prebilling_id = ref("");
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const refreshTablePrebilling = ref(false);
    const billingShow = ref(false);
    const isNew = ref(false);
    const editPrebilling = ref(false);

    const onNewClick = async () => {
      isNew.value = true;
      setTimeout(() => {
        editPrebilling.value = true;
      }, 300);
    };

    const onCancel = async () => {
      refreshTablePrebilling.value = true;
      setTimeout(() => {
        refreshTablePrebilling.value = false;
      }, 300);
      editPrebilling.value = false;
    };

    const onEditPrebilling = (val) => {
      editPrebilling.value = true;
      select_prebilling_id.value = val;
    };

    const onAddBill = () => {
      billingShow.value = true;
    };

    const actionBackButon = () => {
      billingShow.value = false;
    };

    return {
      billingShow,
      refreshTablePrebilling,
      isNew,
      editPrebilling,
      select_prebilling_id,
      maritimeFile,
      onEditPrebilling,
      onNewClick,
      onCancel,
      onAddBill,
      actionBackButon,
    };
  },
};
