
import EquipmentInvolvedsTE from "@/components/maritime-file/tables-editable/EquipmentInvolvedsTE.vue";
import GoodsTE from "@/components/maritime-file/tables-editable/GoodsTE.vue";
import GoodsMeasuresTE from "@/components/maritime-file/tables-editable/GoodsMeasuresTE.vue";
import EquipmentTE from "@/components/maritime-file/tables-editable/EquipmentTE.vue";
import OverdimensionsTE from "@/components/maritime-file/tables-editable/OverdimensionsTE.vue";
import { computed, defineComponent, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "EquipmentBLSection",
  components: {
    EquipmentInvolvedsTE,
    GoodsTE,
    GoodsMeasuresTE,
    EquipmentTE,
    OverdimensionsTE,
  },
  props: {
    booking_id: {
      type: Number,
      default: undefined,
    },
    bl_id: {
      type: Number,
      default: undefined,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const activeNames = ref(["1"]);
    const withBL = ref(false);
    const firstTime = ref(true);

    const maritimeFile = computed(() => store.getters.maritimeFileItem) as any;
    const element_bl = computed(() => store.getters.BLElement);
    const equipmentData = computed(() => store.getters.Equipments);
    const packagingTypeData = computed(() => store.getters.PackagingType);
    const registerEquipmentData = computed(
      () => store.getters.RegisterEquipments
    );
    const customsTariffCodeData = computed(
      () => store.getters.CustomsTariffCode
    );

    const shiploadTableData = computed(() => store.getters.ShiploadsBL);
    const overdimensionsTableData = computed(
      () => store.getters.OverdimensionsBL
    );
    const goodTableData = computed(() => store.getters.GoodsBL);
    const measuresTableData = computed(() => store.getters.GoodsMeasuresBL);
    const equipmentsTableData = computed(() => store.getters.EquipmentsBL);
    const goodOT = computed(() => store.getters.GoodOT);
    const loadEquipments = ref(false);

    onMounted(function () {
      chargeData();
    });

    watch(
      () => element_bl.value,
      (first) => {
        chargeData();
      }
    );

    const chargeData = () => {
      console.log("lement_bl", element_bl);
      if (firstTime.value) {
        if (element_bl.value) {
          element_bl.value.equipment_involveds.forEach((x) => {
            if (
              x.equipment_id &&
              equipmentData.value.findIndex((y) => y.id === x.equipment_id) ===
                -1
            ) {
              store.commit("addEquipment", {
                id: x.equipment_id,
                name: x.equipment.name,
              });
            }
            if (
              x.registered_equipment &&
              registerEquipmentData.value.findIndex(
                (y) => y.id === x.registered_equipment_id
              ) === -1
            ) {
              store.commit("addRegisterEquipment", {
                id: x.registered_equipment_id,
                registration_number: x.registered_equipment.registration_number,
              });
            }
            store.commit("setShiploadBL", {
              id: x.id,
              booking_id: x.booking_id,
              bl_id: x.bl_id,
              registered_equipment_id: x.registered_equipment_id,
              equipment_id: x.equipment_id,
              equipment_name: x.equipment ? x.equipment.name : "",
              connected_onboard: x.connected_onboard === 1 ? "1" : "0",
              connected_in_station: x.connected_in_station === 1 ? "1" : "0",
              connected_transport: x.connected_transport === 1 ? "1" : "0",
              equipment_supplier: x.equipment_supplier,
              seal_number: x.seal_number,
              is_empty: x.is_empty === 1 ? "1" : "0",
              registration_number: x.registered_equipment
                ? x.registered_equipment.registration_number
                : "-",
              pif: x.pif === 1 || x.pif ? "1" : "0",
              customs: x.customs === 1 || x.customs ? "1" : "0",
              dispatched: x.dispatched === 1 || x.dispatched ? "1" : "0",
              weighers: x.weighers === 1 || x.weighers ? "1" : "0",
              under_cover: x.under_cover === 1 || x.under_cover ? "1" : "0",
              is_shipper_owner:
                x.is_shipper_owner === 1 || x.is_shipper_owner ? "1" : "0",
            });

            if (x.goods_measures !== undefined && x.goods_measures.length) {
              x.goods_measures.forEach((o) => {
                store.commit("addMeasureType", {
                  id: o.measure_type_id,
                  name: o.measure_type ? o.measure_type.name : "",
                });
                store.commit("addMeasureUnit", {
                  id: o.measure_unit ? o.measure_unit.id : "",
                  name: o.measure_unit ? o.measure_unit.name : "",
                });
                const overdimension = {
                  id: o.id,
                  equipment_id: x.id,
                  measure_type_id: o.measure_type_id,
                  measure_unit_id: o.measure_unit_id,
                  measure: o.measure,
                  remarks: o.remarks,
                };
                store.commit("setOverdimensionsBL", overdimension);
                store.commit("setOverdimensionsBLCopy", overdimension);
              });
            }
          });
          store.commit("setEmptyImoGoodBL");
          store.commit("setEmptyGoodImoMeasureBL");
          element_bl.value.goods.forEach((x) => {
            if (
              packagingTypeData.value.findIndex(
                (y) => y.id === x.packaging_type_id
              ) === -1
            ) {
              store.commit("addPackagingType", {
                id: x.packaging_type ? x.packaging_type.id : undefined,
                code: x.packaging_type ? x.packaging_type.code : "",
                name: x.packaging_type ? x.packaging_type.name : "",
              });
            }
            if (
              customsTariffCodeData.value.findIndex(
                (y) => y.id === x.customs_tariff_code_id
              ) === -1
            ) {
              store.commit("addCustomsTariffCode", {
                id: x.customs_tariff_code
                  ? x.customs_tariff_code.id
                  : undefined,
                code: x.customs_tariff_code ? x.customs_tariff_code.code : "",
                name: x.customs_tariff_code ? x.customs_tariff_code.name : "",
              });
            }
            store.commit("setGoodBL", {
              id: x.id,
              bl_id: x.bl_id,
              customs_tariff_code_id: x.customs_tariff_code_id,
              packaging_type_id: x.packaging_type_id,
              description: x.description,
              additional_id: x.additional_id,
              imo: x.imo === 0 ? "0" : "1",
              shipment_type: x.shipment_type,
              customs_status: x.customs_status,
              bl_type_mmpp: x.bl_type_mmpp,
              dangerous_goods: x.dangerous_goods,
              departure_order_export: x.departure_order_export,
              departure_order_import: x.departure_order_import,
            });
            if (x.dangerous_goods.length) {
              x.dangerous_goods.forEach((y) => {
                ApiService.query(`/api/article15/is_article15`, {
                  params: {
                    dangerous_goods_type_id: y.dangerous_goods_type_id,
                    port_id: maritimeFile.port_id,
                  },
                }).then(({ data }) => {
                  store.commit("setImoGoodBL", {
                    id: y.id,
                    good_id: y.good_id,
                    name: y.name,
                    dangerous_goods_type_id: y.dangerous_goods_type_id,
                    packaging_type_id: y.packaging_type_id,
                    proper_shipping_name: y.dangerous_goods_type
                      ? y.dangerous_goods_type.proper_shipping_name
                      : "",
                    number_onu: y.dangerous_goods_type
                      ? y.dangerous_goods_type.number_onu
                      : "",
                    class: y.dangerous_goods_type
                      ? y.dangerous_goods_type.class
                      : "",
                    packaged_group: y.dangerous_goods_type
                      ? y.dangerous_goods_type.packaged_group
                      : "",
                    emergency_tag: y.dangerous_goods_type
                      ? y.dangerous_goods_type.emergency_tag
                      : "",
                    article15: data.article15 ? data.article15 : "0",
                    sea_pollutant: `${y.sea_pollutant}`,
                    limited_amount_exemptions: y.dangerous_goods_type
                      ? y.dangerous_goods_type.limited_amount_exemptions
                      : "-",
                    flash_point: y.flash_point,
                  });
                  y.goods_measures.forEach((z) => {
                    store.commit("setGoodImoMeasureBL", {
                      id: z.id,
                      dangerous_goods_id: y.id,
                      measure_type_id: z.measure_type_id,
                      measure_unit_id: z.measure_unit_id,
                      measure: z.measure,
                      remarks: z.remarks,
                    });
                  });
                });
              });
            }
            if (x.goods_measures.length) {
              x.goods_measures.forEach((y) => {
                store.commit("addMeasureType", {
                  id: x.measure_type ? x.measure_type.id : undefined,
                  name: x.measure_type ? x.measure_type.name : "",
                });
                const goodMeasureBL = {
                  id: y.id,
                  good_id: x.id,
                  measure_type_id: y.measure_type_id,
                  measure_unit_id: y.measure_unit_id,
                  measure: y.measure,
                  remarks: y.remarks,
                };
                store.commit("setGoodMeasuresBL", goodMeasureBL);
                store.commit("setGoodMeasureBLCopy", goodMeasureBL);
              });
            }
            if (x.shiploads.length) {
              x.shiploads.forEach((y) => {
                store.commit("setEquipmentsBL", {
                  select: "0",
                  id: y.id,
                  good_id: x.id,
                  identification: y.equipment_involved?.registered_equipment
                    ? y.equipment_involved?.registered_equipment
                        .registration_number
                    : "",
                  equipment_type:
                    y.equipment_involved && y.equipment_involved.equipment
                      ? y.equipment_involved?.equipment.id
                      : "",
                  equipment_id: y.equipment_involved
                    ? y.equipment_involved_id
                    : "",
                });
              });
            }
          });
          loadEquipments.value = true;
          firstTime.value = false;
        }
      }
      setTimeout(() => {
        store.commit("setLoadingStatus", false);
        loadEquipments.value = true;
      }, 10000);
    };

    const createEIElement = () => {
      if (shiploadTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        shiploadTableData.value.forEach((item, index) => {
          if (!item.registered_equipment_id || !item.equipment_id) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addEIELine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rgood"),
            type: "error",
          });
        }
      } else {
        addEIELine();
      }
    };

    const addEIELine = () => {
      store.commit("setShiploadBL", {
        booking_id: props.booking_id !== undefined ? props.booking_id : null,
        bl_id: null,
        registered_equipment_id: null,
        equipment_id: null,
        connected_onboard: 0,
        connected_in_station: 0,
        connected_transport: 0,
        equipment_supplier: "",
        seal_number: null,
        is_empty: 0,
        pif: 0,
        customs: 0,
        dispatched: 0,
        weighers: 0,
        under_cover: 0,
        is_shipper_owner: 0,
      });
    };

    const removeEIElement = (index) => {
      store.commit("removeShiploadBL", index);
    };

    const createGDElement = () => {
      if (goodTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        goodTableData.value.forEach((item, index) => {
          if (item.description === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addGDLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rgood"),
            type: "error",
          });
        }
      } else {
        addGDLine();
      }
    };

    const addGDLine = () => {
      store.commit("setGoodBL", {
        id: goodTableData.value.length
          ? goodTableData.value[goodTableData.value.length - 1].id + 1
          : goodTableData.value.length + 1,
        bl_id: null,
        customs_tariff_code_id: null,
        packaging_type_id: null,
        description: "",
        additional_id: "",
        imo: 0,
        shipment_type: "",
        customs_status: "",
        bl_type_mmpp: "",
        new_item: true,
      });
    };

    const removeGDElement = (index) => {
      store.commit("removeGoodBL", index);
    };

    const createGDMElement = () => {
      if (measuresTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        measuresTableData.value.forEach((item, index) => {
          if (
            !item.good_id ||
            !item.measure_type_id ||
            !item.measure_unit_id ||
            item.measure === ""
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addGDMLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rgoodmeasure"),
            type: "error",
          });
        }
      } else {
        addGDMLine();
      }
    };

    const addGDMLine = () => {
      const payload = {
        check: "0",
        good_id: null,
        measure_type_id: null,
        measure_unit_id: null,
        measure: "",
        remarks: "",
        new_item: true,
      };
      store.commit("setGoodMeasuresBL", payload);
      store.commit("setGoodMeasureBLCopy", payload);
    };

    const removeGDMElement = (index) => {
      store.commit("removeGoodMeasuresBL", index);
      store.commit("removeGoodMeasureBLCopy", index);
    };

    const createEQElement = () => {
      if (equipmentsTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        equipmentsTableData.value.forEach((item, index) => {
          if (
            !item.good_id ||
            item.identification === "" ||
            !item.equipment_type
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addEQLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("requipments"),
            type: "error",
          });
        }
      } else {
        addEQLine();
      }
    };

    const addEQLine = () => {
      store.commit("setEquipmentsBL", {
        id: equipmentsTableData.value.length
          ? equipmentsTableData.value[equipmentsTableData.value.length - 1].id +
            1
          : equipmentsTableData.value.length + 1,
        good_id: null,
        identification: "",
        equipment_type: null,
        new_item: true,
      });
    };

    const removeEQElement = (index) => {
      store.commit("removeEquipmentsBL", index);
    };

    const createODElement = () => {
      if (overdimensionsTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        overdimensionsTableData.value.forEach((item, index) => {
          if (
            !item.equipment_id ||
            !item.measure_type_id ||
            !item.measure_unit_id ||
            item.measure === ""
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addODLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("roverdimensions"),
            type: "error",
          });
        }
      } else {
        addODLine();
      }
    };
    const createOt = () => {
      store.commit("clearMeasureOT");
      store.commit("clearMeasureOTNoCheck");
      store.commit("clearEquipmentListNoCheck");
      store.commit("clearEquipmentList");
      store.commit("clearOtItinerariesList");
      store.commit("clearParticipantList");

      store.commit("updateOverdimensionsOT", []);
      store.commit("updateEquipmentsOT", []);
      store.commit("updateShiploadOT", []);
      store.commit("updateGoodOT", []);
      store.commit("updateGoodMeasuresOT", []);
      shiploadTableData.value.forEach((item) => {
        if (item.check == 1) {
          store.commit("setEquipmentsOT", item);
          overdimensionsTableData.value.forEach((item1) => {
            if (item1.equipment_id == item.id) {
              item1.registration_number = item.registration_number;
              store.commit("setOverdimensionsOT", item1);
            }
          });
          equipmentsTableData.value.forEach((item2) => {
            if (item2.equipment_id == item.id) {
              store.commit("setShiploadOT", item2);
              goodTableData.value.forEach((item3) => {
                if (
                  item2.good_id == item3.id &&
                  !goodOT.value.map((x) => x.id).includes(item3.id)
                ) {
                  store.commit("setGoodOT", item3);
                  measuresTableData.value.forEach((item4) => {
                    if (item4.good_id == item3.id) {
                      store.commit("setGoodMeasuresOT", item4);
                    }
                  });
                }
              });
            }
          });
        }
      });
      store.commit("setActionMode", false);
      if (props.bl_id) {
        emit("changeTab", "three");
      } else {
        emit("changeTab", "second");
      }
    };

    const addODLine = () => {
      const payload = {
        equipment_id: null,
        measure_type_id: null,
        measure_unit_id: null,
        measure: "",
        remarks: "",
        new_item: true,
      };
      store.commit("setOverdimensionsBL", payload);
      store.commit("setOverdimensionsBLCopy", payload);
    };

    const removeODElement = (index) => {
      store.commit("removeOverdimensionsBL", index);
      store.commit("removeOverdimensionsBLCopy", index);
    };

    return {
      activeNames,
      withBL,
      shiploadTableData,
      goodTableData,
      measuresTableData,
      equipmentsTableData,
      overdimensionsTableData,
      loadEquipments,
      element_bl,
      createEIElement,
      removeEIElement,
      createGDElement,
      removeGDElement,
      createGDMElement,
      removeGDMElement,
      createEQElement,
      removeEQElement,
      createODElement,
      createOt,
      removeODElement,
      chargeData,
    };
  },
});
