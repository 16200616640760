
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import BookingSelect from "@/components/catalogs-select/BookingSelect.vue";
import BLsSelect from "@/components/catalogs-select/BLsSelect.vue";
import EquipmentsSelect from "@/components/catalogs-select/EquipmentsSelect.vue";
import RegisteredEquipmentsSelect from "@/components/catalogs-select/RegisteredEquipmentsSelect.vue";
import DispatchesSelect from "@/components/catalogs-select/DispatchesSelect.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "Equipments",
  components: {
    MaritimeVoyageSelect,
    BookingSelect,
    BLsSelect,
    EquipmentsSelect,
    RegisteredEquipmentsSelect,
    DispatchesSelect,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const search = ref("");
    const routeId = ref(route.params.id);
    const selectedData = computed(() => store.getters.TableSelectList);
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const bookingTableData = computed(() => store.getters.Bookings);
    const shiploadTableData = computed(() => store.getters.BookingsBLTable);
    const summary = ref(undefined) as any;
    const dataObject = ref([]) as any;
    const filters = ref({
      maritime_voyages: [] as any,
      bookings: [] as any,
      bls: [] as any,
      equipments: [] as any,
      registered_equipments: [] as any,
      dispatches: [] as any,
      is_empty: false,
      all: false,
    });
    const tableButtons = ref([]);
    const header = ref([
      {
        column: "id",
        key: "id",
        size: "extra_small",
      },
      {
        column: "iToEquipment",
        key: "equipment",
      },
      { column: "Size", key: "equipment_size", size: "extra_small" },
      {
        column: "iregistercontainer",
        key: "registration_number",
        size: "small",
      },
      { column: "tisealnumber", key: "seal_number", size: "small" },
      {
        column: "tbookingNumber",
        key: "booking_number",
        type: "link",
        size: "small",
      },
      { column: "ttiblnumber", key: "bl_number", type: "link", size: "small" },
      {
        column: "iisempty",
        key: "is_empty",
        type: "check",
        size: "extra_small",
      },
      { column: "itripType", key: "trip_type", size: "extra_small" },
      { column: "tmaritimevoyagep", key: "mv_p", size: "small" },
      { column: "tmaritimevoyagesa", key: "mv_s", size: "extra_small" },
      {
        column: "iconectedoninstation",
        key: "connected_in_station",
        type: "check",
        size: "small",
      },
      { column: "ipol", key: "pol", size: "small" },
      { column: "ipod", key: "pod", size: "small" },
      {
        column: "icustomstariffcode",
        key: "customs_tariff_code",
        size: "large",
      },
      { column: "igood_measure_total", key: "peso_mercancia", size: "small" },
      {
        column: "igross_weight_verify",
        key: "gross_weight_verify",
        size: "medium",
      },
      {
        column: "inumber_of_packages",
        key: "number_of_packages",
        size: "medium",
      },
      { column: "iiso", key: "iso", size: "small" },
      { column: "ivgm", key: "vgm", size: "extra_small", type: "check" },
      { column: "ionu", key: "onu", size: "large" },
      {
        column: "ioverdimension",
        key: "overdimension",
        size: "small",
        type: "check",
      },
      {
        column: "ioverdimensionUMEX",
        key: "overdimensionUMEX",
        size: "medium",
        type: "check",
      },
      { column: "itare", key: "tara", size: "small" },
      { column: "itemperature_min", key: "tmp_min", size: "small" },
      { column: "itemperature_max", key: "tmp_max", size: "small" },
      { column: "itemperature", key: "tmp", size: "small" },
      { column: "tdispatches", key: "dispatches" },
    ]);

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: parseInt(user.value.user_preferences.items_per_page),
      last_page: 1,
      total: 1,
    });

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const exportAll = () => {
      let dataExport = [] as any;
      let params = undefined as any;
      params = {
        page: 1,
        per_page: 0,
        maritime_files: [maritimeFileId.value],
      };
      ApiService.query(`api/equipment_involveds`, { params }).then(
        ({ data }) => {
          data.equipment_involveds.forEach((x) => {
            let dispatches = "";
            x.shiploads.forEach((y) => {
              if (y.dispatches.length) {
                const temp = y.dispatches.map((w) => w.customs_document_number);
                dispatches += temp.join(" ");
              }
            });
            let pod = "-";
            let pol = "-";
            x.booking.routes.forEach((r) => {
              if (r.route_type_id === 34 || r.route_type_id === 76) {
                pol = r.location.full_name;
              }
            });
            x.booking.routes.forEach((r) => {
              if (r.route_type_id === 12 || r.route_type_id === 170) {
                pod = r.location.full_name;
              }
            });
            let peso_mercancia = 0;
            let pkt_number = 0;
            let gross_weight_verify = 0;
            let overdimension = false;
            let overdimensionUMEX = false;
            let onu = "";
            let customs_tariff_code = "";
            x.shiploads.forEach((sh) => {
              if (sh.goods.imo) {
                sh.goods.dangerous_goods.forEach((dg) => {
                  if (dg.dangerous_goods_type.number_onu) {
                    onu += `${dg.dangerous_goods_type.number_onu}, `;
                  }
                });
              }
              if (
                sh.goods &&
                sh.goods.customs_tariff_code &&
                sh.goods.customs_tariff_code.code
              ) {
                customs_tariff_code += `${sh.goods.customs_tariff_code.code}, `;
              }
              sh.goods.goods_measures.forEach((gm) => {
                if (
                  (gm.measure_type_id === 10 ||
                    gm.measure_type_id === 11 ||
                    gm.measure_type_id === 19) &&
                  gm.measure_unit_id === 12
                ) {
                  peso_mercancia += convertToKg(
                    parseFloat(gm.measure),
                    gm.measure_unit_id
                  );
                }
                if (
                  gm.measure_type_id === 6 ||
                  gm.measure_type_id === 7 ||
                  gm.measure_type_id === 8
                ) {
                  overdimension = true;
                }
                if (
                  gm.measure_type_id === 14 ||
                  gm.measure_type_id === 15 ||
                  gm.measure_type_id === 16 ||
                  gm.measure_type_id === 17
                ) {
                  overdimensionUMEX = true;
                }
                if (gm.measure_type_id === 18) {
                  pkt_number += parseInt(gm.measure);
                }
                if (gm.measure_type_id === 19) {
                  pkt_number += convertToKg(
                    parseFloat(gm.measure),
                    gm.measure_unit_id
                  );
                }
              });
            });
            const iso =
              x.equipment && x.equipment.iso.length
                ? x.equipment.iso[0].name
                : "-";
            const vgm = x.has_vgm;
            let tmp_min = "-";
            let tmp_max = "-";
            let tmp = "-";
            if (x.goods_measures) {
              x.goods_measures.forEach((em) => {
                if (em.measure_type_id === 12) {
                  tmp_min = em.measure + " " + em.measure_unit.code;
                }
                if (em.measure_type_id === 13) {
                  tmp_max = em.measure + " " + em.measure_unit.code;
                }
                if (em.measure_type_id === 5) {
                  tmp = em.measure + " " + em.measure_unit.code;
                }
              });
            }

            dataExport.push({
              id: x.id,
              equipment: x.equipment ? x.equipment.name : "-",
              equipment_size: x.equipment ? x.equipment.size : "-",
              registration_number: x.registered_equipment
                ? x.registered_equipment.registration_number
                : "-",
              seal_number: x.seal_number ? x.seal_number : "-",
              booking_number: x.booking_id ? x.booking.booking_number : "-",
              booking_id: x.booking_id,
              bl_number: x.bl_id ? x.bl.bl_number : "-",
              bl_id: x.bl_id,
              is_empty: x.is_empty === 0 ? "0" : "1",
              trip_type:
                x.booking.trip_type_id === 1
                  ? "E"
                  : x.booking.trip_type_id === 2
                  ? "I"
                  : x.booking.trip_type_id === 3
                  ? "T"
                  : "S",
              mv_p: x.booking.maritime_voyage.code,
              mv_s: x.booking.transshipment
                ? `${x.booking.transshipment.maritime_trip.trip_type} - ${x.booking.transshipment.maritime_trip.code}`
                : "-",
              connected_in_station: x.connected_in_station === 0 ? "0" : "1",
              dispatches: dispatches === "" ? "-" : dispatches,
              pod: pod,
              pol: pol,
              customs_tariff_code: customs_tariff_code,
              peso_mercancia: peso_mercancia + " KG",
              iso: iso,
              vgm: vgm,
              tara:
                x.registered_equipment && x.registered_equipment.tare
                  ? x.registered_equipment.tare
                  : "-",
              tmp_min: tmp_min,
              tmp_max: tmp_max,
              tmp: tmp,
              overdimension: overdimension,
              overdimensionUMEX: overdimensionUMEX,
              onu: onu === "" ? "-" : onu,
              group:
                x.equipment && x.equipment.equipment_group
                  ? x.equipment.equipment_group.code
                  : "-",
              gross_weight_verify: gross_weight_verify + " KG",
              number_of_packages: pkt_number,
            });
          });

          ApiService.setResponseTypeBlob();
          let headersNames: string[] = [];
          let headersKeys: string[] = [];
          header.value.forEach((element) => {
            headersNames.push(t(element.column));
            headersKeys.push(element.key);
          });
          ApiService.post(`/api/export`, {
            params: {
              thsNames: headersNames,
              ths: headersKeys,
              data: dataExport,
            },
          })
            .then((data) => {
              let fileUrl = window.URL.createObjectURL(data);
              let fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute("download", t("equipments") + `.xlsx`);
              document.body.appendChild(fileLink);
              fileLink.click();
              store.commit("setLoadingStatus", false);
            })
            .catch((error) => {
              console.log(error);
              store.commit("setLoadingStatus", false);
            });
          ApiService.setResponseTypeJson();
        }
      );
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: create")
            .length > 0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: show")
            .length > 0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: update")
            .length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "equipments: delete")
            .length > 0
        );
      }
      return false;
    });

    const updateBookings = (value) => {
      filters.value.bookings = value;
    };

    const updateBLs = (value) => {
      filters.value.bls = value;
    };

    const onSearch = () => {
      serverSearch();
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      let params = undefined as any;
      params = {
        page: pagination.value.page,
        per_page: pagination.value.rowsPerPage,
        maritime_files: [maritimeFileId.value],
        maritime_voyages: filters.value.maritime_voyages,
        bookings: filters.value.bookings,
        bls: filters.value.bls,
        registered_equipments: filters.value.registered_equipments,
        dispatches: filters.value.dispatches,
      };
      if (!filters.value.all) {
        params.is_empty = filters.value.is_empty;
      }
      ApiService.query(`api/equipment_involveds`, { params }).then(
        ({ data }) => {
          pagination.value.total = data.pagination.total;
          pagination.value.page = data.pagination.current_page;
          pagination.value.last_page = data.pagination.last_page;
          summary.value = data.summary;

          while (dataObject.value.length > 0) {
            dataObject.value.pop();
          }
          data.equipment_involveds.forEach((x) => {
            let dispatches = "";
            x.shiploads.forEach((y) => {
              if (y.dispatches.length) {
                const temp = y.dispatches.map((w) => w.customs_document_number);
                dispatches += temp.join(" ");
              }
            });
            let pod = "-";
            let pol = "-";
            x.booking.routes.forEach((r) => {
              if (r.route_type_id === 34 || r.route_type_id === 76) {
                pol = r.location.full_name;
              }
            });
            x.booking.routes.forEach((r) => {
              if (r.route_type_id === 12 || r.route_type_id === 170) {
                pod = r.location.full_name;
              }
            });
            let peso_mercancia = 0;
            let pkt_number = 0;
            let gross_weight_verify = 0;
            let overdimension = false;
            let overdimensionUMEX = false;
            let onu = "";
            let customs_tariff_code = "";
            x.shiploads.forEach((sh) => {
              if (sh.goods.imo) {
                sh.goods.dangerous_goods.forEach((dg) => {
                  if (dg.dangerous_goods_type.number_onu) {
                    onu += `${dg.dangerous_goods_type.number_onu}, `;
                  }
                });
              }
              if (
                sh.goods &&
                sh.goods.customs_tariff_code &&
                sh.goods.customs_tariff_code.code
              ) {
                customs_tariff_code += `${sh.goods.customs_tariff_code.code}, `;
              }
              sh.goods.goods_measures.forEach((gm) => {
                if (
                  (gm.measure_type_id === 10 ||
                    gm.measure_type_id === 11 ||
                    gm.measure_type_id === 19) &&
                  gm.measure_unit_id === 12
                ) {
                  peso_mercancia += convertToKg(
                    parseFloat(gm.measure),
                    gm.measure_unit_id
                  );
                }
                if (
                  gm.measure_type_id === 6 ||
                  gm.measure_type_id === 7 ||
                  gm.measure_type_id === 8
                ) {
                  overdimension = true;
                }
                if (
                  gm.measure_type_id === 14 ||
                  gm.measure_type_id === 15 ||
                  gm.measure_type_id === 16 ||
                  gm.measure_type_id === 17
                ) {
                  overdimensionUMEX = true;
                }
                if (gm.measure_type_id === 18) {
                  pkt_number += parseInt(gm.measure);
                }
                if (gm.measure_type_id === 19) {
                  pkt_number += convertToKg(
                    parseFloat(gm.measure),
                    gm.measure_unit_id
                  );
                }
              });
            });
            const iso =
              x.equipment && x.equipment.iso.length
                ? x.equipment.iso[0].name
                : "-";
            const vgm = x.has_vgm;
            let tmp_min = "-";
            let tmp_max = "-";
            let tmp = "-";
            if (x.goods_measures) {
              x.goods_measures.forEach((em) => {
                if (em.measure_type_id === 12) {
                  tmp_min = em.measure + " " + em.measure_unit.code;
                }
                if (em.measure_type_id === 13) {
                  tmp_max = em.measure + " " + em.measure_unit.code;
                }
                if (em.measure_type_id === 5) {
                  tmp = em.measure + " " + em.measure_unit.code;
                }
              });
            }

            dataObject.value.push({
              id: x.id,
              equipment: x.equipment ? x.equipment.name : "-",
              equipment_size: x.equipment ? x.equipment.size : "-",
              registration_number: x.registered_equipment
                ? x.registered_equipment.registration_number
                : "-",
              seal_number: x.seal_number ? x.seal_number : "-",
              booking_number: x.booking_id ? x.booking.booking_number : "-",
              booking_id: x.booking_id,
              bl_number: x.bl_id ? x.bl.bl_number : "-",
              bl_id: x.bl_id,
              is_empty: x.is_empty === 0 ? "0" : "1",
              trip_type:
                x.booking.trip_type_id === 1
                  ? "E"
                  : x.booking.trip_type_id === 2
                  ? "I"
                  : x.booking.trip_type_id === 3
                  ? "T"
                  : "S",
              mv_p: x.booking.maritime_voyage.code,
              mv_s: x.booking.transshipment
                ? `${x.booking.transshipment.maritime_trip.trip_type} - ${x.booking.transshipment.maritime_trip.code}`
                : "-",
              connected_in_station: x.connected_in_station === 0 ? "0" : "1",
              dispatches: dispatches === "" ? "-" : dispatches,
              pod: pod,
              pol: pol,
              customs_tariff_code: customs_tariff_code,
              peso_mercancia: peso_mercancia + " KG",
              iso: iso,
              vgm: vgm,
              tara:
                x.registered_equipment && x.registered_equipment.tare
                  ? x.registered_equipment.tare
                  : "-",
              tmp_min: tmp_min,
              tmp_max: tmp_max,
              tmp: tmp,
              overdimension: overdimension,
              overdimensionUMEX: overdimensionUMEX,
              onu: onu === "" ? "-" : onu,
              group:
                x.equipment && x.equipment.equipment_group
                  ? x.equipment.equipment_group.code
                  : "-",
              gross_weight_verify: gross_weight_verify + " KG",
              number_of_packages: pkt_number,
            });
          });
          store.commit("setLoadingStatus", false);
        }
      );
    };

    const onClickLink = (param1, param2, param3) => {
      if (param3 === "tbookingNumber") {
        store.commit("setTabSelected", "bookings");
        store.commit("setBookingOpen", param2.booking_id);
      } else {
        store.commit("setTabSelected", "billOfLading");
        store.commit("setBlOpen", param2.bl_id);
      }
    };

    const getKeys = (values) => {
      const aux = [] as any;
      values.forEach((item) => {
        if (
          item !== "empty_equipments_import" &&
          item !== "full_equipments_import" &&
          item !== "empty_equipments_export" &&
          item !== "full_equipments_export" &&
          item !== "empty_equipments_transhipment" &&
          item !== "full_equipments_transhipment" &&
          item !== "transhipment" &&
          item !== "equipment_involveds_summary"
        ) {
          aux.push(item);
        }
      });
      return aux;
    };

    const convertToKg = (value, unit) => {
      switch (unit) {
        case 15:
          return value * 0.000000001;
        case 14:
          return value * 0.000001;
        case 13:
          return value * 0.001;
        case 11:
          return value * 1000;
        default:
          return value;
      }
    };

    onMounted(() => {
      //console.clear();
      if (maritimeFileId.value === undefined) {
        store.commit("setMaritimeFileId", routeId);
      }
      serverSearch();
    });

    return {
      filters,
      search,
      header,
      pagination,
      tableButtons,
      dataObject,
      bookingTableData,
      shiploadTableData,
      summary,
      onSearch,
      serverSearch,
      setItemsPerPage,
      currentPageChange,
      updateBookings,
      updateBLs,
      onClickLink,
      canCreate,
      canShow,
      canUpdate,
      canDelete,
      user,
      selectedData,
      getKeys,
      exportAll,
    };
  },
});
