import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { controls: "" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("audio", _hoisted_1, [
    _createElementVNode("source", {
      src: $setup.getUrl(),
      type: "audio/ogg"
    }, null, 8, _hoisted_2)
  ]))
}