
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: "BookingRoutesTE",
  components: {
    draggable: VueDraggableNext,
  },
  props: {
    tableData: {
      type: Array,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement", "updateElement"],

  setup(props, { emit }) {
    const store = useStore();
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "booking routes: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "booking routes: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "booking routes: delete"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const bookingTableData = computed(() => store.getters.Bookings);
    const locationData = computed(() => store.getters.Locations);
    const routeTypeData = computed(() => store.getters.RouteType);
    const routeType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const locations = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "routes_type", prop: "route_type_id" },
      { label: "location_type", prop: "location_id" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const isUE = (item, index) => {
      const location_item = locations.value.options.find(
        (x: Record<string, undefined>) => {
          return x.id === item.location_id;
        }
      );
      const v = location_item ? location_item["country"] : null;
      const eu = v ? v["is_eu"] : null;
      const payload = {
        index: index,
        is_ue: eu,
      };
      store.commit("editBookingRoute", payload);
      emit("updateElement", "BookingRoute");
    };

    const getRouteType = () => {
      if (!routeTypeData.value.length) {
        ApiService.get("/api/resource/routeType").then(({ data }) => {
          store.commit("setRouteType", data);
          routeType.value.list = data;
          routeType.value.options = data;
        });
      } else {
        routeType.value.list = routeTypeData.value;
        routeType.value.options = routeTypeData.value;
      }
    };
    const selectRouteType = (query) => {
      if (query !== "") {
        routeType.value.loading = true;
        setTimeout(() => {
          routeType.value.loading = false;
          ApiService.query(`/api/route-types/lists`, {
            params: { per_page: 10, name: query },
          }).then(({ data }) => {
            data.route_types.forEach((item) => {
              store.commit("addRouteType", item);
            });
            routeType.value.list = routeTypeData.value;
            routeType.value.options = routeTypeData.value;
          });
        }, 300);
      } else {
        routeType.value.options = routeTypeData.value;
      }
    };

    const getLocation = () => {
      if (!locationData.value.length) {
        ApiService.get("/api/location").then(({ data }) => {
          const temp = data.locations.filter((x) => x.maritime === 1);
          store.commit("setLocation", temp);
          locations.value.list = temp;
          locations.value.options = temp;
        });
      } else {
        locations.value.list = locationData.value;
        locations.value.options = locationData.value;
      }
    };
    const selectLocation = (query) => {
      if (query !== "") {
        locations.value.loading = true;
        setTimeout(() => {
          locations.value.loading = false;
          ApiService.query(`/api/location/lists`, {
            params: { per_page: 10, name: query, is_maritime: true },
          }).then(({ data }) => {
            data.locations.forEach((item) => {
              store.commit("addLocation", item);
            });
            locations.value.list = locationData.value;
            locations.value.options = locationData.value;
          });
        }, 300);
      } else {
        locations.value.options = locationData.value;
      }
    };

    onMounted(() => {
      if (props.mounted) {
        getRouteType();
        getLocation();
      } else {
        routeType.value.list = routeTypeData.value;
        routeType.value.options = routeTypeData.value;
        locations.value.list = locationData.value;
        locations.value.options = locationData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      canDelete,
      activeNames,
      bookingTableData,
      routeType,
      locations,
      headers,
      headerTable,
      selectRouteType,
      selectLocation,
      isUE,
    };
  },
};
