
import { useStore } from "vuex";
import { computed, onMounted, ref, onUnmounted, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { props } from "@syncfusion/ej2-vue-richtexteditor/src/rich-text-editor/richtexteditor.component";
import taxes from "@/views/catalog/taxes/taxes.vue";
import InputNumber from "@/components/inputs/InputNumber.vue";

export default {
  name: "PrebillingAmountBLTE",
  components: { InputNumber },

  props: {
    tableData: {
      type: Array,
    },
    taxesDetails: {
      type: Array,
      default: [] as any,
    },
    is_read_only: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    office_id: {
      type: Number,
      default: null as any,
    },
    tax_id: {
      type: Number,
      default: null as any,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();
    const loading = ref(false);
    const costTypeData = computed(() => store.getters.CostTypes);
    const currenciesData = computed(() => store.getters.Currencies);
    const taxesData = computed(() => {
      if (props.taxesDetails.length === 0) {
        return store.getters.GetTaxesDetails;
      } else {
        return props.taxesDetails;
      }
    });
    const prebillingTableData = computed(() => {
      if (props.tableData?.length > 0) {
        return props.tableData;
      } else {
        return store.getters.Prebilling;
      }
    });
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "icosttypeid", prop: "cost_type_id" },
      { label: "iunits", prop: "units" },
      { label: "iunitamount", prop: "unit_amount" },
      { label: "itotalamount", prop: "total_amount" },
      { label: "icurrency", prop: "currency_id" },
      { label: "itaxesDetail", prop: "tax_id" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);
    const elements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const currencies = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const taxes = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const onchangeUnit = (val, item) => {
      console.log("onchangeUnit val", val);
      console.log("onchangeUnit item", item);
      item.units = val;
      item.total_amount =
        item.units !== "" && item.unit_amount !== ""
          ? item.units * item.unit_amount
          : "";
    };

    const getElements = () => {
      if (elements.value.options.length <= 5) {
        ApiService.query(`/api/costTypes`, {
          params: { per_page: 25 },
        }).then(({ data }) => {
          elements.value.list = data.cost_types;
          elements.value.options = data.cost_types;
        });
      } else {
        elements.value.list = costTypeData.value;
        elements.value.options = costTypeData.value;
      }
    };
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        setTimeout(() => {
          elements.value.loading = false;
          elements.value.options = elements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!elements.value.options.length) {
            ApiService.query(`/api/costTypes/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              store.commit("addCostType", data.cost_types);
              elements.value.list = data.cost_types;
              elements.value.options = data.cost_types;
            });
          }
        }, 200);
      } else {
        elements.value.options = [];
      }
    };

    const getCurencies = () => {
      if (currencies.value.options.length < 5) {
        ApiService.query(`/api/currencies`, {
          params: { per_page: 5 },
        }).then(({ data }) => {
          store.commit("setCurrency", data.currencies);
          currencies.value.list = data.currencies;
          currencies.value.options = data.currencies;
          prebillingTableData.value.forEach((x) => {
            const val = currencies.value.options.find(
              (y) => y.id === x.currency_id
            );
            if (val === undefined || !val) {
              store.commit("setLoading", true);
              ApiService.get(`/api/currencies/${x.currency_id}`).then(
                ({ data }) => {
                  currencies.value.list.push(data);
                  currencies.value.options.push(data);
                  store.commit("setCurrency", currencies.value.options);
                }
              );
            }
          });
        });
      }
    };
    const selectCurrency = (query) => {
      if (query !== "") {
        currencies.value.loading = true;
        setTimeout(() => {
          currencies.value.loading = false;
          currencies.value.options = currencies.value.list.filter(
            (item: Record<string, any>) => {
              if (item.name !== undefined) {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              } else {
                return item;
              }
            }
          );
          if (!currencies.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/currencies/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              store.commit("addCurrency", data.currencies);
              currencies.value.list = data.currencies;
              currencies.value.options = data.currencies;
            });
          }
        }, 200);
      } else {
        currencies.value.options = [];
      }
    };

    const getTaxes = () => {
      taxes.value.list = taxesData.value;
      taxes.value.options = taxesData.value;
    };
    const getTaxesDetails = async () => {
      if (taxes.value.options.length < 5) {
        const { data } = await ApiService.query(`/api/taxes_detail`, {
          params: {
            page: 1,
            per_page: 10,
            offices: props.office_id ? [props.office_id] : [],
            taxes: props.tax_id ? [props.tax_id] : [],
          },
        });
        taxes.value.options = data.taxes_details;
        taxes.value.list = data.taxes_details;
      }
    };
    const selectTaxes = (query) => {
      if (query !== "") {
        taxes.value.loading = true;
        setTimeout(() => {
          taxes.value.loading = false;
          taxes.value.options = taxes.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!taxes.value.options.length) {
            ApiService.query(`/api/taxes/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              store.commit("addTaxes", data.taxes);
              taxes.value.list = data.taxes;
              taxes.value.options = data.taxes;
            });
          }
        }, 200);
      } else {
        taxes.value.options = [];
      }
    };

    watch(
      () => props.taxesDetails,
      (first) => {
        if (first) {
          taxes.value.list = [];
          taxes.value.options = [];
          props.taxesDetails.forEach((x) => {
            taxes.value.list.push(x);
            taxes.value.options.push(x);
          });
        }
      },
      { deep: true }
    );

    watch(
      () => costTypeData.value,
      (first) => {
        if (first) {
          costTypeData.value.forEach((x) => {
            elements.value.list.push(x);
            elements.value.options.push(x);
          });
        }
      },
      { deep: true }
    );
    watch(
      () => currenciesData.value,
      (first) => {
        if (first) {
          currenciesData.value.forEach((x) => {
            currencies.value.list.push(x);
            currencies.value.options.push(x);
          });
        }
      },
      { deep: true }
    );

    onMounted(() => {
      console.log("costTypeData", costTypeData.value);
      costTypeData.value.forEach((x) => {
        elements.value.list.push(x);
        elements.value.options.push(x);
      });
      currenciesData.value.forEach((x) => {
        currencies.value.list.push(x);
        currencies.value.options.push(x);
      });
      taxesData.value.forEach((x) => {
        taxes.value.list.push(x);
        taxes.value.options.push(x);
      });
    });

    onUnmounted(() => {
      store.commit("cleanPrebilling");
    });

    return {
      loading,
      headers,
      headerTable,
      currencies,
      elements,
      taxesData,
      taxes,
      onchangeUnit,
      selectElements,
      selectCurrency,
      selectTaxes,
      getElements,
      getCurencies,
      getTaxes,
      getTaxesDetails,
    };
  },
};
