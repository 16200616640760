
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";

export default {
  name: "OverdimensionsTE",
  props: {
    tableData: {
      type: Array,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    in_ot: {
      type: Boolean,
      default: false,
    },
    bl: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();

    const registerEquipmentData = computed(
      () => store.getters.RegisterEquipments
    );
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: create") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: create"
          ) !== -1
        );
      }
    });
    const canEdit = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: update") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: update"
          ) !== -1
        );
      }
    });

    const activeNames = ref(["1"]);
    const equipmentsTableData = props.in_ot
      ? (computed(() => store.getters.EquipmentsOT) as any)
      : !props.bl
      ? (computed(() => store.getters.Shiploads) as any)
      : (computed(() => store.getters.ShiploadsBL) as any);
    const measureTypeData = computed(() => store.getters.MeasureType);
    const measureUnitData = computed(() => store.getters.MeasureUnit);
    const measureType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const measureUnit = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "iidequipment", prop: "equipment_id" },
      { label: "imeasuretype", prop: "measure_type_id" },
      { label: "imeasureunit", prop: "measure_unit_id" },
      { label: "imeasure", prop: "measure" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const updateMeasureUnit = (item) => {
      const options = measureTypeData.value.find(
        (x) => x.id === item.measure_type_id
      );
      measureUnit.value.options = measureUnitData.value.filter(
        (x) => x.measure_unit_type_id === options.measure_unit_type_id
      );
    };

    const getMeasureType = () => {
      if (!measureTypeData.value?.length) {
        ApiService.get("/api/measure-types").then(({ data }) => {
          store.commit("setMeasureType", data);
          measureType.value.list = data;
          measureType.value.options = data;
        });
      } else {
        measureType.value.list = measureTypeData.value;
        measureType.value.options = measureTypeData.value;
      }
    };
    const selectMeasureType = (query) => {
      if (query !== "") {
        measureType.value.loading = true;
        setTimeout(() => {
          measureType.value.loading = false;
          ApiService.query(`/api/measure-types/lists`, {
            params: { per_page: 10, name: query },
          }).then(({ data }) => {
            data.measure_types.forEach((item) => {
              store.commit("addMeasureType", item);
            });
            measureType.value.list = measureTypeData.value;
            measureType.value.options = data.measure_types;
          });
        }, 300);
      } else {
        measureType.value.options = measureTypeData.value;
      }
    };

    const getregistration = () => {
      let flag = false;
      if (!Array.isArray(registerEquipmentData.value)) {
        store.commit("setRegisterEquipment", [registerEquipmentData.value]);
      }
      equipmentsTableData.value.forEach((value) => {
        const temp = registerEquipmentData.value.filter(
          (x) => x.id === value.registered_equipment_id
        );
        if (temp.length <= 0 && !flag && value.registered_equipment_id) {
          flag = true;
        }
        value.registration_number =
          temp.length > 0 ? temp[0].registration_number : "-";
      });
      if (!localStorage.getItem("user")) {
        flag = false;
      }

      if (flag) {
        ApiService.query(`/api/registered-equipment/lists`, {
          params: {
            per_page: 10,
            registration_number: "",
            equipment_id: equipmentsTableData.value.map((x) => x.equipment_id),
          },
        }).then(({ data }) => {
          data.registered_equipment.forEach((element) => {
            store.commit("addRegisterEquipment", element);
          });
        });
        equipmentsTableData.value.forEach((value) => {
          const temp = registerEquipmentData.value.filter(
            (x) => x.id === value.registered_equipment_id
          );
          value.registration_number =
            temp.length > 0 ? temp[0].registration_number : "-";
        });
      }
      return equipmentsTableData.value;
    };

    const getMeasureUnit = () => {
      if (!measureUnitData.value.length) {
        ApiService.query("/api/measure-units", {
          params: { per_page: 50 },
        }).then(({ data }) => {
          store.commit("setMeasureUnit", data);
          measureUnit.value.list = data.measure_units;
          measureUnit.value.options = data.measure_units;
        });
      } else {
        measureUnit.value.list = measureUnitData.value;
        measureUnit.value.options = measureUnitData.value;
      }
    };
    const selectMeasureUnit = (query) => {
      if (query !== "") {
        measureUnit.value.loading = true;
        setTimeout(() => {
          measureUnit.value.loading = false;
          measureUnit.value.options = measureUnit.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!measureUnit.value.options.length) {
            ApiService.query(`/api/measure-units/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.measure_units.forEach((item) => {
                store.commit("addMeasureUnit", item);
              });
              measureUnit.value.list = measureUnitData.value;
              measureUnit.value.options = data.measure_units;
            });
          }
        }, 200);
      } else {
        measureUnit.value.options = measureUnitData.value;
      }
    };

    onMounted(() => {
      if (props.mounted) {
        getMeasureType();
      } else {
        measureType.value.list = measureTypeData.value;
        measureType.value.options = measureTypeData.value;
      }
      getMeasureUnit();
    });

    return {
      canCreate,
      canEdit,
      activeNames,
      headers,
      headerTable,
      measureType,
      measureUnit,
      equipmentsTableData,
      getregistration,
      updateMeasureUnit,
      selectMeasureType,
      selectMeasureUnit,
    };
  },
};
