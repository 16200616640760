import ApiService from "@/core/services/ApiService";
import { SummaryDeclarationEndpointEnum } from "@/enums/api_endpoints/maritime-file/SummaryDeclarationEnum";
import { MaritimeFile } from  "@/interfaces/general/MaritimeFileInterfaces";
import { Shipper } from  "@/interfaces/general/SyncronizeEntitieInterfaces";
import { SummaryTransportMovement } from  "@/interfaces/general/SummaryTransportMovementInterfaces";
import { SummaryDeclarationItem } from "@/interfaces/general/SummaryDeclarationInterfaces";
import { AxiosResponse } from "axios";

export async function mountSummaryDeclarationGuide(store, id: number) {
    await ApiService.get(`${SummaryDeclarationEndpointEnum.CRUD}${id}`).then(({ data }: AxiosResponse<SummaryDeclarationItem>) => {
        store.commit("resetCustomTab");
        store.commit("setSummaryDeclarationItem", data as SummaryDeclarationItem); 
        store.commit("setShipper", [data.shipper as Shipper]);
        store.commit("setEntryTransportMovement", [data.entry_transport_movement as SummaryTransportMovement]);
        store.commit("setExitTransportMovement", [data.exit_transport_movement as SummaryTransportMovement]);
        store.commit("setEntryRegularServiceCode", [data.entry_regular_service as SummaryTransportMovement]);
        store.commit("setExitRegularServiceCode", [data.exit_regular_service as SummaryTransportMovement]);
        store.commit("setEntrySimplifiedCommunalTransitProcedure", [data.entry_simplified_communal_transit_procedure as SummaryTransportMovement]);
        store.commit("setExitSimplifiedCommunalTransitProcedure", [data.exit_simplified_communal_transit_procedure as SummaryTransportMovement]);
        
    });
};