
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "GoodsMeasuresImoTE",
  props: {
    tableData: {
      type: Array,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    bl: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();
    const loading = ref(false);
    const goodTableData = computed(() => store.getters.GoodsBL);
    const measureTypeData = computed(() => store.getters.MeasureType);
    const measureUnitData = computed(() => store.getters.MeasureUnit);
    const goodImoTableData = computed(() => store.getters.GoodsImoMeasures);
    const goodImoData = computed(() =>
      !props.bl ? store.getters.GoodsImo : store.getters.GoodsImoBL
    );
    const goodImoMeasureData = computed(() =>
      !props.bl
        ? store.getters.GoodsImoMeasures
        : store.getters.GoodsImoMeasuresBL
    );
    const measureType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const measureUnit = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "iidimogood", prop: "dangerous_goods_id" },
      { label: "imeasuretype", prop: "measure_type_id" },
      { label: "imeasureunit", prop: "measure_unit_id" },
      { label: "imeasure", prop: "measure" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const updateMeasureUnit = (item) => {
      const measure = measureType.value.list.find(
        (x) => x.id === item.measure_type_id
      );
      measureUnit.value.options = measureUnitData.value.filter(
        (x) => x.measure_unit_type_id == measure.measure_unit_type_id
      );
    };

    const getMeasureType = () => {
      if (!measureTypeData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/resource/measureType").then(({ data }) => {
          store.commit("setMeasureType", data);
          measureType.value.list = data;
          measureType.value.options = data;
        });
      } else {
        measureType.value.list = measureTypeData.value;
        measureType.value.options = measureTypeData.value;
      }
    };
    const selectMeasureType = (query) => {
      if (query !== "") {
        measureType.value.loading = true;
        setTimeout(() => {
          measureType.value.loading = false;
          measureType.value.options = measureType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!measureType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/measure-types/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.measure_types.forEach((item) => {
                store.commit("addMeasureType", item);
              });
              measureType.value.list = measureTypeData.value;
              measureType.value.options = measureTypeData.value;
            });
          }
        }, 200);
      } else {
        measureType.value.options = [];
      }
    };

    const getMeasureUnit = () => {
      if (!measureUnitData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/measure-units").then(({ data }) => {
          store.commit("setMeasureUnit", data);
          measureUnit.value.list = data.measureUnit;
          measureUnit.value.options = data.measureUnit;
        });
      } else {
        measureUnit.value.list = measureUnitData.value;
        measureUnit.value.options = measureUnitData.value;
      }
    };
    const selectMeasureUnit = (query) => {
      if (query !== "") {
        measureUnit.value.loading = true;
        setTimeout(() => {
          measureUnit.value.loading = false;
          measureUnit.value.options = measureUnit.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!measureUnit.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/measure-units/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.measure_units.forEach((item) => {
                store.commit("addMeasureUnit", item);
              });
              measureUnit.value.list = measureUnitData.value;
              measureUnit.value.options = measureUnitData.value;
            });
          }
        }, 200);
      } else {
        measureUnit.value.options = [];
      }
    };

    onMounted(() => {
      getMeasureType();
      getMeasureUnit();
    });

    return {
      loading,
      goodImoMeasureData,
      goodImoTableData,
      headers,
      headerTable,
      measureType,
      measureUnit,
      goodTableData,
      updateMeasureUnit,
      selectMeasureType,
      selectMeasureUnit,
      goodImoData,
    };
  },
});
