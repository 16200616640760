
import { defineComponent, ref, onMounted, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "MooringOperationTE",
  props: {
    tableData: {
      type: Array,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: update"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const mooringTableData = computed(() => store.getters.ShipMoorings);
    const operationData = computed(() => store.getters.SMOperation);
    const entityData = computed(() => store.getters.Entity);
    const goodsData = computed(() => store.getters.GoodTypes);
    const operationsType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const stations = ref({
      loading: false,
      options: [],
      list: [],
    });
    const goods = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "id", prop: "id" },
      {
        label: "tmoorings",
        prop: "ship_mooring_id",
      },
      {
        label: "ismoperationType",
        prop: "operation_type_id",
      },
      { label: "istation", prop: "station_id" },
      { label: "igoodsType", prop: "goods_type_id" },
    ]);
    const headerTable = ref(headers.value);

    const getOperationsType = () => {
      if (!operationData.value.length) {
        ApiService.get("/api/smoperation_types/lists").then(({ data }) => {
          store.commit("setSMOperation", data);
          operationsType.value.list = data;
          operationsType.value.options = data;
        });
      } else {
        operationsType.value.list = operationData.value;
        operationsType.value.options = operationData.value;
      }
    };
    const selectOperationsType = (query) => {
      if (query !== "") {
        operationsType.value.loading = true;
        setTimeout(() => {
          operationsType.value.loading = false;
          /*operationsType.value.options = operationsType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );*/
          if (query.length > 3) {
            ApiService.query(`/api/smoperation_types/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.smoperation_types.forEach((item) => {
                store.commit("addSMOperation", item);
              });
              operationsType.value.list = operationData.value;
              operationsType.value.options = operationData.value;
            });
          }
        }, 200);
      } else {
        operationsType.value.options = [];
      }
    };

    const getStations = () => {
      if (!entityData.value.length) {
        ApiService.query(`/api/entities`, {
          params: { per_page: 10, entity_type_id: [17] },
        }).then(({ data }) => {
          store.commit("setEntity", data);
          stations.value.list = data;
          stations.value.options = data;
        });
      } else {
        stations.value.list = entityData.value;
        stations.value.options = entityData.value;
      }
    };
    const selectStations = (query) => {
      if (query !== "") {
        stations.value.loading = true;
        setTimeout(() => {
          stations.value.loading = false;
          stations.value.options = stations.value.list.filter(
            (item: Record<string, any>) => {
              if (item.name) {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            }
          );
          if (!stations.value.options.length) {
            ApiService.query(`/api/entities/lists`, {
              params: { per_page: 10, name: query, entity_type_id: [17] },
            }).then(({ data }) => {
              data.entities.forEach((item) => {
                store.commit("addEntity", item);
              });
              stations.value.list = entityData.value;
              stations.value.options = entityData.value;
            });
          }
        }, 200);
      } else {
        stations.value.options = [];
      }
    };

    const getGoods = () => {
      if (!goodsData.value.length) {
        ApiService.get("/api/good-types").then(({ data }) => {
          store.commit("setGoods", data);
          goods.value.list = data;
          goods.value.options = data;
        });
      } else {
        goods.value.list = goodsData.value;
        goods.value.options = goodsData.value;
      }
    };
    const selectGoods = (query) => {
      if (query !== "") {
        goods.value.loading = true;
        setTimeout(() => {
          goods.value.loading = false;
          goods.value.options = goods.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!goods.value.options.length) {
            ApiService.query(`/api/good-types/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.good_types.forEach((item) => {
                store.commit("addGoods", item);
              });
              goods.value.list = goodsData.value;
              goods.value.options = data.good_types;
            });
          }
        }, 200);
      } else {
        goods.value.options = goodsData.value;
      }
    };

    onMounted(() => {
      if (props.mounted) {
        getOperationsType();
        getStations();
        getGoods();
      } else {
        operationsType.value.list = operationData.value;
        operationsType.value.options = operationData.value;
        stations.value.list = entityData.value;
        stations.value.options = entityData.value;
        goods.value.list = goodsData.value;
        goods.value.options = goodsData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      activeNames,
      headers,
      headerTable,
      operationsType,
      stations,
      goods,
      mooringTableData,
      selectOperationsType,
      selectStations,
      selectGoods,
    };
  },
});
