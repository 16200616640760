
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "TemplateTypeSelect",
  props: {
    template_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    datas: {
      type: Array,
      default: [] as any,
    },
  },
  emits: ["update:template_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const inputTemplate = ref({
      loading: false,
      options: props.datas,
      list: props.datas,
    });

    const getTemplate = () => {
      if (!props.datas.length) {
        ApiService.get("/api/template").then(({ data }) => {
          inputTemplate.value.list = data.data;
          inputTemplate.value.options = data.data;
        });
      } else {
        inputTemplate.value.list = props.datas;
        inputTemplate.value.options = props.datas;
      }
    };

    const selectTemplate = (query) => {
      if (query !== "") {
        inputTemplate.value.loading = true;
        setTimeout(() => {
          inputTemplate.value.loading = false;
          inputTemplate.value.options = inputTemplate.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputTemplate.value.options = inputTemplate.value.list;
      }
    };

    watch(
      () => props.template_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => props.datas,
      (first) => {
        inputTemplate.value.list = props.datas;
        inputTemplate.value.options = props.datas;
      }
    );

    onMounted(() => {
      element_id.value = props.template_id;
    });

    return {
      element_id,
      inputTemplate,
      selectTemplate,
    };
  },
};
