
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, watch } from "vue";
import { ElTable } from "element-plus";
import moment from "moment";
import { useStore } from "vuex";
import SendBillingDialog from "@/components/dialogs/SendBillingDialog.vue";

export default {
  name: "TableDocuments",
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sendEmail: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    "sendEmail",
  ],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const buttonRef = ref();
    const url = ref("");
    const items = ref([]);
    const headers = ref([
      { column: t("iname"), key: "name" },
      { column: t("itype"), key: "type" },
      { column: t("icategory"), key: "category" },
      { column: t("iuser"), key: "user" },
    ]);

    const onClickDownload = (index, item) => {
      window.open(item.path);
    };

    const onSendEmail = (scope) => {
      emit("sendEmail", scope.row);
    };

    const getUrl = (item) => {
      return `${item.path}`;
    };

    const getItems = computed(() => {
      return JSON.parse(JSON.stringify(props.data));
    });

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const handleSelectedRow = (row) => {
      store.commit("setAudioItem", null);
      store.commit("setVideoItem", null);
      if (row && "type" in row) {
        switch (row.type) {
          case "mp3":
          case "m4a":
          case "flac":
          case "wav":
          case "wma":
          case "aac":
          case "aiff":
          case "ogg":
            store.commit("setAudioItem", row);
            break;
          case "avchd":
          case "avi":
          case "flv":
          case "mkv":
          case "mov":
          case "mp4":
          case "wfrm":
          case "wmv":
          case "webm":
          case "3gp":
            store.commit("setVideoItem", row);
        }
      }
    };

    onMounted(() => {
      //console.clear();
    });

    watch(props.data, (val) => {
      items.value = JSON.parse(JSON.stringify(props.data));
    });

    return {
      url,
      headers,
      buttonRef,
      items,
      getItems,
      getUrl,
      formatDate,
      onClickDownload,
      onSendEmail,
      handleSelectedRow,
    };
  },
  components: {
    SendBillingDialog,
  },
};
