
import { onMounted, onUpdated, reactive, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "TransportOrderStatusSelect",
  props: {
    transport_order_status_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:transport_order_status_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputTransportOrderStatus = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isOrderStatusRequired = (value) => {
      if (props.required && !value) {
        return t("rtransportorderstatus");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "orderStatus",
      isOrderStatusRequired
    );

    const getTransportOrderStatus = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/edifact_status/lists").then(({ data }) => {
        inputTransportOrderStatus.value.list = data.edifact_status;
        inputTransportOrderStatus.value.options = data.edifact_status;
      });
    };
    const propData = ref(props.transport_order_status_id);
    const selectTransportOrderStatus = (query) => {
      if (query !== "") {
        inputTransportOrderStatus.value.loading = true;
        setTimeout(() => {
          inputTransportOrderStatus.value.loading = false;
          inputTransportOrderStatus.value.options =
            inputTransportOrderStatus.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
        }, 200);
      } else {
        inputTransportOrderStatus.value.options = [];
      }
    };

    watch(
      () => propData,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => getTransportOrderStatus());

    return {
      propData,
      element_id,
      inputTransportOrderStatus,
      errorMessage,
      getTransportOrderStatus,
      selectTransportOrderStatus,
    };
  },
};
