import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white rounded shadow-sm p-5 p-lg-8 mx-auto tab-element" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = {
  key: 1,
  tabindex: "0"
}
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prebillings = _resolveComponent("Prebillings")!
  const _component_PrebillingForm = _resolveComponent("PrebillingForm")!
  const _component_billing_register = _resolveComponent("billing-register")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.editPrebilling && !$setup.billingShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Prebillings, {
            refresh: $setup.refreshTablePrebilling,
            origin: "bl",
            bl: $setup.currentBL,
            propMaritimeFile: $setup.maritimeFile,
            maritimeFileId: $setup.maritimeFile.id,
            officesId: $setup.maritimeFile.office_id,
            currentBooking: $setup.currentBooking,
            onOnEditPrebilling: $setup.onEditPrebilling,
            onOnNewPrebilling: $setup.onNewClick,
            onOnAddBillClick: $setup.onAddBill
          }, null, 8, ["refresh", "bl", "propMaritimeFile", "maritimeFileId", "officesId", "currentBooking", "onOnEditPrebilling", "onOnNewPrebilling", "onOnAddBillClick"])
        ]))
      : _createCommentVNode("", true),
    ($setup.editPrebilling)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PrebillingForm, {
            origin: "bl",
            isNew: $setup.isNew,
            propMBL: $setup.currentBL,
            prebilling_id: $setup.select_prebilling_id,
            onBackPrebilling: $setup.onCancel,
            propMaritimeFile: $setup.maritimeFile
          }, null, 8, ["isNew", "propMBL", "prebilling_id", "onBackPrebilling", "propMaritimeFile"])
        ]))
      : _createCommentVNode("", true),
    ($setup.billingShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_billing_register, {
            showBack: "true",
            bl_id: $setup.currentBL.id,
            bl: $setup.currentBL,
            onActionBack: $setup.actionBackButon,
            origin: "bl",
            maritime_file_id: $setup.maritimeFileID,
            maritime_file: $setup.maritimeFile
          }, null, 8, ["bl_id", "bl", "onActionBack", "maritime_file_id", "maritime_file"])
        ]))
      : _createCommentVNode("", true)
  ]))
}