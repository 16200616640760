
import moment from "moment";
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "ShipMooringTE",
  props: {
    tableData: {
      type: Array,
    },
    port_id: {
      type: Number,
      default: undefined,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement", "updateCheckin"],

  setup(props, { emit }) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: update"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const requirementData = computed(() => store.getters.Requirements);
    const purposeData = computed(() => store.getters.Purposes);
    const dockData = computed(() => store.getters.Docks);
    const requirements = ref({
      loading: false,
      options: [],
      list: [],
    });
    const purposes = ref({
      loading: false,
      options: [],
      list: [],
    });
    const docks = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "ismrequirement", prop: "requirement_id" },
      { label: "ismpurpose", prop: "purpose_id" },
      { label: "idock", prop: "dock_id" },
      { label: "ETA", prop: "check_in" },
      { label: "ETS", prop: "check_out" },
    ]);
    const headerTable = ref(headers.value);

    const updateCheckOut = (item) => {
      emit("updateCheckin", item.check_in);
      if (item.check_in !== "") {
        const newDate = moment(item.check_in).add(1, "m").toDate();
        item.check_out = moment(newDate).format("YYYY-MM-DD h:mm:ss");
      }
    };
    const getRequirements = () => {
      if (!requirementData.value.length) {
        ApiService.get("/api/resource/shipMooringRequirement").then(
          ({ data }) => {
            store.commit("setRequirements", data);
            requirements.value.list = data;
            requirements.value.options = data;
          }
        );
      } else {
        requirements.value.list = requirementData.value;
        requirements.value.options = requirementData.value;
      }
    };
    const selectRequirements = (query) => {
      if (query !== "") {
        requirements.value.loading = true;
        setTimeout(() => {
          requirements.value.loading = false;
          requirements.value.options = requirements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!requirements.value.options.length) {
            ApiService.query(`/api/smrequirements/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.smrequirements.forEach((item) => {
                store.commit("addRequirements", item);
              });
              requirements.value.list = requirementData.value;
              requirements.value.options = requirementData.value;
            });
          }
        }, 200);
      } else {
        requirements.value.options = [];
      }
    };

    const getPurposes = () => {
      if (!purposeData.value.length) {
        ApiService.get("/api/resource/shipMooringPurpose").then(({ data }) => {
          store.commit("setPurposes", data);
          purposes.value.list = data;
          purposes.value.options = data;
        });
      } else {
        purposes.value.list = purposeData.value;
        purposes.value.options = purposeData.value;
      }
    };
    const selectPurposes = (query) => {
      if (query !== "") {
        purposes.value.loading = true;
        setTimeout(() => {
          purposes.value.loading = false;
          purposes.value.options = purposes.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!purposes.value.options.length) {
            ApiService.query(`/api/smpurposes/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.ship_mooring_purpose.forEach((item) => {
                store.commit("addPurposes", item);
              });
              purposes.value.list = purposeData.value;
              purposes.value.options = data.ship_mooring_purpose;
            });
          }
        }, 200);
      } else {
        purposes.value.options = purposeData.value;
      }
    };

    const getDocks = () => {
      ApiService.get("/api/docks").then(({ data }) => {
        const temp =
          props.port_id !== undefined
            ? data.filter((x) => x.port_id === props.port_id)
            : data;
        docks.value.list = temp;
        docks.value.options = temp;
      });
    };
    const selectDocks = (query) => {
      if (query !== "") {
        docks.value.loading = true;
        setTimeout(() => {
          docks.value.loading = false;
          docks.value.options = docks.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        docks.value.options = [];
      }
    };

    watch(
      () => props.port_id,
      (first) => {
        if (first) {
          getDocks();
        }
      }
    );

    watch(
      () => requirementData.value,
      (first) => {
        requirements.value.list = first;
        requirements.value.options = first;
      }
    );

    watch(
      () => purposeData.value,
      (first) => {
        purposes.value.list = first;
        purposes.value.options = first;
      }
    );

    onMounted(() => {
      if (props.mounted) {
        getRequirements();
        getPurposes();
      } else {
        requirements.value.list = requirementData.value;
        requirements.value.options = requirementData.value;
        purposes.value.list = purposeData.value;
        purposes.value.options = purposeData.value;
        docks.value.list = dockData.value;
        docks.value.options = dockData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      activeNames,
      headers,
      headerTable,
      requirements,
      purposes,
      docks,
      updateCheckOut,
      selectRequirements,
      selectPurposes,
      selectDocks,
    };
  },
});
