import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_select, {
      modelValue: $setup.propData,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.propData) = $event)),
      name: "orderStatus",
      class: "custom-select chevron w-100 p-0",
      size: $props.size,
      readonly: $props.readonly,
      clearable: "",
      placeholder: `${_ctx.$t('iselect')}`,
      filterable: "",
      remote: "",
      multiple: $props.multiselect,
      "collapse-tags": $props.multiselect,
      "reserve-keyword": "",
      "remote-method": $setup.selectTransportOrderStatus,
      loading: $setup.inputTransportOrderStatus.loading,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:transport_order_status_id', $event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputTransportOrderStatus.options, (status) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: status.id,
            label: `${status.id} ${status.name}`,
            value: status.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(`${status.id} ${status.name}`), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "size", "readonly", "placeholder", "multiple", "collapse-tags", "remote-method", "loading"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString($setup.errorMessage), 1)
  ]))
}