import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    src: $props.url,
    title: "description",
    width: "auto",
    height: "auto",
    allow: "encrypted-media"
  }, null, 8, _hoisted_1))
}