
import { computed, defineComponent, onMounted, onUnmounted } from "vue";
import MaritimeFileTabs from "@/components/maritime-file/MaritimeFileTabs.vue";
import OverviewTab from "@/components/maritime-file/tabs/OverviewTab.vue";
import PrebillingConcepts from "@/components/maritime-file/tabs/PrebillingConcepts.vue";
import CustomsTab from "@/components/maritime-file/tabs/CustomsTab.vue";
import Booking from "@/components/maritime-file/tabs/Booking.vue";
import BillOfLading from "@/components/maritime-file/tabs/BillOfLading.vue";
import Dispatches from "@/components/maritime-file/tabs/Dispatches.vue";
import Equipments from "@/components/maritime-file/tabs/Equipments.vue";
import Billing from "@/components/maritime-file/tabs/Billing.vue";
import Documents from "@/components/maritime-file/tabs/Documents.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import ImportFileDialog from "@/components/dialogs/ImportFileDialog.vue";
import MFPrebillingForm from "@/components/maritime-file/forms/MFPrebillingForm.vue";
import Prebillings from "@/views/maritime_file/Prebillings.vue";

export default defineComponent({
  name: "MaritimeFileForm",
  components: {
    MFPrebillingForm,
    Booking,
    BillOfLading,
    MaritimeFileTabs,
    OverviewTab,
    Dispatches,
    Equipments,
    Billing,
    Documents,
    ImportFileDialog,
    PrebillingConcepts,
    CustomsTab
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const tab = computed(() => store.getters.getTabSelected);
    const permissions = computed(() => store.getters.permissions);
    const showTabBooking = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "bookings: index") !== -1
      );
    });
    const showTabBl = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "bills of lading: index"
        ) !== -1
      );
    });
    const showTabDispatches = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "dispatch: index") !== -1
      );
    });
    const showTabEquipments = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "registered equipment: index"
        ) !== -1
      );
    });
    const showTabBilling = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "billing: index") !== -1
      );
    });
    const showTabDocuments = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: view_files"
        ) !== -1
      );
    });

    const selectedTab = (value) => {
      store.commit("setTabSelected", value);
    };

    onMounted(async () => {
      store.commit("resetModule");
      store.commit("resetFileSelectModule");
      store.commit("ressetDocumentsModule");
      store.commit("resetImoDeclarationModule");
      setCurrentPageBreadcrumbs("maritime_file", [
        { route: "/maritimefiles", label: "maritime_files" },
      ]);
      const paramsRoute = route.params;
      if (Object.keys(paramsRoute).length && paramsRoute.id) {
        store.commit("setMaritimeFileId", paramsRoute.id);
      }
    });

    onUnmounted(() => {
      store.commit("clearCurrencieEchanges");
      store.commit("resetModule");
      store.commit("removeMaritimeFileTab");
      store.commit("resetFileSelectModule");
    });

    return {
      showTabBooking,
      showTabBl,
      showTabDispatches,
      showTabEquipments,
      showTabBilling,
      showTabDocuments,
      tab,
      route,
      selectedTab,
      CustomsTab
    };
  },
});
