
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import DispatchesGoodsMeasuresTE from "@/components/maritime-file/tables-editable/DispatchesGoodsMeasuresTE.vue";
import { ElNotification } from "element-plus/es";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import moment from "moment/moment";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  components: {
    TableBasicSelect,
    DispatchesGoodsMeasuresTE,
  },
  props: {
    father_id: {
      type: Number,
      default: null,
    },
    bl: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref(false);
    const showTable = ref(true);
    const measuresTableData = ref([]) as any;
    const equipmentsTableData = computed(() => store.getters.Equipments);
    const shiploads = computed(() =>
      equipmentsTableData.value.filter((x) => !("new_item" in x))
    );
    const header = ref([
      { column: "dispatch_date", key: "dispatch_date", size: "extra_small" },
      { column: "iregistration_number", key: "registration_number" },
      { column: "igoods", key: "goods_description" },
      { column: "filesTypeAss", key: "associated_doc_type" },
      { column: "customs_situation_document", key: "customs_situation" },
      { column: "customs_document_number", key: "customs_document_number" },
      {
        column: "description_document",
        key: "doc_description",
        size: "extra_large",
      },
      { column: "mask", key: "mask", size: "extra_large" },
    ]) as any;
    const dataList = ref([]) as any;
    const dataObject = ref([]) as any;
    const dispatches = computed(() => store.getters.Dispatches);
    const customs_docs = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Generar EDI" as never,
        type: "GENERATE_EDI" as never,
        color: "primary" as never,
      } as never,
    ]);
    const shipload_id = ref(null);
    const form = ref({
      id: undefined,
      shipload_id: undefined,
      customs_situation_document_id: null,
      dispatch_date: "",
      customs_document_number: "",
      goods_measures: [],
    });
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      last_page: 1,
      total: 1,
    });

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "dispatch: create").length >
            0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "dispatch: show").length >
            0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "dispatch: update").length >
            0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "dispatch: delete").length >
            0
        );
      }
      return false;
    });

    const forceDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "dispatch: force_delete").length >
            0
        );
      }
      return false;
    });

    const getCustomsDocs = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/customs_situation_documents/lists").then(
        ({ data }) => {
          customs_docs.value.list = data.customs_situation_documents;
          customs_docs.value.options = data.customs_situation_documents;
        }
      );
    };
    const selectCustomsDocs = (query) => {
      if (query !== "") {
        customs_docs.value.loading = true;
        setTimeout(() => {
          customs_docs.value.loading = false;
          customs_docs.value.options = customs_docs.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!customs_docs.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/customs_situation_documents/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.customs_situation_documents.forEach((item) => {
                customs_docs.value.list.push(item);
              });
              customs_docs.value.options = data.customs_situation_documents;
            });
          }
        }, 200);
      } else {
        customs_docs.value.options = customs_docs.value.list;
      }
    };

    /*watch(
      () => dispatches.value,
      (first) => {
        first.forEach((x) => {
          dataObject.push({
            customs_situation_document_id: `${x.goods.customs_tariff_code} ${x.goods.description}`,
            dispatch_date: moment(x.created_at).format("DD-MM-YYYY"),
            customs_document_number: moment(x.created_at).format("DD-MM-YYYY"),
          })
        })
      }
    );*/

    const onNewClick = () => {
      showTable.value = false;
    };

    const onCancel = () => {
      form.value.id = undefined;
      form.value.customs_situation_document_id = null;
      form.value.shipload_id = undefined;
      form.value.dispatch_date = "";
      form.value.customs_document_number = "";
      form.value.goods_measures = [];
      measuresTableData.value = [];
      getItems();
      showTable.value = true;
    };

    const createGDMElement = () => {
      if (measuresTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        measuresTableData.value.forEach((item, index) => {
          if (
            !item.measure_type_id ||
            !item.measure_unit_id ||
            item.measure === ""
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addGDMLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rgoodmeasure"),
            type: "error",
          });
        }
      } else {
        addGDMLine();
      }
    };

    const addGDMLine = () => {
      const payload = {
        check: "0",
        measure_type_id: null,
        measure_unit_id: null,
        measure: "",
        remarks: "",
        new_item: true,
      };
      measuresTableData.value.push(payload);
    };

    const removeGDMElement = (index) => {
      measuresTableData.value.splice(index, 1);
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        ApiService.get(`/api/dispatches/${param1}`).then(({ data }) => {
          form.value.id = data.id;
          form.value.customs_situation_document_id =
            data.customs_situation_document_id;
          form.value.dispatch_date = data.dispatch_date;
          form.value.customs_document_number = data.customs_document_number;
          form.value.shipload_id = data.shipload_id;
          measuresTableData.value = data.goods_measures;
          if (
            customs_docs.value.options.findIndex(
              (y) => y.id === data.customs_situation_document_id
            ) === -1
          ) {
            const item = {
              id: data.customs_situation_document.id,
              customs_situation:
                data.customs_situation_document.customs_situation,
              doc_description: data.customs_situation_document.doc_description,
            };
            customs_docs.value.options.push(item);
            customs_docs.value.list.push(item);
          }
          showTable.value = false;
        });
      } else if (param2 == "REMOVE") {
        ApiService.delete(`/api/dispatches/${param1}?force_delete=true`).then(
          ({ data }) => {
            getItems();
          }
        );
      }
    };

    const onSubmit = () => {
      form.value.goods_measures = measuresTableData.value;
      let payload = { ...form.value };
      if (form.value.id === undefined) {
        ApiService.post(`/api/dispatches`, payload)
          .then(({ data }) => {
            form.value.id = data.id;
            form.value.customs_situation_document_id =
              data.customs_situation_document_id;
            form.value.dispatch_date = data.dispatch_date;
            form.value.customs_document_number = data.customs_document_number;
            form.value.shipload_id = data.shipload_id;
            measuresTableData.value = data.goods_measures;
          })
          .catch(() => {
            console.log("error submit");
          });
      } else {
        ApiService.put(`/api/dispatches/${form.value.id}`, payload)
          .then(({ data }) => {
            form.value.id = data.id;
            form.value.customs_situation_document_id =
              data.customs_situation_document_id;
            form.value.dispatch_date = data.dispatch_date;
            form.value.customs_document_number = data.customs_document_number;
            form.value.shipload_id = data.shipload_id;
            measuresTableData.value = data.goods_measures;
          })
          .catch(() => {
            console.log("error submit");
          });
      }
    };

    const getItems = () => {
      let params = {
        page: pagination.value.page,
        per_page: pagination.value.rowsPerPage,
        bls: [] as any,
        bookings: [] as any,
      };
      if (props.bl) {
        params.bls = [props.father_id];
      } else {
        params.bookings = [props.father_id];
      }
      store.commit("setLoading", false);
      ApiService.query(`/api/dispatches`, {
        params: params,
      })
        .then(({ data }) => {
          if ("dispatches" in data) {
            dataList.value = data.dispatches;
            dataObject.value = [];
            data.dispatches.forEach((x) => {
              dataObject.value.push({
                id: x.id,
                dispatch_date: x.dispatch_date,
                registration_number:
                  x.shipload.equipment_involved.registered_equipment
                    .registration_number,
                goods_description: x.shipload.goods.description,
                associated_doc_type: x.customs_situation_document_id
                  ? x.customs_situation_document.associated_doc_type
                  : "",
                customs_situation: x.customs_situation_document_id
                  ? x.customs_situation_document.customs_situation
                  : "",
                customs_document_number: x.customs_document_number,
                doc_description: x.customs_situation_document_id
                  ? x.customs_situation_document.doc_description
                  : "",
                mask: x.customs_situation_document_id
                  ? x.customs_situation_document.mask
                  : "",
              });
            });
            pagination.value.total = data.pagination.total;
            pagination.value.page = data.pagination.current_page;
            pagination.value.last_page = data.pagination.last_page;
            pagination.value.rowsPerPage = 10;
            data.dispatches.forEach((x) => {
              store.commit("setDispatches", x);
            });
          }
        })
        .catch(() => {
          console.log("error submit");
        });
    };

    onMounted(() => {
      getCustomsDocs();
      getItems();
    });

    return {
      canCreate,
      canShow,
      canUpdate,
      canDelete,
      isLoading,
      showTable,
      header,
      dataObject,
      tableButtons,
      form,
      customs_docs,
      measuresTableData,
      shiploads,
      shipload_id,
      pagination,
      currentPageChange,
      setItemsPerPage,
      onNewClick,
      onCancel,
      actionsButton,
      createGDMElement,
      removeGDMElement,
      onSubmit,
      selectCustomsDocs,
      user,
    };
  },
});
