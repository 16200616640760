
import {onMounted, ref, watch, defineComponent, computed} from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "BLsSelect",
  props: {
    bl_id: {
      type: [Number, Array],
      default: undefined,
    },
    dataList: {
      type: Array,
      default: () => [],
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:bl_id"],

  setup: function (props) {
    const { t } = useI18n();
    const route = useRoute();
    const maritimeId = computed(() => store.getters.maritimeId);
    const inputBLs = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isBlRequired = (value) => {
      if (props.required && !value) {
        return t("rbookingid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
        "bls",
        isBlRequired
    );

    const selectBLs = (query) => {
      if (query !== "") {
        inputBLs.value.loading = true;
        setTimeout(() => {
          inputBLs.value.loading = false;
          inputBLs.value.options = inputBLs.value.list.filter(
              (item: Record<string, any>) => {
                if (item.name) {
                  return (
                      item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                  );
                }
              }
          );
          console.log(
              "inputBLs.value.options.length",
              inputBLs.value.options.length
          );
          if (inputBLs.value.options.length === 0) {
            const paramsRoute = route.params;
            ApiService.query(`/api/bills_of_lading/lists`, {
              params: {
                per_page: 10,
                bl_number: query,
                maritime_files: [maritimeId.value],
              },
            }).then(({ data }) => {
              data.bills_of_lading.forEach((item) => {
                store.commit("addBLTable", item);
              });
              inputBLs.value.list = data.bills_of_lading;
              inputBLs.value.options = data.bills_of_lading;
            });
          }
        }, 200);
      } else {
        inputBLs.value.options = [];
      }
    };

    watch(
        () => props.bl_id,
        (first) => {
          element_id.value = first;
          /* const element = props.dataList.find((e) => Object(e).id === first);
          element_id.value = element ? Object(element).id : undefined;*/
        }
    );

    onMounted(() => {
      inputBLs.value.list = props.dataList;
      inputBLs.value.options = props.dataList;
      const element = props.dataList.find(
          (e) => Object(e).id === props.bl_id
      );
      element_id.value = element ? Object(element).id : undefined;
    });

    return {
      element_id,
      inputBLs,
      errorMessage,
      selectBLs,
    };
  },
});
