
import CurrencySelect from "@/components/catalogs-select/CurrencySelect.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { Form, Field, ErrorMessage } from "vee-validate";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "CurrencyExchange",
  components: {
    Form,
    Field,
    ErrorMessage,
    CurrencySelect,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const search = ref("");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "currency exchange: create").length >
            0
        );
      }
      return false;
    });

    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "currency exchange: update").length >
            0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "currency exchange: show").length >
            0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "currency exchange: delete").length >
            0
        );
      }
      return false;
    });

    const forceDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "currency exchange: force_delete").length >
            0
        );
      }
      return false;
    });

    const dataObject = ref([]) as any;
    const filterOrCurrencies = ref([]) as any;
    const filterExCurrencies = ref([]) as any;
    const filterTraffic = "";
    const routeId = ref(route.params.id);
    const submitButton = ref<HTMLElement | null>(null);
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const trafficType = ref([
      { value: "I", label: "Import" },
      { value: "E", label: "Export" },
    ]);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
    ]);
    const header = ref([
      { column: t("torigincurrencies"), key: "origin_currency" },
      { column: t("texchangecurrencies"), key: "exchange_currency" },
      { column: t("titraffic_type"), key: "trafic_type_code" },
      { column: t("dispatch_date"), key: "exchange_date" },
      { column: t("tiexchangesale"), key: "exchange_sale" },
      { column: t("tiexchangepurchase"), key: "exchange_purchase" },
    ]);
    const form = ref({
      id: null,
      maritime_file_id: maritimeFileId.value,
      origin_currency_id: undefined,
      exchange_currency_id: undefined,
      exchange_date: "",
      trafic_type: "",
      shipper_exchange: "",
      exchange_sale: 0,
      exchange_purchase: 0,
      conference: "0",
    });
    const editExchange = ref(false);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: 10,
      last_page: 1,
      total: 1,
    });

    const setItemsPerPage = (event) => {
      pagination.value.page =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const onSearch = () => {
      while (dataObject.value.length > 0) {
        dataObject.value.pop();
      }
      serverSearch();
    };

    const serverSearch = () => {
      store.commit("setLoading", false);
      dataObject.value = [];
      store.commit("setLoading", false);
      ApiService.query(
        `api/currency_exchanges?page=${pagination.value.page}&per_page=${pagination.value.rowsPerPage}`,
        {
          params: {
            maritime_files: [maritimeFileId.value],
            origin_currencies: filterOrCurrencies.value,
            exchange_currencies: filterExCurrencies.value,
            trafic_type: filterTraffic,
          },
        }
      ).then(({ data }) => {
        pagination.value.total = data.pagination.total;
        pagination.value.page = data.pagination.current_page;
        pagination.value.last_page = data.pagination.last_page;
        data.currency_exchanges.forEach((x) => {
          dataObject.value.push({
            id: x.id,
            maritime_file_id: x.maritime_file_id,
            origin_currency_id: x.origin_currency_id,
            origin_currency: x.origin_currency_id
              ? x.origin_currency.code
              : "-",
            exchange_currency_id: x.exchange_currency_id,
            exchange_currency: x.exchange_currency_id
              ? x.exchange_currency.code
              : "-",
            exchange_date: x.exchange_date,
            trafic_type: x.trafic_type,
            trafic_type_code: x.trafic_type === "I" ? "Import" : "Export",
            shipper_exchange: x.shipper_exchange,
            exchange_sale: x.exchange_sale,
            exchange_purchase: x.exchange_purchase,
            conference: x.conference,
          });
        });
      });
    };

    const onNewClick = () => {
      editExchange.value = !editExchange.value;
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        const element = dataObject.value.find((b) => b.id == param1);
        form.value.id = element.id;
        form.value.maritime_file_id = maritimeFileId.value;
        form.value.origin_currency_id = element.origin_currency_id;
        form.value.exchange_currency_id = element.exchange_currency_id;
        form.value.exchange_date = element.exchange_date;
        form.value.trafic_type = element.trafic_type;
        form.value.shipper_exchange = element.shipper_exchange;
        form.value.exchange_sale = element.exchange_sale;
        form.value.exchange_purchase = element.exchange_purchase;
        form.value.conference = element.conference;
        editExchange.value = true;
      } else if (param2 === "REMOVE") {
        ApiService.delete(`/api/currency_exchanges/${param1}?force_delete=true`)
          .then(({ data }) => {
            const index = dataObject.value.findIndex((x) => x.id === param1);
            dataObject.value.splice(index, 1);
          })
          .catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const validateDecimal = (value) => {
      if (value) {
        const regexp = /^\d+(\.\d{1,8})?$/;
        if (!regexp.test(value)) {
          return t("rdecimal");
        }
        /*if (value.length > 8) {
          return t("rcodelength1");
        }*/
      }
      return true;
    };

    const onSubmit = () => {
      console.log("sadsdasd");
      if (submitButton.value) {
        form.value.exchange_date = moment(form.value.exchange_date).format(
          "YYYY-MM-DD"
        );
        if (!form.value.id) {
          submitButton.value.setAttribute("data-kt-indicator", "on");
          let payload = { ...form.value };
          ApiService.post(`/api/currency_exchanges`, payload)
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        } else {
          submitButton.value.setAttribute("data-kt-indicator", "on");
          let payload = { ...form.value };
          ApiService.put(`/api/currency_exchanges/${form.value.id}`, payload)
            .then(({ data }) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
        }
      }
    };

    const onCancel = () => {
      editExchange.value = false;
      form.value = {
        id: null,
        maritime_file_id: maritimeFileId.value,
        origin_currency_id: undefined,
        exchange_currency_id: undefined,
        exchange_date: "",
        trafic_type: "",
        shipper_exchange: "",
        exchange_sale: 0,
        exchange_purchase: 0,
        conference: "0",
      };
      serverSearch();
    };

    onMounted(() => {
      if (maritimeFileId.value === undefined) {
        store.commit("setMaritimeFileId", routeId);
      }
      serverSearch();
    });

    return {
      user,
      canCreate,
      canEdit,
      canShow,
      canDelete,
      forceDelete,
      search,
      filterOrCurrencies,
      filterExCurrencies,
      dataObject,
      tableButtons,
      pagination,
      header,
      trafficType,
      filterTraffic,
      form,
      editExchange,
      submitButton,
      onSearch,
      serverSearch,
      onNewClick,
      validateDecimal,
      setItemsPerPage,
      currentPageChange,
      actionsButton,
      onSubmit,
      onCancel,
    };
  },
});
