
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "CustomerEntityOfficeSelect",
  props: {
    entity_id: {
      type: [Number, Array],
      default: undefined,
    },
    office_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:entity_id", "changeEntity"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const entityData = computed(() => store.getters.Customer);
    const inputElements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isElementRequired = (value) => {
      if (props.required && !value) {
        return t("rentity");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("entity", isElementRequired);

    const getEntities = () => {
      const payload = {
        per_page: 25,
        type_entity_ids: [1, 13],
        office_id: props.office_id !== undefined ? [props.office_id] : [],
      };
      ApiService.query("api/entities/lists", {
        params: payload,
      }).then(({ data }) => {
        inputElements.value.list = data.entities;
        inputElements.value.options = data.entities;
      });
    };
    const selectElement = (query) => {
      if (query !== "") {
        const payload = {
          per_page: 25,
          name: query,
          type_entity_ids: [1, 13],
          office_id: props.office_id !== undefined ? [props.office_id] : [],
        };
        ApiService.query(`/api/entities/lists`, {
          params: payload,
        }).then(({ data }) => {
          data.entities.forEach((element) => {
            store.commit("addCustomer", element);
          });
          inputElements.value.list = data.entities;
          inputElements.value.options = data.entities;
        });
      } else {
        inputElements.value.options = [];
      }
    };

    watch(
      () => props.office_id,
      (first) => {
        if (props.mounted) {
          getEntities();
        }
      }
    );

    watch(
      () => props.entity_id,
      (first) => {
        element_id.value = first;
        const selected = inputElements.value.list.find((x) => x.id === first);
        if (selected) {
          emit("changeEntity", selected.name);
        }
      },{deep:true}
    );

    onMounted(() => {
      console.log("onMounted entityData",entityData.value);
      console.log("onMounted props.mounted",props.mounted);
      element_id.value=props.entity_id;
      if (props.mounted) {
        getEntities();
      } else {
        inputElements.value.list = entityData.value;
        inputElements.value.options = entityData.value;
      }
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      meta,
      getEntities,
      selectElement,
    };
  },
};
