
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import SelectBase from "@/components/Base/SelectBase.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import moment from "moment/moment";
import CurrencyExchange from "@/components/maritime-file/tabs/billing-tabs/CurrencyExchange.vue";
import BillingRegister from "@/views/facturation_data/bill_register.vue";
import { CustomerSelect, SPSelect } from "@/components/catalogs-select";
import SendBillingDialog from "@/components/dialogs/SendBillingDialog.vue";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "Billing",
  components: {
    CurrencyExchange,
    OfficeSelect,
    TableBasicSelect,
    SelectBase,
    BillingRegister,
    CustomerSelect,
    SPSelect,
    SendBillingDialog,
  },

  setup: function () {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const tab = computed(() => store.getters.getTabSelected);
    const search = ref("");
    const selected_bill = ref([] as any);
    const current_bill = ref(undefined as any);
    const currentId = ref(undefined);
    const showDelete = ref(false);
    const billingShow = ref(false);
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing: create").length >
          0
        );
      }
      return false;
    });
    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing: show").length > 0
        );
      }
      return false;
    });
    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing: update").length >
          0
        );
      }
      return false;
    });
    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing: delete").length >
          0
        );
      }
      return false;
    });
    const showCurrencyExchange = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "currency exchange: index")
            .length > 0
        );
      }
      return false;
    });

    const routeId = ref(route.params.id);
    const maritimeFileTab = computed(() => store.getters.maritimeFileTabItem);
    const activeName = ref("general");
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const filterDates = ref([]) as any;
    const billDates = ref([]) as any;
    const expDates = ref([]) as any;
    const accountingDates = ref([]) as any;
    const filterStatus = ref(null) as any;
    const filterOffices = ref(null) as any;
    const filterSeries = ref(null) as any;
    const filterBillNumbers = ref(null) as any;
    const filterReference = ref(null) as any;
    const filterCustomers = ref(null) as any;
    const filterSense = ref(null) as any;
    const filterExercise = ref(null) as any;
    const filterMaxBillNumber = ref(null) as any;
    const filterMinBillNumber = ref(null) as any;
    let currentMF = computed(() => store.getters.maritimeFileItem);
    const isPayed = ref(null);
    const dataObject = ref([]) as any;

    const customerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [1, 13],
      offices_id: [],
    });
    const serieSearch = ref<{
      offices_id: number[];
    }>({
      offices_id: [],
    });
    const list = ref({
      bill_sense: [
        {
          id: "E",
          label: t("iFacturaEmitida"),
        },
        {
          id: "R",
          label: t("iFacturaRecibida"),
        },
        {
          id: "A",
          name: t("iAbono"),
        },
        {
          id: "FR",
          name: t("iFacturaRectificada"),
        },
      ],
      billing_status: [
        {
          id: "A",
          label: t("iAnulada"),
        },
        {
          id: "C",
          label: t("iContabilizada"),
        },
        {
          id: "E",
          label: t("iEmitida"),
        },
        {
          id: "H",
          label: t("iValidacionRechazada"),
        },
        {
          id: "I",
          label: t("iBorrador"),
        },
        {
          id: "P",
          label: t("iPendienteValidar"),
        },
        {
          id: "R",
          label: t("iRecibida"),
        },
        {
          id: "V",
          label: t("iPendienteEnvioMail"),
        },
        {
          id: "X",
          label: t("iIncobrable"),
        },
      ],
      bill_sense_loading: false,
      payment_type_detail: [],
      offices: [],
      customer: [],
      currencies: [],
      taxesDetails: [] as any,
      billing_serie: [],
      bill_number: [] as any,
      payment_type_detail_loading: false,
      customer_loading: false,
      billing_serie_loading: false,
      billing_number_loading: false,
      in_search_billing_progress_billing_number: false,
      in_search_billing_progress_billing_reference: false,
      reference_loading: false,
      offices_loading: false,
      exercise: [] as any,
      reference: [] as any,
    });
    const filters = ref({
      maritime_voyages: [] as any,
      bookings: [] as any,
      bls: [] as any,
      equipments: [] as any,
      registered_equipments: [] as any,
    });
    const tableButtons = ref([
      {
        name: t("icontabilizar") as never,
        type: "CONTABILIZAR" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
    ]);
    const header = ref([
      { column: t("i_sense"), key: "sense" },
      { column: t("istatus"), key: "state" },
      { column: t("tbill_date"), key: "bill_date" },
      { column: t("tbill_total"), key: "bill_total" },
      { column: t("texpiration_date"), key: "expiration_date" },
      { column: t("tiBillOfLadings"), key: "bl_number" },
      { column: t("ireference"), key: "reference" },
      { column: t("icustomer"), key: "customer" },
      { column: t("icurrency"), key: "currency" },
    ]);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      last_page: 0,
      total: 0,
    });
    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const handleClick = (value) => {
      if (value === "exchange") {
        console.log("aasasas");
      }
    };

    const updateBookings = (value) => {
      filters.value.bookings = value;
    };

    const updateBLs = (value) => {
      filters.value.bls = value;
    };

    const onSearch = () => {
      while (dataObject.value.length > 0) {
        dataObject.value.pop();
      }
      serverSearch();
    };

    const getSeries = () => {
      store.commit("setLoading", false);
      ApiService.get(`/api/billing_serie/lists?page=1&per_page=10`).then(
        ({ data }) => {
          const dta = [] as any;
          data.billing_serie.forEach(function (value) {
            dta.push({
              name: value.code,
              id: value.id,
              companies: value.company_id,
            });
          });
          list.value.billing_serie = dta;
        }
      );

    };
    const loadBillingNumber = async () => {
      const maritimeId = maritimeFileId.value;
      try {
        list.value.billing_number_loading = true;
        const { data } = await ApiService.query('/api/billing', {
          params: {
            maritime_files: [maritimeId],
          },
        });

        const dta = [] as any;
        if (data && data.billing) {
          data.billing.forEach(function (value) {
            if (value.bill_number !== null && value.bill_number !== undefined) {
              dta.push({
                name: value.bill_number,
                id: value.id,
              });
            }
          });
        }

        if (!list.value.in_search_billing_progress_billing_number && list.value.billing_number_loading) {
          list.value.bill_number = dta.length ? dta : [];
          list.value.billing_number_loading = false;
        }
      } catch (error) {
        console.error("Error loading billing numbers:", error);
        list.value.bill_number = [];
        list.value.billing_number_loading = false;
      }
    };

    const searchBillingNumber = async (query) => {
      if (query !== "") {
        if (query.length >= 3) {
          list.value.in_search_billing_progress_billing_number = true;
          list.value.billing_number_loading = true;
          setTimeout(async () => {
            const filteredBillNumbers = list.value.bill_number.filter(
              (item: Record<string, any>) => {
                return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
              }
            );
            try {
              const maritimeId = maritimeFileId.value;
              const { data } = await ApiService.query(`/api/billing`, {
                params: {
                  per_page: 5,
                  bill_numbers: [query],
                  maritime_files: [maritimeId],
                },
              });

              const dta = [] as any;
              if (data && data.billing) {
                data.billing.forEach(function (value) {
                  if (value.bill_number !== null && value.bill_number !== undefined) {
                    dta.push({
                      name: value.bill_number,
                      id: value.id,
                    });
                  }
                });
              }

              if (list.value.in_search_billing_progress_billing_number && list.value.billing_number_loading) {
                list.value.bill_number = dta.length ? dta : [];
                list.value.billing_number_loading = false;
              }
            } catch (error) {
              console.error("Error searching billing numbers:", error);
              list.value.bill_number = [];
              list.value.billing_number_loading = false;
            } finally {
              list.value.in_search_billing_progress_billing_number = false;
            }
          }, 200);
        }
      } else {
        list.value.bill_number = [];
      }
    };

    const loadReference = async () => {
      const maritimeId = maritimeFileId.value;
      try {
        list.value.reference_loading = true;
        const { data } = await ApiService.query('/api/billing', {
          params: {
            maritime_files: [maritimeId],
          },
        });

        const uniqueNames = [] as any;
        const dta = data.billing.reduce((acc, value) => {
          if (value.reference !== null && value.reference !== undefined) {
            const lowerCaseName = value.reference.toLowerCase();
            if (!uniqueNames.includes(lowerCaseName)) {
              uniqueNames.push(lowerCaseName);
              acc.push({
                name: value.reference,
                id: value.id,
              });
            }
          }
          return acc;
        }, []);

        if (!list.value.in_search_billing_progress_billing_reference && list.value.reference_loading) {
          list.value.reference = dta.length ? dta : [];
          list.value.reference_loading = false;
        }
      } catch (error) {
        console.error("Error loading reference:", error);
        list.value.reference = [];
        list.value.reference_loading = false;
      }
    };

    const searchReference = async (query) => {
      if (query !== "") {
        if (query.length >= 3) {
          list.value.in_search_billing_progress_billing_reference = true;
          list.value.reference_loading = true;
          setTimeout(async () => {
            const filteredBillNumbers = list.value.reference.filter(
              (item: Record<string, any>) => {
                return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
              }
            );
            try {
              const maritimeId = maritimeFileId.value;
              const { data } = await ApiService.query(`/api/billing`, {
                params: {
                  per_page: 5,
                  reference: query,
                  maritime_files: [maritimeId],
                },
              });

              const uniqueNames = [] as any;
              const dta = data.billing.reduce((acc, value) => {
                if (value.reference !== null && value.reference !== undefined) {
                  const lowerCaseName = value.reference.toLowerCase();
                  if (!uniqueNames.includes(lowerCaseName)) {
                    uniqueNames.push(lowerCaseName);
                    acc.push({
                      name: value.reference,
                      id: value.id,
                    });
                  }
                }
                return acc;
              }, []);

              if (list.value.in_search_billing_progress_billing_reference && list.value.reference_loading) {
                list.value.reference = dta.length ? dta : [];
                list.value.reference_loading = false;
              }
            } catch (error) {
              console.error("Error searching reference:", error);
              list.value.reference = [];
              list.value.reference_loading = false;
            } finally {
              list.value.in_search_billing_progress_billing_reference = false;
            }
          }, 200);
        }
      } else {
        list.value.reference = [];
      }
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(
        `api/billing`,
        {
          params: {
            maritime_files: [maritimeFileId.value],
            min_created: filterDates.value.length
              ? moment(filterDates.value[0]).format("DD-MM-YYYY")
              : "",
            max_created: filterDates.value.length
              ? moment(filterDates.value[1]).format("DD-MM-YYYY")
              : "",
            min_bill: billDates.value.length
              ? moment(billDates.value[0]).format("DD-MM-YYYY")
              : "",
            max_bill: billDates.value.length
              ? moment(billDates.value[1]).format("DD-MM-YYYY")
              : "",
            min_expiration: expDates.value.length
              ? moment(expDates.value[0]).format("DD-MM-YYYY")
              : "",
            max_expiration: expDates.value.length
              ? moment(expDates.value[1]).format("DD-MM-YYYY")
              : "",
            min_accounting: accountingDates.value.length
              ? moment(accountingDates.value[0]).format("DD-MM-YYYY")
              : "",
            max_accounting: accountingDates.value.length
              ? moment(accountingDates.value[1]).format("DD-MM-YYYY")
              : "",
            status: filterStatus.value ? [filterStatus.value] : null,
            offices: filterOffices.value ? filterOffices.value : null,
            order_by_id: "DESC",
            series: filterSeries.value ? [filterSeries.value] : null,
            bill_numbers: filterBillNumbers.value ? filterBillNumbers.value : null,
            reference: filterReference.value ? filterReference.value : null,
            customers: filterCustomers.value ? [filterCustomers.value] : null,
            sense: filterSense.value ? [filterSense.value] : null,
            exercise: filterExercise.value ? filterExercise.value : null,
            min_bill_number: filterMinBillNumber.value,
            max_bill_number: filterMaxBillNumber.value,
            paid: isPayed.value ? isPayed.value : null,
            per_page: pagination.value.rowsPerPage,
            page: pagination.value.page,
          },
        }
      ).then(({ data }) => {
        pagination.value.total = data.pagination.total;
        pagination.value.page = data.pagination.current_page;
        pagination.value.last_page = data.pagination.last_page;
        dataObject.value = [];
        data.billing.forEach((x) => {
          const state = list.value.billing_status.find(
            (y) => y.id === x.billing_status
          );
          dataObject.value.push({
            id: x.id,
            bill_number: x.bill_number,
            bill_date: moment(x.bill_date).format("DD-MM-YYYY"),
            sense: `${x.billing_serie.code}-${x.bill_number === null ? "" : x.bill_number
              }`,
            expiration_date: moment(x.expiration_date).format("DD-MM-YYYY"),
            reference: x.reference,
            bill_total: x.bill_total,
            billing_status: x.billing_status,
            all_data: x,
            customer: x.customer_info.name,
            state: state ? state.label : "",
            currency: x.currency_exchange
              ? x.currency_exchange.exchange_currency.code
              : "",
            bl_number: x.bills_of_lading ? x.bills_of_lading.bl_number : '-',
          });
        });
        store.commit("setLoadingStatus", false);
      });
    };

    watch(
      () => dataObject.value,
      (first) => {
        if (first) {
          if (dataObject.value.length > 0) {
            if ("tab" in maritimeFileTab.value) {
              if ("billing" === maritimeFileTab.value.tab) {
                actionsButton(maritimeFileTab.value.item_id, "EDIT", null);
              }
            }
          }
        }
      },
      { deep: true }
    );
    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        current_bill.value = dataObject.value.find((x) => x.id === param1);
        store.commit("setcurrentMF", maritimeFile.value);
        billingShow.value = true;
      }
      if (param2 == "CONTABILIZAR") {
        contabilizeFacture(param1);
      }
      if (param2 == "REMOVE") {
        currentId.value = param1;
        showDelete.value = true;
      }
    };

    const showDialogDelete = (id) => {
      currentId.value = id;
      showDelete.value = true;
    };
    const deleteElement = () => {
      ApiService.delete(
        "/api/billing/" + currentId.value + "?force_delete=false"
      ).then(function (response) {
        showDelete.value = false;
        serverSearch();
      });
    };

    const contabilizeFacture = (row) => {
      doContabilize(row.id);
      serverSearch();
    };
    const contabilizeAllFacture = () => {
      selected_bill.value.forEach(async (x) => {
        await doContabilize(x.id);
      });
      serverSearch();
    };

    const doContabilize = async (id) => {
      await ApiService.patch("/api/billing/" + id, {
        billing_status: "C",
      });
    };

    onMounted(() => {
      // console.clear();
      if (maritimeFileId.value === undefined) {
        store.commit("setMaritimeFileId", routeId);
      }
      for (let i = new Date().getFullYear() + 1; i > 1990; i--) {
        list.value.exercise.push({
          id: i,
          name: i,
        });
      }
      getSeries();
      serverSearch();
    });

    const onAddBillEdit = (dta) => {
      selected_bill.value = dta;
    };

    const isSelectable = (row, index) => {
      return row.billing_status === "E";
    };

    const actionBackButon = () => {
      store.commit("removeMaritimeFileTab");
      serverSearch();
      billingShow.value = false;
    };

    const generateDocEquipmentsInvolves = async(scope) => {
      store.commit("setLoadingStatus", true);
      generateAttachmentEquipmentInvolves(scope.id).then(({data:responseAttach}) => {
        ApiService.setResponseTypeBlob();
        ApiService.get(`/api/messaging_panel/download/${responseAttach[0].id}`)
          .then((data) => {
            let fileUrl = window.URL.createObjectURL(data);
            let fileLink = document.createElement("a");

            fileLink.href = fileUrl;
            fileLink.setAttribute(
              "download",
              `${
                responseAttach[0].path
                  ? responseAttach[0].path.split("/")[1]
                  : responseAttach[0].filename
                  ? responseAttach[0].filename
                  : "File"
              }`
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            store.commit("setLoadingStatus", false);
          })
          .catch((error) => {
            Swal.fire({
              text: t("edipath_error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            console.log(error);
            store.commit("setLoadingStatus", false);
          });
        ApiService.setResponseTypeJson();
        if (!responseAttach.data[0].error) {
          ElNotification({
            title: "Success",
            message: t("bill_equipments_generated"),
            type: "success",
          });
          store.commit("addFileStoredData", responseAttach[0]);
        }
      }).catch((err) => {

      })
    }
    const generateAttachmentEquipmentInvolves = async (billingID) => {
      return await ApiService.query('/api/billing/attachment_equipments_involves', {
        params: {
          billing_id: billingID
        }
      })
    }
    
    const onSendEmail = async (element: any) => {
      let emails = [] as any;
      const { data } = await ApiService.query("/api/contacts", {
        params: {
          entity_id: [element.office_id],
          contact_type_id: [5],
        },
      });
      data.contacts.forEach((x: any) => {
        const communications = x.communications.filter(
          (y: any) => y.media.name === "Email"
        );
        communications.forEach((y: any) => {
          if (y.value && y.value !== "") {
            emails.push(y.value);
          }
        });
      });
      console.log(element)
      store.commit("setEmailItem", element.all_data.edi_paths);
      store.commit("setMails", emails);
      store.commit("setSendBillingDialog", true);
    };

    return {
      canCreate,
      canEdit,
      canShow,
      canDelete,
      showCurrencyExchange,
      activeName,
      filters,
      filterDates,
      billDates,
      expDates,
      accountingDates,
      filterStatus,
      filterOffices,
      filterSeries,
      filterBillNumbers,
      filterReference,
      filterCustomers,
      filterSense,
      filterExercise,
      filterMaxBillNumber,
      filterMinBillNumber,
      search,
      header,
      pagination,
      tableButtons,
      dataObject,
      list,
      selected_bill,
      isPayed,
      showDelete,
      billingShow,
      current_bill,
      tab,
      customerSearch,
      serieSearch,
      onSearch,
      serverSearch,
      searchBillingNumber,
      loadBillingNumber,
      searchReference,
      loadReference,
      setItemsPerPage,
      currentPageChange,
      updateBookings,
      updateBLs,
      actionsButton,
      getSeries,
      handleClick,
      onAddBillEdit,
      isSelectable,
      contabilizeAllFacture,
      deleteElement,
      actionBackButon,
      user,
      generateDocEquipmentsInvolves,
      onSendEmail,
    };
  },
});
