
import { onMounted, ref, watch, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "BookingSelect",
  props: {
    booking_id: {
      type: [Number, Array],
      default: undefined,
    },
    dataList: {
      type: Array,
      default: () => [],
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:booking_id"],

  setup: function (props) {
    const { t } = useI18n();
    const route = useRoute();
    const inputBooking = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isBookingRequired = (value) => {
      if (props.required && !value) {
        return t("rbookingid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "booking",
      isBookingRequired
    );

    const selectBooking = (query) => {
      if (query !== "") {
        inputBooking.value.loading = true;
        setTimeout(() => {
          inputBooking.value.loading = false;
          inputBooking.value.options = inputBooking.value.list.filter(
            (item: Record<string, any>) => {
              if (item.name) {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            }
          );
          console.log(
            "inputBooking.value.options.length",
            inputBooking.value.options.length
          );
          if (inputBooking.value.options.length === 0) {
            const paramsRoute = route.params;
            ApiService.query(`/api/bookings/lists`, {
              params: { per_page: 10, booking_number: query },
            }).then(({ data }) => {
              data.bookings.forEach((item) => {
                store.commit("setBooking", item);
              });
              inputBooking.value.list = data.bookings;
              inputBooking.value.options = data.bookings;
            });
          }
        }, 200);
      } else {
        inputBooking.value.options = [];
      }
    };

    watch(
      () => props.booking_id,
      (first) => {
        element_id.value = first;
        /* const element = props.dataList.find((e) => Object(e).id === first);
        element_id.value = element ? Object(element).id : undefined;*/
      }
    );

    onMounted(() => {
      inputBooking.value.list = props.dataList;
      inputBooking.value.options = props.dataList;
      const element = props.dataList.find(
        (e) => Object(e).id === props.booking_id
      );
      element_id.value = element ? Object(element).id : undefined;
    });

    return {
      element_id,
      inputBooking,
      errorMessage,
      selectBooking,
    };
  },
});
