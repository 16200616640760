
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "CurrencySelect",
  props: {
    currency_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    custom_class: {
      type: String,
      default: "custom-select w-100 p-0",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:currency_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const currenciesData = computed(() => store.getters.Currencies);
    const inputCurrency = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isCurrencyRequired = (value) => {
      if (props.required && !value) {
        return t("rcurrency");
      }
      return true;
    };

    const { errorMessage, value } = useField("currency", isCurrencyRequired);

    const getCurrencies = () => {
      if (props.load && !currenciesData.value.length) {
        store.commit("setLoading", false);
        ApiService.query(`/api/currencies/lists`, {
          params: {
            per_page: 25,
            name: "",
          },
        }).then(({ data }) => {
          store.commit("setCurrency", data.currencies);
          inputCurrency.value.list = data.currencies;
          inputCurrency.value.options = data.currencies;
        });
      } else {
        inputCurrency.value.list = currenciesData.value;
        inputCurrency.value.options = currenciesData.value;
      }
    };
    const getCurrency = async () => {
      ApiService.query(`/api/currencies/lists`, {
        params: {
          per_page: 25,
          name: "",
        },
      }).then(({ data }) => {
        data.currencies.forEach((item) => {
          store.commit("addCurrency", item);
        });
        inputCurrency.value.list = currenciesData.value;
        inputCurrency.value.options = data.currencies;
      });
    };
    const selectCurrency = (query) => {
      if (query !== "") {
        store.commit("setLoading", false);
        ApiService.query(`/api/currencies/lists`, {
          params: {
            per_page: 25,
            name: query,
          },
        }).then(({ data }) => {
          data.currencies.forEach((item) => {
            store.commit("addCurrency", item);
          });
          inputCurrency.value.list = currenciesData.value;
          inputCurrency.value.options = data.currencies;
        });
      } else {
        inputCurrency.value.options = currenciesData.value;
      }
    };

    watch(
      () => props.currency_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => currenciesData.value,
      (first) => {
        inputCurrency.value.list = first;
        inputCurrency.value.options = first;
      }
    );

    onMounted(() => {
      getCurrencies();
      setTimeout(function () {
        element_id.value = props.currency_id;
      }, 500);
    });

    return {
      element_id,
      inputCurrency,
      errorMessage,
      selectCurrency,
      getCurrency,
    };
  },
};
