

export default {
  name: "VideoPlayer",
  props: {
    url: {
      type: String,
      default: "",
    },
  },
};
