
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { now } from "moment";
import moment from "moment/moment";

export default {
  name: "EventTE",
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: update"
        ) !== -1
      );
    });

    const loading = ref(false);
    const EventList = computed(() => store.getters.EventList);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const elements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const addressData = ref({
      loading: false,
      options: [],
      list: [],
    });
    const participant_type = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;
    const contactByKey = ref([]) as any;
    const contactData = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "event_type_id", prop: "event_type_id" },
      { label: "idate", prop: "date" },
      { label: "observations", prop: "observations" },
    ]);
    const headerTable = ref(headers.value);

    const getElements = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/event_types/lists?per_page=100").then(function (
        response
      ) {
        participant_type.value.options = response.data.event_types;
      });
    };

    const formatDate = (fecha) => {
      let month = (fecha.getMonth() + 1).toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      const date_format =
        fecha.getFullYear() +
        "-" +
        month +
        "-" +
        fecha.getDate() +
        " " +
        fecha.getHours() +
        ":" +
        fecha.getMinutes();

      return date_format;
    };

    onMounted(() => {
      getElements();
      if (EventList.value.length <= 0) {
        const today = new Date();
        const tomorrow = new Date();
        const later = new Date();
        today.setDate(today.getDate());
        tomorrow.setDate(tomorrow.getDate() + 1);
        later.setDate(later.getDate() + 10);
        console.log(formatDate(today));
        console.log(formatDate(tomorrow));
        console.log(formatDate(later));
        EventList.value.push({
          date_time: formatDate(today),
          event_type_id: 8,
          id: undefined,
          observations: "",
          transportation_order_id: undefined,
        });
        EventList.value.push({
          date_time: formatDate(tomorrow),
          event_type_id: 9,
          id: undefined,
          observations: "",
          transportation_order_id: undefined,
        });
        EventList.value.push({
          date_time: formatDate(later),
          event_type_id: 1,
          id: undefined,
          observations: "",
          transportation_order_id: undefined,
        });
      }
    });

    return {
      canCreate,
      canEdit,
      participant_type,
      EventList,
      loading,
      elements,
      contactData,
      addressData,
      headers,
      headerTable,
    };
  },
};
