
import BookingSelect from "@/components/catalogs-select/BookingSelect.vue";
import MaritimeTripTypesSelect from "@/components/catalogs-select/MaritimeTripTypesSelect.vue";
import CustomerEntityOfficeSelect from "@/components/catalogs-select/CustomerEntityOfficeSelect.vue";
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import EquipmentBLSection from "@/components/maritime-file/sections/EquipmentBLSection.vue";
import BookingRoutesTE from "@/components/maritime-file/tables-editable/BookingRoutesTE.vue";
import ParticipantsTE from "@/components/maritime-file/tables-editable/ParticipantsTE.vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { Form, useField } from "vee-validate";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { SPSelect } from "@/components/catalogs-select";
import GeneralUseSelect from "@/components/catalogs-select/GeneralUseSelect.vue";


export default {
  name: "BLForm",
  components: {
    SPSelect,
    BookingSelect,
    MaritimeVoyageSelect,
    MaritimeTripTypesSelect,
    CustomerEntityOfficeSelect,
    ParticipantsTE,
    BookingRoutesTE,
    EquipmentBLSection,
    Form,
    GeneralUseSelect
  },
  props: {
    blnumberf: {
      type: String,
      default: "",
    },
    bldatef: {
      type: String,
      default: "",
    },
    blf: {
      type: Object,
      default() {
        return {
          bl_number: "",
          bl_date: "",
          booking_id: undefined,
          customer_id: undefined,
        };
      },
    },
    formf: {
      type: Object,
      default() {
        return {
          id: undefined,
          maritime_voyage_id: undefined,
          trip_type_id: undefined,
          booking_number: "",
          is_bl: true,
          booking_date: "",
          edi_path_id: undefined,
          reference: "",
        };
      },
    },
    originalBL: {
      type: Object,
      default() {
        return {
          id: null,
          maritime_voyage_id: undefined,
          trip_type_id: undefined,
          booking_number: "",
          booking_date: "",
          edi_path_id: undefined,
          reference: "",
        };
      },
    },
    transshipmentf: {
      type: Object,
      default() {
        return {
          maritime_voyage_id: undefined,
          remarks: "",
        };
      },
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["editBooking", "onCancelClick"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "bills of lading: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "bills of lading: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "bills of lading: delete"
        ) !== -1
      );
    });
    const showBookingRoutes = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "booking routes: index"
        ) !== -1
      );
    });
    const showEquipmentsInvolveds = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "equipment involved: index"
        ) !== -1
      );
    });

    const editBooking = ref(false);
    const addBooking = ref(false);
    const currentBl = computed(() => store.getters.BLElement);
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const bookingTableData = computed(() => store.getters.Bookings);
    const dataObject = computed(() => store.getters.BookingsBLTable);
    const transshipmentTableData = computed(() => store.getters.Transshipments);
    const routeTableData = computed(() => store.getters.BookingRoutesBL);
    const participantTableData = computed(() => store.getters.ParticipantsBL);
    const billOfLandingTableData = computed(() => store.getters.BillsOfLanding);
    const shiploadTableData = computed(() => store.getters.ShiploadsBL);
    const goodTableData = computed(() => store.getters.GoodsBL);
    const goodMeasureTableData = computed(() => store.getters.GoodsMeasuresBL);
    const equipmentTableData = computed(() => store.getters.EquipmentsBL);
    const viewTranshipment = computed(() => form.value.trip_type_id === 3);
    const voyagesTableData = computed(() => store.getters.MaritimeVoyage);
    const goodImoData = computed(() => store.getters.GoodsImoBL);
    const goodImoMeasureData = computed(() => store.getters.GoodsImoMeasuresBL);
    const overdimensionsTableData = computed(
      () => store.getters.OverdimensionsBL
    );

    const voyageType = ref("");
    const activeNames = ref("1");
    const bl = ref(props.blf);
    const form = ref(props.formf);
    const transshipment = ref(props.transshipmentf);
    const dialogaprove = ref(false);
    const extra = ref("");

    const isBLNumberRequired = (value) => {
      if (!value) {
        return t("rbillofladingnumber");
      }
      return true;
    };

    const isBLRequired = (value) => {
      if (!value) {
        return t("rbillofladingdate");
      }
      return true;
    };

    const { errorMessage: numberError, value: blnumber } = useField(
      "bl_number",
      isBLNumberRequired
    );
    const { errorMessage: dateError, value: bldate } = useField(
      "bl_date",
      isBLRequired
    );

    watch(
      () => form.value.trip_type_id,
      (first) => {
        if (first) {
          if (
            (voyageType.value === "E" && first === 2) ||
            (voyageType.value === "I" && first === 1)
          ) {
            form.value.trip_type_id = undefined;
            ElNotification({
              title: "Error",
              message: t("rbookingvoyagetype"),
              type: "error",
            });
          }
        }
      }
    );

    watch(
      () => bl.value.bl_number,
      (first) => {
        blnumber.value = first;
      }
    );

    watch(
      () => bl.value.bl_date,
      (first) => {
        bldate.value = first;
      }
    );
    

    watch(
      () => transshipment.value.maritime_voyage_id,
      (first) => {
        if (first) {
          const voyage = voyagesTableData.value.find((v) => v.id === first);
          extra.value = voyage
            ? `${
                voyage.maritime_file.port ? voyage.maritime_file.port.name : ""
              } - ${
                voyage.maritime_file.vessel
                  ? voyage.maritime_file.vessel.name
                  : ""
              }`
            : "";
        } else {
          extra.value = "";
        }
      }
    );

    watch(
      () => blnumber.value,
      (newBlnumber, oldBlnumber) => {
        if (currentBl.value && newBlnumber) {
          form.value.bill_of_lading_type_id = currentBl.value.bill_of_lading_type_id;
          form.value.next_previous_transport_method_id = currentBl.value.next_previous_transport_method_id;
        }
      }
    );
    const updateBooking = (value) => {
      store.commit("clearMaritimeVoyage");
      emit("editBooking", {
        booking_id: value,
        type: "EDIT",
      });
    };

    const checkMaritimeVotage = () => {
      if (
        form.value.id &&
        form.value.maritime_voyage_id !== props.originalBL.maritime_voyage_id
      ) {
        const item = voyagesTableData.value.find(
          (v) => v.id === form.value.maritime_voyage_id
        );
        if (
          item &&
          item.maritime_file.office_id !== maritimeFile.value.office_id
        ) {
          dialogaprove.value = true;
          return;
        }
        onSubmit();
      } else {
        onSubmit();
      }
    };

    const updateVoyage = (value) => {
      voyageType.value = value;
    };

    const createTSElement = () => {
      if (transshipmentTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        transshipmentTableData.value.forEach((item, index) => {
          if (
            item.maritime_voyage_id === "" ||
            item.booking_id === "" ||
            item.remarks === ""
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addTSLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rtseditabletable"),
            type: "error",
          });
        }
      } else {
        addTSLine();
      }
    };

    const addTSLine = () => {
      store.commit("setTransshipment", {
        maritime_voyage_id: "",
        remarks: "",
      });
    };

    const removeTSItem = (index) => {
      store.commit("removeTransshipment", index);
    };

    const createBRElement = () => {
      if (routeTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        routeTableData.value.forEach((item, index) => {
          if (item.route_type_id === "" || item.location_id === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addBRLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rbreditabletable"),
            type: "error",
          });
        }
      } else {
        addBRLine();
      }
    };

    const addBRLine = () => {
      store.commit("setBookingRouteBL", {
        route_type_id: "",
        location_id: "",
        order: "",
        remarks: "",
      });
    };

    const removeBRItem = (index) => {
      store.commit("removeBookingRouteBL", index);
    };

    const createPElement = () => {
      if (participantTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        participantTableData.value.forEach((item, index) => {
          if (item.participant_type_id === "" || item.booking_id === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addPLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rpeditabletable"),
            type: "error",
          });
        }
      } else {
        addPLine();
      }
    };

    const addPLine = () => {
      store.commit("setParticipantBL", {
        entity_id: "",
        participant_type_id: "",
        entity_data: "",
      });
    };

    const removePItem = (index) => {
      store.commit("removeParticipantBL", index);
    };

    const createBLElement = () => {
      if (billOfLandingTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        billOfLandingTableData.value.forEach((item, index) => {
          if (item.bl_date === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addBLLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rbleditabletable"),
            type: "error",
          });
        }
      } else {
        addBLLine();
      }
    };

    const addBLLine = () => {
      store.commit("setBillOfLanding", {
        bl_date: "",
      });
    };

    const removeBLItem = (index) => {
      store.commit("removeBillOfLanding", index);
    };

    const resetFields = () => {
      store.commit("resetBLTab");
      form.value.id = undefined;
      form.value.maritime_voyage_id = undefined;
      form.value.trip_type_id = undefined;
      form.value.booking_number = "";
      form.value.booking_date = "";
      form.value.reference = "";
      form.value.manifest_charge = "";
      form.value.manifest_discharge = "";
      form.value.summary_declaration_charge = "";
      form.value.summary_declaration_discharge = "";
      bl.value.bl_date = "";
      bl.value.has_ens = "";
      bl.value.reference = "";
      bl.value.booking_id = undefined;
      bl.value.id = undefined;
      bl.value.bl_number = "";
      bl.value.customer_id = undefined;
      bl.value.manifest_charge = "";
      bl.value.manifest_discharge = "";
      form.value.humanitary_aid = "";
      form.value.accoutrements = "";
      form.value.military_purposes = "";
      form.value.bill_of_lading_type_id = undefined;
      form.value.next_previous_transport_method_id = undefined;
      form.value.summary_declaration_discharge = 0;
      bldate.value = "";
      bl.value.summary_declaration_discharge = "";
      bldate.value = "";
      bldate.value = "";
      blnumber.value = "";
      transshipment.value.maritime_voyage_id = undefined;
      transshipment.value.remarks = "";
    };

    const onCancel = () => {
      resetFields();
      editBooking.value = false;
      addBooking.value = false;
      store.commit("setBLElement", undefined);
      emit("onCancelClick");
    };

    const onSubmit = () => {
      dialogaprove.value = false;
      let payload = { ...form.value };
      if (
        transshipment.value.maritime_voyage_id !== undefined &&
        transshipment.value.remarks !== ""
      ) {
        payload["transshipment"] = transshipment.value;
      }
      payload["booking_routes"] = routeTableData.value;
      payload["participants"] = participantTableData.value;
      payload["bills_of_lading"] = [
        {
          id: bl.value.id,
          bl_date: bldate.value,
          bl_number: blnumber.value,
          customer_id: bl.value.customer_id,
          summary_declaration_charge: form.value.summary_declaration_charge,
          summary_declaration_discharge: form.value.summary_declaration_discharge,
          manifest_discharge: form.value.manifest_discharge,
          manifest_charge: form.value.manifest_charge,
          has_ens: form.value.has_ens === "1",
          reference: form.value.reference,
          humanitary_aid: form.value.humanitary_aid === "1",
          military_purposes: form.value.military_purposes === "1",
          accoutrements: form.value.accoutrements === "1",
          bill_of_lading_type_id: form.value.bill_of_lading_type_id,
          next_previous_transport_method_id: form.value.next_previous_transport_method_id
        },
      ];

      payload["booking_routes"].forEach((x, index) => {
        x.order = index + 1;
      });

      shiploadTableData.value.forEach((x) => {
        x.goods_measures = overdimensionsTableData.value.filter(
          (o) => o.equipment_id === x.id
        );
      });
      payload["equipment_involveds"] = shiploadTableData.value;

      let finalGoods = [] as any;
      // Creo un map para asociar los dangerous_goods a un good
      const dangerousGoodsMap = new Map();
      // Lleno el map con los dangerous_goods asociados a los good
      goodImoData.value.forEach((value) => {
        const goodId = value.good_id;
        if (!dangerousGoodsMap.has(goodId)) {
          dangerousGoodsMap.set(goodId, []);
        }
        dangerousGoodsMap.get(goodId).push({
          dangerous_goods_type_id: value.dangerous_goods_type_id,
          name: value.name,
          packaging_type_id: value.packaging_type_id,
          shipper: null,
          sea_pollutant: value.sea_pollutant,
          flash_point: value.flash_point,
          goods_measures: goodImoMeasureData.value.filter((m) => m.dangerous_goods_id === value.id),
        });
      });

      goodTableData.value.forEach((x) => {
        const goodsMeasure = goodMeasureTableData.value.filter((g) => g.good_id === x.id);
        const equipments = equipmentTableData.value.filter((g) => g.good_id === x.id);

        const dangerousGoods = dangerousGoodsMap.get(x.id) || [];

        goodsMeasure.forEach((y) => {
          if ("new_item" in y) {
            delete y["good_id"];
          }
        });

        equipments.forEach((y) => {
          const equipmentsGoodMeasures = overdimensionsTableData.value.filter((o) => o.equipment_id === y.id);
          if ("new_item" in y) {
            delete y["good_id"];
            delete y["id"];
          }
          y.goods_measures = equipmentsGoodMeasures;
        });

        const good = { ...x };
        good.goods_measures = goodsMeasure;
        good.equipments = equipments;
        good.dangerous_goods = dangerousGoods;
        finalGoods.push(good);
      });

      payload["goods"] = finalGoods;

      ApiService.put(`/api/bookings/${bl.value.booking_id}`, payload)
        .then(({ data }) => {
          store.commit("setLoadingStatus", true);
          store.dispatch("GET_LOGACTIVITIES_API");
          store.commit("updateBooking", data);
          store.commit("setBookingElement", data);
          updateInfo(data);
          const bl = data.bills_of_lading.find(
            (x) => x.id === currentBl.value.id
          );
          console.log("currentBl ", currentBl);
          /*bl.equipment_involved_types_summary = currentBl.value.equipment_involved_types_summary;
          bl.equipment_involveds_summary = currentBl.value.equipment_involveds_summary;*/
          console.log("edit bl ", bl);
          if (bl) {
            store.commit("setBLElement", bl);
          }
          if (data.maritime_voyage.maritime_file_id == maritimeFileId.value) {
            const routes = data.routes.map((x) => x.location.full_name);
            const participants = data.participants.map((x) => x.entity?.name);
            const shiploads = data.equipment_involveds.map(
              (x) => x.equipment?.name
            );
            store.commit("updateBookingTable", {
              id: data.id,
              maritime_voyage_id: data.maritime_voyage.code,
              trip_type_id: data.trip_type.name,
              booking_number: data.booking_number,
              booking_date: data.booking_date,
              edi_path_id: data.edi_path_id,
              reference: data.reference,
              booking_route: routes
                .filter((x, index) => {
                  return routes.indexOf(x) === index;
                })
                .join(", "),
              participants: participants
                .filter((x, index) => {
                  return participants.indexOf(x) === index;
                })
                .join(", "),
              shiploads: shiploads
                .filter((x, index) => {
                  return shiploads.indexOf(x) === index;
                })
                .join(", "),
            });
            const index = dataObject.value.findIndex(
              (x) => x.booking_id == data.id
            );
            const payload = {
              id: data.id,
              bl_date: data.bills_of_lading[0].bl_date,
              bl_number: data.bills_of_lading[0].bl_number,
              bl_customer: data.bills_of_lading[0].customer_id,
              maritime_voyage_id: data.maritime_voyage.code,
              trip_type_id: data.trip_type.name,
              booking_number: data.booking_number,
              booking_date: data.booking_date,
              edi_path_id: data.edi_path_id,
              reference: data.reference,
              booking_route: routes
                .filter((x, index) => {
                  return routes.indexOf(x) === index;
                })
                .join(", "),
              participants: participants
                .filter((x, index) => {
                  return participants.indexOf(x) === index;
                })
                .join(", "),
              shiploads: shiploads
                .filter((x, index) => {
                  return shiploads.indexOf(x) === index;
                })
                .join(", "),
              buttons: [
                { type: "EDIT", id: data.id },
                { type: "REMOVE", id: data.id },
              ],
            };
            if (index !== -1) {
              store.commit("updateBLTable", payload);
            } else {
              store.commit("setBLTable", payload);
            }
          } else {
            const index = bookingTableData.value.findIndex(
              (x) => x.id === data.id
            );
            store.commit("removeBL", index);
            store.commit("removeBLTable", index);
          }
          store.commit("setLoadingStatus", false);
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    const updateInfo = (data) => {
      console.log("ASASASASASASASASSS", data)
      let booking_routes = [] as any;
      let participants = [] as any;
      let equip_inv = [] as any;
      let g = [] as any;
      let m = [] as any;
      let e = [] as any;
      let o = [] as any;
      let d = [] as any;
      let dm = [] as any;
      data.routes.forEach((x) => {
        booking_routes.push({
          id: x.id,
          booking_id: x.booking_id,
          route_type_id: x.route_type_id,
          location_id: x.location_id,
          order: x.order,
          remarks: x.remarks,
        });
      });
      data.participants.forEach((x) => {
        participants.push({
          id: x.id,
          booking_id: x.booking_id,
          entity_id: x.entity_id,
          participant_type_id: x.participant_type_id,
          entity_data: x.entity_data,
        });
      });
      data.equipment_involveds.forEach((x) => {
        equip_inv.push({
          id: x.id,
          bl_id: x.bl_id,
          registered_equipment_id: x.registered_equipment_id,
          equipment_id: x.equipment_id,
          connected_onboard: x.connected_onboard === 1 ? "1" : "0",
          connected_in_station: x.connected_in_station === 1 ? "1" : "0",
          connected_transport: x.connected_transport === 1 ? "1" : "0",
          equipment_supplier: x.equipment_supplier,
          seal_number: x.seal_number,
          is_empty: x.is_empty === 1 ? "1" : "0",
        });
        x.goods_measures.forEach((z) => {
          o.push({
            id: z.id,
            equipment_id: z.equipment_involved_id,
            measure_type_id: z.measure_type_id,
            measure_unit_id: z.measure_unit_id,
            measure: z.measure,
            remarks: z.remarks,
          });
        });
      });
      data.goods.forEach((x) => {
        console.log("x", x);
        g.push({
          id: x.id,
          bl_id: x.bl_id,
          customs_tariff_code_id: x.customs_tariff_code_id,
          packaging_type_id: x.packaging_type_id,
          description: x.description,
          additional_id: x.additional_id,
          imo: x.imo === 1 ? "1" : "0",
          shipment_type: x.shipment_type,
          customs_status: x.customs_status,
          bl_type_mmpp: x.bl_type_mmpp,
          departure_order_export: x.departure_order_export,
          departure_order_import: x.departure_order_import,
        });
        x.goods_measures.forEach((y) => {
          m.push({
            id: y.id,
            good_id: y.goods_id,
            measure_type_id: y.measure_type_id,
            measure_unit_id: y.measure_unit_id,
            measure: y.measure,
            remarks: y.remarks,
          });
        });
        x.dangerous_goods.forEach((y) => {
          console.log("y", y);
          d.push({
            id: y.id,
            good_id: y.good_id,
            name: y.name,
            packaging_type_id: y.packaging_type_id,
            p_s_n: y.dangerous_goods_type_id,
          });
          y.goods_measures.forEach((z) => {
            dm.push({
              id: z.id,
              dangerous_goods_id: y.id,
              measure_type_id: z.measure_type_id,
              measure_unit_id: z.measure_unit_id,
              measure: z.measure,
              remarks: z.remarks,
            });
          });
        });
        x.shiploads.forEach((y) => {
          e.push({
            id: y.id,
            good_id: y.good_id,
            identification:
              y.equipment_involved_id &&
              y.equipment_involved.registered_equipment_id
                ? y.equipment_involved.registered_equipment.registration_number
                : "",
            equipment_type: y.equipment_involved_id
              ? y.equipment_involved.equipment_id
              : "",
          });
        });
      });
      store.commit("updateBookingRouteBL", booking_routes);
      store.commit("updateParticipantBL", participants);
      store.commit("updateShiploadBL", equip_inv);
      store.commit("updateGoodBL", g);
      store.commit("updateGoodMeasuresBL", m);
      store.commit("updateEquipmentsBL", e);
      store.commit("updateOverdimensionsBL", o);
      store.commit("updateImoGoodBL", d);
      store.commit("updateGoodImoMeasureBL", dm);
    };

    onMounted(() => {
      console.log("onMounted BlForm bl", bl.value);
      blnumber.value = bl.value.bl_number !== "" ? bl.value.bl_number : "";
      bldate.value = bl.value.bl_date !== "" ? bl.value.bl_date : "";
      bldate.value = bl.value.bl_date !== "" ? bl.value.bl_date : "";
      
       if (currentBl.value && form) {
         form.value.bill_of_lading_type_id = currentBl.value.bill_of_lading_type_id;
         form.value.next_previous_transport_method_id = currentBl.value.next_previous_transport_method_id;
       }

    });

    onUnmounted(() => {
      resetFields();
      editBooking.value = false;
      addBooking.value = false;
      store.commit("setBLElement", undefined);
      emit("onCancelClick");
    });

    return {
      canCreate,
      canEdit,
      canDelete,
      showBookingRoutes,
      showEquipmentsInvolveds,
      bl,
      form,
      extra,
      viewTranshipment,
      transshipment,
      editBooking,
      addBooking,
      dataObject,
      bookingTableData,
      transshipmentTableData,
      routeTableData,
      participantTableData,
      billOfLandingTableData,
      shiploadTableData,
      maritimeFile,
      goodTableData,
      blnumber,
      bldate,
      numberError,
      dateError,
      dialogaprove,
      voyagesTableData,
      activeNames,
      checkMaritimeVotage,
      updateBooking,
      updateVoyage,
      onCancel,
      createTSElement,
      removeTSItem,
      createBRElement,
      removeBRItem,
      createPElement,
      removePItem,
      createBLElement,
      removeBLItem,
      onSubmit,
    };
  },
};
