import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-xl-12 file-container" }
const _hoisted_2 = { class: "tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaritimeFileTabs = _resolveComponent("MaritimeFileTabs")!
  const _component_OverviewTab = _resolveComponent("OverviewTab")!
  const _component_Booking = _resolveComponent("Booking")!
  const _component_BillOfLading = _resolveComponent("BillOfLading")!
  const _component_Dispatches = _resolveComponent("Dispatches")!
  const _component_Equipments = _resolveComponent("Equipments")!
  const _component_PrebillingConcepts = _resolveComponent("PrebillingConcepts")!
  const _component_MFPrebillingForm = _resolveComponent("MFPrebillingForm")!
  const _component_Billing = _resolveComponent("Billing")!
  const _component_Documents = _resolveComponent("Documents")!
  const _component_CustomsTab = _resolveComponent("CustomsTab")!
  const _component_import_file_dialog = _resolveComponent("import-file-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MaritimeFileTabs, { onChangeTab: _ctx.selectedTab }, null, 8, ["onChangeTab"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.tab === 'overview')
          ? (_openBlock(), _createBlock(_component_OverviewTab, { key: 0 }))
          : _createCommentVNode("", true)
      ], 1024)),
      (_ctx.tab === 'bookings' && _ctx.showTabBooking)
        ? (_openBlock(), _createBlock(_component_Booking, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'billOfLading' && _ctx.showTabBl)
        ? (_openBlock(), _createBlock(_component_BillOfLading, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'dispatches' && _ctx.showTabDispatches)
        ? (_openBlock(), _createBlock(_component_Dispatches, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'equipments' && _ctx.showTabEquipments)
        ? (_openBlock(), _createBlock(_component_Equipments, { key: 3 }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'prebilling-concepts')
        ? (_openBlock(), _createBlock(_component_PrebillingConcepts, { key: 4 }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'prebilling')
        ? (_openBlock(), _createBlock(_component_MFPrebillingForm, { key: 5 }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'billing' && _ctx.showTabBilling)
        ? (_openBlock(), _createBlock(_component_Billing, { key: 6 }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'documents' && _ctx.showTabDocuments)
        ? (_openBlock(), _createBlock(_component_Documents, {
            key: 7,
            showClass: true
          }))
        : _createCommentVNode("", true),
      (_ctx.tab === 'customs')
        ? (_openBlock(), _createBlock(_component_CustomsTab, {
            key: 8,
            showClass: true
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_import_file_dialog)
  ]))
}