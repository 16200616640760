
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { Form } from "vee-validate";
import FlagSelect from "@/components/catalogs-select/FlagSelect.vue";
import ApiService from "@/core/services/ApiService";

export default {
  name: "ImportFileTODialog",
  components: {
    FlagSelect,
  },

  setup(props) {
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const select_label = computed(() => {
      switch (option.value) {
        case 0:
          return "imaritimevoyage";
        case 1:
          return "tibookings";
        case 2:
          return "tiBillOfLadings";
        case 3:
          return "tiOrderTransport";
        case 4:
          return "icontainermovement";
        case 5:
          return "tiPrebilling";
        case 6:
          return "billing";
      }
    });
    const dialogvisible = computed(() => store.getters.ImportFileDialogOt);
    const selectedTab = computed(() => store.getters.getTabSelected);
    const option = computed(() => store.getters.ImportOption);
    const booking_edit = computed(() => store.getters.EditItem);
    const bookings = computed(() => store.getters.Bookings);
    const elements = computed(() => store.getters.ElementSelectDialog);
    const disable_booking = ref(false);
    const booking_id = ref(undefined);
    const form = ref({
      synchronize_flag_id: undefined as any,
      status: 1,
      type: "E",
      observations: "",
    });
    const statuses = ref([
      { value: 1, label: "Pendiente" },
      { value: 2, label: "Leido" },
      { value: 3, label: "Incorporado" },
      { value: 4, label: "No procesar nunca" },
    ]);
    const types = ref([
      { value: "E", label: "Entrada" },
      { value: "S", label: "Salida" },
      { value: "R", label: "Reporte" },
    ]);

    const uploadFile = (event) => {
      const frm = new FormData();
      if (form.value.synchronize_flag_id) {
        frm.append(
          "synchronize_flag_id",
          form.value.synchronize_flag_id.toString()
        );
      }
      frm.append("status", form.value.status.toString());
      frm.append("type", form.value.type);
      frm.append("observations", form.value.observations);
      const files = [] as any;
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
        frm.append("file[]", event.target.files[i]);
      }
      ApiService.postAttach(
        `/api/transportation_orders/${props.to_id}/attach_files`,
        frm
      ).then(function (response) {
        // console.log("response", response);
        store.commit("setImportFileTODialog", {
          show: false,
          option: null,
          data: [] as any,
        });
      });
    };

    const onClose = () => {
      booking_id.value = undefined;
      form.value.synchronize_flag_id = undefined;
      form.value.status = 1;
      form.value.type = "E";
      form.value.observations = "";
      disable_booking.value = false;
      store.commit("setImportFileTODialog", {
        show: false,
        option: null,
        data: [] as any,
      });
    };

    const onOpen = () => {
      if (selectedTab.value.tab !== "documents") {
        const item = bookings.value.filter(
          (x) => x.booking_number === booking_edit.value.value
        );
        disable_booking.value = true;
        booking_id.value = item[0].id;
      }
    };

    return {
      bookings,
      elements,
      submitButton,
      dialogvisible,
      disable_booking,
      booking_id,
      form,
      statuses,
      types,
      booking_edit,
      select_label,
      uploadFile,
      onOpen,
      onClose,
    };
  },
};
