
import { useI18n } from "vue-i18n";
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import { ElTree } from "element-plus";
import AudioPlayer from "@/components/media/AudioPlayer.vue";
import VideoPlayer from "@/components/media/VideoPlayer.vue";
import SendPdfDialog from "@/components/dialogs/SendPdfDialog.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import moment from "moment/moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import i18n from "@/core/plugins/i18n";

interface Tree {
  id: number;
  label: string;
  children?: Tree[];
}

export default {
  name: "Documents",
  props: {
    father_id: {
      type: Number,
      default: undefined,
    },
    showClass: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SendPdfDialog,
    AudioPlayer,
    VideoPlayer,
    VueJsonPretty,
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const search = ref("");
    const files = ref([]);
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const file_item = computed(() => store.getters.maritimeFileItem);
    const selectedTab = computed(() => store.getters.getTabSelected);
    const bookings = computed(() => store.getters.Bookings);
    const maritime_voyages = computed(() => store.getters.MaritimeTrips);
    const bills_of_lading = computed(() => store.getters.BookingsBLTable);
    const reload = computed(() => store.getters.Reload);
    const prebilling = computed(() => store.getters.Prebilling);
    const equipmentData = computed(() => store.getters.Equipments);
    const registerEquipmentData = computed(
      () => store.getters.RegisterEquipments
    );
    const audioItem = computed(() => store.getters.AudioItem);
    const videoItem = computed(() => store.getters.VideoItem);
    const activeIndex = ref("2");
    const seeFiles = ref(false);
    const defaultProps = ref({
      children: "children",
      label: "label",
    });
    const logs = ref([] as any);
    const data = ref([] as any);
    const filter = ref(data.value);
    const fileList = ref([]) as any;
    const bodyEdiPath = ref("");
    const dialogVisible = ref(false);

    const handleSelect = (key: string, keyPath: string[]) => {
      // console.log(key, keyPath);
    };

    const filterNode = (value: string, data: Tree) => {
      if (!value) return true;
      return data.label.includes(value);
    };
    const nodeClick = (value) => {
      let payload = {} as any;
      payload.maritimefile_id = [maritimeFileId.value];
      switch (value.type) {
        case "voyage":
          if (value.children && value.children.length) {
            payload = {
              maritime_voyage_id: value.children.map((x) => x.id),
            };
          } else if (!value.children) {
            payload = {
              maritime_voyage_id: [value.id],
            };
          }
          break;
        case "booking":
          if (value.children && value.children.length) {
            payload = {
              booking_id: value.children.map((x) => x.id),
            };
          } else if (!value.children) {
            payload = {
              booking_id: [value.id],
            };
          }
          break;
        case "bl":
          if (value.children && value.children.length) {
            payload = {
              bl_id: value.children.map((x) => x.id),
            };
          } else if (!value.children) {
            payload = {
              bl_id: [value.id],
            };
          }
          break;
        case "transportation order":
          if (value.children && value.children.length) {
            payload = {
              transportation_order_id: value.children.map((x) => x.id),
            };
          } else if (!value.children) {
            payload = {
              transportation_order_id: [value.id],
            };
          }
          break;
        case "prebilling":
          if (value.children && value.children.length) {
            payload = {
              prebilling_id: value.children.map((x) => x.id),
            };
          } else if (!value.children) {
            payload = {
              prebilling_id: [value.id],
            };
          }
          break;
        case "billing":
          if (value.children && value.children.length) {
            payload = {
              billing: value.children.map((x) => x.id),
            };
          } else if (!value.children) {
            payload = {
              billing: [value.id],
            };
          }
          break;
        case "container movement":
          if (value.children && value.children.length) {
            payload = {
              container_movement_id: value.children.map((x) => x.id),
            };
          } else if (!value.children) {
            payload = {
              container_movement_id: [value.id],
            };
          }
          break;
      }
      payload.per_page = 1000;
      ApiService.query(`/api/maritimefile/files`, {
        params: payload,
      }).then(({ data }) => {
        logs.value.splice(0, logs.value.length);
        if (data.files.length) {
          logs.value = workWithFiles(data.files);
        }
      });
      seeFiles.value = true;
    };

    const workWithFiles = (data) => {
      let result_files: any = [];
      let ids: any = [];
      data.forEach((x) => {
        const index = ids.findIndex((y) => y === x.id);
        if (index === -1) {
          const item = formFile(x);
          const children = data.filter((y) => y.parent_id === item.id);
          // 1st Level
          children.forEach((z) => {
            const child = formFile(z);
            item.children.push(child);
            ids.push(child.id);
            const grandchildren = data.filter((g) => g.parent_id === child.id);
            // 2nd Level
            grandchildren.forEach((g) => {
              const grandchild = formFile(g);
              child.children.push(grandchild);
              ids.push(grandchild.id);
              const ggrandchildren = data.filter(
                (gg) => gg.parent_id === grandchild.id
              );
              // 3rd Level
              ggrandchildren.forEach((gg) => {
                const ggrandchild = formFile(gg);
                grandchild.children.push(ggrandchild);
                ids.push(ggrandchild.id);
                const gggrandchildren = data.filter(
                  (gg) => gg.parent_id === ggrandchild.id
                );
                // 4th Level
                gggrandchildren.forEach((ggg) => {
                  const gggrandchild = formFile(ggg);
                  ggrandchild.children.push(gggrandchild);
                  ids.push(gggrandchild.id);
                  const ggggrandchildren = data.filter(
                    (ggg) => ggg.parent_id === gggrandchild.id
                  );
                  // 5th Level
                  ggggrandchildren.forEach((gggg) => {
                    const ggggrandchild = formFile(gggg);
                    gggrandchild.children.push(ggggrandchild);
                    ids.push(ggggrandchild.id);
                    const gggggrandchildren = data.filter(
                      (gggg) => gggg.parent_id === ggggrandchild.id
                    );
                    // 6th Level
                    gggggrandchildren.forEach((ggggg) => {
                      const gggggrandchild = formFile(ggggg);
                      ggggrandchild.children.push(gggggrandchild);
                      ids.push(gggggrandchild.id);
                    });
                  });
                });
              });
            });
          });
          ids.push(item.id);
          result_files.push(item);
        }
      });
      return result_files;
    };

    const formFile = (item) => {
      const file_split = item.filename.split(".");
      const result = {
        id: item.id,
        check: false,
        label: item.filename,
        type: item.type,
        message_type: item.message_type,
        size: "-",
        user: "-",
        date: item.created_at,
        path: item.url,
        status:
          item.type === "S"
            ? item.status_dictionary.outbound
            : item.status_dictionary.inbound,
        sent:
          item.type === "S" && item.sent
            ? "enviado"
            : item.type === "S" && !item.sent
            ? "no enviado"
            : "",
        sent_date: item.sent
          ? formatDate(item.edi_channels.created_at)
          : formatDate(item.created_at),
        category:
          item.type == "E"
            ? "Entrada"
            : item.type == "S"
            ? "Salida"
            : "Reporte",
        children: [] as any,
      };
      return result;
    };

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const onClickDownload = (item) => {
      store.commit("setLoadingStatus", true);
      ApiService.setResponseTypeBlob();
      ApiService.get(`/api/messaging_panel/download/${item.id}`)
        .then((data) => {
          let fileUrl = window.URL.createObjectURL(data);
          let fileLink = document.createElement("a");

          fileLink.href = fileUrl;
          fileLink.setAttribute(
            "download",
            `${
              item.path
                ? item.path.split("/")[1]
                : item.name
                ? item.name
                : "File"
            }`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit("setLoadingStatus", false);
        })
        .catch((error) => {
          Swal.fire({
            text: t("edipath_error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("tryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          console.log(error);
          store.commit("setLoadingStatus", false);
        });
      ApiService.setResponseTypeJson();
    };

    const onClickView = (item) => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/messaging_panel/view/${item.id}`)
        .then((data) => {
          let edi = data.data ? data.data : data;
          if (edi.ext === "application/pdf") {
            window.open(edi.url);
          } else {
            bodyEdiPath.value = edi.body;
            dialogVisible.value = true;
          }
          store.commit("setLoadingStatus", false);
        })
        .catch((error) => {
          console.log("Sd", error);
          Swal.fire({
            html: i18n.global.t("edipath_error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: i18n.global.t("tryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          store.commit("setLoadingStatus", false);
        });
    };

    const onClickSend = (data) => {
      store.commit("setSendPdfDialog", true);
    };

    const onclickLoadTerminal = async () => {
      const { data } = await ApiService.query(
        `/api/messaging/${route.params.id as string}/coprar`,
        {
          params: {
            instruction: "E",
            message_type: 9,
            message_receiver: "T",
          },
        }
      );
      onSendEdifile(data[0].id);
    };

    const onclickLoadConsignee = async () => {
      const { data } = await ApiService.query(
        `/api/messaging/${file_item.value.id}/coprar`,
        {
          params: {
            instruction: "E",
            message_type: 9,
            message_receiver: "C",
          },
        }
      );
      onSendEdifile(data[0].id);
    };

    const onclickUnloadTerminal = async () => {
      const { data } = await ApiService.query(
        `/api/messaging/${file_item.value.id}/coprar`,
        {
          params: {
            instruction: "I",
            message_type: 9,
            message_receiver: "T",
          },
        }
      );
      onSendEdifile(data[0].id);
    };

    const onclickUnloadConsignee = async () => {
      const { data } = await ApiService.query(
        `/api/messaging/${file_item.value.id}/coprar`,
        {
          params: {
            instruction: "I",
            message_type: 9,
            message_receiver: "C",
          },
        }
      );
      onSendEdifile(data[0].id);
    };

    const onclickLsp = async () => {
      const { data } = await ApiService.query(
        "api/maritimefile/generate_lspexport",
        {
          params: {
            maritime_files: [file_item.value.id],
          },
        }
      );
      if (file_item.value.port.location.full_code === "ESVLC") {
        const { data } = await ApiService.query(
          `/api/edifile/download_lsp_fromsteinvalenciaportpcs`,
          {
            params: {
              calls: [file_item.value.calls],
            },
          }
        );
      } else {
        onSendEdifile(data[0].id);
      }
    };

    const onclickIfcsum = async () => {
      const { data } = await ApiService.post(
        `api/maritimefile/${file_item.value.id}/ifcsum`,
        {}
      );
      onSendEdifile(data.id);
    };

    const onSendEdifile = (edifile_id) => {
      ApiService.post("/api/edifile/send", {
        edi_paths: [
          {
            id: edifile_id,
            edi_channels: [
              {
                offices: [
                  {
                    id: file_item.value.office.id,
                  },
                ],
              },
            ],
          },
        ],
      }).then((result) => {});
    };

    const onclickPdf = () => {
      store.commit("setLoading", false);
      ApiService.get(`/api/messaging/${file_item.value.id}/pdfdata`).then(
        ({ data }) => {
          //Falta otra parte
          // console.log("data", data);
        }
      );
    };

    const onclickSiduneaPort = async () => {
      const result = (await ApiService.query(
        `/api/messaging/${file_item.value.id}/sidunea`,
        {
          params: {
            receiver: "P",
            trip_type: "I",
          },
        }
      )) as any;
      const edipath_id = result.message.split("EdiPath ID: ");
      onSendEdifile(edipath_id[1]);
    };

    const onclickSiduneaColoader = async () => {
      const result = (await ApiService.query(
        `/api/messaging/${file_item.value.id}/sidunea`,
        {
          params: {
            receiver: "C",
            trip_type: "I",
          },
        }
      )) as any;
      const edipath_id = result.message.split("EdiPath ID: ");
      onSendEdifile(edipath_id[1]);
    };

    const onclickEmptyFiles = async (event) => {
      const frm = new FormData();
      const files = [] as any;
      frm.append("booking_id", props.father_id);
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
        frm.append("file[]", event.target.files[i]);
      }
      const result = await ApiService.postAttach(
        `/api/bookings/${props.father_id}/add_containers`,
        frm
      );
      console.log("result", result);
      if (result.status === 200) {
        result.data.equipment_involveds.forEach((x: any) => {
          if (
            x.equipment_id &&
            equipmentData.value.findIndex((y) => y.id === x.equipment_id) === -1
          ) {
            store.commit("addEquipment", {
              id: x.equipment_id,
              name: x.equipment.name,
            });
          }
          if (
            x.registered_equipment &&
            registerEquipmentData.value.findIndex(
              (y) => y.id === x.registered_equipment_id
            ) === -1
          ) {
            store.commit("addRegisterEquipment", {
              id: x.registered_equipment_id,
              registration_number: x.registered_equipment.registration_number,
            });
          }
          store.commit("setShiploadBL", {
            id: x.id,
            booking_id: x.booking_id,
            bl_id: x.bl_id,
            registered_equipment_id: x.registered_equipment_id,
            equipment_id: x.equipment_id,
            equipment_name: x.equipment ? x.equipment.name : "",
            connected_onboard: x.connected_onboard === 1 ? "1" : "0",
            connected_in_station: x.connected_in_station === 1 ? "1" : "0",
            connected_transport: x.connected_transport === 1 ? "1" : "0",
            equipment_supplier: x.equipment_supplier,
            seal_number: x.seal_number,
            is_empty: x.is_empty === 1 ? "1" : "0",
            registration_number: x.registered_equipment
              ? x.registered_equipment.registration_number
              : "-",
          });
        });
      }
    };

    const onclickImport = async (value) => {
      let payload = {
        show: true,
        option: value,
        data: [] as any,
      };

      switch (value) {
        case 0:
          const data_voyages = await ApiService.query(
            `/api/maritimevoyages/lists`,
            {
              params: {
                maritime_files: [file_item.value.id],
              },
            }
          );
          data_voyages.data.maritime_voyages.forEach((x: any) => {
            payload.data.push({
              id: x.id,
              value: `${x.trip_type} - ${x.code}`,
            });
          });
          break;

        case 1:
          const voyages = maritime_voyages.value.map((x: any) => x.id);
          const result_bookings = await ApiService.query(
            `/api/bookings/lists`,
            {
              params: {
                maritime_voyages: voyages,
              },
            }
          );
          result_bookings.data.bookings.forEach((y: any) => {
            payload.data.push({
              id: y.id,
              value: y.booking_number,
            });
          });
          break;

        case 2:
          const data_bls = await ApiService.query(`/api/bills_of_lading`, {
            params: {
              maritime_files: [file_item.value.id],
            },
          });
          data_bls.data.bills_of_lading.forEach((x: any) => {
            payload.data.push({
              id: x.bills_of_lading[0].id,
              value: x.bills_of_lading[0].bl_number,
            });
          });
          break;

        case 3:
          const voyages_id = maritime_voyages.value.map((x: any) => x.id);
          const temp_bookings = await ApiService.query(`/api/bookings/lists`, {
            params: {
              maritime_voyages: voyages_id,
            },
          });
          const bookings_id = temp_bookings.data.bookings.map((x: any) => x.id);
          const data_ot = await ApiService.query(`/api/transportation_orders`, {
            params: {
              bookings: bookings_id,
            },
          });
          data_ot.data.transportation_orders.forEach((x: any) => {
            payload.data.push({
              id: x.id,
              value: x.description,
            });
          });
          break;

        case 4:
          const data_cm = await ApiService.query(`/api/container_movement`, {
            params: {
              maritime_files: [file_item.value.id],
            },
          });
          data_cm.data.container_movements.forEach((x: any) => {
            payload.data.push({
              id: x.id,
              value: x.movement_type.description,
            });
          });
          break;

        case 5:
          const data_pb = await ApiService.query(`/api/prebillings`, {
            params: {
              mf_id: [file_item.value.id],
            },
          });
          data_pb.data.prebillings.forEach((x: any) => {
            payload.data.push({
              id: x.id,
              value: x.billing_concept.code,
            });
          });
          break;

        case 6:
          const data_billing = await ApiService.query(`/api/billing`, {
            params: {
              maritime_files: [file_item.value.id],
            },
          });
          data_billing.data.billing.forEach((x: any) => {
            payload.data.push({
              id: x.id,
              value: x.bill_number,
            });
          });
          break;
      }

      store.commit("setImportFileDialog", payload);
    };

    const loadNodes = async () => {
      let mv_children = [] as any;
      let bk_children = [] as any;
      let bl_children = [] as any;
      let pb_children = [] as any;
      let tr_children = [] as any;
      let cm_children = [] as any;
      let pbl_children = [] as any;
      let general_children = [] as any;

      const { data: files } = await ApiService.query(
        `/api/maritimefile/files`,
        {
          params: {
            maritimefile_id: file_item.value.id,
            with_trashed: false,
            per_page: 200,
          },
        }
      );
      files.files.forEach((f: any) => {
        //Maritime Voyages
        if (f.maritime_voyages.length && selectedTab.value === "documents") {
          f.maritime_voyages.forEach((v) => {
            if (mv_children.findIndex((i: any) => i.id === v.id) === -1) {
              mv_children.push({
                id: v.id,
                label: `${v.trip_type} - ${v.code}`,
                type: "voyage",
              });
            }
          });
        }

        //Bookings
        if (
          (f.bookings.length && selectedTab.value === "documents") ||
          selectedTab.value === "bookings"
        ) {
          f.bookings.forEach((b) => {
            if (bk_children.findIndex((i: any) => i.id === b.id) === -1) {
              bk_children.push({
                id: b.id,
                label: b.booking_number,
                type: "booking",
              });
            }
          });
        }

        //Bills of Lading
        if (
          f.bls.length &&
          (selectedTab.value === "documents" ||
            selectedTab.value === "billOfLading")
        ) {
          f.bls.forEach((bl) => {
            if (bl_children.findIndex((i: any) => i.id === bl.id) === -1)
              bl_children.push({
                id: bl.id,
                label: bl.bl_number,
                type: "bl",
              });
          });
        }

        //Transportation Orders
        if (
          f.transportation_orders.length &&
          selectedTab.value === "documents"
        ) {
          f.transportation_orders.forEach((tr) => {
            if (tr_children.findIndex((i: any) => i.id === tr.id) === -1)
              tr_children.push({
                id: tr.id,
                label: tr.description,
                type: "transportation order",
              });
          });
        }

        //Container Movement
        if (f.container_movements.length && selectedTab.value === "documents") {
          f.container_movements.forEach((cm) => {
            if (cm_children.findIndex((i: any) => i.id === cm.id) === -1)
              cm_children.push({
                id: cm.id,
                label: cm.movement_type.description,
                type: "container movement",
              });
          });
        }

        //Prebilling
        if (
          f.prebillings.length &&
          (selectedTab.value === "documents" ||
            selectedTab.value === "prebilling")
        ) {
          f.prebillings.forEach((bl) => {
            if (pb_children.findIndex((i: any) => i.id === bl.id) == -1)
              pb_children.push({
                id: bl.id,
                label: bl.billing_concept.code,
                type: "prebilling",
              });
          });
        }

        //Billing
        if (f.billings.length && selectedTab.value === "documents") {
          f.billings.forEach((bl) => {
            if (pbl_children.findIndex((i: any) => i.id === bl.id) == -1)
              pbl_children.push({
                id: bl.id,
                label: bl.bill_number,
                type: "billing",
              });
          });
        }
      });

      switch (selectedTab.value) {
        case "documents":
          general_children = [
            {
              id: 2,
              label: "timaritimetrips",
              type: "voyage",
              children: mv_children,
            },
            {
              id: 3,
              label: "bookings",
              type: "booking",
              children: bk_children,
            },
            {
              id: 4,
              label: "tiBillOfLadings",
              type: "bl",
              children: bl_children,
            },
            {
              id: 5,
              label: "tiOrderTransport",
              type: "transportation_orders",
              children: tr_children,
            },
            {
              id: 6,
              label: "icontainermovement",
              type: "container_movement",
              children: cm_children,
            },
            {
              id: 7,
              label: "tiPrebilling",
              type: "general",
              children: pb_children,
            },
            {
              id: 8,
              label: "billing",
              type: "general",
              children: pbl_children,
            },
          ];
          break;
        case "bookings":
          general_children = [
            {
              id: 3,
              label: "bookings",
              type: "general",
              children: bk_children,
            },
          ];
          break;
        case "billOfLading":
          general_children = [
            {
              id: 4,
              label: "tiBillOfLadings",
              type: "general",
              children: bl_children,
            },
          ];
          break;
        case "prebilling":
          general_children = [
            {
              id: 7,
              label: "tiPrebilling",
              type: "general",
              children: pb_children,
            },
          ];
          break;
      }
      data.value.push({
        id: 1,
        label: "General",
        children: general_children,
        type: "general",
      });
    };

    watch(search, (val) => {
      if (val !== "") {
        filter.value = data.value.filter((item: Record<string, any>) => {
          return item.label.toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
      } else {
        filter.value = data.value;
      }
    });

    onMounted(() => {
      // console.clear();
      data.value.splice(0, data.value.length);
      loadNodes();
    });

    return {
      search,
      logs,
      files,
      filter,
      seeFiles,
      activeIndex,
      defaultProps,
      audioItem,
      videoItem,
      selectedTab,
      maritimeFileId,
      fileList,
      dialogVisible,
      bodyEdiPath,
      onclickEmptyFiles,
      onClickSend,
      onClickDownload,
      onClickView,
      nodeClick,
      filterNode,
      handleSelect,
      onclickImport,
      onclickLoadTerminal,
      onclickLoadConsignee,
      onclickUnloadTerminal,
      onclickUnloadConsignee,
      onclickLsp,
      onclickIfcsum,
      onclickPdf,
      onclickSiduneaPort,
      onclickSiduneaColoader,
      file_item,
    };
  },
};
