
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import {useI18n} from "vue-i18n";
import InputNumber from "@/components/inputs/InputNumber.vue";

export default defineComponent({
  name: "BillImportExpirationDatesTE",
  components: {InputNumber},
  props: {
    tableData: {
      type: Array as any,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    billing_status: {
      type: String,
      default: "N",
    },
    bl: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();
    const {t} = useI18n();
    const activeNames = ref(["1"]);
    const goodTableData = !props.bl
      ? computed(() => store.getters.Goods)
      : computed(() => store.getters.GoodsBL);
    const equipmentTypeData = computed(() => store.getters.RegisterEquipments);
    const equipmentData = computed(() => store.getters.EquipmentsMF);
    const tempBilling = computed(() => store.getters.tempBilling);
    const expiration_dates = computed(
      () => store.getters.billTempExpirationDates
    );
    // const expiration_dates = computed(() => props.tableData);
    const equipmentType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const equipments = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: t("expiration_date"), prop: "expiration_date" },
      { label: t("iamount"), prop: "amount" },
      { label: t("iremarks"), prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const onChangeCheck = () => {
      const checkElements = props.tableData.filter((x) => x.select === "1");
      const ids = checkElements.map((x) => x.id);
      if (!ids.length) {
        const reset_name = props.bl
          ? "resetOverdimensionsBL"
          : "resetOverdimensions";
        store.commit(`${reset_name}`);
      } else {
        const filter_name = props.bl
          ? "filterOverdimensionsBL"
          : "filterOverdimensions";
        store.commit(`${filter_name}`, ids);
      }
    };

    const getEquipmentType = () => {
      if (!equipmentTypeData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/registered-equipment").then(({ data }) => {
          store.commit("setRegisterEquipment", data);
          equipmentType.value.list = data;
          equipmentType.value.options = data;
        });
      } else {
        equipmentType.value.list = equipmentTypeData.value;
        equipmentType.value.options = equipmentTypeData.value;
      }
    };
    const selectEquipmentType = (query) => {
      if (query !== "") {
        equipmentType.value.loading = true;
        setTimeout(() => {
          equipmentType.value.loading = false;
          equipmentType.value.options = equipmentType.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.registration_number
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) > -1
              );
            }
          );
          if (!equipmentType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/registered-equipment/lists`, {
              params: { per_page: 25, registration_number: query },
            }).then(({ data }) => {
              data.registered_equipment.forEach((element) => {
                store.commit("addEquipmentType", element);
              });
              equipmentType.value.list = equipmentTypeData.value;
              equipmentType.value.options = data.registered_equipment;
            });
          }
        }, 200);
      } else {
        equipmentType.value.options = equipmentTypeData.value;
      }
    };

    const getEquipments = () => {
      if (!equipmentData.value.length) {
        ApiService.get("/api/equipments/lists").then(({ data }) => {
          store.commit("setEquipment", data.equipments);
          equipments.value.list = data.equipments;
          equipments.value.options = data.equipments;
        });
      } else {
        equipments.value.list = equipmentData.value;
        equipments.value.options = equipmentData.value;
      }
    };
    const selectEquipment = (query) => {
      if (query !== "") {
        equipments.value.loading = true;
        setTimeout(() => {
          equipments.value.loading = false;
          equipments.value.options = equipments.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!equipments.value.options.length) {
            ApiService.query(`/api/equipments/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.equipments.forEach((element) => {
                store.commit("addEquipment", element);
              });
              equipments.value.list = equipmentData.value;
              equipments.value.options = data.equipments;
            });
          }
        }, 200);
      } else {
        equipments.value.options = equipmentData.value;
      }
    };

    onMounted(() => {
      getEquipmentType();
      getEquipments();
    });

    return {
      activeNames,
      expiration_dates,
      headers,
      headerTable,
      equipmentType,
      equipments,
      goodTableData,
      onChangeCheck,
      selectEquipmentType,
      selectEquipment,
    };
  },
});
