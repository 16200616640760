
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import BillingRegister from "@/views/facturation_data/bill_register.vue";

import Prebillings from "@/views/maritime_file/Prebillings.vue";
import PrebillingForm from "@/views/maritime_file/PrebillingForm.vue";
export default {
  name: "PrebillingConcepts",
  components: {
    BillingRegister,
    Prebillings,
    PrebillingForm,
  },
  setup() {
    //nuevo
    const refreshTablePrebilling = ref(false);
    const currentBooking = computed(() => store.getters.BLElement);
    const currentElementItem = computed(() => store.getters.currentElementItem);
    const select_prebilling_id = ref("");
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const editPrebilling = ref(false);
    const store = useStore();
    const isNew = ref(false);
    const billingShow = ref(false);

    //nuevo
    const onEditPrebilling = (val) => {
      console.log("onEditPrebilling val", val);
      editPrebilling.value = true;
      select_prebilling_id.value = val;
    };

    const onNewClick = async () => {
      isNew.value = true;
      setTimeout(() => {
        editPrebilling.value = true;
      }, 300);
    };

    const onCancel = async () => {
      refreshTablePrebilling.value = true;
      setTimeout(() => {
        refreshTablePrebilling.value = false;
      }, 300);
      editPrebilling.value = false;
    };
    const onAddBill = () => {
      billingShow.value = true;
    };

    const actionBackButon = () => {
      billingShow.value = false;
    };

    return {
      refreshTablePrebilling,
      currentBooking,
      currentElementItem,
      isNew,
      editPrebilling,
      select_prebilling_id,
      maritimeFile,
      billingShow,
      onEditPrebilling,
      onNewClick,
      onCancel,
      onAddBill,
      actionBackButon,
    };
  },
};
