
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "EquipmentTE",
  props: {
    tableData: {
      type: Array as any,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    in_ot: {
      type: Boolean,
      default: false,
    },
    bl: {
      type: Boolean,
      default: false,
    },
    booking_id: {
      type: Number,
      default: undefined,
    },
    bl_id: {
      type: Number,
      default: undefined,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: create") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: create"
          ) !== -1
        );
      }
    });
    const canEdit = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: update") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: update"
          ) !== -1
        );
      }
    });
    const canCreateOT = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: create"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const goodTableData = !props.bl
      ? computed(() => store.getters.Goods)
      : computed(() => store.getters.GoodsBL);
    const shiploadTableData = !props.bl
      ? computed(() => store.getters.Shiploads)
      : computed(() => store.getters.ShiploadsBL);
    const identifications = ref([]) as any;
    const items_check = ref([]) as any;
    const equipmentTypeData = computed(() => store.getters.RegisterEquipments);
    const equipmentData = computed(() => store.getters.EquipmentsMF);
    const equipmentType = ref({
      loading: false,
      options: shiploadTableData.value,
      list: shiploadTableData.value,
    });
    const equipments = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headers_in_ot = ref([
      { label: "id", prop: "id" },
      { label: "ito_special_condition_id", prop: "to_special_condition_id" },
      { label: "iobservations", prop: "observations" },
      { label: "iidgood", prop: "good_id" },
      { label: "iequipmentid", prop: "equipment_type" },
      { label: "iregistercontainer", prop: "identification" },
    ]);
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "iidgood", prop: "good_id" },
      { label: "iequipmentid", prop: "equipment_type" },
      { label: "iregistercontainer", prop: "identification" },
    ]);
    const headerTable = ref(props.in_ot ? headers_in_ot.value : headers.value);

    const specialConditionTableData = ref([]);
    const getSpecialConditionTableData = () => {
      ApiService.get("/api/to_special_condition/lists?per_page=100").then(
        function (response) {
          specialConditionTableData.value = response.data.to_special_condition;
          store.commit("setLoadingStatus", false);
        }
      );
    };
    const onChangeCheck = () => {
      const checkElements = props.tableData.filter((x) => x.select === "1");
      const ids = checkElements.map((x) => x.id);
      items_check.value = ids;
      if (!ids.length) {
        const reset_name = props.bl
          ? "resetOverdimensionsBL"
          : "resetOverdimensions";
        store.commit(`${reset_name}`);
      } else {
        const filter_name = props.bl
          ? "filterOverdimensionsBL"
          : "filterOverdimensions";
        store.commit(`${filter_name}`, ids);
      }
    };

    const createDispatch = () => {
      // console.log("props.tableData", props.tableData);
    };

    watch(
      () => shiploadTableData.value,
      (first) => {
        console.log("watch shiploadTableData", shiploadTableData.value);
        if (first.length) {
          let temp = [] as any;
          getSelectedEquipments();
          shiploadTableData.value.forEach((x) => {
            if (x.registered_equipment_id) {
              temp.push(
                identifications.value.find(
                  (y) => y.id === x.registered_equipment_id
                )
              );
            }
          });
          equipmentType.value.list = temp;
          equipmentType.value.options = temp;
        } else {
          equipmentType.value.list = [];
          equipmentType.value.options = [];
        }
      },
      { deep: true }
    );

    const getEquipmentType = () => {
      ApiService.get("/api/registered-equipment").then(({ data }) => {
        identifications.value = data;
      });
    };
    const selectEquipmentType = (query) => {
      if (query !== "") {
        equipmentType.value.loading = true;
        setTimeout(() => {
          equipmentType.value.loading = false;
          equipmentType.value.options = equipmentType.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.registration_number
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) > -1
              );
            }
          );
          // if (!equipmentType.value.options.length) {
          //   store.commit("setLoading", false);
          //   ApiService.query(`/api/registered-equipment/lists`, {
          //     params: { per_page: 25, registration_number: query },
          //   }).then(({ data }) => {
          //     data.registered_equipment.forEach((element) => {
          //       store.commit("addEquipmentType", element);
          //     });
          //     equipmentType.value.list = equipmentTypeData.value;
          //     equipmentType.value.options = data.registered_equipment;
          //   });
          // }
        }, 200);
      } else {
        equipmentType.value.options = equipmentTypeData.value;
      }
    };

    const getEquipments = () => {
      if (!equipmentData.value.length) {
        ApiService.get("/api/equipments/lists").then(({ data }) => {
          store.commit("setEquipment", data.equipments);
          equipments.value.list = data.equipments;
          equipments.value.options = data.equipments;
        });
      } else {
        equipments.value.list = equipmentData.value;
        equipments.value.options = equipmentData.value;
      }
    };

    const getSelectedEquipments = () => {
      const container_type = Object.keys(
        groupBy(shiploadTableData.value, "equipment_id")
      );
      const elements = [] as any;
      if (container_type.length > 0) {
        container_type.forEach((x) => {
          if (x !== "null" && equipmentData.value.length > 0) {
            const temp = equipmentData.value.find((y) => y.id === parseInt(x));
            elements.push(temp);
          }
        });
        equipments.value.list = elements;
        equipments.value.options = elements;
      }
    };

    const groupBy = (xs, key) => {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const selectEquipment = (query) => {
      if (query !== "") {
        equipments.value.loading = true;
        setTimeout(() => {
          equipments.value.loading = false;
          equipments.value.options = equipments.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!equipments.value.options.length) {
            ApiService.query(`/api/equipments/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.equipments.forEach((element) => {
                store.commit("addEquipment", element);
              });
              equipments.value.list = equipmentData.value;
              equipments.value.options = data.equipments;
            });
          }
        }, 200);
      } else {
        equipments.value.options = equipmentData.value;
      }
    };

    const updateREquipment = (element) => {
      // const item = equipmentType.value.options.find(
      //   (x) => x.registration_number === element.identification
      // );
      // element.equipment_type = item.equipment_id;
    };
    const changeEquipmentType = (item) => {
      const temp = shiploadTableData.value.filter(
        (x) => x.equipment_id === item.equipment_type
      );
      const register_temp = [] as any;
      temp.forEach((x) => {
        if (x.registered_equipment_id) {
          const temp = equipmentTypeData.value.find(
            (y) => y.id === x.registered_equipment_id
          );
          register_temp.push(temp);
        }
      });
      equipmentType.value.list = register_temp;
      equipmentType.value.options = register_temp;
      item.identification = "";
    };
    const equipmentRegisterByEqipment = (item) => {
      const temp = shiploadTableData.value.filter(
        (x) => x.equipment_id === item
      );
      const register_temp = [] as any;
      temp.forEach((x) => {
        if (x.registered_equipment_id) {
          const temp_r = equipmentTypeData.value.find(
            (y) => y.id === x.registered_equipment_id
          );
          if (temp_r !== undefined) {
            register_temp.push(temp_r);
          }
        }
      });
      return register_temp;
    };
    onMounted(() => {
      if (props.mounted) {
        getSelectedEquipments();
        getEquipments();
      } else {
        equipments.value.list = equipmentData.value;
        equipments.value.options = equipmentData.value;
      }
      if (props.in_ot) {
        getSpecialConditionTableData();
      }
    });

    return {
      canCreate,
      canEdit,
      canCreateOT,
      activeNames,
      headers,
      headerTable,
      equipmentType,
      equipments,
      goodTableData,
      items_check,
      specialConditionTableData,
      createDispatch,
      onChangeCheck,
      selectEquipmentType,
      selectEquipment,
      updateREquipment,
      changeEquipmentType,
      equipmentRegisterByEqipment,
      getSpecialConditionTableData,
    };
  },
});
