
import { defineComponent, ref, onMounted, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "GoodsConsigneesTE",
  props: {
    tableData: {
      type: Array,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime trips: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime trips: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime trips: delete"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const entityData = computed(() => store.getters.ShipConsignee);
    const consigneeTableData = computed(() => store.getters.GoodsConsignees);
    const consignees = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const consigneesType = ref([
      {
        value: "E",
        label: "Entrada - check in",
      },
      {
        value: "S",
        label: "Salida - check out",
      },
      {
        value: "ES",
        label: "Entrada/Salida",
      },
    ]);
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "iconsignne", prop: "consignee_id" },
      { label: "itype", prop: "type" },
      { label: "isection", prop: "sections" },
      { label: "iinitialBL", prop: "initial_bl" },
      { label: "ifinalBL", prop: "final_bl" },
      {
        label: "iinitialDeparture",
        prop: "initial_departure",
      },
      { label: "ifinalDeparture", prop: "final_departure" },
      { label: "ibs", prop: "base_section" },
      {
        label: "isectionIdentifier",
        prop: "section_identificator",
      },
    ]);
    const headerTable = ref(headers.value);

    const getConsignees = () => {
      if (!entityData.value.length) {
        ApiService.query(`/api/entities`, {
          params: {
            entity_type_id: [8],
            per_page: 10,
          },
        }).then(({ data }) => {
          store.commit("setshipConsignee", data.entities);
          consignees.value.list = data.entities;
          consignees.value.options = data.entities;
        });
      } else {
        consignees.value.list = entityData.value;
        consignees.value.options = entityData.value;
        consigneeTableData.value.forEach((x) => {
          const value = entityData.value.find((y) => y.id === x.consignee_id);
          if (!value) {
            ApiService.get(`/api/entities/${x.consignee_id}`).then(
              ({ data }) => {
                store.commit("addshipConsignee", data);
                consignees.value.list.push(data);
                consignees.value.options.push(data);
              }
            );
          }
        });
      }
    };
    const selectConsignees = (query) => {
      if (query !== "") {
        consignees.value.loading = true;
        setTimeout(() => {
          consignees.value.loading = false;
          consignees.value.options = consignees.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!consignees.value.options.length) {
            ApiService.query(`/api/entities/lists`, {
              params: { per_page: 10, name: query, entity_type_id: [8] },
            }).then(({ data }) => {
              data.entities.forEach((item) => {
                store.commit("addshipConsignee", item);
              });
              consignees.value.list = entityData.value;
              consignees.value.options = data.entities;
            });
          }
        }, 200);
      } else {
        consignees.value.options = entityData.value;
      }
    };

    onMounted(() => {
      if (props.mounted) {
        getConsignees();
      } else {
        consignees.value.list = entityData.value;
        consignees.value.options = entityData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      canDelete,
      activeNames,
      headers,
      headerTable,
      consignees,
      consigneesType,
      selectConsignees,
    };
  },
});
