
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment/moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import SPSelect from "@/components/catalogs-select/SPSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default {
  name: "Prebillings",
  components: {
    SPSelect,
    CardBasicNew,
    TableBasicSelect,
  },
  props: {
    origin: {
      type: String,
      default: "global",
    },
    maritimeFileId: {
      type: String,
      default: "",
    },
    propMaritimeFile: {
      type: Object,
      default: {} as any,
    },
    bl: {
      type: Object,
      default: null as any,
    },
    currentBooking: {
      type: Object,
      default: null as any,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    officesId: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onEditPrebilling", "onNewPrebilling", "onAddBillClick"],
  setup: function (props, { emit }) {
    // Start: Permissions
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing concept: create")
            .length > 0
        );
      }
      return false;
    });
    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing concept: show")
            .length > 0
        );
      }
      return false;
    });
    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing concept: update")
            .length > 0
        );
      }
      return false;
    });
    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "billing concept: delete")
            .length > 0
        );
      }
      return false;
    });
    // End: Permissions

    //Start: Store and config Const
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const viewType = ref(user.value.user_preferences.view_type_items);
    //End: Store and config Const

    //Start: Table const
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
      last_page: 1,
    });
    const header = ref(
      props.origin === "global"
        ? [
            { column: "status_led", key: "status_led", fixed: "left" },
            {
              column: "maritime_file",
              key: "maritime_file",
              size: "small",
              type: "link",
              fixed: "left",
            },
            {
              column: "bills_of_lading",
              key: "bills_of_lading",
              size: "small",
              type: "link",
              fixed: "left",
            },
            {
              column: "ioffices",
              key: "office_id",
              size: "xsmall",
              fixed: "left",
            },
            {
              column: "icode",
              key: "code",
              size: "xsmall",
            },
            { column: "iconcept", key: "billing_concept", size: "extra_large" },
            { column: "fushion_concept", key: "fusion_concept" },
            { column: "iamounts2", key: "amounts", size: "xsmall" },
            { column: "cost", key: "cost", size: "xsmall" },
            { column: "unit_sell", key: "unit_sell", size: "xsmall" },
            {
              column: "unit_sell_amount",
              key: "unit_sell_amount",
              size: "xsmall",
            },
            { column: "sell", key: "venta", size: "xsmall" },
            {
              column: "iva_detail_sell_id",
              key: "iva_detail_sell",
              size: "small",
            },
            { column: "ishipowner2", key: "armador", size: "xsmall" },
            { column: "icurrency", key: "currency", size: "xsmall" },
            { column: "icustomer", key: "customer" },
            { column: "tiprovider", key: "provider" },
            { column: "ishipowner", key: "shipowner" },
            { column: "tfreightpaymenttype", key: "freight_payment_type" },
            { column: "location_abroad", key: "location_abroad" },
          ]
        : [
            { column: "status_led", key: "status_led", fixed: "left" },
            {
              column: "icode",
              key: "code",
              size: "xsmall",
            },
            { column: "iconcept", key: "billing_concept" },
            { column: "fushion_concept", key: "fusion_concept" },
            { column: "iamounts2", key: "amounts", size: "xsmall" },
            { column: "cost", key: "cost", size: "xsmall" },
            { column: "unit_sell", key: "unit_sell", size: "xsmall" },
            {
              column: "unit_sell_amount",
              key: "unit_sell_amount",
              size: "xsmall",
            },
            { column: "sell", key: "venta", size: "xsmall" },
            {
              column: "iva_detail_sell_id",
              key: "iva_detail_sell",
              size: "small",
            },
            { column: "ishipowner2", key: "armador", size: "xsmall" },
            { column: "icurrency", key: "currency", size: "xsmall" },
            { column: "icustomer", key: "customer" },
            { column: "tiprovider", key: "provider" },
            { column: "ishipowner", key: "shipowner" },
            { column: "tfreightpaymenttype", key: "freight_payment_type" },
            { column: "location_abroad", key: "location_abroad" },
          ]
    );
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Duplicar" as never,
        type: "DUPLICATE-2" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);
    //End: Table const

    const existPending = ref(false);
    const insertInline = ref(false);
    const editPrebilling = ref(false);
    const existSelect = ref(false);
    const search = ref("");
    const dataObject = ref([] as any);
    const dataCostType = ref([
      { name: t("cost"), id: 3 },
      { name: t("venta"), id: 1 },
    ]);
    const apply_to = ref([
      { name: t("ifile"), id: "MF" },
      { name: t("bills_of_lading"), id: "BL" },
    ]);
    const showDelete = ref(false);
    const showInsertInline = ref(true);
    const select_prebilling_id = ref("");
    const seleted_id = ref(null as any);
    const showDuplicate = ref(false);
    const showPrefactureDialog = ref(false);
    const dataList = ref([]);
    const filtered = ref([] as any);
    const maritime_voyage_id = ref([]);
    const booking_id = ref([]);
    const bl_id = ref([]);
    const equipment_id = ref([]);
    const registered_equipment_id = ref([]);
    const dispatch_id = ref([]);
    const dataBls = ref([] as any);
    const dataBookngs = ref([] as any);
    const selectedPrebillings = ref([] as any);
    const maritime_files = ref([] as any);
    const summary = ref(undefined) as any;
    const filters = ref({
      bl_id: [] as any,
      cost_type_id: [] as any,
      apply_to_id: [] as any,
      maritime_id: [] as any,
      customer_id: [] as any,
      provider_id: [] as any,
      office_id: [] as any,
      is_prebilling: false,
      show_maritme_prebillings: true,
      date: "",
      query: "",
      filterDates: null as any,
      filterMFDates: null as any,
    });
    const totals = ref({
      cost: 0,
      sell: 0,
      armador: 0,
      ganancia: 0,
    });
    const form_other = ref({
      traslados: undefined,
      shipment: undefined,
      shipment_items: undefined,
      shipment_veterinarie: undefined,
      shipment_farmacy: undefined,
      shipment_soivre: undefined,
      shipment_leroy: undefined,
      transmition_late: undefined,
      pegatinas_imo: undefined,
      waiver: false,
      telex: false,
      dae: false,
      aduana: false,
      grua: false,
      ctf: false,
    });
    const filters_prefacture = ref({
      bl_id: [] as any,
      maritime_id: [] as any,
    });

    const doPrefacturar = async () => {
      store.commit("setLoadingStatus", true);
      try {
        showPrefactureDialog.value = false;
        for (let i = 0; i < filters_prefacture.value.maritime_id.length; i++) {
          const element = maritime_files.value.find(
            (x) => x.id === filters_prefacture.value.maritime_id[i]
          );
          if (element) {
            await onPrefacturarMf(element);
          }
        }
        for (let i = 0; i < filters_prefacture.value.bl_id.length; i++) {
          const element = dataBls.value.find(
            (x) => x.id === filters_prefacture.value.bl_id[i]
          );
          if (element) {
            const booking = dataBookngs.value.find(
              (x) => x.id === element.pivot.booking_id
            );
            await onPrefacturarBl(element, booking);
          }
        }
        filters_prefacture.value.maritime_id = [];
        filters_prefacture.value.bl_id = [];
        await serverSearch();
        store.commit("setLoadingStatus", false);
      } catch (e) {
        store.commit("setLoadingStatus", false);
        console.error(e);
      }
    };

    const onPrefacturarMf = async (mf) => {
      const { data: criterias } = await ApiService.get(
        "/api/synchronize_criterias"
      );
      const criteria_onwhl = criterias.find(
        (x) => x.flag_synchronize_id === 19
      );
      const criteria_customer = criterias.find(
        (x) => x.flag_synchronize_id === 20
      );
      const criteria_imo = criterias.find((x) => x.flag_synchronize_id === 21);
      const criteria_transportista = criterias.find(
        (x) => x.flag_synchronize_id === 28
      );
      const criteria_supplier = criterias.find(
        (x) => x.flag_synchronize_id === 27
      );
      const criteria_distancia = criterias.find(
        (x) => x.flag_synchronize_id === 22
      );
      const criteria_equipment_type = criterias.find(
        (x) => x.flag_synchronize_id === 23
      );
      const criteria_estadia = criterias.find(
        (x) => x.flag_synchronize_id === 24
      );

      let payload_venta = {
        cost_type_id: 1,
        date: mf.created_at,
        office: mf.office_id,
        conditions: [] as any,
      };
      let payload_coste = {
        cost_type_id: 3,
        date: mf.created_at,
        office: mf.office_id,
        conditions: [] as any,
      };
      if (mf.customer_id) {
        payload_venta.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: mf.customer_id,
        });
        payload_coste.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: mf.customer_id,
        });
      }

      let list_prefactures = [] as any;
      // Search Rate Form
      const { data } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_coste
      );
      const { data: ventas } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_venta
      );
      const tarifas = [] as any;
      let new_tarifas = [] as any;
      data.tariffs.forEach((x) => {
        x.level = x.tariff.billing_concept.level;
        tarifas.push(x);
      });
      ventas.tariffs.forEach((x) => {
        x.level = x.tariff.billing_concept.level;
        tarifas.push(x);
      });
      tarifas.forEach((t) => {
        const val = dataObject.value.find(
          (x) =>
            (x.all_data.billing_concept_id === t.tariff.billing_concept.id ||
              x.all_data.prebilling_concept.level ===
                t.tariff.billing_concept.level) &&
            x.all_data.has_been_billed === true
        );
        if (val === undefined || val === null) {
          new_tarifas.push(t);
        }
      });
      new_tarifas = groupBy(new_tarifas, "level");
      new_tarifas = Object.entries(new_tarifas);
      new_tarifas.forEach((x) => {
        x.forEach((t) => {
          if (Array.isArray(t)) {
            const billings_amount = [] as any;
            let billing_concept = undefined as any;
            let allow_billing = 0;
            let is_manifest = 0;
            let taxes_detail_id = null as any;
            t.forEach((y) => {
              const units = 1;
              const bill_amount = {
                prebilling_id: null,
                cost_type_id: y.tariff.billing_concept.cost_type_id,
                units: units,
                unit_amount: y.price,
                total_amount: units * y.price,
                currency_id: y.currency.id,
                taxes_detail_id: y.tariff.tariff_values.taxes_detail_id,
                remarks: "",
                cost_type: null,
                currency: "eur",
              };
              billings_amount.push(bill_amount);
              billing_concept = y.tariff.billing_concept;
              if (t.length === 1) {
                taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
              } else {
                if (y.tariff.billing_concept.cost_type_id === 1) {
                  taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
                }
              }
              if (y.tariff.billing_concept.is_manifest === 1) {
                is_manifest = 1;
              }
              if (y.tariff.billing_concept.allows_billing === 1) {
                allow_billing = 1;
              }
            });

            let find_addresses = undefined as any;
            if (mf.office.addresses) {
              find_addresses = mf.office.addresses.find(
                (x) => x.office_id === mf.office_id
              );
            }

            let location_id = null as any;
            if (find_addresses !== undefined) {
              location_id = find_addresses.location_id;
            }
            const allData = {
              id: null,
              bl_id: null,
              shipload_id: null,
              maritime_file_id: mf.id,
              taxes_detail_id: taxes_detail_id,
              equipment_involved_id: null,
              equipment_involved: null,
              transportation_order_id: null,
              billing_concept_id: billing_concept.id,
              billing_concept: billing_concept.name,
              customer_id: mf.customer_id,
              provider_id: null,
              is_manifest: is_manifest,
              freight_payment_type_id: 4, //todo ver de donde se saca
              office_id: mf.office_id,
              allows_billing: allow_billing,
              shipowner_id: null,
              customer: mf.customer_id ? mf.customer : null,
              provider: null,
              freight_payment_type: null,
              location_abroad_id: location_id,
              location_abroad:
                find_addresses.location && find_addresses.location.name
                  ? find_addresses.location.name
                  : "",
              office: mf.office_id ? mf.office : null,
              shipowner: null,
              prebilling_amounts: billings_amount,
            };
            let amount_total = 0;
            billings_amount.forEach((z) => {
              if (z.cost_type_id === 1) {
                amount_total += z.total_amount;
              } else if (z.cost_type_id === 3) {
                amount_total += -1 * z.total_amount;
              }
            });
            const currency_code = billings_amount[0].currency_id;
            const prefacture = {
              active: false,
              billing_concept_id:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              billing_concept:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              customer_id:
                mf.customer && mf.customer.name ? mf.customer.name : "",
              provider_id: "",
              is_manifest: is_manifest,
              freight_payment_type_id: "",
              location_abroad_id:
                find_addresses.location && find_addresses.location.name
                  ? find_addresses.location.name
                  : "",
              all_data: allData,
              shipload_id: null,
              office_id: mf.office && mf.office.name ? mf.office.name : "",
              allows_billing: allow_billing !== 0,
              shipowner_id: "",
              transportation_order_id: null,
              amounts: amount_total,
              prebilling_amounts: billings_amount,
              currency: currency_code,
            };
            list_prefactures.push(prefacture);
          }
        });
      });

      let cont = 0;
      const list_prebilling_data = [] as any;
      for (const l of list_prefactures) {
        store.commit("setLoading", false);
        const payload = {
          id: null,
          is_automatic: true,
          maritime_file_id: mf.id,
          billing_concept_id: l.all_data.billing_concept_id,
          billing_concept: l.all_data.billing_concept,
          customer_id: l.all_data.customer_id,
          provider_id: l.all_data.provider_id,
          is_manifest: l.all_data.is_manifest,
          freight_payment_type_id: l.all_data.freight_payment_type_id,
          location_abroad_id: l.all_data.location_abroad_id,
          office_id: l.all_data.office_id,
          allows_billing: l.all_data.allows_billing,
          transportation_order_id: l.transportation_order_id,
          shipload_id: l.shipload_id,
          taxes_detail_id: l.all_data.taxes_detail_id,
          shipowner_id: l.all_data.shipowner_id,
          prebilling_amounts: l.all_data.prebilling_amounts,
          is_autimatic_prebilling: true,
        };
        list_prebilling_data.push(payload);
        cont++;
      }
      try {
        const { data: crateData } = await ApiService.post("/api/prebillings", {
          list_prebillings: list_prebilling_data,
        });
      } catch (e) {
        store.commit("setLoadingStatus", false);
      }
    };

    const onPrefacturarBl = async (bl, book) => {
      let existImos = [] as any;
      const { data: criterias } = await ApiService.get(
        "/api/synchronize_criterias"
      );
      const criteria_onwhl = criterias.find(
        (x) => x.flag_synchronize_id === 19
      );
      const criteria_customer = criterias.find(
        (x) => x.flag_synchronize_id === 20
      );
      const criteria_imo = criterias.find((x) => x.flag_synchronize_id === 21);
      const criteria_transportista = criterias.find(
        (x) => x.flag_synchronize_id === 28
      );
      const criteria_supplier = criterias.find(
        (x) => x.flag_synchronize_id === 27
      );
      const criteria_distancia = criterias.find(
        (x) => x.flag_synchronize_id === 22
      );
      const criteria_equipment_type = criterias.find(
        (x) => x.flag_synchronize_id === 23
      );
      const criteria_estadia = criterias.find(
        (x) => x.flag_synchronize_id === 24
      );
      const criteria_puerto_carga = criterias.find(
        (x) => x.flag_synchronize_id === 25
      );
      const criteria_puerto_descarga = criterias.find(
        (x) => x.flag_synchronize_id === 26
      );
      const criteria_dias_ocupacion = criterias.find(
        (x) => x.flag_synchronize_id === 52
      );
      const criteria_dias_libre = criterias.find(
        (x) => x.flag_synchronize_id === 53
      );
      const criteria_dobl = criterias.find((x) => x.flag_synchronize_id === 33);
      const criteria_tipo_trafico_maritimo = criterias.find(
        (x) => x.flag_synchronize_id === 61
      );
      const criteria_vgml = criterias.find((x) => x.flag_synchronize_id === 35);
      const criteria_teco = criterias.find((x) => x.flag_synchronize_id === 60);
      const criteria_ens = criterias.find((x) => x.flag_synchronize_id === 51);
      const criteria_recargo = criterias.find(
        (x) => x.flag_synchronize_id === 29
      );
      const criteria_temp = criterias.find((x) => x.flag_synchronize_id === 62);
      const criteria_refeer = criterias.find(
        (x) => x.flag_synchronize_id === 32
      );
      const criteria_sobrepeso = criterias.find(
        (x) => x.flag_synchronize_id === 30
      );
      const criteria_g_p_contenedor = criterias.find(
        (x) => x.flag_synchronize_id === 65
      );
      const criteria_g_mercancia = criterias.find(
        (x) => x.flag_synchronize_id === 66
      );
      const criteria_tonelada = criterias.find(
        (x) => x.flag_synchronize_id === 67
      );

      let payload_venta = {
        cost_type_id: 1,
        date: book.maritime_voyage.maritime_file.created_at,
        office: book.maritime_voyage.maritime_file.office_id,
        conditions: [] as any,
      };
      let payload_coste = {
        cost_type_id: 3,
        date: book.maritime_voyage.maritime_file.created_at,
        office: book.maritime_voyage.maritime_file.office_id,
        conditions: [] as any,
      };

      const current_bl = bl;
      payload_venta.conditions.push({
        criteria_id: criteria_customer.criteria_id,
        value: current_bl.customer_id,
      });
      payload_coste.conditions.push({
        criteria_id: criteria_customer.criteria_id,
        value: current_bl.customer_id,
      });
      for (const x of props.currentBooking.transportation_orders) {
        if (
          x.transportation_type === "Cedido" ||
          x.transportation_type === "C"
        ) {
          payload_venta.conditions.push({
            criteria_id: criteria_onwhl.criteria_id,
            value: 1,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_onwhl.criteria_id,
            value: 1,
          });
        } else {
          payload_venta.conditions.push({
            criteria_id: criteria_recargo.criteria_id,
            value: 1,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_recargo.criteria_id,
            value: 1,
          });
          x.to_shiploads.forEach((t) => {
            if (t.shipload.goods.imo === 1) {
              payload_venta.conditions.push({
                criteria_id: criteria_imo.criteria_id,
                value: 1,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_imo.criteria_id,
                value: 1,
              });
            }
            payload_venta.conditions.push({
              criteria_id: criteria_temp.criteria_id,
              value: t.shipload.equipment_involved.equipment.temperature,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_temp.criteria_id,
              value: t.shipload.equipment_involved.equipment.temperature,
            });

            if (
              t.shipload.equipment_involved.equipment.equipment_subtype_id ===
                14 ||
              t.shipload.equipment_involved.equipment.equipment_subtype_id ===
                15
            ) {
              payload_venta.conditions.push({
                criteria_id: criteria_refeer.criteria_id,
                value: 1,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_refeer.criteria_id,
                value: 1,
              });
            }
            t.shipload.equipment_involved.equipment.equipment_measures.forEach(
              (m) => {
                if (m.measure_type_id === 20) {
                  payload_venta.conditions.push({
                    criteria_id: criteria_sobrepeso.criteria_id,
                    value: 1,
                  });
                  payload_coste.conditions.push({
                    criteria_id: criteria_sobrepeso.criteria_id,
                    value: 1,
                  });
                }
              }
            );
          });
          x.to_participants.forEach((p) => {
            if (p.to_participant_type_id === 2) {
              payload_coste.conditions.push({
                criteria_id: criteria_transportista.criteria_id,
                value: p.entity_id,
              });
              payload_venta.conditions.push({
                criteria_id: criteria_transportista.criteria_id,
                value: p.entity_id,
              });
            }
          });
          if (props.currentBooking.km_to_bill) {
            payload_venta.conditions.push({
              criteria_id: criteria_distancia.criteria_id,
              value: props.currentBooking.km_to_bill,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_distancia.criteria_id,
              value: props.currentBooking.km_to_bill,
            });
          }
          if (x.type_id === 4) {
            payload_venta.conditions.push({
              criteria_id: criteria_teco.criteria_id,
              value: 1,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_teco.criteria_id,
              value: 1,
            });
            payload_venta.conditions.push({
              criteria_id: criteria_ens.criteria_id,
              value: 1,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_ens.criteria_id,
              value: 1,
            });
          }
        }
      }

      let list_prefactures = [] as any;
      console.log(
        "transportationOrders",
        props.currentBooking.transportation_orders
      );

      // nuevo
      payload_venta.conditions.push({
        criteria_id: criteria_dobl.criteria_id,
        value: 1,
      });
      payload_coste.conditions.push({
        criteria_id: criteria_dobl.criteria_id,
        value: 1,
      });
      payload_coste.conditions.push({
        criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
        value: props.currentBooking.trip_type_id,
      });
      payload_venta.conditions.push({
        criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
        value: props.currentBooking.trip_type_id,
      });
      current_bl.routes.forEach((x) => {
        if (x.route_type_id === 34) {
          payload_coste.conditions.push({
            criteria_id: criteria_puerto_carga.criteria_id,
            value: x.route_type_id,
          });
          payload_venta.conditions.push({
            criteria_id: criteria_puerto_carga.criteria_id,
            value: x.route_type_id,
          });
        }
        if (x.route_type_id === 12) {
          payload_coste.conditions.push({
            criteria_id: criteria_puerto_descarga.criteria_id,
            value: x.route_type_id,
          });
          payload_venta.conditions.push({
            criteria_id: criteria_puerto_descarga.criteria_id,
            value: x.route_type_id,
          });
        }
      });
      const equipments = groupBy(
        current_bl.equipment_involveds,
        "equipment_id"
      );
      const equip_dtas = Object.entries(equipments);
      equip_dtas.forEach((y) => {
        payload_coste.conditions.push({
          criteria_id: criteria_equipment_type.criteria_id,
          value: parseInt(y[0]),
        });
        payload_venta.conditions.push({
          criteria_id: criteria_equipment_type.criteria_id,
          value: parseInt(y[0]),
        });
      });
      current_bl.equipment_involveds.forEach((x) => {
        if (x.has_vgm) {
          payload_venta.conditions.push({
            criteria_id: criteria_vgml.criteria_id,
            value: 1,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_vgml.criteria_id,
            value: 1,
          });
        }
        if (x.days_in_use) {
          payload_venta.conditions.push({
            criteria_id: criteria_dias_ocupacion.criteria_id,
            value: 1,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_dias_ocupacion.criteria_id,
            value: x.days_in_use,
          });
        }
        if (x.days_delay_of_return) {
          payload_venta.conditions.push({
            criteria_id: criteria_dias_libre.criteria_id,
            value: 1,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_dias_libre.criteria_id,
            value: x.days_delay_of_return,
          });
        }
        //nuevosif
        if (criteria_g_p_contenedor) {
          payload_venta.conditions.push({
            criteria_id: criteria_g_p_contenedor.criteria_id,
            value: x.equipment.equipment_group_id,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_g_p_contenedor.criteria_id,
            value: x.equipment.equipment_group_id,
          });
        }
        if (criteria_tonelada) {
          payload_venta.conditions.push({
            criteria_id: criteria_tonelada.criteria_id,
            value: convertToToneladas(x.goods_measures),
          });
          payload_coste.conditions.push({
            criteria_id: criteria_tonelada.criteria_id,
            value: convertToToneladas(x.goods_measures),
          });
        }
        if (criteria_g_mercancia) {
          let mercnacia = null as any;
          current_bl.goods.forEach((g) => {
            g.shiploads.forEach((s) => {
              if (s.equipment_involved_id === x.id) {
                mercnacia = g;
              }
            });
          });
          console.log("mercancia", mercnacia);
          if (mercnacia && criteria_g_mercancia) {
            payload_venta.conditions.push({
              criteria_id: criteria_g_mercancia.criteria_id,
              value: mercnacia.customs_tariff_code.group,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_g_mercancia.criteria_id,
              value: mercnacia.customs_tariff_code.group,
            });
          }
        }
      });

      // Search Rate Form
      const { data } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_coste
      );
      const { data: ventas } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_venta
      );
      const tarifas = [] as any;
      let new_tarifas = [] as any;
      data.tariffs.forEach((x) => {
        if (x.price > 0) {
          x.level = x.tariff.billing_concept.level;
          x.tax_id = x.tariff.tariff_values.taxes_detail.tax_id;
          tarifas.push(x);
        }
      });
      ventas.tariffs.forEach((x) => {
        if (x.price > 0) {
          x.level = x.tariff.billing_concept.level;
          x.tax_id = x.tariff.tariff_values.taxes_detail.tax_id;
          tarifas.push(x);
        }
      });
      tarifas.forEach((t) => {
        const val = dataObject.value.find(
          (x) =>
            (x.all_data.billing_concept_id === t.tariff.billing_concept.id ||
              x.all_data.prebilling_concept.level ===
                t.tariff.billing_concept.level) &&
            x.all_data.has_been_billed === true
        );
        if (val === undefined || val === null) {
          new_tarifas.push(t);
        }
      });
      if (book.trip_type && book.trip_type.code === "E") {
        let dtas = groupBy(new_tarifas, "tax_id");
        dtas = Object.entries(dtas);
        for (const h of dtas) {
          for (const a of h) {
            if (Array.isArray(a) && h.length > 0) {
              const { data } = await ApiService.get(
                "/api/taxes/" + a[0].tax_id
              );
              data.tax_all_details.forEach((y) => {
                if (y.percentage === "0.00") {
                  new_tarifas.forEach((q) => {
                    if (
                      q.tariff.tariff_values.taxes_detail.tax_id === y.tax_id
                    ) {
                      q.taxes_detail_id = y.id;
                    }
                  });
                }
              });
            }
          }
        }
      }
      new_tarifas = groupBy(new_tarifas, "level");
      new_tarifas = Object.entries(new_tarifas);
      new_tarifas.forEach((x) => {
        x.forEach((t) => {
          if (Array.isArray(t)) {
            const billings_amount = [] as any;
            let billing_concept = undefined as any;
            let allow_billing = 0;
            let is_manifest = 0;
            let taxes_detail_id = null as any;
            t.forEach((y) => {
              const units = 1;
              const bill_amount = {
                prebilling_id: null,
                cost_type_id: y.tariff.billing_concept.cost_type_id,
                units: units,
                unit_amount: y.price,
                total_amount: units * y.price,
                currency_id: y.currency.id,
                taxes_detail_id: y.taxes_detail_id
                  ? y.taxes_detail_id
                  : y.tariff.tariff_values.taxes_detail_id,
                remarks: "",
                cost_type: null,
                currency: "eur",
              };
              billings_amount.push(bill_amount);
              billing_concept = y.tariff.billing_concept;
              if (t.length === 1) {
                taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
              } else {
                if (y.tariff.billing_concept.cost_type_id === 1) {
                  taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
                }
              }
              if (y.tariff.billing_concept.is_manifest === 1) {
                is_manifest = 1;
              }
              if (y.tariff.billing_concept.allows_billing === 1) {
                allow_billing = 1;
              }
            });
            let find_addresses = undefined as any;
            if (book.maritime_voyage.maritime_file.office.addresses) {
              find_addresses =
                book.maritime_voyage.maritime_file.office.addresses.find(
                  (x) =>
                    x.office_id === book.maritime_voyage.maritime_file.office_id
                );
            }
            let location_id = null as any;
            if (find_addresses !== undefined) {
              location_id = find_addresses.location_id;
            }

            const allData = {
              id: null,
              bl_id: current_bl.id,
              shipload_id: null,
              taxes_detail_id: taxes_detail_id,
              equipment_involved_id: null,
              equipment_involved: null,
              transportation_order_id: null,
              billing_concept_id: billing_concept.id,
              billing_concept: billing_concept.name,
              customer_id: current_bl.customer_id,
              provider_id: null,
              is_manifest: is_manifest,
              freight_payment_type_id: 4, //todo ver de donde se saca
              location_abroad_id: location_id,
              location_abroad:
                find_addresses.location && find_addresses.location.name
                  ? find_addresses.location.name
                  : "",
              office_id: book.maritime_voyage.maritime_file.office_id,
              allows_billing: allow_billing,
              shipowner_id: null,
              // prebilling_concept: y.billing_concept,
              customer: current_bl.customer_id ? current_bl.customer : null,
              provider: null,
              freight_payment_type: null,
              office: book.maritime_voyage.maritime_file.office_id
                ? book.maritime_voyage.maritime_file.office
                : null,
              shipowner: null,
              prebilling_amounts: billings_amount,
            };
            let amount_total = 0;
            billings_amount.forEach((z) => {
              if (z.cost_type_id === 1) {
                amount_total += z.total_amount;
              } else if (z.cost_type_id === 3) {
                amount_total += -1 * z.total_amount;
              }
            });
            const currency_code = billings_amount[0].currency_id;
            const prefacture = {
              active: false,
              billing_concept_id:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              billing_concept:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              customer_id:
                current_bl.customer && current_bl.customer.name
                  ? current_bl.customer.name
                  : "",
              provider_id: "",
              is_manifest: is_manifest,
              freight_payment_type_id: "",
              location_abroad_id:
                find_addresses.location && find_addresses.location.name
                  ? find_addresses.location.name
                  : "",
              all_data: allData,
              shipload_id: null,
              office_id:
                book.maritime_voyage.maritime_file.office &&
                book.maritime_voyage.maritime_file.office.name
                  ? book.maritime_voyage.maritime_file.office.name
                  : "",
              allows_billing: allow_billing !== 0,
              shipowner_id: "",
              transportation_order_id: null,
              amounts: amount_total,
              prebilling_amounts: billings_amount,
              currency: currency_code,
            };
            list_prefactures.push(prefacture);
          }
        });
      });

      let cont = 0;
      const list_prebilling_data = [] as any;
      for (const l of list_prefactures) {
        store.commit("setLoading", false);
        const payload = {
          id: null,
          is_automatic: true,
          bl_id: l.all_data.bl_id,
          billing_concept_id: l.all_data.billing_concept_id,
          billing_concept: l.all_data.billing_concept,
          customer_id: l.all_data.customer_id,
          provider_id: l.all_data.provider_id,
          is_manifest: l.all_data.is_manifest,
          taxes_detail_id: l.all_data.taxes_detail_id,
          freight_payment_type_id: l.all_data.freight_payment_type_id,
          location_abroad_id: l.all_data.location_abroad_id,
          office_id: l.all_data.office_id,
          allows_billing: l.all_data.allows_billing,
          transportation_order_id: l.transportation_order_id,
          shipload_id: l.shipload_id,
          shipowner_id: l.all_data.shipowner_id,
          prebilling_amounts: l.all_data.prebilling_amounts,
          is_autimatic_prebilling: true,
        };
        list_prebilling_data.push(payload);
        cont++;
      }
      try {
        const { data: crateData } = await ApiService.post("/api/prebillings", {
          list_prebillings: list_prebilling_data,
        });
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    };

    //Start: Funciones para acciones a nivel de expediente
    const onPrefacturar = async (type, action) => {
      try {
        console.log("onPrefacturar maritimeFile", maritimeFile.value);
        console.log("onPrefacturar action", action);
        console.log("type", type);
        store.commit("setLoadingStatus", true);
        const { data: criterias } = await ApiService.get(
          "/api/synchronize_criterias"
        );
        const criteria_customer = criterias.find(
          (x) => x.flag_synchronize_id === 20
        );
        const criteria_tipo_trafico_maritimo = criterias.find(
          (x) => x.flag_synchronize_id === 61
        );
        const criteria_no_contenedores = criterias.find(
          (x) => x.flag_synchronize_id === 50
        );
        const criteria_g_p_contenedor = criterias.find(
          (x) => x.flag_synchronize_id === 65
        );
        const criteria_ctf = criterias.find(
          (x) => x.flag_synchronize_id === 41
        );
        const criteria_ens = criterias.find(
          (x) => x.flag_synchronize_id === 51
        );
        const criteria_flete = criterias.find(
          (x) => x.flag_synchronize_id === 77
        );
        const criteria_maritime_file = criterias.find(
          (x) => x.flag_synchronize_id === 82
        );
        const criteria_is_empty = criterias.find(
          (x) => x.flag_synchronize_id === 78
        );
        const criteria_armador = criterias.find(
          (x) => x.flag_synchronize_id === 84
        );

        const filter_mv_by_action = action === "I" ? "I" : "E";
        const maritime_voyages_by_mf =
          maritimeFile.value.maritime_voyages.filter(
            (x) => x.trip_type == filter_mv_by_action
          );

        for (const maritime_voyage_by_mf of maritime_voyages_by_mf) {
          let payload_venta = {
            cost_type_id: 1,
            date: maritimeFile.value.created_at,
            office: maritimeFile.value.office_id,
            conditions: [] as any,
            tariff_type: "EXP",
          };
          let payload_coste = {
            cost_type_id: 3,
            date: maritimeFile.value.created_at,
            office: maritimeFile.value.office_id,
            conditions: [] as any,
            tariff_type: "EXP",
          };

          if (criteria_maritime_file) {
            payload_venta.conditions.push({
              criteria_id: criteria_maritime_file.criteria_id,
              value: true,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_maritime_file.criteria_id,
              value: true,
            });
          }

          if (criteria_customer && maritimeFile.value.customer_id) {
            payload_venta.conditions.push({
              criteria_id: criteria_customer.criteria_id,
              value: maritimeFile.value.customer_id,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_customer.criteria_id,
              value: maritimeFile.value.customer_id,
            });
          }

          if (criteria_armador) {
            payload_venta.conditions.push({
              criteria_id: criteria_armador.criteria_id,
              value: maritime_voyage_by_mf.shipowner_id,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_armador.criteria_id,
              value: maritime_voyage_by_mf.shipowner_id,
            });
          }

          const { data: equipment_involveds_info } = await ApiService.get(
            "api/equipment_involveds?page=1&per_page=10&maritime_files[]=" +
              maritimeFile.value.id +
              "&maritime_voyages[]=" +
              maritime_voyage_by_mf.id
          );
          if (action == "I") {
            if (criteria_tipo_trafico_maritimo) {
              payload_venta.conditions.push({
                criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
                value: 2,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
                value: 2,
              });
            }

            if (type == "E") {
              if (criteria_no_contenedores && equipment_involveds_info) {
                payload_venta.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.empty_equipments_import,
                });
                payload_coste.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.empty_equipments_import,
                });
              }

              if (criteria_is_empty) {
                payload_coste.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 1,
                });
                payload_venta.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 1,
                });
              }
            }

            if (type == "F") {
              if (criteria_no_contenedores && equipment_involveds_info) {
                payload_venta.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.full_equipments_import,
                });
                payload_coste.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.full_equipments_import,
                });
              }

              if (criteria_is_empty) {
                payload_coste.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 0,
                });
                payload_venta.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 0,
                });
              }
            }
          } else {
            if (criteria_tipo_trafico_maritimo) {
              payload_venta.conditions.push({
                criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
                value: 1,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
                value: 1,
              });
            }

            if (type == "E") {
              if (criteria_no_contenedores && equipment_involveds_info) {
                payload_venta.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.empty_equipments_export +
                    equipment_involveds_info.summary
                      .empty_equipments_transhipment,
                });
                payload_coste.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.empty_equipments_export +
                    equipment_involveds_info.summary
                      .empty_equipments_transhipment,
                });
              }

              if (criteria_is_empty) {
                payload_coste.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 1,
                });
                payload_venta.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 1,
                });
              }
            }

            if (type == "F") {
              if (criteria_no_contenedores && equipment_involveds_info) {
                payload_venta.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.full_equipments_export,
                });
                payload_coste.conditions.push({
                  criteria_id: criteria_no_contenedores.criteria_id,
                  value:
                    equipment_involveds_info.summary.full_equipments_export,
                });
              }

              if (criteria_ctf) {
                payload_venta.conditions.push({
                  criteria_id: criteria_ctf.criteria_id,
                  value: true,
                });
                payload_coste.conditions.push({
                  criteria_id: criteria_ctf.criteria_id,
                  value: true,
                });
              }

              if (criteria_is_empty) {
                payload_coste.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 0,
                });
                payload_venta.conditions.push({
                  criteria_id: criteria_is_empty.criteria_id,
                  value: 0,
                });
              }
            }
          }

          if (type == "T" && action == "E") {
            if (
              equipment_involveds_info.summary.equipment_involveds_summary
                .length > 0
            ) {
              for (const x of equipment_involveds_info.summary
                .equipment_involveds_summary) {
                let payload_venta1 = {
                  cost_type_id: 1,
                  date: maritimeFile.value.created_at,
                  office: maritimeFile.value.office_id,
                  conditions: payload_venta.conditions,
                  tariff_type: "EXP",
                };
                let payload_coste1 = {
                  cost_type_id: 3,
                  date: maritimeFile.value.created_at,
                  office: maritimeFile.value.office_id,
                  conditions: payload_coste.conditions,
                  tariff_type: "EXP",
                };

                let entries = Object.entries(x)[0];
                if (criteria_g_p_contenedor) {
                  payload_coste1.conditions.push({
                    criteria_id: criteria_g_p_contenedor.criteria_id,
                    value: entries[0],
                  });
                  payload_venta1.conditions.push({
                    criteria_id: criteria_g_p_contenedor.criteria_id,
                    value: entries[0],
                  });
                }
                if (criteria_no_contenedores) {
                  payload_coste1.conditions.push({
                    criteria_id: criteria_no_contenedores.criteria_id,
                    value: entries[1],
                  });
                  payload_venta1.conditions.push({
                    criteria_id: criteria_no_contenedores.criteria_id,
                    value: entries[1],
                  });
                }
                await createPrebillingMf(payload_coste1, payload_venta1);
              }
            }
          } else {
            if (type == "F") {
              const { data } = await ApiService.query(
                `api/maritimefile/${maritimeFile.value.id}/bookingsBl`,
                {
                  params: {
                    page: 1,
                    per_page: 0,
                    trip_types: action == "E" ? [1] : [2],
                  },
                }
              );
              const has_ens = data.bookings.filter(
                (x: any) => (x.has_ens = true)
              );
              if (has_ens && criteria_ens) {
                payload_coste.conditions.push({
                  criteria_id: criteria_ens.criteria_id,
                  value: 1,
                });
                payload_venta.conditions.push({
                  criteria_id: criteria_ens.criteria_id,
                  value: 1,
                });
              }
              const bills_of_lading = data.bookings.filter((y) => y.bills_of_lading[0]).map((x: any) => x.bills_of_lading[0]);
              let amountFlete = 0;
              bills_of_lading.forEach((x) => {
                let equipment_involveds = [] as any;
                let allPrebillings = [] as any;
                equipment_involveds = x.equipment_involveds;
                allPrebillings = x.prebillings;
                const has_full = equipment_involveds.filter(
                  (z: any) => z.is_empty == 0
                );
                if (has_full) {
                  allPrebillings.forEach((y) => {
                    if (
                      (y.prebilling_concept.billing_concept_code_id == 51 ||
                        y.prebilling_concept.billing_concept_code_id == 222) &&
                      x.customer_id !== 23
                    ) {
                      amountFlete += y.prebilling_amounts.find(
                        (z) => z.cost_type_id == 4
                      )
                        ? parseFloat(
                            y.prebilling_amounts.find(
                              (z) => z.cost_type_id == 4
                            ).total_amount
                          )
                        : 0;
                    }
                  });
                }
              });
              if (amountFlete > 0) {
                if (criteria_flete) {
                  payload_coste.conditions.push({
                    criteria_id: criteria_flete.criteria_id,
                    value: amountFlete,
                  });
                  payload_venta.conditions.push({
                    criteria_id: criteria_flete.criteria_id,
                    value: amountFlete,
                  });
                }
              }
            }

            await createPrebillingMf(payload_coste, payload_venta);
          }
        }

        await serverSearch();
        store.commit("setLoadingStatus", false);
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    };

    const createPrebillingMf = async (payload_coste, payload_venta) => {
      let list_prefactures = [] as any;

      const tarifas = [] as any;
      let new_tarifas = [] as any;
      const { data: coste } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_coste
      );
      const { data: ventas } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_venta
      );

      if (!Array.isArray(coste.tariffs)) {
        coste.tariffs = Object.values(coste.tariffs);
      }
      coste.tariffs.forEach((x) => {
        // const exist = tarifas.find((t) => t.id === x.id);
        if (x.price > 0) {
          x.level = x.tariff.billing_concept.level;
          tarifas.push(x);
        }
      });

      if (!Array.isArray(ventas.tariffs)) {
        ventas.tariffs = Object.values(ventas.tariffs);
      }
      ventas.tariffs.forEach((x) => {
        // const exist = tarifas.find((t) => t.tariff.id === x.tariff.id);
        if (x.price > 0) {
          x.level = x.tariff.billing_concept.level;
          tarifas.push(x);
        }
      });

      tarifas.forEach((t) => {
        const val = dataObject.value.find(
          (x) =>
            (x.all_data.billing_concept_id === t.tariff.billing_concept.id ||
              x.all_data.prebilling_concept.level ===
                t.tariff.billing_concept.level) &&
            x.all_data.has_been_billed === true
        );
        if (val === undefined || val === null) {
          new_tarifas.push(t);
        }
      });

      new_tarifas = groupBy(new_tarifas, "level");
      new_tarifas = Object.entries(new_tarifas);
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAA", new_tarifas);
      new_tarifas.forEach((x) => {
        x.forEach((t) => {
          if (Array.isArray(t)) {
            const billings_amount = [] as any;
            let billing_concept = undefined as any;
            let allow_billing = 0;
            let is_manifest = 0;
            let taxes_detail_id = null as any;
            t.forEach((y) => {
              const units = y.units;
              const bill_amount = {
                prebilling_id: null,
                cost_type_id: y.tariff.billing_concept.cost_type_id,
                units: units,
                unit_amount: y.unit_price,
                total_amount: y.price,
                currency_id: y.currency.id,
                taxes_detail_id: y.tariff.tariff_values.taxes_detail_id,
                remarks: "",
                cost_type: null,
                currency: y.currency.code,
              };
              billings_amount.push(bill_amount);
              billing_concept = y.tariff.billing_concept;
              if (t.length === 1) {
                taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
              } else {
                if (y.tariff.billing_concept.cost_type_id === 1) {
                  taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
                }
              }
              if (y.tariff.billing_concept.is_manifest === 1) {
                is_manifest = 1;
              }
              if (y.tariff.billing_concept.allows_billing === 1) {
                allow_billing = 1;
              }
            });

            const find_addresses = maritimeFile.value.office.addresses.find(
              (x) => x.office_id === maritimeFile.value.office_id
            );
            let location_id = null as any;
            if (find_addresses !== undefined) {
              location_id = find_addresses.location_id;
            }

            const allData = {
              id: null,
              bl_id: null,
              shipload_id: null,
              taxes_detail_id: taxes_detail_id,
              maritime_file_id: maritimeFile.value.id,
              equipment_involved_id: null,
              equipment_involved: null,
              transportation_order_id: null,
              billing_concept_id: billing_concept.id,
              billing_concept: billing_concept.name,
              customer_id: maritimeFile.value.customer_id,
              fusion_concept: t[0].tariff.fusion ? t[0].tariff.fusion : "",
              provider_id: null,
              is_manifest: is_manifest,
              freight_payment_type_id: 4, //todo ver de donde se saca
              office_id: maritimeFile.value.office_id,
              allows_billing: allow_billing,
              shipowner_id: null,
              // prebilling_concept: y.billing_concept,
              customer: maritimeFile.value.customer_id
                ? maritimeFile.value.customer
                : null,
              provider: null,
              freight_payment_type: null,
              location_abroad_id: location_id,
              location_abroad:
                find_addresses &&
                find_addresses.location &&
                find_addresses.location.name
                  ? find_addresses.location.name
                  : "",
              office: maritimeFile.value.office_id
                ? maritimeFile.value.office
                : null,
              shipowner: null,
              prebilling_amounts: billings_amount,
            };
            let amount_total = 0;
            billings_amount.forEach((z) => {
              if (z.cost_type_id === 1) {
                amount_total += z.total_amount;
              } else if (z.cost_type_id === 3) {
                amount_total += -1 * z.total_amount;
              }
            });
            const currency_code = billings_amount[0].currency_id;
            const prefacture = {
              active: false,
              billing_concept_id:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              billing_concept:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              customer_id:
                maritimeFile.value.customer && maritimeFile.value.customer.name
                  ? maritimeFile.value.customer.name
                  : "",
              provider_id: "",
              is_manifest: is_manifest,
              freight_payment_type_id: "",
              location_abroad_id:
                find_addresses &&
                find_addresses.location &&
                find_addresses.location.name
                  ? find_addresses.location.name
                  : "",
              all_data: allData,
              shipload_id: null,
              office_id:
                maritimeFile.value.office && maritimeFile.value.office.name
                  ? maritimeFile.value.office.name
                  : "",
              allows_billing: allow_billing !== 0,
              shipowner_id: "",
              transportation_order_id: null,
              amounts: amount_total,
              prebilling_amounts: billings_amount,
              currency: currency_code,
              fusion_concept: t[0].tariff.fusion ? t[0].tariff.fusion : "",
            };
            list_prefactures.push(prefacture);
          }
        });
      });

      let cont = 0;
      const list_prebilling_data = [] as any;
      for (const l of list_prefactures) {
        store.commit("setLoading", false);
        const payload = {
          id: null,
          is_automatic: true,
          bl_id: null,
          maritime_file_id: maritimeFile.value.id,
          billing_concept_id: l.all_data.billing_concept_id,
          billing_concept: l.all_data.billing_concept,
          customer_id: l.all_data.customer_id,
          provider_id: l.all_data.provider_id,
          is_manifest: l.all_data.is_manifest,
          fusion_concept: l.fusion_concept,
          freight_payment_type_id: l.all_data.freight_payment_type_id,
          location_abroad_id: l.all_data.location_abroad_id,
          office_id: l.all_data.office_id,
          taxes_detail_id: l.all_data.taxes_detail_id,
          allows_billing: l.all_data.allows_billing,
          transportation_order_id: l.transportation_order_id,
          shipload_id: l.shipload_id,
          shipowner_id: l.all_data.shipowner_id,
          prebilling_amounts: l.all_data.prebilling_amounts,
          is_autimatic_prebilling: true,
        };
        list_prebilling_data.push(payload);
        cont++;
      }
      try {
        const { data: crateData } = await ApiService.post("/api/prebillings", {
          list_prebillings: list_prebilling_data,
        });
        store.commit("setLoadingStatus", false);
        return [];
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    };
    //End: Funciones para acciones a nivel de expediente

    //Start: Funciones para acciones a nivel de bls
    const onPrefacturarNivelBlHTML = async () => {
      await onPrefacturarNivelBl();
    };

    const onPrefacturarNivelBl = async (
      bookingInput = null,
      blInput = null
    ) => {
      store.commit("setLoadingStatus", true);
      try {
        const allCriterias = [] as any;
        console.log("bookingInput", bookingInput);
        console.log("blInput", blInput);
        const current_bl = blInput ? blInput : props.bl;
        const booking = bookingInput ? bookingInput : props.currentBooking;
        const mFile = props.propMaritimeFile;
        console.log("currentBooking", booking);
        console.log("current_bl", current_bl);
        console.log("mFile", mFile);

        //Start: create criterias
        const { data: criterias } = await ApiService.get(
          "/api/synchronize_criterias"
        );
        const criteria_onwhl = criterias.find(
          (x) => x.flag_synchronize_id === 19
        );
        const criteria_customer = criterias.find(
          (x) => x.flag_synchronize_id === 20
        );
        const criteria_imo = criterias.find(
          (x) => x.flag_synchronize_id === 21
        );
        const criteria_distancia = criterias.find(
          (x) => x.flag_synchronize_id === 22
        );
        const criteria_equipment_type = criterias.find(
          (x) => x.flag_synchronize_id === 23
        );
        const criteria_puerto_carga = criterias.find(
          (x) => x.flag_synchronize_id === 25
        );
        const criteria_puerto_descarga = criterias.find(
          (x) => x.flag_synchronize_id === 26
        );
        const criteria_transportista = criterias.find(
          (x) => x.flag_synchronize_id === 28
        );
        const criteria_sobrepeso = criterias.find(
          (x) => x.flag_synchronize_id === 30
        );
        const criteria_refeer = criterias.find(
          (x) => x.flag_synchronize_id === 32
        );
        const criteria_dobl = criterias.find(
          (x) => x.flag_synchronize_id === 33
        );
        const criteria_bl = criterias.find((x) => x.flag_synchronize_id === 34);
        const criteria_vgml = criterias.find(
          (x) => x.flag_synchronize_id === 35
        );
        const criteria_waiver = criterias.find(
          (x) => x.flag_synchronize_id === 36
        );
        const criteria_telex = criterias.find(
          (x) => x.flag_synchronize_id === 37
        );
        const criteria_dae = criterias.find(
          (x) => x.flag_synchronize_id === 38
        );
        const criteria_sol_aduana = criterias.find(
          (x) => x.flag_synchronize_id === 39
        );
        const criteria_grua = criterias.find(
          (x) => x.flag_synchronize_id === 40
        );
        const criteria_ctf = criterias.find(
          (x) => x.flag_synchronize_id === 41
        );
        const criteria_desp = criterias.find(
          (x) => x.flag_synchronize_id === 43
        );
        const criteria_desp_part = criterias.find(
          (x) => x.flag_synchronize_id === 44
        );
        const criteria_desp_vete = criterias.find(
          (x) => x.flag_synchronize_id === 45
        );
        const criteria_desp_farm = criterias.find(
          (x) => x.flag_synchronize_id === 46
        );
        const criteria_leroy = criterias.find(
          (x) => x.flag_synchronize_id === 47
        );
        const criteria_trans_vgm = criterias.find(
          (x) => x.flag_synchronize_id === 48
        );
        const criteria_peg_imo = criterias.find(
          (x) => x.flag_synchronize_id === 49
        );
        const criteria_container_number = criterias.find(
          (x) => x.flag_synchronize_id === 50
        );
        const criteria_ens = criterias.find(
          (x) => x.flag_synchronize_id === 51
        );
        const criteria_dias_ocupacion = criterias.find(
          (x) => x.flag_synchronize_id === 52
        );
        const criteria_equipment_subtype_value = criterias.find(
          (x) => x.flag_synchronize_id === 54
        );
        const criteria_teco = criterias.find(
          (x) => x.flag_synchronize_id === 60
        );
        const criteria_tipo_trafico_maritimo = criterias.find(
          (x) => x.flag_synchronize_id === 61
        );
        const criteria_temp = criterias.find(
          (x) => x.flag_synchronize_id === 62
        );
        const criteria_g_p_contenedor = criterias.find(
          (x) => x.flag_synchronize_id === 65
        );
        const criteria_g_mercancia = criterias.find(
          (x) => x.flag_synchronize_id === 66
        );
        const criteria_tonelada = criterias.find(
          (x) => x.flag_synchronize_id === 67
        );
        const critteria_currency_id = criterias.find(
          (x) => x.flag_synchronize_id === 68
        );
        const criteria_currency_amount = criterias.find(
          (x) => x.flag_synchronize_id === 69
        );
        const criteria_country_client = criterias.find(
          (x) => x.flag_synchronize_id === 70
        );
        const criteria_equipment_type_value = criterias.find(
          (x) => x.flag_synchronize_id === 71
        );
        const criteria_demoras = criterias.find(
          (x) => x.flag_synchronize_id === 72
        );
        const criteria_detentions = criterias.find(
          (x) => x.flag_synchronize_id === 73
        );
        const criteria_days_delay_of_return_and_detentions = criterias.find(
          (x) => x.flag_synchronize_id === 74
        );
        const criteria_terminal = criterias.find(
          (x) => x.flag_synchronize_id === 75
        );
        const criteria_flete = criterias.find(
          (x) => x.flag_synchronize_id === 77
        );
        const criteria_is_empty = criterias.find(
          (x) => x.flag_synchronize_id === 78
        );
        const criteria_tpt = criterias.find(
          (x) => x.flag_synchronize_id === 80
        );
        const criteria_fuel = criterias.find(
          (x) => x.flag_synchronize_id === 81
        );
        const criteria_teus = criterias.find(
          (x) => x.flag_synchronize_id === 83
        );
        const criteria_armador = criterias.find(
          (x) => x.flag_synchronize_id === 84
        );
        const criteria_equipment_group_type = criterias.find(
          (x) => x.flag_synchronize_id === 85
        );
        const criteria_intermodal_origin = criterias.find(
          (x) => x.flag_synchronize_id === 86
        );
        const criteria_intermodal_dest = criterias.find(
          (x) => x.flag_synchronize_id === 87
        );
        //End: create criterias

        let prebilling_code = (Math.random() + 1).toString(36).substring(2);

        //Start: seccion del bl y general
        let payload_venta = {
          cost_type_id: 1,
          date: current_bl.bl_date,
          office: maritimeFile.value.office_id,
          conditions: [] as any,
          tariff_type: "",
        };
        let payload_coste = {
          cost_type_id: 3,
          date: current_bl.bl_date,
          office: maritimeFile.value.office_id,
          conditions: [] as any,
          tariff_type: "",
        };
        let payload_venta_form = {
          cost_type_id: 1,
          date: current_bl.bl_date,
          office: maritimeFile.value.office_id,
          conditions: [] as any,
          tariff_type: "",
        };
        let payload_coste_form = {
          cost_type_id: 3,
          date: current_bl.bl_date,
          office: maritimeFile.value.office_id,
          conditions: [] as any,
          tariff_type: "",
        };

        //Start: adicionar criterios generales
        if (current_bl.customer_id && criteria_customer) {
          payload_venta.conditions.push({
            criteria_id: criteria_customer.criteria_id,
            value: current_bl.customer_id,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_customer.criteria_id,
            value: current_bl.customer_id,
          });
          let address = null as any;
          current_bl.customer.addresses.forEach((a) => {
            a.address_types.forEach((at) => {
              if (at.id === 2) {
                address = a;
              }
            });
          });
          console.log("address", address);
          if (address && address.location && criteria_country_client) {
            payload_venta.conditions.push({
              criteria_id: criteria_country_client.criteria_id,
              value: address.location.country_id,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_country_client.criteria_id,
              value: address.location.country_id,
            });
          }
        }
        if (criteria_armador) {
          console.log("pppppppppppppppppppppppp", current_bl);
          console.log("qqqqqqqqqqqqqqqqqqqqqqq", booking);
          let value_criteria_armador = current_bl.bookings
            ? current_bl.bookings[0].maritime_voyage.shipowner_id
            : booking && booking.maritime_voyage
            ? booking.maritime_voyage.shipowner_id
            : null;
          if (value_criteria_armador) {
            payload_venta.conditions.push({
              criteria_id: criteria_armador.criteria_id,
              value: value_criteria_armador,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_armador.criteria_id,
              value: value_criteria_armador,
            });
          }
        }
        if (criteria_dobl) {
          payload_venta.conditions.push({
            criteria_id: criteria_dobl.criteria_id,
            value: 1,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_dobl.criteria_id,
            value: 1,
          });
        }
        if (criteria_bl) {
          payload_venta.conditions.push({
            criteria_id: criteria_bl.criteria_id,
            value: 1,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_bl.criteria_id,
            value: 1,
          });
        }
        if (criteria_tipo_trafico_maritimo) {
          payload_coste.conditions.push({
            criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
            value: booking.trip_type_id,
          });
          payload_venta.conditions.push({
            criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
            value: booking.trip_type_id,
          });
        }
        if (booking.trip_type_id === 2 && criteria_puerto_descarga) {
          payload_coste.conditions.push({
            criteria_id: criteria_puerto_descarga.criteria_id,
            value: mFile.office.port_id,
          });
          payload_venta.conditions.push({
            criteria_id: criteria_puerto_descarga.criteria_id,
            value: mFile.office.port_id,
          });
        }
        if (
          (booking.trip_type_id === 1 || booking.trip_type_id === 3) &&
          criteria_puerto_carga
        ) {
          payload_coste.conditions.push({
            criteria_id: criteria_puerto_carga.criteria_id,
            value: mFile.office.port_id,
          });
          payload_venta.conditions.push({
            criteria_id: criteria_puerto_carga.criteria_id,
            value: mFile.office.port_id,
          });
        }
        //End: adicionar criterios generales

        //Start: adicionar criterios del FORM
        if (form_other.value.waiver && criteria_waiver) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_waiver.criteria_id,
            value: form_other.value.waiver,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_waiver.criteria_id,
            value: form_other.value.waiver,
          });
        }
        if (form_other.value.telex && criteria_telex) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_telex.criteria_id,
            value: form_other.value.telex,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_telex.criteria_id,
            value: form_other.value.telex,
          });
        }
        if (form_other.value.dae && criteria_dae) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_dae.criteria_id,
            value: form_other.value.dae,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_dae.criteria_id,
            value: form_other.value.dae,
          });
        }
        if (form_other.value.aduana && criteria_sol_aduana) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_sol_aduana.criteria_id,
            value: form_other.value.aduana,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_sol_aduana.criteria_id,
            value: form_other.value.aduana,
          });
        }
        if (form_other.value.pegatinas_imo && criteria_peg_imo) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_peg_imo.criteria_id,
            value: form_other.value.pegatinas_imo,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_peg_imo.criteria_id,
            value: form_other.value.pegatinas_imo,
          });
        }
        if (form_other.value.shipment && criteria_desp) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_desp.criteria_id,
            value: form_other.value.shipment,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_desp.criteria_id,
            value: form_other.value.shipment,
          });
        }
        if (form_other.value.shipment_items && criteria_desp_part) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_desp_part.criteria_id,
            value: form_other.value.shipment_items,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_desp_part.criteria_id,
            value: form_other.value.shipment_items,
          });
        }
        if (form_other.value.shipment_veterinarie && criteria_desp_vete) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_desp_vete.criteria_id,
            value: form_other.value.shipment_veterinarie,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_desp_vete.criteria_id,
            value: form_other.value.shipment_veterinarie,
          });
        }
        if (form_other.value.shipment_farmacy && criteria_desp_farm) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_desp_farm.criteria_id,
            value: form_other.value.shipment_farmacy,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_desp_farm.criteria_id,
            value: form_other.value.shipment_farmacy,
          });
        }
        if (form_other.value.shipment_leroy && criteria_leroy) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_leroy.criteria_id,
            value: form_other.value.shipment_leroy,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_leroy.criteria_id,
            value: form_other.value.shipment_leroy,
          });
        }
        if (form_other.value.transmition_late && criteria_trans_vgm) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_trans_vgm.criteria_id,
            value: form_other.value.transmition_late,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_trans_vgm.criteria_id,
            value: form_other.value.transmition_late,
          });
        }
        if (form_other.value.grua && criteria_grua) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_grua.criteria_id,
            value: form_other.value.grua,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_grua.criteria_id,
            value: form_other.value.grua,
          });
        }
        if (form_other.value.ctf && criteria_ctf) {
          payload_coste_form.conditions.push({
            criteria_id: criteria_ctf.criteria_id,
            value: form_other.value.ctf,
          });
          payload_venta_form.conditions.push({
            criteria_id: criteria_ctf.criteria_id,
            value: form_other.value.ctf,
          });
        }
        //End: adicionar criterios del FORM

        //Start: Crear prefacturas de la seccion del bl
        payload_coste.tariff_type = "BL";
        payload_venta.tariff_type = "BL";
        payload_venta_form.tariff_type = "BL";
        payload_coste_form.tariff_type = "BL";
        await createPrebilling(
          prebilling_code,
          payload_coste,
          payload_venta,
          booking,
          current_bl,
          current_bl.bl_number,
          allCriterias,
          payload_venta_form,
          payload_coste_form
        );
        //End: Crear prefacturas de la seccion del bl
        //End: seccion del bl y general

        //Start: Seccion equipment involved
        console.log(
          "current_bl.equipment_involveds",
          current_bl.equipment_involveds
        );

        for (const x of current_bl.equipment_involveds) {
          let equipmentConditionsCost = {
            cost_type_id: 3,
            date: current_bl.bl_date,
            office: maritimeFile.value.office_id,
            conditions: [] as any,
            tariff_type: "",
          };
          equipmentConditionsCost.conditions = payload_coste.conditions.filter(
            (x) => x.criteria_id !== 12 && x.criteria_id !== 13
          );

          let equipmentConditionsVenta = {
            cost_type_id: 1,
            date: current_bl.bl_date,
            office: maritimeFile.value.office_id,
            conditions: [] as any,
            tariff_type: "",
          };
          equipmentConditionsVenta.conditions = payload_venta.conditions.filter(
            (x) => x.criteria_id !== 12 && x.criteria_id !== 13
          );

          if (criteria_dobl) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_dobl.criteria_id,
              value: 0,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_dobl.criteria_id,
              value: 0,
            });
          }
          if (criteria_bl) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_bl.criteria_id,
              value: 0,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_bl.criteria_id,
              value: 0,
            });
          }

          console.log("payload_venta", payload_venta);
          console.log("equipmentConditionsVenta", equipmentConditionsVenta);
          console.log("equipment_involveds x", x);

          if (criteria_equipment_type) {
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_equipment_type.criteria_id,
              value: x.equipment_id,
            });
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_equipment_type.criteria_id,
              value: x.equipment_id,
            });
          }
          if (criteria_equipment_type_value) {
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_equipment_type_value.criteria_id,
              value: x.equipment.size,
            });
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_equipment_type_value.criteria_id,
              value: x.equipment.size,
            });
          }
          if (criteria_equipment_subtype_value) {
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_equipment_subtype_value.criteria_id,
              value: x.equipment.equipment_subtype_id,
            });
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_equipment_subtype_value.criteria_id,
              value: x.equipment.equipment_subtype_id,
            });
          }

          if (criteria_imo) {
            if (x.has_dangerous_goods === true) {
              equipmentConditionsVenta.conditions.push({
                criteria_id: criteria_imo.criteria_id,
                value: 1,
              });
              equipmentConditionsCost.conditions.push({
                criteria_id: criteria_imo.criteria_id,
                value: 1,
              });
            } else {
              equipmentConditionsVenta.conditions.push({
                criteria_id: criteria_imo.criteria_id,
                value: 0,
              });
              equipmentConditionsCost.conditions.push({
                criteria_id: criteria_imo.criteria_id,
                value: 0,
              });
            }
          }
          if (criteria_temp) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_temp.criteria_id,
              value: x.equipment.temperature === 0 ? 0 : 1,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_temp.criteria_id,
              value: x.equipment.temperature === 0 ? 0 : 1,
            });
          }
          if (
            x.equipment.equipment_subtype_id === 14 ||
            x.equipment.equipment_subtype_id === 15
          ) {
            if (criteria_refeer) {
              equipmentConditionsVenta.conditions.push({
                criteria_id: criteria_refeer.criteria_id,
                value: 1,
              });
              equipmentConditionsCost.conditions.push({
                criteria_id: criteria_refeer.criteria_id,
                value: 1,
              });
            }
          }
          x.equipment.equipment_measures.forEach((m) => {
            if (m.measure_type_id === 20 && criteria_sobrepeso) {
              equipmentConditionsVenta.conditions.push({
                criteria_id: criteria_sobrepeso.criteria_id,
                value: 1,
              });
              equipmentConditionsCost.conditions.push({
                criteria_id: criteria_sobrepeso.criteria_id,
                value: 1,
              });
            }
          });
          if (x.has_vgm && criteria_vgml) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_vgml.criteria_id,
              value: 1,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_vgml.criteria_id,
              value: 1,
            });
          }

          //Start: Seccion ordenes de trasnporte
          let find_equpment_ots = equipmentHasOT(x, booking);
          console.log("find_equpment_ot", find_equpment_ots);

          const suffix = x.registered_equipment
            ? `${x.registered_equipment.registration_number}_${x.equipment.size}`
            : "";

          for (const find_equpment_ot of find_equpment_ots) {
            let otConditionsCost = {
              cost_type_id: 3,
              date: current_bl.bl_date,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              prueba: 0,
              tariff_type: "",
            };

            let otConditionsVenta = {
              cost_type_id: 1,
              date: current_bl.bl_date,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              prueba: 0,
              tariff_type: "",
            };

            if (criteria_armador) {
              otConditionsVenta.conditions.push({
                criteria_id: criteria_armador.criteria_id,
                value: current_bl.bookings
                  ? current_bl.bookings[0].maritime_voyage.shipowner_id
                  : booking.maritime_voyage.shipowner_id,
              });
              otConditionsCost.conditions.push({
                criteria_id: criteria_armador.criteria_id,
                value: current_bl.bookings
                  ? current_bl.bookings[0].maritime_voyage.shipowner_id
                  : booking.maritime_voyage.shipowner_id,
              });
            }

            if (
              find_equpment_ot.transportation_type === "Cedido" ||
              find_equpment_ot.transportation_type === "C"
            ) {
              otConditionsCost.conditions = payload_coste.conditions.filter(
                (x) => x.criteria_id !== 12 && x.criteria_id !== 13
              );

              otConditionsVenta.conditions = payload_venta.conditions.filter(
                (x) => x.criteria_id !== 12 && x.criteria_id !== 13
              );

              if (criteria_dobl) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_dobl.criteria_id,
                  value: 0,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_dobl.criteria_id,
                  value: 0,
                });
              }
              if (criteria_bl) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_bl.criteria_id,
                  value: 0,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_bl.criteria_id,
                  value: 0,
                });
              }
              if (criteria_onwhl) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_onwhl.criteria_id,
                  value: 1,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_onwhl.criteria_id,
                  value: 1,
                });
              }
            } else {
              otConditionsCost.conditions = equipmentConditionsCost.conditions;
              otConditionsVenta.conditions =
                equipmentConditionsVenta.conditions;

              if (criteria_tpt) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_tpt.criteria_id,
                  value: 1,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_tpt.criteria_id,
                  value: 1,
                });
              }
              find_equpment_ot.to_participants.forEach((p) => {
                if (p.to_participant_type_id === 2) {
                  if (criteria_transportista) {
                    otConditionsCost.conditions.push({
                      criteria_id: criteria_transportista.criteria_id,
                      value: p.entity_id,
                    });
                    otConditionsVenta.conditions.push({
                      criteria_id: criteria_transportista.criteria_id,
                      value: p.entity_id,
                    });
                  }
                  if (criteria_fuel) {
                    otConditionsVenta.conditions.push({
                      criteria_id: criteria_fuel.criteria_id,
                      value: 1,
                    });
                    otConditionsCost.conditions.push({
                      criteria_id: criteria_fuel.criteria_id,
                      value: 1,
                    });
                  }
                }
              });
              if (find_equpment_ot.km_to_bill && criteria_distancia) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_distancia.criteria_id,
                  value: find_equpment_ot.km_to_bill,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_distancia.criteria_id,
                  value: find_equpment_ot.km_to_bill,
                });
              }
              if (find_equpment_ot.type_id === 5 && criteria_teco) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_teco.criteria_id,
                  value: 1,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_teco.criteria_id,
                  value: 1,
                });
              }
            }

            if (criteria_equipment_group_type) {
              otConditionsVenta.conditions.push({
                criteria_id: criteria_equipment_group_type.criteria_id,
                value: x.equipment.equipment_subtype.group,
              });
              otConditionsCost.conditions.push({
                criteria_id: criteria_equipment_group_type.criteria_id,
                value: x.equipment.equipment_subtype.group,
              });
            }
            if (criteria_tonelada) {
              otConditionsVenta.conditions.push({
                criteria_id: criteria_tonelada.criteria_id,
                value: convertToToneladas(x.goods_measures),
              });
              otConditionsCost.conditions.push({
                criteria_id: criteria_tonelada.criteria_id,
                value: convertToToneladas(x.goods_measures),
              });
            }

            if (
              find_equpment_ot.type_id === 5 &&
              find_equpment_ot.to_itineraries.length > 0
            ) {
              let itineraries_intermodal =
                find_equpment_ot.to_itineraries.filter((x) => x.type === "I");
              itineraries_intermodal.sort((a, b) => {
                // si las fechas tienen formato día-mes-año
                // entonces hay que manipular un poco para crear el objeto Date correctamente
                let partes = a.accouting_date.split("-") as any;
                const fa = new Date(partes[2], partes[1], partes[0]) as any;
                partes = b.accouting_date.split("-");
                const fb = new Date(partes[2], partes[1], partes[0]) as any;

                return fa - fb;
              });
              if (criteria_intermodal_origin && itineraries_intermodal[0]) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_intermodal_origin.criteria_id,
                  value: itineraries_intermodal[0].start_date,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_intermodal_origin.criteria_id,
                  value: itineraries_intermodal[0].start_date,
                });
              }
              if (criteria_intermodal_dest && itineraries_intermodal[1]) {
                otConditionsVenta.conditions.push({
                  criteria_id: criteria_intermodal_dest.criteria_id,
                  value: itineraries_intermodal[1].start_date,
                });
                otConditionsCost.conditions.push({
                  criteria_id: criteria_intermodal_dest.criteria_id,
                  value: itineraries_intermodal[1].start_date,
                });
              }
            }

            otConditionsVenta.prueba = find_equpment_ot.id;
            otConditionsCost.prueba = find_equpment_ot.id;

            otConditionsCost.tariff_type = "TO";
            otConditionsVenta.tariff_type = "TO";
            await createPrebilling(
              prebilling_code,
              otConditionsCost,
              otConditionsVenta,
              booking,
              current_bl,
              suffix,
              allCriterias,
              null,
              null,
              [x.id],
              find_equpment_ot.id
            );
          }
          //End: Seccion ordenes de transportes

          if (x.days_in_use && criteria_dias_ocupacion) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_dias_ocupacion.criteria_id,
              value: x.days_in_use,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_dias_ocupacion.criteria_id,
              value: x.days_in_use,
            });
          }
          if (criteria_g_p_contenedor) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_g_p_contenedor.criteria_id,
              value: x.equipment.equipment_group_id,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_g_p_contenedor.criteria_id,
              value: x.equipment.equipment_group_id,
            });
          }
          if (criteria_tonelada) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_tonelada.criteria_id,
              value: convertToToneladas(x.goods_measures),
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_tonelada.criteria_id,
              value: convertToToneladas(x.goods_measures),
            });
          }
          if (criteria_g_mercancia) {
            let mercnacia = null as any;
            current_bl.goods.forEach((g) => {
              g.shiploads.forEach((s) => {
                if (s.equipment_involved_id === x.id) {
                  mercnacia = g;
                }
              });
            });
            console.log("mercancia", mercnacia);
            if (
              mercnacia &&
              mercnacia.customs_tariff_code &&
              criteria_g_mercancia
            ) {
              equipmentConditionsVenta.conditions.push({
                criteria_id: criteria_g_mercancia.criteria_id,
                value: mercnacia.customs_tariff_code.group,
              });
              equipmentConditionsCost.conditions.push({
                criteria_id: criteria_g_mercancia.criteria_id,
                value: mercnacia.customs_tariff_code.group,
              });
            }
          }
          if (criteria_is_empty) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_is_empty.criteria_id,
              value: x.is_empty,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_is_empty.criteria_id,
              value: x.is_empty,
            });
          }
          if (x.days_delay_of_return && criteria_demoras) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_demoras.criteria_id,
              value: x.days_delay_of_return,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_demoras.criteria_id,
              value: x.days_delay_of_return,
            });
          }
          if (x.detentions && criteria_detentions) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_detentions.criteria_id,
              value: x.detentions,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_detentions.criteria_id,
              value: x.detentions,
            });
          }
          if (criteria_terminal) {
            let value = null;
            if (
              booking.trip_type_id === 2 &&
              mFile.ship_moorings.length > 0 &&
              mFile.ship_moorings[0].mooring_operations.length > 0
            ) {
              let mooring_operation =
                mFile.ship_moorings[0].mooring_operations.find(
                  (x) => x.operation_type_id === 1
                );
              if (mooring_operation) {
                value = mooring_operation.station_id;
              }
            }
            if (
              (booking.trip_type_id === 1 || booking.trip_type_id === 3) &&
              mFile.ship_moorings.length > 0 &&
              mFile.ship_moorings[0].mooring_operations.length > 0
            ) {
              let mooring_operation =
                mFile.ship_moorings[0].mooring_operations.find(
                  (x) => x.operation_type_id === 3
                );
              if (mooring_operation) {
                value = mooring_operation.station_id;
              }
            }

            if (value !== null) {
              equipmentConditionsVenta.conditions.push({
                criteria_id: criteria_terminal.criteria_id,
                value: value,
              });
              equipmentConditionsCost.conditions.push({
                criteria_id: criteria_terminal.criteria_id,
                value: value,
              });
            }
          }
          if (
            x.days_delay_of_return_and_detentions &&
            criteria_days_delay_of_return_and_detentions
          ) {
            equipmentConditionsVenta.conditions.push({
              criteria_id:
                criteria_days_delay_of_return_and_detentions.criteria_id,
              value: x.days_delay_of_return_and_detentions,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id:
                criteria_days_delay_of_return_and_detentions.criteria_id,
              value: x.days_delay_of_return_and_detentions,
            });
          }
          if (x.custom && criteria_ens) {
            if (criteria_ens) {
              equipmentConditionsVenta.conditions.push({
                criteria_id: criteria_ens.criteria_id,
                value: x.custom,
              });
              equipmentConditionsCost.conditions.push({
                criteria_id: criteria_ens.criteria_id,
                value: x.custom,
              });
            }
          }
          if (criteria_equipment_group_type) {
            equipmentConditionsVenta.conditions.push({
              criteria_id: criteria_equipment_group_type.criteria_id,
              value: x.equipment.equipment_subtype.group,
            });
            equipmentConditionsCost.conditions.push({
              criteria_id: criteria_equipment_group_type.criteria_id,
              value: x.equipment.equipment_subtype.group,
            });
          }

          equipmentConditionsCost.tariff_type = "EQD";
          equipmentConditionsVenta.tariff_type = "EQD";
          await createPrebilling(
            prebilling_code,
            equipmentConditionsCost,
            equipmentConditionsVenta,
            booking,
            current_bl,
            suffix,
            allCriterias,
            null,
            null,
            [x.id]
          );
        }
        //End: Seccion equipment involved

        //Star: Seccion monedas
        console.log("allCriterias", allCriterias);
        console.log("allCriterias", allCriterias);
        const { data } = await ApiService.query("/api/prebillings", {
          params: {
            per_page: 0,
            bl_id: [current_bl.id],
          },
        });
        let allPrebillings = [] as any;
        data.prebillings.forEach((x) => {
          const aux = convertPrebiling(x);
          allPrebillings.push(aux);
        });
        console.log(allPrebillings);
        let diferentCurrency = false;
        let amountFlete = 0;
        allPrebillings.forEach((x) => {
          if (x.all_data.prebilling_concept.billing_concept_code_id == 51) {
            amountFlete += x.venta;
          }
          if (x.currency_id !== maritimeFile.value.office.company.currency_id) {
            diferentCurrency = true;
          }
        });
        if (diferentCurrency) {
          const currencies = groupBy(allPrebillings, "currency_id");
          const currencies_dts = Object.entries(currencies);
          console.log("currencies", currencies);
          console.log("currencies_dts", currencies_dts);
          for (const x of currencies_dts) {
            let payload_venta2 = {
              cost_type_id: 1,
              date: maritimeFile.value.created_at,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              tariff_type: "",
            };
            let payload_coste2 = {
              cost_type_id: 3,
              date: maritimeFile.value.created_at,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              tariff_type: "",
            };

            console.log("parseInt(x[0])", parseInt(x[0]));
            console.log("currencies_dts x", x);
            if (
              parseInt(x[0]) !== maritimeFile.value.office.company.currency_id
            ) {
              let total = 0;
              x.forEach((y) => {
                if (Array.isArray(y)) {
                  y.forEach((z) => {
                    total += parseFloat(z.amounts);
                  });
                }
              });
              if (critteria_currency_id && criteria_currency_amount) {
                payload_venta2.conditions.push({
                  criteria_id: critteria_currency_id.criteria_id,
                  value: parseInt(x[0]),
                });
                payload_coste2.conditions.push({
                  criteria_id: critteria_currency_id.criteria_id,
                  value: parseInt(x[0]),
                });

                payload_venta2.conditions.push({
                  criteria_id: criteria_currency_amount.criteria_id,
                  value: total,
                });
                payload_coste2.conditions.push({
                  criteria_id: criteria_currency_amount.criteria_id,
                  value: total,
                });

                if (criteria_tipo_trafico_maritimo) {
                  payload_coste2.conditions.push({
                    criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
                    value: booking.trip_type_id,
                  });
                  payload_venta2.conditions.push({
                    criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
                    value: booking.trip_type_id,
                  });
                }

                if (criteria_armador) {
                  payload_venta2.conditions.push({
                    criteria_id: criteria_armador.criteria_id,
                    value: current_bl.bookings
                      ? current_bl.bookings[0].maritime_voyage.shipowner_id
                      : booking.maritime_voyage.shipowner_id,
                  });
                  payload_coste2.conditions.push({
                    criteria_id: criteria_armador.criteria_id,
                    value: current_bl.bookings
                      ? current_bl.bookings[0].maritime_voyage.shipowner_id
                      : booking.maritime_voyage.shipowner_id,
                  });
                }

                if (current_bl.customer_id && criteria_customer) {
                  payload_venta2.conditions.push({
                    criteria_id: criteria_customer.criteria_id,
                    value: current_bl.customer_id,
                  });
                  payload_coste2.conditions.push({
                    criteria_id: criteria_customer.criteria_id,
                    value: current_bl.customer_id,
                  });
                }

                payload_coste2.tariff_type = "QUEB";
                payload_venta2.tariff_type = "QUEB";
                await createPrebilling(
                  prebilling_code,
                  payload_coste2,
                  payload_venta2,
                  booking,
                  current_bl,
                  ""
                );
              }
            }
          }
        }
        //End: Seccion monedas

        //Start: Seccion flete
        if (amountFlete > 0 && criteria_flete) {
          let payload_venta3 = {
            cost_type_id: 1,
            date: maritimeFile.value.created_at,
            office: maritimeFile.value.office_id,
            conditions: [
              {
                criteria_id: criteria_flete.criteria_id,
                value: amountFlete,
              },
            ] as any,
            tariff_type: "SOF",
          };
          let payload_coste3 = {
            cost_type_id: 3,
            date: maritimeFile.value.created_at,
            office: maritimeFile.value.office_id,
            conditions: [
              {
                criteria_id: criteria_flete.criteria_id,
                value: amountFlete,
              },
            ] as any,
            tariff_type: "SOF",
          };

          if (criteria_armador) {
            payload_venta3.conditions.push({
              criteria_id: criteria_armador.criteria_id,
              value: current_bl.bookings
                ? current_bl.bookings[0].maritime_voyage.shipowner_id
                : booking.maritime_voyage.shipowner_id,
            });
            payload_coste3.conditions.push({
              criteria_id: criteria_armador.criteria_id,
              value: current_bl.bookings
                ? current_bl.bookings[0].maritime_voyage.shipowner_id
                : booking.maritime_voyage.shipowner_id,
            });
          }

          if (current_bl.customer_id && criteria_customer) {
            payload_venta3.conditions.push({
              criteria_id: criteria_customer.criteria_id,
              value: current_bl.customer_id,
            });
            payload_coste3.conditions.push({
              criteria_id: criteria_customer.criteria_id,
              value: current_bl.customer_id,
            });
          }

          await createPrebilling(
            prebilling_code,
            payload_coste3,
            payload_venta3,
            booking,
            current_bl,
            ""
          );
        }
        //End: Seccion flete

        //Start: Seccion bl segunda parte equipment involveds summary
        if (
          current_bl.equipment_involveds_summary &&
          current_bl.equipment_involveds_summary.length > 0
        ) {
          let cantEquipment = 0 as any;
          let cantTeus = 0 as any;

          let payload_venta5 = {
            cost_type_id: 1,
            date: maritimeFile.value.created_at,
            office: maritimeFile.value.office_id,
            conditions: [] as any,
            tariff_type: "BLSUMARY",
          };
          let payload_coste5 = {
            cost_type_id: 3,
            date: maritimeFile.value.created_at,
            office: maritimeFile.value.office_id,
            conditions: [] as any,
            tariff_type: "BLSUMARY",
          };

          payload_venta5.conditions = payload_coste.conditions.filter(
            (x) => x.criteria_id !== 12 && x.criteria_id !== 13
          );

          payload_coste5.conditions = payload_venta.conditions.filter(
            (x) => x.criteria_id !== 12 && x.criteria_id !== 13
          );

          for (const x of current_bl.equipment_involveds_summary) {
            let payload_venta4 = {
              cost_type_id: 1,
              date: maritimeFile.value.created_at,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              tariff_type: "",
            };
            let payload_coste4 = {
              cost_type_id: 3,
              date: maritimeFile.value.created_at,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              tariff_type: "",
            };

            payload_venta4.conditions = payload_coste.conditions.filter(
              (x) => x.criteria_id !== 12 && x.criteria_id !== 13
            );

            payload_coste4.conditions = payload_venta.conditions.filter(
              (x) => x.criteria_id !== 12 && x.criteria_id !== 13
            );

            let entries = Object.entries(x)[0] as any;
            if (criteria_g_p_contenedor) {
              payload_coste4.conditions.push({
                criteria_id: criteria_g_p_contenedor.criteria_id,
                value: entries[0],
              });
              payload_venta4.conditions.push({
                criteria_id: criteria_g_p_contenedor.criteria_id,
                value: entries[0],
              });
            }
            if (criteria_container_number) {
              payload_coste4.conditions.push({
                criteria_id: criteria_container_number.criteria_id,
                value: entries[1].total,
              });
              payload_venta4.conditions.push({
                criteria_id: criteria_container_number.criteria_id,
                value: entries[1].total,
              });
            }

            cantEquipment += entries[1].total;
            cantTeus += entries[0] == "1" ? 1 : 2;

            payload_coste4.tariff_type = "EQDSUMMY";
            payload_venta4.tariff_type = "EQDSUMMY";
            await createPrebilling(
              prebilling_code,
              payload_coste4,
              payload_venta4,
              booking,
              current_bl,
              current_bl.bl_number,
              null,
              null,
              null,
              entries[1].ids
            );
          }

          if (criteria_container_number) {
            payload_coste5.conditions.push({
              criteria_id: criteria_container_number.criteria_id,
              value: cantEquipment,
            });
            payload_venta5.conditions.push({
              criteria_id: criteria_container_number.criteria_id,
              value: cantEquipment,
            });
          }

          if (criteria_teus) {
            payload_coste5.conditions.push({
              criteria_id: criteria_teus.criteria_id,
              value: cantTeus,
            });
            payload_venta5.conditions.push({
              criteria_id: criteria_teus.criteria_id,
              value: cantTeus,
            });
          }
          payload_coste5.tariff_type = "TOTALEQDSUMMY";
          payload_venta5.tariff_type = "TOTALEQDSUMMY";
          await createPrebilling(
            prebilling_code,
            payload_coste5,
            payload_venta5,
            booking,
            current_bl,
            current_bl.bl_number
          );
        }
        //End: Seccion bl segunda parte equipment involveds summary

        //Start: Seccion bl segunda parte equipment involveds type summary
        if (
          current_bl.equipment_involved_types_summary &&
          current_bl.equipment_involved_types_summary.length > 0
        ) {
          for (const x of current_bl.equipment_involved_types_summary) {
            let payload_venta4 = {
              cost_type_id: 1,
              date: maritimeFile.value.created_at,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              tariff_type: "",
            };
            let payload_coste4 = {
              cost_type_id: 3,
              date: maritimeFile.value.created_at,
              office: maritimeFile.value.office_id,
              conditions: [] as any,
              tariff_type: "",
            };

            payload_venta4.conditions = payload_coste.conditions.filter(
              (x) => x.criteria_id !== 12 && x.criteria_id !== 13
            );

            payload_coste4.conditions = payload_venta.conditions.filter(
              (x) => x.criteria_id !== 12 && x.criteria_id !== 13
            );

            let entries = Object.entries(x)[0] as any;
            if (criteria_equipment_group_type) {
              payload_coste4.conditions.push({
                criteria_id: criteria_equipment_group_type.criteria_id,
                value: entries[0] ? entries[0] : "",
              });
              payload_venta4.conditions.push({
                criteria_id: criteria_equipment_group_type.criteria_id,
                value: entries[0] ? entries[0] : "",
              });
            }
            if (criteria_container_number) {
              payload_coste4.conditions.push({
                criteria_id: criteria_container_number.criteria_id,
                value: entries[1].total,
              });
              payload_venta4.conditions.push({
                criteria_id: criteria_container_number.criteria_id,
                value: entries[1].total,
              });
            }

            payload_coste4.tariff_type = "EQDSUMMY";
            payload_venta4.tariff_type = "EQDSUMMY";
            await createPrebilling(
              prebilling_code,
              payload_coste4,
              payload_venta4,
              booking,
              current_bl,
              current_bl.bl_number,
              null,
              null,
              null,
              entries[1].ids
            );
          }
        }
        //End: Seccion bl segunda parte equipment involveds type summary

        await serverSearch();
        store.commit("setLoadingStatus", false);
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    };

    const createPrebilling = async (
      prebilling_code,
      payload_coste,
      payload_venta,
      booking,
      current_bl,
      suffix,
      allCriterias = null as any,
      venta_form = null as any,
      cost_form = null as any,
      equipment_involved_ids = [] as any,
      transportation_order_id = null as any
    ) => {
      try {
        // Search Rate Form
        if (venta_form) {
          venta_form.conditions = venta_form.conditions.concat(
            payload_venta.conditions
          );
        }
        if (cost_form) {
          cost_form.conditions = cost_form.conditions.concat(
            payload_coste.conditions
          );
        }

        let list_prefactures = [] as any;
        const { data: coste } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          cost_form ? cost_form : payload_coste
        );
        const { data: ventas } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          venta_form ? venta_form : payload_venta
        );
        const tarifas = [] as any;
        let new_tarifas = [] as any;
        if (!Array.isArray(coste.tariffs)) {
          coste.tariffs = Object.values(coste.tariffs);
        }
        coste.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.tariff.billing_concept.level;
            x.tax_id = x.tariff.tariff_values.taxes_detail.tax_id;
            tarifas.push(x);
          }
        });
        if (!Array.isArray(ventas.tariffs)) {
          ventas.tariffs = Object.values(ventas.tariffs);
        }
        ventas.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.tariff.billing_concept.level;
            x.tax_id = x.tariff.tariff_values.taxes_detail.tax_id;
            tarifas.push(x);
          }
        });
        tarifas.forEach((t) => {
          const val = dataObject.value.find(
            (x) =>
              (x.all_data.billing_concept_id === t.tariff.billing_concept.id ||
                x.all_data.prebilling_concept.level ===
                  t.tariff.billing_concept.level) &&
              x.all_data.has_been_billed === true
          );
          if (val === undefined || val === null) {
            new_tarifas.push(t);
          }
        });
        if (new_tarifas.length > 0) {
          if (booking.trip_type && booking.trip_type.code === "E") {
            let dtas = groupBy(new_tarifas, "tax_id");
            dtas = Object.entries(dtas);
            for (const h of dtas) {
              for (const a of h) {
                if (Array.isArray(a) && h.length > 0) {
                  const { data } = await ApiService.get(
                    "/api/taxes/" + a[0].tax_id
                  );
                  data.tax_all_details.forEach((y) => {
                    if (y.percentage === "0.00") {
                      new_tarifas.forEach((q) => {
                        if (
                          q.tariff.tariff_values.taxes_detail.tax_id ===
                          y.tax_id
                        ) {
                          q.taxes_detail_id = y.id;
                        }
                      });
                    }
                  });
                }
              }
            }
          }
          new_tarifas = groupBy(new_tarifas, "level");
          new_tarifas = Object.entries(new_tarifas);
          new_tarifas.forEach((x) => {
            x.forEach((t) => {
              if (Array.isArray(t)) {
                const billings_amount = [] as any;
                let billing_concept = undefined as any;
                let billing_concept_data = undefined as any;
                let allow_billing = 0;
                let is_manifest = 0;
                let taxes_detail_id = null as any;
                t.forEach((y) => {
                  const units = y.units;
                  const bill_amount = {
                    prebilling_id: null,
                    cost_type_id: y.tariff.billing_concept.cost_type_id,
                    units: units,
                    unit_amount: y.unit_price,
                    total_amount: y.price,
                    currency_id: y.currency.id,
                    taxes_detail_id: y.taxes_detail_id
                      ? y.taxes_detail_id
                      : y.tariff.tariff_values.taxes_detail_id,
                    remarks: "",
                    cost_type: null,
                    currency: y.currency.code,
                  };
                  billings_amount.push(bill_amount);
                  billing_concept = y.tariff.name;
                  billing_concept_data = y.tariff.billing_concept;
                  if (t.length === 1) {
                    taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
                  } else {
                    if (y.tariff.billing_concept.cost_type_id === 1) {
                      taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
                    }
                  }
                  if (y.tariff.billing_concept.is_manifest === 1) {
                    is_manifest = 1;
                  }
                  if (y.tariff.billing_concept.allows_billing === 1) {
                    allow_billing = 1;
                  }
                });
                const find_addresses = maritimeFile.value.port.location;
                let location_id = null as any;
                if (find_addresses !== undefined) {
                  location_id = find_addresses.location_id;
                }
                const allData = {
                  id: null,
                  taxes_detail_id: taxes_detail_id,
                  bl_id: current_bl.id,
                  shipload_id: null,
                  equipment_involved: null,
                  billing_concept_id: billing_concept_data.id,
                  billing_concept: `${billing_concept}_${suffix}`,
                  customer_id: current_bl.customer_id,
                  provider_id: null,
                  fusion_concept: t[0].tariff.fusion ? t[0].tariff.fusion : "",
                  is_manifest: is_manifest,
                  freight_payment_type_id: 4, //todo ver de donde se saca
                  location_abroad_id: location_id
                    ? location_id
                    : maritimeFile.value.port.location_id,
                  office_id: maritimeFile.value.office_id,
                  allows_billing: allow_billing,
                  shipowner_id: null,
                  // prebilling_concept: y.billing_concept,
                  customer: current_bl.customer_id ? current_bl.customer : null,
                  provider: null,
                  freight_payment_type: null,
                  location_abroad:
                    find_addresses &&
                    find_addresses.location &&
                    find_addresses.location.name
                      ? find_addresses.location.name
                      : "",
                  office: maritimeFile.value.office_id
                    ? maritimeFile.value.office
                    : null,
                  shipowner: null,
                  prebilling_amounts: billings_amount,
                  equipment_involved_ids: equipment_involved_ids,
                  transportation_order_id: transportation_order_id,
                };
                let amount_total = 0;
                billings_amount.forEach((z) => {
                  if (z.cost_type_id === 1) {
                    amount_total += z.total_amount;
                  } else if (z.cost_type_id === 3) {
                    amount_total += -1 * z.total_amount;
                  }
                });
                const currency_code = billings_amount[0].currency_id;
                const prefacture = {
                  active: false,
                  billing_concept_id:
                    billing_concept && billing_concept.name
                      ? `${billing_concept.name}_${suffix}`
                      : "",
                  billing_concept:
                    billing_concept && billing_concept.name
                      ? `${billing_concept.name}_${suffix}`
                      : "",
                  customer_id:
                    current_bl.customer && current_bl.customer_id
                      ? current_bl.customer_id
                      : "",
                  provider_id: "",
                  is_manifest: is_manifest,
                  freight_payment_type_id: "",
                  location_abroad_id:
                    find_addresses &&
                    find_addresses.location &&
                    find_addresses.location.name
                      ? find_addresses.location.name
                      : "",
                  all_data: allData,
                  shipload_id: null,
                  office_id:
                    maritimeFile.value.office && maritimeFile.value.office.name
                      ? maritimeFile.value.office.name
                      : "",
                  allows_billing: allow_billing !== 0,
                  shipowner_id: "",
                  amounts: amount_total,
                  prebilling_amounts: billings_amount,
                  currency: currency_code,
                  fusion_concept:
                    t[1] && t[1].tariff.fusion
                      ? t[1].tariff.fusion
                      : t[0] && t[0].tariff.fusion
                      ? t[0].tariff.fusion
                      : "",
                  equipment_involved_ids: equipment_involved_ids,
                  transportation_order_id: transportation_order_id,
                };
                list_prefactures.push(prefacture);
                if (allCriterias) {
                  allCriterias.push(prefacture);
                }
              }
            });
          });

          let cont = 0;
          const list_prebilling_data = [] as any;
          for (const l of list_prefactures) {
            store.commit("setLoading", false);
            const payload = {
              id: null,
              is_automatic: true,
              bl_id: l.all_data.bl_id,
              maritime_file_id: null,
              billing_concept_id: l.all_data.billing_concept_id,
              billing_concept: l.all_data.billing_concept,
              customer_id: l.all_data.customer_id,
              provider_id: l.all_data.provider_id,
              fusion_concept: l.fusion_concept,
              taxes_detail_id: l.all_data.taxes_detail_id,
              is_manifest: l.all_data.is_manifest,
              freight_payment_type_id: l.all_data.freight_payment_type_id,
              location_abroad_id: l.all_data.location_abroad_id,
              office_id: l.all_data.office_id,
              allows_billing: l.all_data.allows_billing,
              shipload_id: l.shipload_id,
              shipowner_id: l.all_data.shipowner_id,
              prebilling_amounts: l.all_data.prebilling_amounts,
              is_autimatic_prebilling: true,
              equipment_involved_ids: l.equipment_involved_ids,
              transportation_order_id: l.transportation_order_id,
              prebilling_code: prebilling_code,
            };
            list_prebilling_data.push(payload);
            cont++;
          }
          try {
            const { data: crateData } = await ApiService.post(
              "/api/prebillings",
              {
                list_prebillings: list_prebilling_data,
              }
            );
            return [];
          } catch (e) {
            store.commit("setLoadingStatus", false);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    const equipmentHasOT = (equipment_involved, booking) => {
      let ot = [] as any;
      console.log("equipmentHasOT booking", booking);
      booking.transportation_orders.forEach((x) => {
        x.to_shiploads.forEach((sh) => {
          console.log("sh", sh.shipload);
          console.log("equipment_involved.id", equipment_involved.id);
          if (
            sh.shipload.equipment_involved_id === equipment_involved.id &&
            x.edi_paths.length > 0 &&
            x.edi_paths.map((y) => y.sent).length > 0 &&
            (x.type_id === 4 || x.type_id === 5)
          ) {
            if (!ot.map((z) => z.id).includes(x.id)) {
              ot.push(x);
            }
          }
        });
      });

      return ot;
    };

    const doPrefacturarAllBL = async (trip_type_import) => {
      store.commit("setLoadingStatus", true);
      const { data } = await ApiService.query(
        `api/maritimefile/${maritimeFile.value.id}/bookingsBl`,
        {
          params: {
            page: 1,
            per_page: 0,
            trip_types: trip_type_import ? [2] : [1, 3, 4],
          },
        }
      );
      for (const booking of data.bookings) {
        for (const bl of booking.bills_of_lading) {
          try {
            await onPrefacturarNivelBl(booking, bl);
          } catch (e) {
            console.error(e);
            store.commit("setLoadingStatus", false);
          }
        }
      }
      store.commit("setLoadingStatus", false);
    };
    //End: Funciones para acciones a nivel de bls

    //Start: general actions functions
    const serverSearch = async () => {
      let params = {};
      let from_bl = "";
      let to_bl = "";
      let from_mf = "";
      let to_mf = "";
      if (filters.value.filterDates !== null) {
        if (filters.value.filterDates.length === 1) {
          from_bl = filters.value.filterDates[0];
        } else if (filters.value.filterDates.length === 2) {
          from_bl = filters.value.filterDates[0];
          to_bl = filters.value.filterDates[1];
        }
      }
      if (filters.value.filterMFDates !== null) {
        if (filters.value.filterMFDates.length === 1) {
          from_mf = filters.value.filterMFDates[0];
        } else if (filters.value.filterMFDates.length === 2) {
          from_mf = filters.value.filterMFDates[0];
          to_mf = filters.value.filterMFDates[1];
        }
      }
      if (props.origin === "global") {
        params = {
          name: filters.value.query,
          page: pagination.value.page,
          bl_id: filters.value.bl_id,
          office_id: filters.value.office_id,
          customer_id: filters.value.customer_id,
          provider_id: filters.value.provider_id,
          cost_type_id: filters.value.cost_type_id,
          per_page: pagination.value.rowsPerPage,
          mf_id: filters.value.maritime_id,
          apply_to: filters.value.apply_to_id,
          from_bl: from_bl,
          to_bl: to_bl,
          from_mf: from_mf,
          to_mf: to_mf,
        };
      }
      if (
        props.origin === "maritime_file" ||
        props.origin === "prebillings_concept"
      ) {
        params = {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          apply_to:
            props.origin === "maritime_file"
              ? ["MF"]
              : filters.value.show_maritme_prebillings
              ? ["MF"]
              : [],
          mf_id: [props.maritimeFileId],
          cost_type_id: filters.value.cost_type_id,
          bl_id: filters.value.bl_id,
          from_bl: from_bl,
          to_bl: to_bl,
          from_mf: from_mf,
          to_mf: to_mf,
        };
      }
      if (props.origin === "bl") {
        params = {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          bl_id: [props.bl.id],
        };
      }
      const { data } = await ApiService.query("/api/prebillings", {
        params: params,
      });
      pagination.value.total = data.pagination.total;
      pagination.value.last_page = data.pagination.last_page;
      dataObject.value = [];
      getAllTotals(data.prebillings);
      data.prebillings.forEach((x) => {
        if (!x.has_been_billed) {
          existPending.value = true;
        }
        if (filters.value.is_prebilling) {
          if (!x.has_been_billed) {
            dataObject.value.push(convertPrebiling(x));
          }
        } else {
          dataObject.value.push(convertPrebiling(x));
        }
      });
      filtered.value = convertCardData(dataObject.value);
    };

    const doServerSearch = async () => {
      store.commit("setLoadingStatus", true);
      await serverSearch();
      store.commit("setLoadingStatus", false);
    };

    const onSearch = () => {
      dataObject.value = dataObject.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const createElement = () => {
      if (props.origin === "global") {
        store.commit("setLoadingStatus", true);
        router.push({ name: "prebillingsCreate" });
      } else {
        emit("onNewPrebilling");
      }
    };

    const onAddBillPending = async () => {
      store.commit("clearTempBill");

      store.commit("setLoadingStatus", true);

      let params = {
        allows_billing: "1",
        per_page: 0,
      } as any;
      if (props.origin == "maritime_file") {
        params.mf_id = [props.propMaritimeFile.id];
        params.apply_to = ["MF"];
      }
      if (props.origin == "bl") {
        params.bl_id = [props.bl.id];
        params.apply_to = ["BL"];
      }
      try {
        const { data } = await ApiService.query("/api/prebillings", {
          params: params,
        });
        const temp_bill = [] as any;
        data.prebillings.forEach((x) => {
          if (!x.has_been_billed) {
            temp_bill.push(x);
          }
        });
        store.commit("setTempBill", temp_bill);
        onAddBillClick();
        store.commit("setLoadingStatus", false);
      } catch (e) {
        store.commit("setLoadingStatus", false);
      }
    };

    const onAddBillClick = () => {
      store.commit("setResetSelectedTable", false);
      if (props.origin === "global") {
        router.push({
          name: "bill_new",
        });
      } else {
        emit("onAddBillClick");
      }
    };

    const seeDetails = (id: any) => {
      router.push({
        name: "prebillingsEdit",
        params: {
          id: id,
        },
      });
    };

    const deleteDialogCard = (param1) => {
      seleted_id.value = param1;
      showDelete.value = true;
    };

    const onDuplicateElelement = (val) => {
      actionsButton(val, "DUPLICATE", val);
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        if (props.origin === "global") {
          router.push({
            name: "prebillingsEdit",
            params: {
              id: param1,
            },
          });
        } else {
          console.log("onEditPrebilling emit param1", param1);
          emit("onEditPrebilling", param1);
        }
      }
      if (param2 == "DUPLICATE") {
        store.commit("setLoadingStatus", true);
        ApiService.get(`/api/prebillings/${param3}`).then(async ({ data }) => {
          let payload = {
            maritime_file_id: data.maritime_file_id,
            bl_id: data.bl_id,
            billing_concept_id: data.prebilling_concept.id,
            billing_concept: data.billing_concept,
            customer_id: data.customer_id,
            provider_id: data.provider_id,
            is_manifest: data.is_manifest,
            freight_payment_type_id: data.freight_payment_type_id,
            location_abroad_id: data.location_abroad_id,
            office_id: data.office_id,
            allows_billing: data.allows_billing,
            shipowner_id: data.shipowner_id,
            prebilling_amounts: data.prebilling_amounts,
            is_duplicate: 1,
          };
          try {
            const { data } = await ApiService.post("/api/prebillings", {
              list_prebillings: [payload],
            });
            await serverSearch();
            store.commit("setLoadingStatus", false);
          } catch (e) {
            store.commit("setLoadingStatus", false);
          }
        });
      }
      if (param2 == "REMOVE") {
        seleted_id.value = param1;
        showDelete.value = true;
      }
    };

    const onChangeCheckCard = async (val, item) => {
      if (val) {
        selectedPrebillings.value.push(item);
        if (is_diferent_currency(selectedPrebillings.value)) {
          Swal.fire({
            html: t("diferent_currency"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          selectedPrebillings.value.pop();
        }
        if (is_diferent_origin(selectedPrebillings.value)) {
          const result = await Swal.fire({
            html: t("diferent_origin"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("tryAgaint"),
            cancelButtonText: t("btnCancel"),
            showCancelButton: true,
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-danger",
            },
          });
          if (result.isConfirmed) {
            store.commit("clearTempBill");
            const temp_bill = [] as any;
            if (selectedPrebillings.value.length === 0 && existSelect.value) {
              existSelect.value = false;
            }
            selectedPrebillings.value.forEach((x) => {
              if (!existSelect.value) {
                existSelect.value = true;
              }
              temp_bill.push(x.all_data);
            });
            store.commit("setTempBill", temp_bill);
            return;
          } else if (result.isDismissed) {
            store.commit("clearTempBill");
            store.commit("setResetSelectedCard", true);
            selectedPrebillings.value = [];
            existSelect.value = false;
            setTimeout(() => {
              store.commit("setResetSelectedTable", false);
            }, 300);
            return;
          }
        }
        store.commit("clearTempBill");
        const temp_bill = [] as any;
        if (selectedPrebillings.value.length === 0 && existSelect.value) {
          existSelect.value = false;
        }
        selectedPrebillings.value.forEach((x) => {
          if (!existSelect.value) {
            existSelect.value = true;
          }
          temp_bill.push(x.all_data);
        });
        store.commit("setTempBill", temp_bill);
      } else {
        selectedPrebillings.value = selectedPrebillings.value.filter(
          (x) => x.id !== item.all_data.id
        );
        if (selectedPrebillings.value.length === 0) {
          existSelect.value = false;
        }
      }
    };

    const onLink = (index, row, event, is_card = false) => {
      console.log("event", event);
      console.log("row", row);
      let tab = "";
      let item_id = "";
      let mf_id = "";
      tab = event === "ibookingNumber" ? "bookings" : "billOfLading";
      item_id =
        event === "maritime_file"
          ? row["all_data"]["maritime_file_id"]
          : row["all_data"]["bl_id"];
      mf_id =
        event === "maritime_file"
          ? item_id
          : row["all_data"]["bills_of_lading"]["bookings"][0][
              "maritime_voyage"
            ]["maritime_file_id"];
      console.log("data", {
        tab: tab,
        item_id: item_id,
      });

      if (tab !== "maritime_file") {
        store.commit("setMaritimeFileTab", {
          tab: tab,
          item_id: item_id,
        });
      }
      // router.push("/maritimefile/" + mf_id);
    };

    const doRemove = () => {
      store.commit("setLoadingStatus", true);
      ApiService.delete(
        `/api/prebillings/${seleted_id.value}?force_delete=${false}`
      )
        .then(async ({ data }) => {
          showDelete.value = false;
          seleted_id.value = null;
          await serverSearch();
          store.commit("setLoadingStatus", false);
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    const onAddBillEdit = async (dta) => {
      if (is_diferent_origin(dta)) {
        const result = await Swal.fire({
          html: t("diferent_origin"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("tryAgaint"),
          cancelButtonText: t("btnCancel"),
          showCancelButton: true,
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
            cancelButton: "btn fw-bold btn-light-danger",
          },
        });
        if (result.isConfirmed) {
          store.commit("clearTempBill");
          const temp_bill = [] as any;
          if (dta.length === 0 && existSelect.value) {
            existSelect.value = false;
          }
          dta.forEach((x) => {
            if (!existSelect.value) {
              existSelect.value = true;
            }

            temp_bill.push(x.all_data);
            setTimeout(() => {
              store.commit("setResetSelectedTable", false);
            }, 300);
            return;
          });
          store.commit("setTempBill", temp_bill);
          return;
        } else if (result.isDismissed) {
          store.commit("setResetSelectedTable", true);
          store.commit("setTempBill", []);
          setTimeout(() => {
            store.commit("setResetSelectedTable", false);
          }, 300);
          return;
        }
      }
      store.commit("clearTempBill");
      const temp_bill = [] as any;
      if (dta.length === 0 && existSelect.value) {
        existSelect.value = false;
      }
      dta.forEach((x) => {
        if (!existSelect.value) {
          existSelect.value = true;
        }

        temp_bill.push(x.all_data);
      });
      store.commit("setTempBill", temp_bill);
    };

    const newInline = () => {
      console.log("newInline", props.propMaritimeFile);
      const prebilling = {
        active: false,
        id: null,
        bills_of_lading: props.origin === "bl" ? props.bl.bl_number : "",
        bills_of_lading_id: props.origin === "bl" ? props.bl.id : "",
        maritime_file:
          props.origin === "maritime_file" ||
          props.origin === "prebillings_concepts"
            ? props.propMaritimeFile.code
            : "",
        maritime_file_id:
          props.origin === "maritime_file" ||
          props.origin === "prebillings_concepts"
            ? props.propMaritimeFile.id
            : "",
        billing_concept_id: "",
        billing_concept: "",
        iva_detail_sell_id: "",
        customer_id:
          (props.origin === "maritime_file" ||
            props.origin === "prebillings_concept") &&
          props.propMaritimeFile.customer_id
            ? props.propMaritimeFile.customer_id
            : props.origin === "bl" && props.bl.customer_id
            ? props.bl.customer_id
            : "",
        customer:
          (props.origin === "maritime_file" ||
            props.origin === "prebillings_concept") &&
          props.propMaritimeFile.customer_id
            ? props.propMaritimeFile.customer.name
            : props.origin === "bl" && props.bl.customer_id
            ? props.bl.customer.name
            : "",
        provider_id: "",
        is_manifest: 0,
        location_abroad_id:
          props.origin !== "global"
            ? props.propMaritimeFile.port.location_id
            : "",
        all_data: null,
        office_id:
          props.origin !== "global" ? props.propMaritimeFile.office.name : "",
        office_id2:
          props.origin !== "global" ? props.propMaritimeFile.office_id : "",
        allows_billing: 1,
        unit_sell: 1,
        iva_detail_sell: "",
        shipowner_id: "",
        amounts: 0,
        cost: 0,
        code: "",
        code_id: "",
        venta: 0,
        armador: 0,
        is_loss: false,
        status_led: "disabled",
        freight_payment_type_id: 4,
        currency_id:
          props.origin !== "global"
            ? props.propMaritimeFile.office.company.currency_id
            : "",
      };
      dataObject.value.unshift(prebilling);
      console.log("arraay despues del unshift", dataObject.value);
      showInsertInline.value = false;
      insertInline.value = true;
    };

    const cancelInsertInline = (index) => {
      if (index !== -1) {
        dataObject.value.splice(index, 1);
        insertInline.value = false;
        showInsertInline.value = true;
        header.value.push({ column: "icustomer", key: "customer" });
      }
    };

    const saveInline = async (row) => {
      showInsertInline.value = true;
      insertInline.value = false;
      store.commit("setLoadingStatus", true);
      const prebilling_amounts = [] as any;
      prebilling_amounts.push({
        cost_type_id: 1,
        total_amount: (
          row.unit_sell * parseFloat(row.unit_sell_amount)
        ).toFixed(2),
        currency_id: row.currency_id,
        taxes_detail_id: row.iva_detail_sell_id,
        unit_amount: parseFloat(row.unit_sell_amount),
        units: row.unit_sell,
      });
      prebilling_amounts.push({
        cost_type_id: 3,
        total_amount: row.cost,
        currency_id: row.currency_id,
        taxes_detail_id: row.iva_detail_sell_id,
        unit_amount: (row.cost / row.unit_sell).toFixed(2),
        units: row.unit_sell,
      });
      const payload = {
        maritime_file_id: row.maritime_file_id,
        bl_id: row.bills_of_lading_id,
        shipload_id: row.shipload_id,
        is_autimatic_prebilling: 0,
        billing_concept_id: row.code_id,
        billing_concept: row.billing_concept,
        fusion_concept: row.fusion_concept,
        freight_payment_type_id: row.freight_payment_type_id,
        customer_id: row.customer_id,
        provider_id: row.provider_id,
        location_abroad_id: row.location_abroad_id,
        office_id: row.office_id2,
        allows_billing: true,
        is_manifest: true,
        shipowner_id: row.shipowner_id,
        prebilling_amounts: prebilling_amounts,
      };
      try {
        if (row.all_data) {
          const { data } = await ApiService.put(
            `/api/prebillings/${row.all_data.id}`,
            payload
          );
        } else {
          const { data } = await ApiService.post("/api/prebillings", {
            list_prebillings: [payload],
          });
        }
        const exist = header.value.find((x) => x.key === "customer");
        if (!exist) {
          header.value.push({ column: "icustomer", key: "customer" });
        }
        await serverSearch();
        store.commit("setLoadingStatus", false);
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    };

    const onEditInline = () => {
      if (insertInline.value) {
        dataObject.value.splice(dataObject.value.length - 1, 1);
        header.value = header.value.filter((x) => x.key !== "customer");
      }
      insertInline.value = false;
    };

    const changeItenPage = async (val) => {
      store.commit("setLoadingStatus", true);
      const page = typeof val === "object" ? parseInt(val.target.value) : val;
      pagination.value.rowsPerPage = page;
      await serverSearch();
      store.commit("setLoadingStatus", false);
    };

    const currentPageChange = async (val) => {
      store.commit("setLoadingStatus", true);
      pagination.value.page = val;
      await serverSearch();
      store.commit("setLoadingStatus", false);
    };

    const setItemsPerPage = async (event) => {
      store.commit("setLoadingStatus", true);
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      await serverSearch();
      store.commit("setLoadingStatus", false);
    };

    const onCancelPrefactureDialog = () => {
      filters_prefacture.value.maritime_id = [];
      filters_prefacture.value.bl_id = [];
      showPrefactureDialog.value = false;
    };
    //End: general actions functions

    //Start: Tools Functions
    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const groupBy = (xs, key) => {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    const getAllTotals = (dts) => {
      dts.forEach((x) => {
        let cost = 0;
        let venta = 0;
        let armador = 0;
        x.prebilling_amounts.forEach((a) => {
          if (a.cost_type_id === 1) {
            venta = Number(venta) + Number(a.total_amount);
          } else if (a.cost_type_id === 2 || a.cost_type_id === 3) {
            cost = Number(cost) + Number(a.total_amount);
          } else if (a.cost_type_id === 4) {
            armador = Number(armador) + Number(a.total_amount);
          }
        });
        totals.value.cost = totals.value.cost + cost;
        totals.value.sell = totals.value.sell + venta;
        totals.value.armador = totals.value.armador + armador;
      });
      totals.value.ganancia = totals.value.sell - totals.value.cost;
    };

    const convertPrebiling = (x) => {
      let temp = 0;
      x.prebilling_amounts.forEach(
        (a) => (temp = Number(temp) + Number(a.total_amount))
      );
      let cost = 0;
      let venta = 0;
      let unit_sell = 0;
      let unit_cost = 0;
      let armador = 0;
      let iva_detail_sell = "";
      let iva_detail_sell_id = "";
      let iva_detail_cost = "";
      let iva_detail_cost_id = "";
      let unit_sell_amount = 0;
      x.prebilling_amounts.forEach((a) => {
        if (a.cost_type_id === 1) {
          venta = Number(venta) + Number(a.total_amount);
          unit_sell = a.units;
          unit_sell_amount = a.unit_amount;
          iva_detail_sell_id = a.taxes_detail_id;
          iva_detail_sell = a.taxes_detail
            ? `${a.taxes_detail.tax_type.name}-${a.taxes_detail.percentage}`
            : "";
        } else if (a.cost_type_id === 2 || a.cost_type_id === 3) {
          cost = Number(cost) + Number(a.total_amount);
          iva_detail_cost_id = a.taxes_detail_id;
          iva_detail_cost = a.taxes_detail
            ? `${a.taxes_detail.tax_type.name}-${a.taxes_detail.percentage}`
            : "";
          unit_cost = a.units;
        } else if (a.cost_type_id === 4) {
          armador = Number(armador) + Number(a.total_amount);
        }
      });
      let amount = venta - cost;
      return {
        active: false,
        id: x.id,
        search: "",
        code: x.prebilling_concept.billing_concept_code.name,
        code_id: x.prebilling_concept.id,
        billing_concept_id: x.prebilling_concept.billing_concept_code_id,
        billing_concept: x.billing_concept
          ? x.billing_concept
          : x.prebilling_concept.name,
        maritime_file_id: x.maritime_file_id,
        fusion_concept: x.fusion_concept,
        maritime_file:
          x.maritime_file_id !== null ? x.maritime_file.code : null,
        bills_of_lading: x.bl_id !== null ? x.bills_of_lading.bl_number : null,
        bills_of_lading_id: x.bl_id,
        customer: x.customer_id ? x.customer.name : "",
        customer_id: x.customer_id,
        provider_id: x.provider_id,
        unit_sell: unit_sell,
        unit_cost: unit_cost,
        provider: x.provider_id ? x.provider.name : "",
        iva_detail_sell_id: iva_detail_sell_id,
        iva_detail_sell: iva_detail_sell,
        iva_detail_cost: iva_detail_cost,
        iva_detail_cost_id: iva_detail_cost_id,
        is_manifest: x.is_manifest !== 0,
        freight_payment_type_id: x.freight_payment_type_id
          ? x.freight_payment_type_id
          : 4,
        freight_payment_type: x.freight_payment_type_id
          ? x.freight_payment_type.name
          : "Otro no especificado",
        location_abroad_id: x.location_abroad_id,
        location_abroad: x.location_abroad_id
          ? x.location_abroad.full_name
          : "",
        all_data: x,
        office_id: x.office_id ? x.office.name : "",
        office_id2: x.office_id,
        allows_billing: x.allows_billing !== 0,
        shipowner_id: x.shipowner_id,
        shipowner: x.shipowner_id ? x.shipowner.name : "",
        amounts: amount.toFixed(2),
        cost: cost.toFixed(2),
        venta: venta.toFixed(2),
        unit_sell_amount: unit_sell_amount,
        armador: armador,
        is_loss: cost > venta,
        status_led:
          maritimeFile.value.port.location_id == x.location_abroad_id
            ? x.has_been_billed
              ? "success"
              : "warning"
            : "disabled",
        currency: `${
          x.prebilling_amounts.length
            ? x.prebilling_amounts[0].currency.code
            : x.bills_of_lading.bookings[0].maritime_voyage.maritime_file.office
                .company.currency.code
        }`,
        currency_id: x.prebilling_amounts.length
          ? x.prebilling_amounts[0].currency_id
          : x.bills_of_lading.bookings[0].maritime_voyage.maritime_file.office
              .company.currency_id,
      };
    };

    const convertCardData = (datas) => {
      const values = datas.map((x) => {
        x.items = {
          id: x.all_data.id,
          billing_concept_id: x.all_data.billing_concept,
          ioffice: x.all_data.office ? x.all_data.office.name : "",
          icode: x.all_data.prebilling_concept.code,
          maritime_file:
            x.all_data.maritime_file_id !== null
              ? x.all_data.maritime_file.code
              : null,
          bills_of_lading:
            x.all_data.bl_id !== null
              ? x.all_data.bills_of_lading.bl_number
              : null,
          iamounts2: x.amounts,
          cost: x.cost,
          sell: x.venta,
          ishipowner2: x.armador,
          icurrency: x.currency,
          all_data: x.all_data,
        };
        return x;
      });
      return values;
    };

    const is_diferent_currency = (dta) => {
      const element = dta[0];
      let val = false;
      dta.forEach((x) => {
        if (x.currency_id !== null && x.currency_id !== element.currency_id) {
          val = true;
        }
      });
      return val;
    };

    const is_diferent_origin = (dta) => {
      const element = dta[0];
      let val = false;
      dta.forEach((x) => {
        if (element.all_data.bl_id !== null) {
          if (
            x.all_data.maritime_file_id !== null ||
            (x.all_data.bl_id !== null &&
              x.all_data.bl_id !== element.all_data.bl_id)
          ) {
            val = true;
          }
        } else {
          if (
            x.all_data.bl_id !== null ||
            (x.all_data.maritime_file_id !== null &&
              x.all_data.maritime_file_id !== element.all_data.maritime_file_id)
          ) {
            val = true;
          }
        }
      });
      return val;
    };

    const isSelectable = (row, index) => {
      if (Array.isArray(row) && row.length === 0) {
        return false;
      } else {
        return row.all_data !== null
          ? !row.all_data.has_been_billed &&
              row.location_abroad_id === maritimeFile.value.port.location_id
          : true;
      }
    };

    const convertToToneladas = (goods_measures) => {
      let value_ton = 0;
      goods_measures.forEach((x) => {
        if (x.measure_type_id === 19) {
          value_ton = doConvertTonelada(x.measure, x.measure_unit_id);
          return;
        }
      });
      return value_ton;
    };

    const doConvertTonelada = (value, type) => {
      switch (type) {
        case 11:
          return parseFloat(value);
          break;
        case 12:
          return parseFloat(value) / 1000;
          break;
        case 13:
          return parseFloat(value) / 1000000;
          break;
        case 14:
          return parseFloat(value) / 1000000000;
          break;
        case 15:
          return parseFloat(value) / 1000000000000;
          break;
        default:
          return 0;
      }
    };
    //End: Tools Functions

    //Start: Mounted, Updated and Watch
    onMounted(async () => {
      console.log("maritime", maritimeFile.value);
      store.commit("setLoadingStatus", true);
      setCurrentPageBreadcrumbs("", [
        { route: "/rebillings", label: "tiPrebilling3" },
      ]);
      try {
        // if (props.origin === "global") {
        //   await getBls();
        //   await getMFs();
        // }
        // if (
        //   props.origin === "maritime_file" ||
        //   props.origin === "prebillings_concept"
        // ) {
        //   await getBls(props.maritimeFileId);
        // }
        await serverSearch();
        store.commit("setLoadingStatus", false);
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    });

    onUpdated(() => {
      // console.clear();
    });

    watch(
      () => props.refresh,
      async () => {
        if (props.refresh) {
          store.commit("setLoadingStatus", true);
          await serverSearch();
          store.commit("setLoadingStatus", false);
        }
      },
      { deep: true }
    );

    watch(
      () => viewType.value,
      (first) => {
        if (first && first === "card") {
          pagination.value.total = filtered.value.length;
          pagination.value.last_page = Math.floor(filtered.value.length / 12);
        } else {
          pagination.value.total = dataObject.value.length;
          pagination.value.last_page = Math.floor(dataObject.value.length / 12);
        }
      }
    );
    //End: Mounted, Updated and Watch

    /*
    const getBls = async (maritime_file_id = "") => {
      const { data } = await ApiService.query("/api/bills_of_lading", {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          maritime_files: maritime_file_id ? [maritime_file_id] : [],
        },
      });
      data.bills_of_lading.forEach((x) => {
        dataBookngs.value.push(x);
        x.bills_of_lading.forEach((y) => {
          y.name = `${y.id}-${y.bl_number}`;
          dataBls.value.push(y);
        });
      });
    };
    const getMFs = async () => {
      const { data } = await ApiService.query("/api/maritimefiles", {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
        },
      });
      data.maritime_files.forEach((x) => {
        x.name = `${x.id}-${x.code}`;
        maritime_files.value.push(x);
      });
    };
    const onPrefacturarEmpty = async (
      cont_import_empty,
      cont_export_empty,
      criteria_customer,
      criteria_tipo_trafico,
      criteria_no_contenedores,
      criteria_vacio,
      action
    ) => {
      console.log("cont_import_empty", cont_import_empty);
      console.log("cont_export_empty", cont_export_empty);
      let tarifas = [] as any;
      let new_payload_venta = {
        cost_type_id: 1,
        date: maritimeFile.value.created_at,
        office: maritimeFile.value.office_id,
        conditions: [] as any,
      };
      let new_payload_coste = {
        cost_type_id: 3,
        date: maritimeFile.value.created_at,
        office: maritimeFile.value.office_id,
        conditions: [] as any,
      };
      if (cont_import_empty > 0 && action === "I") {
        new_payload_venta.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_venta.conditions.push({
        //   criteria_id: criteria_pod.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_venta.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 2,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_import_empty,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 1,
        });

        new_payload_coste.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_coste.conditions.push({
        //   criteria_id: criteria_pol.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_coste.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 2,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_import_empty,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 1,
        });
        const { data: coste1 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_coste
        );
        const { data: ventas1 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_venta
        );
        coste1.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
        ventas1.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
      }
      if (cont_export_empty > 0 && action === "E") {
        new_payload_venta.conditions = [];
        new_payload_coste.conditions = [];
        new_payload_venta.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_venta.conditions.push({
        //   criteria_id: criteria_pol.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_venta.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 1,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 1,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_export_empty,
        });

        new_payload_coste.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_coste.conditions.push({
        //   criteria_id: criteria_pol.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_coste.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 1,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 1,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_export_empty,
        });
        const { data: coste2 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_coste
        );
        const { data: ventas2 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_venta
        );
        coste2.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
        ventas2.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
      }
      return tarifas;
    };
    const getPrebillingAll = async (bls, mfs) => {
      dataObjectBl.value = [];
      store.commit("cleanPrefactureTable");
      const { data } = await ApiService.query(`/api/prebillings`, {
        params: {
          page: 1,
          per_page: 100,
          bl_id: bls,
          maritime_file_id: mfs,
        },
      });
      data.prebillings.forEach((x) => {
        dataObjectBl.value.push(convertPrebiling(x));
      });
    };
    const onPrefacturarFully = async (
      cont_import_fully,
      cont_export_fully,
      criteria_customer,
      criteria_tipo_trafico,
      criteria_no_contenedores,
      criteria_vacio,
      action
    ) => {
      let tarifas = [] as any;
      let new_payload_venta = {
        cost_type_id: 1,
        date: maritimeFile.value.created_at,
        office: maritimeFile.value.office_id,
        conditions: [] as any,
      };
      let new_payload_coste = {
        cost_type_id: 3,
        date: maritimeFile.value.created_at,
        office: maritimeFile.value.office_id,
        conditions: [] as any,
      };
      if (cont_import_fully > 0 && action === "I") {
        new_payload_venta.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_venta.conditions.push({
        //   criteria_id: criteria_pod.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_venta.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 2,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_import_fully,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 0,
        });

        new_payload_coste.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_coste.conditions.push({
        //   criteria_id: criteria_pol.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_coste.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 2,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 0,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_import_fully,
        });
        const { data: coste1 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_coste
        );
        const { data: ventas1 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_venta
        );
        coste1.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
        ventas1.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
      }
      if (cont_export_fully) {
        new_payload_venta.conditions = [];
        new_payload_coste.conditions = [];
        new_payload_venta.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_venta.conditions.push({
        //   criteria_id: criteria_pol.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_venta.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 1,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 0,
        });
        new_payload_venta.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_export_fully,
        });

        new_payload_coste.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: maritimeFile.value.customer_id,
        });
        // new_payload_coste.conditions.push({
        //   criteria_id: criteria_pol.criteria_id,
        //   value: maritimeFile.value.customer_id,
        // });
        new_payload_coste.conditions.push({
          criteria_id: criteria_tipo_trafico.criteria_id,
          value: 1,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_vacio.criteria_id,
          value: 0,
        });
        new_payload_coste.conditions.push({
          criteria_id: criteria_no_contenedores.criteria_id,
          value: cont_export_fully,
        });
        const { data: coste2 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_coste
        );
        const { data: ventas2 } = await ApiService.post(
          "/api/tariff_oficial/search-rates",
          new_payload_venta
        );
        coste2.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
        ventas2.tariffs.forEach((x) => {
          if (x.price > 0) {
            x.level = x.billing_concept.level;
            tarifas.push(x);
          }
        });
      }
      return tarifas;
    };
    const onPrefacturarAll = async (bl, book) => {
      let existImos = [] as any;
      await getPrebillingAll([bl.id], []);
      const { data: criterias } = await ApiService.get(
        "/api/synchronize_criterias"
      );
      const criteria_onwhl = criterias.find(
        (x) => x.flag_synchronize_id === 19
      );
      const criteria_customer = criterias.find(
        (x) => x.flag_synchronize_id === 20
      );
      const criteria_imo = criterias.find((x) => x.flag_synchronize_id === 21);
      const criteria_transportista = criterias.find(
        (x) => x.flag_synchronize_id === 28
      );
      const criteria_supplier = criterias.find(
        (x) => x.flag_synchronize_id === 27
      );
      const criteria_distancia = criterias.find(
        (x) => x.flag_synchronize_id === 22
      );
      const criteria_equipment_type = criterias.find(
        (x) => x.flag_synchronize_id === 23
      );
      const criteria_estadia = criterias.find(
        (x) => x.flag_synchronize_id === 24
      );

      const criteria_puerto_carga = criterias.find(
        (x) => x.flag_synchronize_id === 25
      );
      const criteria_puerto_descarga = criterias.find(
        (x) => x.flag_synchronize_id === 26
      );
      const criteria_dias_ocupacion = criterias.find(
        (x) => x.flag_synchronize_id === 52
      );
      const criteria_dias_libre = criterias.find(
        (x) => x.flag_synchronize_id === 53
      );
      const criteria_dobl = criterias.find((x) => x.flag_synchronize_id === 33);
      const criteria_tipo_trafico_maritimo = criterias.find(
        (x) => x.flag_synchronize_id === 56
      );
      const criteria_vgml = criterias.find((x) => x.flag_synchronize_id === 35);
      const criteria_teco = criterias.find((x) => x.flag_synchronize_id === 60);
      const criteria_ens = criterias.find((x) => x.flag_synchronize_id === 51);
      const criteria_temp = criterias.find((x) => x.flag_synchronize_id === 62);
      const criteria_g_p_contenedor = criterias.find(
        (x) => x.flag_synchronize_id === 65
      );
      const criteria_g_mercancia = criterias.find(
        (x) => x.flag_synchronize_id === 66
      );
      const criteria_tonelada = criterias.find(
        (x) => x.flag_synchronize_id === 67
      );
      const criteria_recargo = criterias.find(
        (x) => x.flag_synchronize_id === 29
      );
      const criteria_refeer = criterias.find(
        (x) => x.flag_synchronize_id === 32
      );
      const criteria_sobrepeso = criterias.find(
        (x) => x.flag_synchronize_id === 30
      );
      let payload_venta = {
        cost_type_id: 1,
        date: bl.bl_date,
        office: maritimeFile.value.office_id,
        conditions: [] as any,
      };
      let payload_coste = {
        cost_type_id: 3,
        date: maritimeFile.value.created_at,
        office: maritimeFile.value.office_id,
        conditions: [] as any,
      };

      let list_prefactures = [] as any;

      const current_bl = bl;
      if (criteria_customer) {
        payload_venta.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: current_bl.customer_id,
        });
        payload_coste.conditions.push({
          criteria_id: criteria_customer.criteria_id,
          value: current_bl.customer_id,
        });
      }

      for (const x of book.transportation_orders) {
        if (
          x.transportation_type === "Cedido" ||
          x.transportation_type === "C"
        ) {
          if (criteria_onwhl) {
            payload_venta.conditions.push({
              criteria_id: criteria_onwhl.criteria_id,
              value: 1,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_onwhl.criteria_id,
              value: 1,
            });
          }
        } else {
          if (criteria_recargo) {
            payload_venta.conditions.push({
              criteria_id: criteria_recargo.criteria_id,
              value: 1,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_recargo.criteria_id,
              value: 1,
            });
          }

          x.to_shiploads.forEach((t) => {
            if (t.shipload.goods.imo === 1) {
              if (criteria_imo) {
                payload_venta.conditions.push({
                  criteria_id: criteria_imo.criteria_id,
                  value: 1,
                });
                payload_coste.conditions.push({
                  criteria_id: criteria_imo.criteria_id,
                  value: 1,
                });
              }
            }
            if (criteria_temp) {
              payload_venta.conditions.push({
                criteria_id: criteria_temp.criteria_id,
                value: t.shipload.equipment_involved.equipment.temperature,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_temp.criteria_id,
                value: t.shipload.equipment_involved.equipment.temperature,
              });
            }

            if (
              t.shipload.equipment_involved.equipment.equipment_subtype_id ===
                14 ||
              t.shipload.equipment_involved.equipment.equipment_subtype_id ===
                15
            ) {
              if (criteria_refeer) {
                payload_venta.conditions.push({
                  criteria_id: criteria_refeer.criteria_id,
                  value: 1,
                });
                payload_coste.conditions.push({
                  criteria_id: criteria_refeer.criteria_id,
                  value: 1,
                });
              }
            }
            t.shipload.equipment_involved.equipment.equipment_measures.forEach(
              (m) => {
                if (m.measure_type_id === 20) {
                  if (criteria_sobrepeso) {
                    payload_venta.conditions.push({
                      criteria_id: criteria_sobrepeso.criteria_id,
                      value: 1,
                    });
                    payload_coste.conditions.push({
                      criteria_id: criteria_sobrepeso.criteria_id,
                      value: 1,
                    });
                  }
                }
              }
            );
          });
          x.to_participants.forEach((p) => {
            if (p.to_participant_type_id === 2) {
              if (criteria_transportista) {
                payload_coste.conditions.push({
                  criteria_id: criteria_transportista.criteria_id,
                  value: p.entity_id,
                });
                payload_venta.conditions.push({
                  criteria_id: criteria_transportista.criteria_id,
                  value: p.entity_id,
                });
              }
            }
          });
          if (props.currentBooking.km_to_bill) {
            if (criteria_distancia) {
              payload_venta.conditions.push({
                criteria_id: criteria_distancia.criteria_id,
                value: props.currentBooking.km_to_bill,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_distancia.criteria_id,
                value: props.currentBooking.km_to_bill,
              });
            }
          }
          if (x.type_id === 4) {
            if (criteria_teco) {
              payload_venta.conditions.push({
                criteria_id: criteria_teco.criteria_id,
                value: 1,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_teco.criteria_id,
                value: 1,
              });
            }
            if (criteria_ens) {
              payload_venta.conditions.push({
                criteria_id: criteria_ens.criteria_id,
                value: 1,
              });
              payload_coste.conditions.push({
                criteria_id: criteria_ens.criteria_id,
                value: 1,
              });
            }
          }
        }
      }
      // nuevo
      if (criteria_dobl) {
        payload_venta.conditions.push({
          criteria_id: criteria_dobl.criteria_id,
          value: 1,
        });
        payload_coste.conditions.push({
          criteria_id: criteria_dobl.criteria_id,
          value: 1,
        });
      }
      if (criteria_tipo_trafico_maritimo) {
        payload_coste.conditions.push({
          criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
          value: book.trip_type_id,
        });
        payload_venta.conditions.push({
          criteria_id: criteria_tipo_trafico_maritimo.criteria_id,
          value: book.trip_type_id,
        });
      }

      book.routes.forEach((x) => {
        if (x.route_type_id === 34) {
          if (criteria_puerto_carga) {
            payload_coste.conditions.push({
              criteria_id: criteria_puerto_carga.criteria_id,
              value: x.route_type_id,
            });
            payload_venta.conditions.push({
              criteria_id: criteria_puerto_carga.criteria_id,
              value: x.route_type_id,
            });
          }
        }
        if (x.route_type_id === 12) {
          if (criteria_puerto_descarga) {
            payload_coste.conditions.push({
              criteria_id: criteria_puerto_descarga.criteria_id,
              value: x.route_type_id,
            });
            payload_venta.conditions.push({
              criteria_id: criteria_puerto_descarga.criteria_id,
              value: x.route_type_id,
            });
          }
        }
      });
      current_bl.equipment_involveds.forEach((x) => {
        if (x.has_vgm) {
          if (criteria_vgml) {
            payload_venta.conditions.push({
              criteria_id: criteria_vgml.criteria_id,
              value: 1,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_vgml.criteria_id,
              value: 1,
            });
          }
        }
        if (x.days_in_use && criteria_dias_ocupacion) {
          payload_venta.conditions.push({
            criteria_id: criteria_dias_ocupacion.criteria_id,
            value: x.days_in_use,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_dias_ocupacion.criteria_id,
            value: x.days_in_use,
          });
        }
        if (x.days_delay_of_return && criteria_dias_libre) {
          payload_venta.conditions.push({
            criteria_id: criteria_dias_libre.criteria_id,
            value: x.days_delay_of_return,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_dias_libre.criteria_id,
            value: x.days_delay_of_return,
          });
        }
        //nuevosif
        if (criteria_g_p_contenedor) {
          payload_venta.conditions.push({
            criteria_id: criteria_g_p_contenedor.criteria_id,
            value: x.equipment.equipment_group_id,
          });
          payload_coste.conditions.push({
            criteria_id: criteria_g_p_contenedor.criteria_id,
            value: x.equipment.equipment_group_id,
          });
        }
        if (criteria_tonelada) {
          payload_venta.conditions.push({
            criteria_id: criteria_tonelada.criteria_id,
            value: convertToToneladas(x.goods_measures),
          });
          payload_coste.conditions.push({
            criteria_id: criteria_tonelada.criteria_id,
            value: convertToToneladas(x.goods_measures),
          });
        }
        if (criteria_g_mercancia) {
          let mercnacia = null as any;
          current_bl.goods.forEach((g) => {
            g.shiploads.forEach((s) => {
              if (s.equipment_involved_id === x.id) {
                mercnacia = g;
              }
            });
          });
          console.log("mercancia", mercnacia);
          if (mercnacia && criteria_g_mercancia) {
            payload_venta.conditions.push({
              criteria_id: criteria_g_mercancia.criteria_id,
              value: mercnacia.customs_tariff_code.group,
            });
            payload_coste.conditions.push({
              criteria_id: criteria_g_mercancia.criteria_id,
              value: mercnacia.group,
            });
          }
        }
      });
      // Search Rate Form
      const { data } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_coste
      );
      const { data: ventas } = await ApiService.post(
        "/api/tariff_oficial/search-rates",
        payload_venta
      );
      const tarifas = [] as any;
      let new_tarifas = [] as any;
      data.tariffs.forEach((x) => {
        if (x.price > 0) {
          x.level = x.tariff.billing_concept.level;
          x.tax_id = x.tariff.tariff_values.taxes_detail.tax_id;
          tarifas.push(x);
        }
      });
      ventas.tariffs.forEach((x) => {
        if (x.price > 0) {
          x.level = x.tariff.billing_concept.level;
          x.tax_id = x.tariff.tariff_values.taxes_detail.tax_id;
          tarifas.push(x);
        }
      });
      tarifas.forEach((t) => {
        const val = dataObjectBl.value.find(
          (x) =>
            (x.all_data.billing_concept_id === t.tariff.billing_concept.id ||
              x.all_data.prebilling_concept.level ===
                t.tariff.billing_concept.level) &&
            x.all_data.has_been_billed === true
        );
        if (val === undefined || val === null) {
          new_tarifas.push(t);
        }
      });
      if (book.trip_type && book.trip_type.code === "E") {
        console.log("book", book);
        let dtas = groupBy(new_tarifas, "tax_id");
        dtas = Object.entries(dtas);
        for (const h of dtas) {
          for (const a of h) {
            if (Array.isArray(a) && h.length > 0) {
              console.log("a", a);
              const { data } = await ApiService.get(
                "/api/taxes/" + a[0].tax_id
              );
              data.tax_all_details.forEach((y) => {
                if (y.percentage === "0.00") {
                  new_tarifas.forEach((q) => {
                    if (
                      q.tariff.tariff_values.taxes_detail.tax_id === y.tax_id
                    ) {
                      q.taxes_detail_id = y.id;
                    }
                  });
                }
              });
            }
          }
        }
      }
      new_tarifas = groupBy(new_tarifas, "level");
      new_tarifas = Object.entries(new_tarifas);
      new_tarifas.forEach((x) => {
        x.forEach((t) => {
          if (Array.isArray(t)) {
            const billings_amount = [] as any;
            let billing_concept = undefined as any;
            let allow_billing = 0;
            let is_manifest = 0;
            let taxes_detail_id = null as any;
            t.forEach((y) => {
              const units = 1;
              const bill_amount = {
                prebilling_id: null,
                taxes_detail_id: y.taxes_detail_id
                  ? y.taxes_detail_id
                  : y.tariff.tariff_values.taxes_detail_id,
                cost_type_id: y.tariff.billing_concept.cost_type_id,
                units: units,
                unit_amount: y.price,
                total_amount: units * y.price,
                currency_id: y.currency.id,
                remarks: "",
                cost_type: null,
                currency: "eur",
              };
              billings_amount.push(bill_amount);
              billing_concept = y.tariff.billing_concept;
              if (t.length === 1) {
                taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
              } else {
                if (y.tariff.billing_concept.cost_type_id === 1) {
                  taxes_detail_id = y.tariff.tariff_values.taxes_detail_id;
                }
              }
              billing_concept = y.tariff.billing_concept;
              if (y.tariff.billing_concept.is_manifest === 1) {
                is_manifest = 1;
              }
              if (y.tariff.billing_concept.allows_billing === 1) {
                allow_billing = 1;
              }
            });
            const find_addresses = maritimeFile.value.office.addresses.find(
              (x) => x.office_id === maritimeFile.value.office_id
            );
            let location_id = null as any;
            if (find_addresses !== undefined) {
              location_id = find_addresses.location_id;
            }

            const allData = {
              id: null,
              bl_id: current_bl.id,
              shipload_id: null,
              taxes_detail_id: taxes_detail_id,
              equipment_involved_id: null,
              equipment_involved: null,
              transportation_order_id: null,
              billing_concept_id: billing_concept.id,
              billing_concept: billing_concept.name,
              customer_id: current_bl.customer_id,
              provider_id: null,
              is_manifest: is_manifest,
              freight_payment_type_id: 4, //todo ver de donde se saca
              location_abroad_id: location_id,
              office_id: maritimeFile.value.office_id,
              allows_billing: allow_billing,
              shipowner_id: null,
              // prebilling_concept: y.billing_concept,
              customer: current_bl.customer_id ? current_bl.customer : null,
              provider: null,
              freight_payment_type: null,
              location_abroad: find_addresses.location.name,
              office: maritimeFile.value.office_id
                ? maritimeFile.value.office
                : null,
              shipowner: null,
              prebilling_amounts: billings_amount,
            };
            let amount_total = 0;
            billings_amount.forEach((z) => {
              if (z.cost_type_id === 1) {
                amount_total += z.total_amount;
              } else if (z.cost_type_id === 3) {
                amount_total += -1 * z.total_amount;
              }
            });
            const currency_code = billings_amount[0].currency_id;
            const prefacture = {
              active: false,
              billing_concept_id:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              billing_concept:
                billing_concept && billing_concept.name
                  ? billing_concept.name
                  : "",
              customer_id:
                current_bl.customer && current_bl.customer.name
                  ? current_bl.customer.name
                  : "",
              provider_id: "",
              is_manifest: is_manifest,
              freight_payment_type_id: "",
              location_abroad_id:
                find_addresses.location && find_addresses.location.name
                  ? find_addresses.location.name
                  : "",
              all_data: allData,
              shipload_id: null,
              office_id:
                maritimeFile.value.office && maritimeFile.value.office.name
                  ? maritimeFile.value.office.name
                  : "",
              allows_billing: allow_billing !== 0,
              shipowner_id: "",
              transportation_order_id: null,
              amounts: amount_total,
              prebilling_amounts: billings_amount,
              currency: currency_code,
            };
            list_prefactures.push(prefacture);
          }
        });
      });

      let cont = 0;
      const list_prebilling_data = [] as any;
      for (const l of list_prefactures) {
        const payload = {
          id: null,
          is_automatic: true,
          bl_id: l.all_data.bl_id,
          taxes_detail_id: l.all_data.taxes_detail_id,
          billing_concept_id: l.all_data.billing_concept_id,
          billing_concept: l.all_data.billing_concept,
          customer_id: l.all_data.customer_id,
          provider_id: l.all_data.provider_id,
          is_manifest: l.all_data.is_manifest,
          freight_payment_type_id: l.all_data.freight_payment_type_id,
          location_abroad_id: l.all_data.location_abroad_id,
          office_id: l.all_data.office_id,
          allows_billing: l.all_data.allows_billing,
          transportation_order_id: l.transportation_order_id,
          shipload_id: l.shipload_id,
          shipowner_id: l.all_data.shipowner_id,
          prebilling_amounts: l.all_data.prebilling_amounts,
          is_autimatic_prebilling: true,
        };
        list_prebilling_data.push(payload);
        cont++;
      }
      try {
        const { data: crateData } = await ApiService.post("/api/prebillings", {
          list_prebillings: list_prebilling_data,
        });
      } catch (e) {
        console.error(e);
        store.commit("setLoadingStatus", false);
      }
    };



    const searchBL = async (query) => {
      if (query !== "") {
        setTimeout(async () => {
          dataBls.value = dataBls.value.filter((item: Record<string, any>) => {
            return (
              item.bl_number.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
          });
          const { data } = await ApiService.query("/api/bills_of_lading/list", {
            params: {
              page: pagination.value.page,
              per_page: pagination.value.rowsPerPage,
              name: query,
            },
          });
          dataBookngs.value = [];
          dataBls.value = [];
          data.bills_of_lading.forEach((x) => {
            dataBookngs.value.push(x);
            x.bills_of_lading.forEach((y) => {
              y.name = `${y.id}-${y.bl_number}`;
              dataBls.value.push(y);
            });
          });
        }, 200);
      }
    };

    const searchMF = async (query) => {
      if (query !== "") {
        setTimeout(async () => {
          maritime_files.value = maritime_files.value.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          const { data } = await ApiService.query("/api/maritimefiles", {
            params: {
              page: pagination.value.page,
              per_page: pagination.value.rowsPerPage,
              code: query,
            },
          });
          maritime_files.value = [];
          data.maritime_files.forEach((x) => {
            x.name = `${x.id}-${x.code}`;
            maritime_files.value.push(x);
          });
        }, 200);
      }
    };
    */

    return {
      canShow,
      canCreate,
      canEdit,
      canDelete,
      permissions,
      filtered,
      dataList,
      search,
      maritime_voyage_id,
      booking_id,
      bl_id,
      equipment_id,
      registered_equipment_id,
      dispatch_id,
      viewType,
      header,
      tableButtons,
      pagination,
      showDelete,
      showDuplicate,
      summary,
      dataCostType,
      filters,
      dataObject,
      existPending,
      existSelect,
      editPrebilling,
      dataBls,
      maritime_files,
      showPrefactureDialog,
      filters_prefacture,
      totals,
      apply_to,
      insertInline,
      form_other,
      user,
      select_prebilling_id,
      showInsertInline,
      maritimeFile,
      seeDetails,
      formatDate,
      onSearch,
      doServerSearch,
      actionsButton,
      currentPageChange,
      setItemsPerPage,
      onAddBillPending,
      isSelectable,
      onAddBillEdit,
      deleteDialogCard,
      doRemove,
      createElement,
      onAddBillClick,
      onLink,
      onDuplicateElelement,
      onChangeCheckCard,
      doPrefacturar,
      onCancelPrefactureDialog,
      changeItenPage,
      newInline,
      cancelInsertInline,
      saveInline,
      onEditInline,
      onPrefacturar,
      doPrefacturarAllBL,
      onPrefacturarNivelBl,
      onPrefacturarNivelBlHTML,
    };
  },
};
