
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { Form } from "vee-validate";
import vars from "@/core/data/vars";
import { VueEditor } from "vue3-editor";
import TemplateTypeSelect from "@/components/catalogs-select/TemplateTypeSelect.vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";

export default {
  name: "ArrivalNoticeDialog",
  components: {
    Form,
    VueEditor,
    TemplateTypeSelect,
  },
  props: {
    maritime_file_id: Number,
    bls_id: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const dialogvisible = computed(() => store.getters.ArrivalNoticeDialog);
    const maritime_file = computed(() => store.getters.maritimeFileItem);
    const template_type_id = ref(undefined) as any;
    const form = ref({
      template_id: null as any,
      subject: "",
      content: "",
      vars: [] as any,
    });
    const real_body = ref("");
    const tags = ref([] as any);
    const values = ref([] as any);
    const templates = ref([] as any);

    const onClickTag = (value) => {
      console.log("value", value);
      form.value.content = `${form.value.content} ${value}`;
    };

    const onChangeId = (value) => {
      form.value.template_id = value;
      if (form.value.template_id) {
        store.commit("setLoading", false);
        ApiService.get(`/api/template/view/${value}`).then(({ data }) => {
          form.value.content = data.body;
          values.value = vars[`${data.template_types_id - 1}`].vars;
          tags.value = values.value.map((x) => x.label);
          template_type_id.value = data.template_types_id;
        });
      } else {
        tags.value = [];
      }
    };

    const loadValues = () => {
      let variables = [] as any;
      const temp = form.value.content.split(/\s+/);
      temp.forEach((x) => {
        x.split(/>/).forEach((y) => {
          if (y[0] === "@") {
            const regex = new RegExp("[\\@&]([^<,&#]*)");
            const result = regex.exec(y);
            if (result) {
              variables.push(result[0]);
            } else {
              variables.push(result);
            }
          }
        });
      });
      real_body.value = form.value.content;
      variables.forEach((x) => {
        const temp = vars[`${template_type_id.value - 1}`].vars;
        const item = temp.find((y) => y.label === x);
        real_body.value = real_body.value.replaceAll(x, item.value);
        form.value.vars.push(item.value.substring(1));
      });

      /*final.forEach((x) => {
        const variable = x.substring(1);
        const variables = variable.split(".");
        switch (variables.length) {
          case 1:
            form.value.content = form.value.content.replace(
              x,
              maritime_file.value[`${variables[0]}`]
            );
            break;
          case 2:
            form.value.content = form.value.content.replace(
              x,
              maritime_file.value[`${variables[0]}`][`${variables[1]}`]
            );
            break;
          case 3:
            form.value.content = form.value.content.replace(
              x,
              maritime_file.value[`${variables[0]}`][`${variables[1]}`][
                `${variables[2]}`
              ]
            );
            break;
          case 4:
            form.value.content = form.value.content.replace(
              x,
              maritime_file.value[`${variables[0]}`][`${variables[1]}`][
                `${variables[2]}`
              ][`${variables[3]}`]
            );
            break;
        }
      });*/
    };

    const onSend = () => {
      loadValues();
      const payload = {
        body: real_body.value,
        subject: form.value.subject,
        bl_ids: props.bls_id.map((x) => x.id),
        vars: form.value.vars,
      };
      store.commit("setArrivalNoticeDialog", false);
        ElNotification({
          title: "Success",
          message: "Se estan procesando los Arrival Notices...",
          type: "success",
        });
      ApiService.post("/api/template/send_mail", payload)
        .then(({ data }) => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          
        })
        .catch((error) => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const resetForm = () => {
      form.value.template_id = null;
      form.value.subject = "";
      form.value.content = "";
      form.value.vars = [];
      real_body.value = "";
      template_type_id.value = null;
      tags.value = [];
    };

    const onClose = () => {
      store.commit("setArrivalNoticeDialog", false);
      resetForm();
    };

    onMounted(async () => {
      await getTemplates();
    });
    const getTemplates = async () => {
      const { data } = await ApiService.query("/api/template/lists", {
        params: {
          office_ids: [maritime_file.value.office_id],
          template_type_id: [1],
        },
      });
      templates.value = data.templates;
    };
    return {
      form,
      tags,
      real_body,
      submitButton,
      dialogvisible,
      templates,
      onSend,
      onChangeId,
      onClose,
      onClickTag,
    };
  },
};
