
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import MaritimeTripTypesSelect from "@/components/catalogs-select/MaritimeTripTypesSelect.vue";
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import ImoDeclarationForm from "@/components/maritime-file/forms/ImoDeclarationForm.vue";
import BLForm from "@/components/maritime-file/forms/BLForm.vue";
import BLPrebillingForm from "@/components/maritime-file/forms/BLPrebillingForm.vue";
import BillOfLandingOT from "@/views/maritime_file/BillOfLandingOT.vue";
import Documents from "@/components/maritime-file/tabs/Documents.vue";
import Dispatches from "@/components/maritime-file/tabs/booking-tabs/Dispatches.vue";
import TariffHeadingsDialog from "@/components/dialogs/TariffHeadingsDialog.vue";
import ArrivalNoticeDialog from "@/components/dialogs/ArrivalNoticeDialog.vue";
import { useRoute } from "vue-router";
import SPSelect from "@/components/catalogs-select/SPSelect.vue";
import { ElNotification } from "element-plus";
import i18n from "@/core/plugins/i18n";
import CustomerEntityOfficeSelect from "@/components/catalogs-select/CustomerEntityOfficeSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "BillOfLading",
  components: {
    CustomerEntityOfficeSelect,
    SPSelect,
    ImoDeclarationForm,
    BillOfLandingOT,
    MaritimeVoyageSelect,
    MaritimeTripTypesSelect,
    BLForm,
    BLPrebillingForm,
    Dispatches,
    Documents,
    TableBasicSelect,
    TariffHeadingsDialog,
    ArrivalNoticeDialog,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const billing_filter = ref(0);
    const participants_id = ref([]);
    const customers_id = ref([]);
    const notify_arrival = ref<Boolean|null>(null)
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bills of lading: create")
            .length > 0
        );
      }
      return false;
    });
    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bills of lading: show")
            .length > 0
        );
      }
      return false;
    });
    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bills of lading: update")
            .length > 0
        );
      }
      return false;
    });
    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "bills of lading: delete")
            .length > 0
        );
      }
      return false;
    });

    const editBooking = ref(false);
    const addBooking = ref(false);
    const showHeadings = ref(false);
    const showDuplicate = ref(false);
    const duplicateId = ref(null) as any;
    const selectMaritimeVoyageNew = ref(false);
    const selectCustomerNew = ref(false);
    const maritime_voyage_id_new = ref(null) as any;
    const client_id_new = ref(null) as any;
    const flowOption = ref(undefined);
    const activeName = ref("first");
    const search = ref("");
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
      last_page: 1,
    });
    const dialogArrivalNotice = computed(
      () => store.getters.ArrivalNoticeDialog
    );
    const selected = computed(() => store.getters.TableSelectList);
    const disable = computed(() => bl.value.booking_id !== "");
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const bookingTableData = computed(() => store.getters.Bookings);
    const dataObject = computed(() => store.getters.BookingsBLTable);
    const showTable = computed(() => editBooking.value || addBooking.value);

    const blnumber = ref("");
    const bldate = ref("");
    const bl = ref({
      id: undefined,
      bl_number: "",
      bl_date: "",
      booking_id: undefined,
      customer_id: undefined,
      manifest_charge: "",
      manifest_discharge: "",
      summary_declaration_charge: "",
      summary_declaration_discharge: "",
    });
    const form = ref({
      id: undefined,
      maritime_voyage_id: undefined,
      trip_type_id: undefined,
      booking_number: "",
      customer_id: undefined,
      booking_date: "",
      edi_path_id: undefined,
      reference: "",
      manifest_charge: "",
      manifest_discharge: "",
      summary_declaration_charge: "",
      summary_declaration_discharge: "",
      has_ens: "0",
      humanitary_aid: "0",
      accoutrements: "0",
      military_purposes: "0",
      bill_of_lading_type_id: undefined,
      next_previous_transport_method_id: undefined
    });
    const originalBL = ref({
      id: undefined,
      maritime_voyage_id: undefined,
      trip_type_id: undefined,
      booking_number: "",
      booking_date: "",
      edi_path_id: undefined,
      reference: "",
      bill_of_lading_type_id: undefined,
      next_previous_transport_method_id: undefined
    });
    const transshipment = ref({
      maritime_voyage_id: undefined,
      remarks: "",
    });
    const filters = ref({
      maritime_voyage_id: undefined,
      trip_type_id: undefined,
    });
    const header = ref([
      {
        column: "",
        key: "has_imo",
        type: "icon",
        icon: "las la-exclamation-triangle",
        color: "warning",
      },
      {
        column: "",
        key: "has_vgm",
        type: "icon",
        icon: "las la-check",
        color: "gray-900",
      },
      {
        column: t("hasbilling"),
        key: "hasbilling",
        size: "small",
        type: "check",
      },
      {
        column: t("ENS"),
        key: "has_ens",
        size: "checkbox",
        type: "checkbox",
      },
      {
        column: t("notified"),
        key: "notify_arrival",
        key_second: "date_notify_arrival",
        size: "small",
        type: "check_tooltip",
      },
      {
        column: "ttiblnumber",
        key: "bl_number",
        size: "small",
      },
      {
        column: "tmaritimevoyage",
        key: "maritime_voyage_id",
        size: "small",
      },
      {
        column: "ttripType",
        key: "trip_type_id",
        size: "small",
      },
      {
        column: "isecondaryvoyage",
        key: "transshipment",
        size: "small",
      },
      { column: "tbookingNumber", key: "booking_number", size: "small" },
      { column: "idate", key: "booking_date", size: "small" },
      { column: "routes", key: "booking_route" },
      // { column: "tparticipants", key: "participants" },
      { column: "tshipsloads", key: "shiploads" },
      { column: t("customer_id"), key: "customer_id" },
      { column: t("ishipper"), key: "ishipper" },
      { column: t("iconsignne"), key: "iconsignne" },
      { column: t("notify"), key: "notify" },
    ]);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Duplicar" as never,
        type: "DUPLICATE-2" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Rolar" as never,
        type: "ROLAR" as never,
        color: "primary" as never,
      } as never,
    ]);

    const duplicateDialog = (value) => {
      duplicateId.value = value;
      showDuplicate.value = true;
    };

    const setItemsPerPage = (event) => {
      store.commit(
        "updateBlPagination",
        typeof event === "object" ? parseInt(event.target.value) : event
      );
      serverSearch();
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const handleClick = (value) => {
      if (value === "docs") {
        store.commit("setReload", true);
      }
    };

    const openTariffHeadings = (option: any) => {
      showHeadings.value = true;
      flowOption.value = option;
    };

    const setArrivalNotice = () => {
      console.log(
        "setArrivalNotice dialogArrivalNotice",
        !dialogArrivalNotice.value
      );
      store.commit("setArrivalNoticeDialog", !dialogArrivalNotice.value);
      console.log("dialogArrivalNotice", dialogArrivalNotice.value);
    };

    const resetFields = () => {
      store.commit("resetBLTab");
      form.value.id = undefined;
      form.value.maritime_voyage_id = undefined;
      form.value.trip_type_id = undefined;
      form.value.booking_number = "";
      form.value.booking_date = "";
      form.value.has_ens = "0";
      form.value.military_purposes = "0";
      form.value.accoutrements = "0";
      form.value.humanitary_aid = "0";
      form.value.bill_of_lading_type_id = undefined;
      form.value.next_previous_transport_method_id = undefined;
      form.value.reference = "";
      form.value.manifest_charge = "";
      form.value.manifest_discharge = "";
      form.value.summary_declaration_charge = "";
      form.value.summary_declaration_discharge = "";
      bl.value.bl_date = "";
      bl.value.booking_id = undefined;
      bl.value.id = undefined;
      bl.value.bl_number = "";
      bl.value.customer_id = undefined;
      bl.value.manifest_charge = "";
      bl.value.manifest_discharge = "";
      bl.value.summary_declaration_charge = "";
      bl.value.summary_declaration_discharge = "";
      bldate.value = "";
      blnumber.value = "";
      transshipment.value.maritime_voyage_id = undefined;
      transshipment.value.remarks = "";
    };

    const onCancel = () => {
      resetFields();
      editBooking.value = false;
      addBooking.value = false;
      store.commit("setPrebillingOpen", undefined);
      store.commit("setCurrentElement", undefined);
      store.commit("setsaveForm", false);
      serverSearch();
    };

    const editBookingInfo = (param) => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/bookings/${param.booking_id}`)
        .then(({ data }) => {
          form.value.id = data.id;
          form.value.maritime_voyage_id = data.maritime_voyage_id;
          form.value.trip_type_id = data.trip_type_id;
          form.value.booking_number = data.booking_number;
          form.value.booking_date = data.booking_date
            ? moment(data.booking_date).format("YYYY-MM-DD hh:mm:ss")
            : "";
          form.value.edi_path_id = data.edi_path_id;
          form.value.reference = data.reference;
          store.commit("addTripType", data.trip_type);
          store.commit("clearMaritimeVoyage");
          store.commit("addMaritimeVoyage", data.maritime_voyage);

          if (data.transshipment) {
            transshipment.value.maritime_voyage_id =
              data.transshipment.maritime_voyage_id;
            transshipment.value.remarks = data.transshipment.remarks;
            store.commit("addMaritimeVoyage", data.transshipment.maritime_trip);
          }

          const setBookingRoute = [] as any;
          if ("routes" in data && data.routes.length) {
            data.routes.forEach(async (x, index) => {
              store.commit("addRouteType", {
                id: x.route_type.id,
                name: x.route_type.name,
                description: x.route_type.description,
              });
              store.commit("addLocation", {
                id: x.location.id,
                country_id: x.location.country_id,
                subdivision_id: x.location.subdivision_id,
                code: x.location.code,
                full_code: x.location.full_code,
                name: x.location.name,
                full_name: x.location.full_name,
              });
              setBookingRoute.push({
                id: x.id,
                ue: x.location ? x.location.country.is_eu : false,
                booking_id: x.booking_id,
                route_type_id: x.route_type_id,
                location_id: x.location_id,
                order: x.order,
                remarks: x.remarks,
              });
            });
            store.commit("setBookingRouteBL", setBookingRoute);
          }

          if ("participants" in data && data.participants.length) {
            data.participants.forEach((x) => {
              store.commit("addEntity", {
                id: x.entity_id,
                name: x.entity ? x.entity.name : "",
                legal_name: x.entity ? x.entity.legal_name : "",
                identification: x.entity ? x.entity.identification : "",
                identification_type_id: x.entity
                  ? x.entity.identification_type_id
                  : "",
              });
              store.commit("addParticipantType", {
                id: x.participant_type_id,
                name: x.participant_type.name,
                code: x.participant_type.code,
              });
              store.commit("setParticipantBL", {
                id: x.id,
                booking_id: x.booking_id,
                entity_id: x.entity_id,
                participant_type_id: x.participant_type_id,
                entity_data: x.entity_data,
              });
            });
          }
          store.commit("setBookingElement", data);
          store.commit("setLoadingStatus", false);
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    const actionsButton = async (param1, param2, param3) => {
      if (param2 == "EDIT") {
        store.commit("setLoadingStatus", true);
        ApiService.get(`/api/bills_of_lading/${param1}`)
          .then(({ data }) => {
            form.value.id = data.id;
            form.value.bill_of_lading_type_id = data.bill_of_lading_type_id
              ? data.bookings[0].bill_of_lading_type_id
              : null;
            form.value.next_previous_transport_method_id = data.next_previous_transport_method_id
              ? data.bookings[0].next_previous_transport_method_id
              : null;
            form.value.humanitary_aid =
            data.humanitary_aid || data.humanitary_aid == "1" ? "1" : "0";
            form.value.accoutrements =
            data.accoutrements || data.accoutrements == "1" ? "1" : "0";
            form.value.military_purposes =
            data.military_purposes || data.military_purposes == "1" ? "1" : "0";
            form.value.has_ens =
            data.has_ens || data.has_ens == "1" ? "1" : "0";
            form.value.maritime_voyage_id = data.bookings.length
              ? data.bookings[0].maritime_voyage_id
              : null;
            form.value.manifest_charge = data.manifest_charge
              ? data.manifest_charge
              : null;
            form.value.manifest_discharge = data.manifest_discharge
              ? data.manifest_discharge
              : null;
            form.value.summary_declaration_charge =
              data.summary_declaration_charge
                ? data.summary_declaration_charge
                : null;
            form.value.summary_declaration_discharge =
              data.summary_declaration_discharge
                ? data.summary_declaration_discharge
                : null;
            form.value.trip_type_id = data.bookings.length
              ? data.bookings[0].trip_type_id
              : null;
            form.value.booking_number = data.bookings.length
              ? data.bookings[0].booking_number
              : "";
            form.value.booking_date = data.bookings[0].booking_date
              ? moment(data.bookings[0].booking_date).format(
                  "YYYY-MM-DD hh:mm:ss"
                )
              : "";
            form.value.edi_path_id = data.bookings.length
              ? data.bookings[0].edi_path_id
              : null;
            form.value.reference = data.bookings.length
              ? data.bookings[0].reference
              : "";
            originalBL.value = { ...form.value };
            bl.value.id = addBooking.value ? null : param1;
            bl.value.bl_date = moment(data.bl_date).format(
              "YYYY-MM-DD hh:mm:ss"
            );
            bl.value.booking_id = data.bookings.length
              ? data.bookings[0].id
              : "";
            bl.value.customer_id = data.customer_id;
            bl.value.bl_number = data.bl_number;
            bl.value.manifest_charge = data.manifest_charge
              ? data.manifest_charge
              : null;
            bl.value.manifest_discharge = data.manifest_discharge
              ? data.manifest_discharge
              : null;
            bl.value.summary_declaration_charge =
              data.summary_declaration_charge
                ? data.summary_declaration_charge
                : null;
            bl.value.summary_declaration_discharge =
              data.summary_declaration_discharge
                ? data.summary_declaration_discharge
                : null;
            bldate.value = bl.value.bl_date;
            blnumber.value = bl.value.bl_number;
            if (data.customer) {
              store.commit("addCustomer", data.customer);
            }
            const currentElement = {
              name: "tiblnumber",
              value: bl.value.bl_number,
              id: bl.value.id,
            };
            if (data.bookings.length) {
              store.commit("setBooking", {
                id: data.bookings[0].id,
                booking_number: data.bookings[0].booking_number,
              });
              store.commit("addTripType", data.bookings[0].trip_type);
              store.commit(
                "addMaritimeVoyage",
                data.bookings[0].maritime_voyage
              );
            }
            store.commit("setPrebillingOpen", currentElement);
            store.commit("setCurrentElement", currentElement);

            if (data.goods.length) {
              data.goods.forEach((x) => {
                if (x.shiploads.length) {
                  const valsOt = [] as any;
                  const vals = [] as any;
                  x.shiploads.forEach((y) => {
                    if (y.has_transportation_order) {
                      valsOt.push(y);
                    } else {
                      vals.push(y);
                    }
                  });
                  store.commit("setShiploadBlOT", valsOt);
                  store.commit("setShiploadBlUOT", vals);
                }
              });
            }
            if (
              data.bookings.length &&
              "transshipment" in data.bookings[0] &&
              data.bookings[0].transshipment
            ) {
              transshipment.value.maritime_voyage_id =
                data.bookings[0].transshipment.maritime_voyage_id;
              transshipment.value.remarks =
                data.bookings[0].transshipment.remarks;
              store.commit(
                "addMaritimeVoyage",
                data.bookings[0].transshipment.maritime_trip
              );
            }

            const setBookingRoute = [] as any;
            if (data.bookings.length) {
              data.bookings[0].routes.forEach((x) => {
                store.commit("addRouteType", {
                  id: x.route_type.id,
                  name: x.route_type.name,
                  description: x.route_type.description,
                });
                if (x.location_id) {
                  store.commit("addLocation", {
                    id: x.location.id,
                    country_id: x.location.country_id,
                    subdivision_id: x.location.subdivision_id,
                    code: x.location.code,
                    full_code: x.location.full_code,
                    name: x.location.name,
                    full_name: x.location.full_name,
                  });
                }
                setBookingRoute.push({
                  id: x.id,
                  ue: x.location.country.is_eu,
                  booking_id: x.booking_id,
                  route_type_id: x.route_type_id,
                  location_id: x.location_id,
                  order: x.order,
                  remarks: x.remarks,
                });
              });
              store.commit("setBookingRouteBL", setBookingRoute);

              data.bookings[0].participants.forEach((x) => {
                if (x.entity) {
                  store.commit("addEntity", {
                    id: x.id,
                    name: x.entity.name,
                    legal_name: x.entity.legal_name,
                    identification: x.entity.identification,
                    identification_type_id: x.entity.identification_type_id,
                  });
                }
                store.commit("addParticipantType", {
                  id: x.participant_type_id,
                  name: x.participant_type.name,
                  code: x.participant_type.code,
                });
                store.commit("setParticipantBL", {
                  id: x.id,
                  booking_id: x.booking_id,
                  entity_id: x.entity_id ? x.entity_id : undefined,
                  participant_type_id: x.participant_type_id,
                  entity_data: x.entity_data,
                });
              });
              store.commit("setBLElement", data);
              store.commit("setBookingElement", data.bookings[0]);
            }
            editBooking.value = true;
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
          });
      }
      if (param2 == "DUPLICATE") {
        duplicateDialog(param1);
        /*const item = bookingTableData.value.filter((x) => x.id === param1);
        let payload = {
          maritime_voyage_id: item[0].maritime_voyage_id,
          trip_type_id: item[0].trip_type_id,
          booking_number: item[0].booking_number,
          booking_date: item[0].booking_date,
          edi_path_id: item[0].edi_path_id,
          reference: item[0].reference,
        };
        if (item[0].transshipment !== null) {
          payload["transshipment"] = item[0].transshipment;
        }
        payload["booking_routes"] = item[0].routes;
        payload["participants"] = item[0].participants;
        //payload["shiploads"] = item[0].shiploads;

        const blfinal = item[0].bills_of_lading;
        const add = {
          booking_id:
            item[0].bills_of_lading[item[0].bills_of_lading.length - 1][
              "booking_id"
            ],
          bl_date: moment().format("YYYY-MM-DD"),
          bl_number: blfinal[0].bl_number,
        };
        blfinal.push(add);
        payload["bills_of_lading"] = blfinal;

        ApiService.put(`/api/bookings/${item[0].id}`, payload)
          .then(({ data }) => {
            store.commit("setLoadingStatus", true);
            store.commit("updateBooking", data);
            const routes = data.routes.map((x) => x.location.full_name);
            const participants = data.participants.map((x) => x.entity.name);
            const shiploads = data.containers.map((x) => x.name);
            store.commit("updateBookingTable", {
              id: data.id,
              maritime_voyage_id: data.maritime_voyage.code,
              trip_type_id: data.trip_type.name,
              booking_number: data.booking_number,
              booking_date: data.booking_date,
              edi_path_id: data.edi_path_id,
              booking_route: routes
                .filter((x, index) => {
                  return routes.indexOf(x) === index;
                })
                .join(", "),
              participants: participants
                .filter((x, index) => {
                  return participants.indexOf(x) === index;
                })
                .join(", "),
              shiploads: shiploads
                .filter((x, index) => {
                  return shiploads.indexOf(x) === index;
                })
                .join(", "),
            });
            store.commit("addBLTable", {
              id: data.bills_of_lading[0].id,
              bl_date: data.bills_of_lading[0].bl_date,
              bl_number: data.bills_of_lading[0].bl_number,
              maritime_voyage_id: data.maritime_voyage.code,
              trip_type_id: data.trip_type.name,
              booking_number: data.booking_number,
              booking_date: data.booking_date,
              edi_path_id: data.edi_path_id,
              reference: data.reference,
              booking_route: routes
                .filter((x, index) => {
                  return routes.indexOf(x) === index;
                })
                .join(", "),
              participants: participants
                .filter((x, index) => {
                  return participants.indexOf(x) === index;
                })
                .join(", "),
              shiploads: shiploads
                .filter((x, index) => {
                  return shiploads.indexOf(x) === index;
                })
                .join(", "),
              buttons: [
                { type: "EDIT", id: data.id },
                { type: "REMOVE", id: data.id },
              ],
            });
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            store.commit("setLoadingStatus", false);
          });*/
      }
      if (param2 === "MOVE") {
        const item1 = bookingTableData.value.find(
          (x) => x.bills_of_lading[0].id === param1
        );
        maritime_voyage_id_new.value = param3;
        rolar(item1);
      }
      if (param2 === "DOWNLOAD") {
        const url = `${param1.edi_path_id.path}`;
        window.open(url);
      }
      if (param2 == "REMOVE") {
        ApiService.delete(`/api/bills_of_lading/${param1}?force_delete=true`).then(({ data }) => {
          store.dispatch("GET_LOGACTIVITIES_API");
          const index = dataObject.value.findIndex((x) => x.id === param1);
          store.commit("removeBL", index);
          store.commit("removeBLTable", index);
        });
      }
    };

    const rolar = (data) => {
      store.commit("setLoadingStatus", true);
      selectMaritimeVoyageNew.value = false;
      let flag = true;
      let payload = {
        bookings: [] as any,
      };

      if (!Array.isArray(data)) {
        const aux = {
          id: data.id,
          maritime_voyage_id: maritime_voyage_id_new.value,
          transshipment: null,
        };
        if (data.transshipment !== null) {
          if (data.transshipment.maritime_trip) {
            delete data.transshipment.maritime_trip;
          }
          delete data.transshipment.created_at;
          delete data.transshipment.updated_at;
          delete data.transshipment.deleted_at;
          aux["transshipment"] = data.transshipment;
        }
        payload.bookings.push(aux);
      } else {
        const resultData = data.reduce((arrGroup, current) => {
          let thatItem = arrGroup.find(
            (item) =>
              item.all_data.trip_type_id == current.all_data.trip_type_id
          );
          if (thatItem == undefined) arrGroup = [...arrGroup, { ...current }];
          return arrGroup;
        }, []);

        flag = resultData.length == 1;

        data.forEach((x) => {
          const aux = {
            id: x.all_data.id,
            maritime_voyage_id: maritime_voyage_id_new.value,
            transshipment: null,
          };
          if (x.all_data.transshipment !== null) {
            if (x.all_data.transshipment.maritime_trip) {
              delete x.all_data.transshipment.maritime_trip;
            }
            delete x.all_data.transshipment.created_at;
            delete x.all_data.transshipment.updated_at;
            delete x.all_data.transshipment.deleted_at;
            aux["transshipment"] = x.all_data.transshipment;
          }
          payload.bookings.push(aux);
        });
      }

      if (flag) {
        ApiService.patch(`/api/bookings/change_maritime_voyages`, payload)
          .then(({ data }) => {
            maritime_voyage_id_new.value = "";
            serverSearch();
          })
          .catch(() => {
            maritime_voyage_id_new.value = "";
            store.commit("setLoadingStatus", false);
          });
      } else {
        ElNotification({
          title: "Error",
          message: i18n.global.t("onlyOneTripType"),
          type: "error",
        });
        maritime_voyage_id_new.value = "";
        store.commit("setLoadingStatus", false);
      }
    };

    const addCustomer = (data) => {
      store.commit("setLoadingStatus", true);
      selectCustomerNew.value = false;
      let flag = true;
      let payload = {} as any;

      if (!Array.isArray(data)) {
        payload = {
          customer_id: client_id_new.value,
          bls_id: [data.id],
        };
      } else {
        payload = {
          customer_id: client_id_new.value,
          bls_id: data.map((x) => x.id),
        };
      }

      ApiService.patch(`api/bills_of_lading/set_customer`, payload)
        .then(({ data }) => {
          client_id_new.value = "";
          serverSearch();
        })
        .catch(() => {
          client_id_new.value = "";
          store.commit("setLoadingStatus", false);
        });
    };

    const serverSearch = () => {
      if (localStorage.getItem("user")) {
        store.commit("setLoadingStatus", true);
        ApiService.query(`/api/bills_of_lading`, {
          params: {
            per_page: pagination.value.rowsPerPage,
            page: pagination.value.page,
            billing_filter: billing_filter.value,
            bl_number: search.value,
            maritime_files: [maritimeFileId.value],
            maritime_voyage: filters.value.maritime_voyage_id,
            trip_types: filters.value.trip_type_id,
            participants: participants_id.value,
            customers: customers_id.value,
            notify_arrival: notify_arrival.value,
          },
        }).then(({ data: blInfo }) => {
          store.commit("cleanBLTable");
          store.commit("setLoadingStatus", true);
          pagination.value.rowsPerPage = blInfo.pagination.per_page;
          pagination.value.total = blInfo.pagination.total;
          pagination.value.last_page = blInfo.pagination.last_page;
          pagination.value.page = blInfo.pagination.current_page;
          blInfo.bills_of_lading.forEach((z) => {
            store.commit("setBooking", z);
            const routes = z.routes.map((x) => x.location.full_name);
            const participants = z.participants.map((x) => x.entity?.name);
            const shiploads = z.equipment_involveds.map(
              (x) => x.equipment?.name
            );
            const has_imo = z.has_dangerous_goods;
            const has_vgm =
              z.equipment_involveds.findIndex((w) => w.has_vgm) > -1;
            store.commit("addBLTable", {
              all_data: z,
              id: z.bills_of_lading[0].id,
              can_it_be_deleted: z.bills_of_lading[0].can_it_be_deleted,
              bl_date: z.bills_of_lading[0].bl_date,
              bl_number: z.bills_of_lading[0].bl_number,
              bl_customer: z.bills_of_lading[0].customer_id,
              notify_arrival: z.bills_of_lading[0].notify_arrival,
              date_notify_arrival: z.bills_of_lading[0].date_notify_arrival,
              booking_id: z.id,
              maritime_voyage_id: z.maritime_voyage.code,
              trip_type_id: z.trip_type.code,
              transshipment: z.transshipment
                ? `${z.transshipment.maritime_trip.trip_type} - ${z.transshipment.maritime_trip.code}`
                : "-",
              booking_number: z.booking_number,
              booking_date: z.booking_date,
              edi_path_id: z.edi_path,
              reference: z.reference,
              has_imo: has_imo,
              has_vgm: has_vgm,
              hasbilling: z.has_billing,
              has_ens: z.has_ens,
              humanitary_aid: z.humanitary_aid,
              bill_of_lading_type_id: z.bill_of_lading_type_id,
              next_previous_transport_method_id: z.next_previous_transport_method_id,
              accoutrements: z.accoutrements,
              military_purposes: z.military_purposes,
              booking_route: routes
                .filter((x, index) => {
                  return routes.indexOf(x) === index;
                })
                .join(", "),
              participants: participants
                .filter((x, index) => {
                  return participants.indexOf(x) === index;
                })
                .join(", "),
              shiploads: shiploads
                .filter((x, index) => {
                  return shiploads.indexOf(x) === index;
                })
                .join(", "),
              customer_id:
                z.bills_of_lading[0] && z.bills_of_lading[0].customer
                  ? z.bills_of_lading[0].customer.name
                  : "-",
              ishipper:
                z.participants.length > 0
                  ? z.participants
                      .filter((x) => x.participant_type.code === "CZ")
                      .map((y) => y.entity_data.split("|")[0])
                      .toString()
                  : "-",
              iconsignne:
                z.participants.length > 0
                  ? z.participants
                      .filter((x) => x.participant_type.code === "CN")
                      .map((y) => y.entity_data.split("|")[0])
                      .toString()
                  : "-",
              notify:
                z.participants.length > 0
                  ? z.participants
                      .filter((x) => x.participant_type.code === "N1")
                      .map((y) => y.entity_data.split("|")[0])
                      .toString()
                  : "-",
              buttons: [
                { type: "EDIT", id: z.id },
                { type: "REMOVE", id: z.id },
              ],
            });
          });
          store.commit("setLoadingStatus", false);
        });
      }
    };

    const exportAll = () => {
      if (localStorage.getItem("user")) {
        store.commit("setLoadingStatus", true);
        ApiService.query(`/api/bills_of_lading`, {
          params: {
            per_page: 0,
            page: 1,
            maritime_files: [maritimeFileId.value],
          },
        }).then(({ data: blInfo }) => {
          let dataExport = [] as any;
          blInfo.bills_of_lading.forEach((z) => {
            const routes = z.routes.map((x) => x.location.full_name);
            const participants = z.participants.map((x) => x.entity?.name);
            const shiploads = z.equipment_involveds.map(
              (x) => x.equipment?.name
            );
            const has_imo = z.has_dangerous_goods;
            const has_vgm =
              z.equipment_involveds.findIndex((w) => w.has_vgm) > -1;
            dataExport.push({
              all_data: z,
              id: z.bills_of_lading[0].id,
              can_it_be_deleted: z.bills_of_lading[0].can_it_be_deleted,
              bl_date: z.bills_of_lading[0].bl_date,
              bl_number: z.bills_of_lading[0].bl_number,
              bl_customer: z.bills_of_lading[0].customer_id,
              booking_id: z.id,
              maritime_voyage_id: z.maritime_voyage.code,
              trip_type_id: z.trip_type.code,
              transshipment: z.transshipment
                ? `${z.transshipment.maritime_trip.trip_type} - ${z.transshipment.maritime_trip.code}`
                : "-",
              booking_number: z.booking_number,
              booking_date: z.booking_date,
              edi_path_id: z.edi_path,
              reference: z.reference,
              has_imo: has_imo,
              has_vgm: has_vgm,
              hasbilling: z.has_billing,
              has_ens: z.has_ens,
              booking_route: routes
                .filter((x, index) => {
                  return routes.indexOf(x) === index;
                })
                .join(", "),
              participants: participants
                .filter((x, index) => {
                  return participants.indexOf(x) === index;
                })
                .join(", "),
              shiploads: shiploads
                .filter((x, index) => {
                  return shiploads.indexOf(x) === index;
                })
                .join(", "),
              buttons: [
                { type: "EDIT", id: z.id },
                { type: "REMOVE", id: z.id },
              ],
            });
          });

          ApiService.setResponseTypeBlob();
          let headersNames: string[] = [];
          let headersKeys: string[] = [];
          header.value.forEach((element) => {
            headersNames.push(t(element.column));
            headersKeys.push(element.key);
          });
          ApiService.post(`/api/export`, {
            params: {
              thsNames: headersNames,
              ths: headersKeys,
              data: dataExport,
            },
          })
            .then((data) => {
              let fileUrl = window.URL.createObjectURL(data);
              let fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute("download", t("tiBillOfLadings") + `.xlsx`);
              document.body.appendChild(fileLink);
              fileLink.click();
              store.commit("setLoadingStatus", false);
            })
            .catch((error) => {
              console.log(error);
              store.commit("setLoadingStatus", false);
            });
          ApiService.setResponseTypeJson();
        });
      }
    };

    const onSearch = () => {
      if (search.value !== "") {
        const filtered = dataObject.value.filter(
          (x) =>
            JSON.stringify(x)
              .toLowerCase()
              .indexOf(search.value.toLowerCase()) !== -1
        );
        store.commit("filterBLTable", filtered);
      } else {
        serverSearch();
      }
    };

    const hasEnsChange = (val) => {
      store.commit("setLoadingStatus", true);
      ApiService.patch(`/api/bills_of_lading/` + val.id, { has_ens: val.value })
        .then(({ data }) => {
          serverSearch();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    onMounted(async () => {
      console.clear();
      const bl_id = localStorage.getItem("bl_id");
      const paramsRoute = route.params;
      if (Object.keys(paramsRoute).length && paramsRoute.id) {
        store.commit("setMaritimeFileId", paramsRoute.id);
        if (bl_id) {
          await actionsButton(bl_id, "EDIT", null);
          editBooking.value = true;
          localStorage.removeItem("bl_id");
        } else {
          serverSearch();
        }
      }
      if (
        store.getters.blBoTabItem.tab !== null &&
        store.getters.blBoTabItem.tab !== undefined &&
        store.getters.blBoTabItem.tab !== ""
      ) {
        activeName.value = store.getters.blBoTabItem.tab;
      }
    });
    const duplicateE = async (id) => {
      store.commit("setLoadingStatus", true);
      const { data } = await ApiService.get(
        `/api/bills_of_lading/${duplicateId.value}`
      );
      let bl = data;
      ApiService.get(`/api/bookings/${data.bookings[0].id}`)
        .then(async ({ data }) => {
          const booking_routes = [] as any;
          const participants = [] as any;
          const equipment_involveds = [] as any;
          const goods = [] as any;
          if (data.routes) {
            data.routes.forEach((x) => {
              booking_routes.push({
                route_type_id: x.route_type_id,
                location_id: x.location_id,
                order: x.order,
                remarks: x.remarks,
              });
            });
          }
          if (data.participants) {
            data.participants.forEach((x) => {
              participants.push({
                entity_id: x.entity_id,
                participant_type_id: x.participant_type_id,
                entity_data: x.entity_data,
              });
            });
          }
          if (data.equipment_involveds) {
            data.equipment_involveds.forEach((x) => {
              equipment_involveds.push({
                bl_id: x.bl_id,
                registered_equipment_id: x.registered_equipment_id,
                equipment_id: x.equipment_id,
                connected_onboard: x.connected_onboard,
                connected_in_station: x.connected_in_station,
                connected_transport: x.connected_transport,
                equipment_supplier: x.equipment_supplier,
                seal_number: x.seal_number,
                is_empty: x.is_empty,
              });
            });
          }
          if (data.goods) {
            data.goods.forEach((x) => {
              const goods_measures = [] as any;
              const equipments = [] as any;
              const dangerous_goods = [] as any;
              if (x.goods_measures) {
                x.goods_measures.forEach((y) => {
                  goods_measures.push({
                    measure_type_id: y.measure_type_id,
                    measure_unit_id: y.measure_unit_id,
                    measure: y.measure,
                    remarks: y.remarks,
                  });
                });
              }
              if (x.equipments) {
                x.equipments.forEach((y) => {
                  const goods_measurese = [] as any;
                  if (y.goods_measures) {
                    y.goods_measures.forEach((z) => {
                      goods_measurese.push({
                        measure_type_id: z.measure_type_id,
                        measure_unit_id: z.measure_unit_id,
                        measure: z.measure,
                        remarks: z.remarks,
                      });
                    });
                  }
                  equipments.push({
                    identification: y.identification,
                    equipment_type: y.equipment_type,
                    measure: y.measure,
                    remarks: y.remarks,
                    goods_measures: goods_measurese,
                  });
                });
              }
              if (x.dangerous_goods) {
                x.dangerous_goods.forEach((y) => {
                  const goods_measurese = [] as any;
                  if (y.goods_measures) {
                    y.goods_measures.forEach((z) => {
                      goods_measurese.push({
                        measure_type_id: z.measure_type_id,
                        measure_unit_id: z.measure_unit_id,
                        measure: z.measure,
                        remarks: z.remarks,
                      });
                    });
                  }
                  dangerous_goods.push({
                    dangerous_goods_type_id: y.dangerous_goods_type_id,
                    name: y.name,
                    packaging_type_id: y.packaging_type_id,
                    shipper: y.shipper,
                    goods_measures: goods_measurese,
                  });
                });
              }
              goods.push({
                bl_id: x.bl_id,
                customs_tariff_code_id: x.customs_tariff_code_id,
                packaging_type_id: x.packaging_type_id,
                description: x.description,
                additional_id: x.additional_id,
                imo: x.imo,
                shipment_type: x.shipment_type,
                customs_status: x.customs_status,
                bl_type_mmpp: x.bl_type_mmpp,
                goods_measures: goods_measures,
                equipments: equipments,
                dangerous_goods: dangerous_goods,
              });
            });
          }

          const new_item = {
            bills_of_lading: [
              {
                bl_date: bl.bl_date,
                bl_number: bl.bl_number,
                customer_id: bl.customer_id,
                has_ens: bl.has_ens,
                humanitary_aid: bl.humanitary_aid,
                accoutrements: bl.accoutrements,
                military_purposes: bl.military_purposes,
                manifest_charge: bl.manifest_charge,
                manifest_discharge: bl.manifest_discharge,
                summary_declaration_charge: bl.summary_declaration_charge,
                summary_declaration_discharge: bl.summary_declaration_discharge,
                bill_of_lading_type_id: bl.bill_of_lading_type_id,
                next_previous_transport_method_id: bl.next_previous_transport_method_id,
              },
            ],
            maritime_voyage_id: data.maritime_voyage_id,
            trip_type_id: data.trip_type_id,
            booking_number: data.booking_number,
            booking_date: data.booking_date,
            reference: data.reference,
            transshipment: data.transshipment,
            booking_routes: booking_routes,
            participants: participants,
            equipment_involveds: equipment_involveds,
            goods: goods,
          };
          duplicateId.value = null;
          showDuplicate.value = false;
          await ApiService.post("/api/bookings", new_item);
          serverSearch();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
        });
    };

    return {
      canCreate,
      canEdit,
      canDelete,
      canShow,
      bl,
      search,
      activeName,
      transshipment,
      editBooking,
      addBooking,
      showTable,
      showHeadings,
      flowOption,
      header,
      tableButtons,
      dataObject,
      bookingTableData,
      blnumber,
      bldate,
      form,
      filters,
      disable,
      originalBL,
      dialogArrivalNotice,
      selected,
      maritimeFileId,
      pagination,
      user,
      maritimeFile,
      maritime_voyage_id_new,
      client_id_new,
      selectMaritimeVoyageNew,
      selectCustomerNew,
      billing_filter,
      setItemsPerPage,
      currentPageChange,
      handleClick,
      setArrivalNotice,
      onSearch,
      onCancel,
      editBookingInfo,
      openTariffHeadings,
      serverSearch,
      actionsButton,
      rolar,
      hasEnsChange,
      showDuplicate,
      duplicateE,
      exportAll,
      addCustomer,
      participants_id,
      customers_id,
      notify_arrival,
    };
  },
});
