const vars = [
  {
    id: 1,
    name: "Avisos de Llegada",
    vars: [
      {
        label: "@numero_bl",
        value: "@bl_number",
      },
      {
        label: "@fecha_bl",
        value: "@bl_date",
      },
      {
        label: "@id_booking",
        value: "@bookings.0.id",
      },
      {
        label: "@numero_booking",
        value: "@bookings.0.booking_number",
      },
      {
        label: "@fecha_booking",
        value: "@bookings.0.booking_date",
      },
      {
        label: "@tipo_viaje",
        value: "@bookings.0.trip_type.name",
      },
      {
        label: "@codigo_tipo_viaje",
        value: "@bookings.0.trip_type.code",
      },
      {
        label: "@codigo_viaje",
        value: "@bookings.0.maritime_voyage.code",
      },
      {
        label: "@id_expediente",
        value: "@bookings.0.maritime_voyage.maritime_file.id",
      },
      {
        label: "@codigo_expediente",
        value: "@bookings.0.maritime_voyage.maritime_file.code",
      },
      {
        label: "@tipo_expediente",
        value: "@bookings.0.maritime_voyage.maritime_file.type.name",
      },
      {
        label: "@codigo_tipo_expediente",
        value: "@bookings.0.maritime_voyage.maritime_file.type.code",
      },
      {
        label: "@puerto",
        value: "@bookings.0.maritime_voyage.maritime_file.port.name",
      },
      {
        label: "@prefijo_puerto",
        value: "@bookings.0.maritime_voyage.maritime_file.port.prefix",
      },
      {
        label: "@barco",
        value: "@bookings.0.maritime_voyage.maritime_file.vessel.name",
      },
      {
        label: "@lloyds_iso",
        value: "@bookings.0.maritime_voyage.maritime_file.vessel.lloyds_iso",
      },
      {
        label: "@consignatario_buque",
        value: "@bookings.0.maritime_voyage.maritime_file.ship_consignee.name",
      },
      {
        label: "@nombre_legal_consignatario_buque",
        value:
          "@bookings.0.maritime_voyage.maritime_file.ship_consignee.legal_name",
      },
      {
        label: "@identificacion_consignatario_buque",
        value:
          "@bookings.0.maritime_voyage.maritime_file.ship_consignee.identifications.0.value",
      },
      {
        label: "@correo_consignatario_buque",
        value: "@bookings.0.maritime_voyage.maritime_file.ship_consignee.email",
      },
      {
        label: "@cliente",
        value: "@customer.name",
      },
      {
        label: "@nombre_legal_cliente",
        value: "@customer.legal_name",
      },
      {
        label: "@identificacion_cliente",
        value: "@customer.identifications.0.value",
      },
      {
        label: "@oficina",
        value: "@bookings.0.maritime_voyage.maritime_file.office.name",
      },
      {
        label: "@compañia",
        value: "@bookings.0.maritime_voyage.maritime_file.office.company.name",
      },
      {
        label: "@compañia_cif",
        value: "@bookings.0.maritime_voyage.maritime_file.office.company.cif",
      },
      {
        label: "@sitio_web_compañia",
        value: "@bookings.0.maritime_voyage.maritime_file.office.company.website",
      },
      {
        label: "@eta",
        value: "@bookings.0.maritime_voyage.maritime_file.ship_moorings.0.check_in",
      },
      {
        label: "@ets",
        value: "@bookings.0.maritime_voyage.maritime_file.ship_moorings.0.check_out",
      },
    ],
  },
  {
    id: 2,
    name: "Observaciones de cabecera de factura sin bl",
    vars: [
      {
        label: "@accouting_date",
        value: "@accouting_date",
      },
      {
        label: "@bill_address",
        value: "@bill_address",
      },
      {
        label: "@bill_date",
        value: "@bill_date",
      },
      {
        label: "@bill_number",
        value: "@bill_number",
      },
      {
        label: "@bill_prefix",
        value: "@bill_prefix",
      },
      {
        label: "@bill_sense",
        value: "@bill_sense",
      },
      {
        label: "@bill_total",
        value: "@bill_total",
      },
      {
        label: "@billing_exercise",
        value: "@billing_exercise",
      },
      {
        label: "@billing_status",
        value: "@billing_status",
      },
      {
        label: "@billing_serie",
        value: "@billing_serie.code",
      },
      {
        label: "@currency",
        value: "@currency.code",
      },
      {
        label: "@customer_info",
        value: "@customer_info.name",
      },
      {
        label: "@format_bill_number",
        value: "@format_bill_number",
      },
      {
        label: "@office_name",
        value: "@office.name",
      },
      {
        label: "@payment_type_detail",
        value: "@payment_type_detail.name",
      },
      {
        label: "@reference",
        value: "@reference",
      },
    ],
  },
  {
    id: 3,
    name: "Observaciones de pie de factura sin bl",
    vars: [
      {
        label: "@accouting_date",
        value: "@accouting_date",
      },
      {
        label: "@bill_address",
        value: "@bill_address",
      },
      {
        label: "@bill_date",
        value: "@bill_date",
      },
      {
        label: "@bill_number",
        value: "@bill_number",
      },
      {
        label: "@bill_prefix",
        value: "@bill_prefix",
      },
      {
        label: "@bill_sense",
        value: "@bill_sense",
      },
      {
        label: "@bill_total",
        value: "@bill_total",
      },
      {
        label: "@billing_exercise",
        value: "@billing_exercise",
      },
      {
        label: "@billing_status",
        value: "@billing_status",
      },
      {
        label: "@billing_serie",
        value: "@billing_serie.code",
      },
      {
        label: "@currency",
        value: "@currency.code",
      },
      {
        label: "@customer_info",
        value: "@customer_info.name",
      },
      {
        label: "@format_bill_number",
        value: "@format_bill_number",
      },
      {
        label: "@office_name",
        value: "@office.name",
      },
      {
        label: "@payment_type_detail",
        value: "@payment_type_detail.name",
      },
      {
        label: "@reference",
        value: "@reference",
      },
    ],
  },
  {
    id: 4,
    name: "Observaciones de cabecera de factura con bl",
    vars: [
      {
        label: "@bills_of_lading_date",
        value: "@bills_of_lading.date",
      },
      {
        label: "@bills_of_lading_number",
        value: "@bills_of_lading.bl_number",
      },
      {
        label: "@bookings_number",
        value: "@bills_of_lading.booking.booking_number",
      },
      {
        label: "@accouting_date",
        value: "@accouting_date",
      },
      {
        label: "@bill_address",
        value: "@bill_address",
      },
      {
        label: "@bill_date",
        value: "@bill_date",
      },
      {
        label: "@bill_number",
        value: "@bill_number",
      },
      {
        label: "@bill_prefix",
        value: "@bill_prefix",
      },
      {
        label: "@bill_sense",
        value: "@bill_sense",
      },
      {
        label: "@bill_total",
        value: "@bill_total",
      },
      {
        label: "@billing_exercise",
        value: "@billing_exercise",
      },
      {
        label: "@billing_status",
        value: "@billing_status",
      },
      {
        label: "@billing_serie",
        value: "@billing_serie.code",
      },
      {
        label: "@currency",
        value: "@currency.code",
      },
      {
        label: "@customer_name",
        value: "@customer_info.name",
      },
      {
        label: "@customer_identification",
        value: "@customer_info.identifications[0].value",
      },
      {
        label: "@customer_email",
        value: "@customer_email",
      },
      {
        label: "@format_bill_number",
        value: "@format_bill_number",
      },
      {
        label: "@office_name",
        value: "@office.name",
      },
      {
        label: "@payment_type_detail",
        value: "@payment_type_detail.name",
      },
      {
        label: "@reference",
        value: "@reference",
      },
      {
        label: "@contact_name",
        value: "@contact_name",
      },
      {
        label: "@contact_last_name",
        value: "@contact_last_name",
      },
      {
        label: "@trip_type",
        value: "@trip_type",
      },
      {
        label: "@maritime_voyage_code",
        value: "@maritime_voyage_code",
      },
      {
        label: "@maritime_file_code",
        value: "@maritime_file_code",
      },
      {
        label: "@maritime_file_type",
        value: "@maritime_file_type",
      },
      {
        label: "@mf_type_code",
        value: "@mf_type_code",
      },
      {
        label: "@port_name",
        value: "@port_name",
      },
      {
        label: "@port_prefix",
        value: "@port_prefix",
      },
      {
        label: "@vessel",
        value: "@vessel",
      },
      {
        label: "@lloyds_iso",
        value: "@lloyds_iso",
      },
      {
        label: "@ship_consignee_name",
        value: "@ship_consignee_name",
      },
      {
        label: "@ship_consignee_email",
        value: "@ship_consignee_email",
      },
      {
        label: "@company_name",
        value: "@company_name",
      },
      {
        label: "@company_website",
        value: "@company_website",
      },
      {
        label: "@company_cif",
        value: "@company_cif",
      },
    ],
  },
  {
    id: 5,
    name: "Observaciones de pie de factura con bl",
    vars: [
      {
        label: "@bills_of_lading_date",
        value: "@bills_of_lading.date",
      },
      {
        label: "@bills_of_lading_number",
        value: "@bills_of_lading.bl_number",
      },
      {
        label: "@bookings_number",
        value: "@bills_of_lading.booking.booking_number",
      },
      {
        label: "@accouting_date",
        value: "@accouting_date",
      },
      {
        label: "@bill_address",
        value: "@bill_address",
      },
      {
        label: "@bill_date",
        value: "@bill_date",
      },
      {
        label: "@bill_number",
        value: "@bill_number",
      },
      {
        label: "@bill_prefix",
        value: "@bill_prefix",
      },
      {
        label: "@bill_sense",
        value: "@bill_sense",
      },
      {
        label: "@bill_total",
        value: "@bill_total",
      },
      {
        label: "@billing_exercise",
        value: "@billing_exercise",
      },
      {
        label: "@billing_status",
        value: "@billing_status",
      },
      {
        label: "@billing_serie",
        value: "@billing_serie.code",
      },
      {
        label: "@currency",
        value: "@currency.code",
      },
      {
        label: "@customer_name",
        value: "@customer_info.name",
      },
      {
        label: "@customer_identification",
        value: "@customer_info.identifications[0].value",
      },
      {
        label: "@customer_email",
        value: "@customer_email",
      },
      {
        label: "@format_bill_number",
        value: "@format_bill_number",
      },
      {
        label: "@office_name",
        value: "@office.name",
      },
      {
        label: "@payment_type_detail",
        value: "@payment_type_detail.name",
      },
      {
        label: "@reference",
        value: "@reference",
      },
      {
        label: "@contact_name",
        value: "@contact_name",
      },
      {
        label: "@contact_last_name",
        value: "@contact_last_name",
      },
      {
        label: "@trip_type",
        value: "@trip_type",
      },
      {
        label: "@maritime_voyage_code",
        value: "@maritime_voyage_code",
      },
      {
        label: "@maritime_file_code",
        value: "@maritime_file_code",
      },
      {
        label: "@maritime_file_type",
        value: "@maritime_file_type",
      },
      {
        label: "@mf_type_code",
        value: "@mf_type_code",
      },
      {
        label: "@port_name",
        value: "@port_name",
      },
      {
        label: "@port_prefix",
        value: "@port_prefix",
      },
      {
        label: "@vessel",
        value: "@vessel",
      },
      {
        label: "@lloyds_iso",
        value: "@lloyds_iso",
      },
      {
        label: "@ship_consignee_name",
        value: "@ship_consignee_name",
      },
      {
        label: "@ship_consignee_email",
        value: "@ship_consignee_email",
      },
      {
        label: "@company_name",
        value: "@company_name",
      },
      {
        label: "@company_website",
        value: "@company_website",
      },
      {
        label: "@company_cif",
        value: "@company_cif",
      },
    ],
  },
  {
    id: 6,
    name: "Factura BL",
    vars: [
      {
        label: "@bills_of_lading_date",
        value: "@bills_of_lading.bl_date",
      },
      {
        label: "@bills_of_lading_number",
        value: "@bills_of_lading.bl_number",
      },
      {
        label: "@bookings_number",
        value: "@bills_of_lading.bookings.0.booking_number",
      },
      {
        label: "@accouting_date",
        value: "@accouting_date",
      },
      {
        label: "@bill_address",
        value: "@bill_address",
      },
      {
        label: "@bill_date",
        value: "@bill_date",
      },
      {
        label: "@bill_number",
        value: "@bill_number",
      },
      {
        label: "@bill_prefix",
        value: "@bill_prefix",
      },
      {
        label: "@bill_sense",
        value: "@bill_sense",
      },
      {
        label: "@bill_total",
        value: "@bill_total",
      },
      {
        label: "@billing_exercise",
        value: "@billing_exercise",
      },
      {
        label: "@billing_status",
        value: "@billing_status",
      },
      {
        label: "@billing_serie",
        value: "@billing_serie.code",
      },
      /* {
        label: "@currency",
        value: "@currency_exchange.code",
      }, */
      {
        label: "@customer_info",
        value: "@customer_info.name",
      },
      {
        label: "@format_bill_number",
        value: "@format_bill_number",
      },
      {
        label: "@office",
        value: "@office.name",
      },
      {
        label: "@payment_type_detail",
        value: "@payment_type_detail.name",
      },
      {
        label: "@reference",
        value: "@reference",
      },
    ],
  },
  {
    id: 7,
    name: "Factura Exp",
    vars: [
      {
        label: "@bills_of_lading_date",
        value: "@bills_of_lading.bl_date",
      },
      {
        label: "@bills_of_lading_number",
        value: "@bills_of_lading.bl_number",
      },
      {
        label: "@bookings_number",
        value: "@bills_of_lading.bookings.0.booking_number",
      },
      {
        label: "@accouting_date",
        value: "@accouting_date",
      },
      {
        label: "@bill_address",
        value: "@bill_address",
      },
      {
        label: "@bill_date",
        value: "@bill_date",
      },
      {
        label: "@bill_number",
        value: "@bill_number",
      },
      {
        label: "@bill_prefix",
        value: "@bill_prefix",
      },
      {
        label: "@bill_sense",
        value: "@bill_sense",
      },
      {
        label: "@bill_total",
        value: "@bill_total",
      },
      {
        label: "@billing_exercise",
        value: "@billing_exercise",
      },
      {
        label: "@billing_status",
        value: "@billing_status",
      },
      {
        label: "@billing_serie",
        value: "@billing_serie.code",
      },
      /* {
        label: "@currency",
        value: "@currency_exchange.code",
      }, */
      {
        label: "@customer_info",
        value: "@customer_info.name",
      },
      {
        label: "@format_bill_number",
        value: "@format_bill_number",
      },
      {
        label: "@office",
        value: "@office.name",
      },
      {
        label: "@payment_type_detail",
        value: "@payment_type_detail.name",
      },
      {
        label: "@reference",
        value: "@reference",
      },
    ],
  },
];

export default vars;
