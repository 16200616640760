
import { computed, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import LocationsSelect from "@/components/catalogs-select/LocationsSelect.vue";
import store from "@/store";
import PackagingTypeSelect from "@/components/catalogs-select/PackagingTypeSelect.vue";

export default {
  name: "OtItinerariesList",
  components: { PackagingTypeSelect, LocationsSelect },
  props: {
    tableData: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();

    const element_booking = computed(() => store.getters.BookingElement);
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: update"
        ) !== -1
      );
    });

    const loading = ref(false);
    const packagingType = ref([]);
    const ItinerariesList = computed(() => store.getters.ItinerariesList);
    const bookingTableData = computed(() => store.getters.Bookings);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "itype", prop: "type" },
      { label: "iis_main", prop: "is_main" },
      { label: "ilocationid", prop: "location_id" },
      { label: "iplace", prop: "place" },
      { label: "istart_date", prop: "start_date" },
      { label: "iend_date", prop: "end_date" },
      { label: "ireference", prop: "reference" },
      // { label: "igross_weight", prop: "gross_weight" },
      { label: "igoods", prop: "goods" },
      { label: "ipackaging_type_id", prop: "packaging_type_id" },
      { label: "ipackages", prop: "packages" },
      { label: "ivolume", prop: "volume" },
      { label: "ilinear_meters", prop: "linear_meters" },
      { label: "iobservations", prop: "observations" },
      { label: "iorder", prop: "order" },
    ]);
    const headerTable = ref(headers.value);

    const getElements = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/maritimevoyages").then(({ data }) => {
        elements.value.list = data;
        elements.value.options = data;
      });
    };
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        setTimeout(() => {
          elements.value.loading = false;
          elements.value.options = elements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        elements.value.options = [];
      }
    };

    onMounted(() => {
      getElements();
      if (ItinerariesList.value.length == 0) {
        let location_id = maritimeFile.value.port.location_id;

        /*element_booking.value.routes.forEach((item) => {
          if (
            item.route_type_id === 9 &&
            element_booking.value.trip_type_id === 1
          ) {
            location_id = item.location_id;
          }
          if (
            item.route_type_id === 12 &&
            element_booking.value.trip_type_id === 2
          ) {
            location_id = item.location_id;
          }
        });*/
        if (element_booking.value.trip_type_id === 2) {
          store.commit("addOtItinerariesList", {
            type: "I",
            is_main: "1",
            location_id: location_id,
            place: "",
            start_date: "",
            end_date: "",
            reference: "",
            goods: "",
            packaging_type_id: undefined,
            packages: undefined,
            volume: "",
            linear_meters: "",
            observations: "",
            order: undefined,
            gross_weight: 0,
          });
          store.commit("addOtItinerariesList", {
            type: "D",
            is_main: "0",
            location_id: undefined,
            place: "",
            start_date: "",
            end_date: "",
            reference: "",
            goods: "",
            packaging_type_id: undefined,
            packages: undefined,
            volume: "",
            linear_meters: "",
            observations: "",
            order: undefined,
            gross_weight: 0,
          });
        }
        if (element_booking.value.trip_type_id === 1) {
          store.commit("addOtItinerariesList", {
            type: "C",
            is_main: "1",
            location_id: undefined,
            place: "",
            start_date: "",
            end_date: "",
            reference: "",
            goods: "",
            packaging_type_id: undefined,
            packages: undefined,
            volume: "",
            linear_meters: "",
            observations: "",
            order: undefined,
            gross_weight: 0,
          });
          store.commit("addOtItinerariesList", {
            type: "I",
            is_main: "0",
            location_id: location_id,
            place: "",
            start_date: "",
            end_date: "",
            reference: "",
            goods: "",
            packaging_type_id: undefined,
            packages: undefined,
            volume: "",
            linear_meters: "",
            observations: "",
            order: undefined,
            gross_weight: 0,
          });
        }
        if ([3, 4].includes(element_booking.value.trip_type_id)) {
          store.commit("addOtItinerariesList", {
            type: "I",
            is_main: "1",
            location_id: location_id,
            place: "",
            start_date: "",
            end_date: "",
            reference: "",
            goods: "",
            packaging_type_id: undefined,
            packages: undefined,
            volume: "",
            linear_meters: "",
            observations: "",
            order: undefined,
            gross_weight: 0,
          });
          store.commit("addOtItinerariesList", {
            type: "I",
            is_main: "0",
            location_id: location_id,
            place: "",
            start_date: "",
            end_date: "",
            reference: "",
            goods: "",
            packaging_type_id: undefined,
            packages: undefined,
            volume: "",
            linear_meters: "",
            observations: "",
            order: undefined,
            gross_weight: 0,
          });
        }
      }
    });

    return {
      canCreate,
      canEdit,
      ItinerariesList,
      loading,
      bookingTableData,
      elements,
      headers,
      headerTable,
      element_booking,
      selectElements,
    };
  },
};
