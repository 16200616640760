
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "PackagingTypeSelect",
  props: {
    packaging_type_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    packaging_type_element: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["update:packaging_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const packagingTypeData = computed(() => store.getters.PackagingType);
    const inputPackagingType = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isPackagingTypeRequired = (value) => {
      if (props.required && !value) {
        return t("rpackagingtype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "packagingType",
      isPackagingTypeRequired
    );

    const getPackagingType = () => {
      store.commit("setLoading", false);
      if (packagingTypeData.value.length > 0) {
        let packaging_types = packagingTypeData.value;
        let packaging_type_ids = packaging_types.map((x) => x.id);
        if (
          props.packaging_type_element &&
          packaging_type_ids.indexOf(props.packaging_type_id) == "-1"
        ) {
          packaging_types.push(props.packaging_type_element);
        }
        inputPackagingType.value.list = packaging_types;
        inputPackagingType.value.options = packaging_types;
        if (element_id.value != props.packaging_type_id) {
          element_id.value = props.packaging_type_id;
        }
      } else {
        ApiService.get("/api/packaging_type/lists").then(({ data }) => {
          let packaging_types = data.packaging_type;
          let packaging_type_ids = packaging_types.map((x) => x.id);
          if (
            props.packaging_type_element &&
            packaging_type_ids.indexOf(props.packaging_type_id) == "-1"
          ) {
            packaging_types.push(props.packaging_type_element);
          }
          inputPackagingType.value.list = packaging_types;
          inputPackagingType.value.options = packaging_types;
          if (element_id.value != props.packaging_type_id) {
            element_id.value = props.packaging_type_id;
          }
        });
      }
    };
    const selectPackagingType = (query) => {
      if (query !== "") {
        inputPackagingType.value.loading = true;
        setTimeout(() => {
          inputPackagingType.value.loading = false;
          inputPackagingType.value.options =
            inputPackagingType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputPackagingType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/packaging_type/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputPackagingType.value.list = data.packaging_type;
              inputPackagingType.value.options = data.packaging_type;
            });
          }
        }, 200);
      } else {
        inputPackagingType.value.options = [];
      }
    };

    watch(
      () => props.packaging_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getPackagingType();
      }, 3000);
    });

    return {
      element_id,
      inputPackagingType,
      errorMessage,
      getPackagingType,
      selectPackagingType,
    };
  },
};
