<template>
  <div class="tabs-container">
    <div v-if="maritimeFile" class="d-flex flex-wrap flex-sm-nowrap mb-3">
      <div v-if="maritimeFileId" class="flex-grow-1">
        <div class="d-flex flex-wrap flex-stack">
          <div class="d-flex flex-column flex-grow-1 pe-8">
            <div class="d-flex flex-wrap">
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span class="svg-icon svg-icon-success me-2"> </span>
                  <div class="fs-4 fw-bolder text-white">
                    {{ maritimeFile.code ? maritimeFile.code : "" }}
                  </div>
                </div>
                <div class="fw-bold fs-7 text-gray-400">
                  {{ $t("timaritimefile") }}
                </div>
              </div>
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span class="svg-icon svg-icon-success me-2"> </span>
                  <div class="fs-4 fw-bolder text-white">
                    {{ maritimeFile.office ? maritimeFile.office.name : "" }}
                  </div>
                </div>
                <div class="fw-bold fs-7 text-gray-400">
                  {{ $t("ioffices") }}
                </div>
              </div>
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span
                    class="svg-icon svg-icon-3 svg-icon-success me-2"
                  ></span>
                  <div class="fs-4 fw-bolder text-white">
                    {{ maritimeFile.port ? maritimeFile.port.name : "" }}
                  </div>
                </div>
                <div class="fw-bold fs-7 text-gray-400">
                  {{ $t("iport") }}
                </div>
              </div>
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span
                    class="svg-icon svg-icon-3 svg-icon-success me-2"
                  ></span>
                  <div class="fs-4 fw-bolder text-white">
                    {{
                      maritimeFile.vessel
                        ? `${maritimeFile.vessel.lloyds_iso} ${maritimeFile.vessel.name}`
                        : ""
                    }}
                  </div>
                </div>
                <div class="fw-bold fs-7 text-gray-400">
                  {{ $t("ivessel") }}
                </div>
              </div>
              <div
                v-if="extraContainer !== undefined"
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span
                    class="svg-icon svg-icon-3 svg-icon-success me-2"
                  ></span>
                  <div class="fs-4 fw-bolder text-white">
                    {{ extraContainer.value }}
                  </div>
                </div>
                <div class="fw-bold fs-7 text-gray-400">
                  {{ $t(extraContainer.name) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          id="overview"
          class="nav-link active"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('overview')"
          >{{ $t("maritimeFileOverview") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId && showTabBooking">
        <a
          id="bookings"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('bookings')"
          >{{ $t("bookings") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId && showTabBl">
        <a
          id="billOfLading"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('billOfLading')"
          >{{ $t("tiBillOfLadings") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId && showTabDispatches">
        <a
          id="dispatches"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('dispatches')"
          >{{ $t("tdispatches") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId && showTabEquipments">
        <a
          id="equipments"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('equipments')"
          >{{ $t("equipments") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId">
        <a
          id="prebilling-concepts"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('prebilling-concepts')"
          >{{ $t("tiPrebillingConcepts") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId">
        <a
          id="prebilling"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('prebilling')"
          >{{ $t("tiPrebilling3") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId && showTabBilling">
        <a
          id="billing"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('billing')"
          >{{ $t("iBilling") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId && showTabDocuments">
        <a
          id="documents"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('documents')"
          >{{ $t("tiDocuments") }}</a
        >
      </li>
      <li class="nav-item" v-if="maritimeFileId">
        <a
          id="customs"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('customs')"
          >{{ $t("icustoms") }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, onUpdated, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "MaritimeFileTabs",
  emits: ["changeTab"],

  setup(props, { emit }) {
    const store = useStore();
    const maritimeFileId = computed(() => store.getters.maritimeId);
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const tab = computed(() => store.getters.getTabSelected);
    const maritimeFileTab = computed(() => store.getters.maritimeFileTabItem);
    const extraContainer = computed(() => store.getters.currentElementItem);
    const bookingOpen = computed(() => store.getters.bookingOpenItem);
    const prebillingOpen = computed(() => store.getters.prebillingOpenItem);
    const permissions = computed(() => store.getters.permissions);
    const showTabBooking = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "bookings: index") !== -1
      );
    });
    const showTabBl = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "bills of lading: index"
        ) !== -1
      );
    });
    const showTabDispatches = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "dispatch: index") !== -1
      );
    });
    const showTabEquipments = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "registered equipment: index"
        ) !== -1
      );
    });
    const showTabBilling = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "billing: index") !== -1
      );
    });
    const showTabDocuments = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime files: view_files"
        ) !== -1
      );
    });

    const handleTabClick = (tab) => {
      if (tab === "bookings" && bookingOpen.value !== undefined) {
        store.commit("setBookingOpen", bookingOpen.value);
        store.commit("setCurrentElement", bookingOpen.value);
      } else if (tab === "billOfLading" && prebillingOpen.value !== undefined) {
        store.commit("setPrebillingOpen", prebillingOpen.value);
        store.commit("setCurrentElement", prebillingOpen.value);
      } else {
        store.commit("removeCurrentElement");
      }
      emit("changeTab", tab);
    };

    watch(
      () => tab.value,
      (first, second) => {
        const current_value = document.getElementById(second);
        const next_value = document.getElementById(first);
        next_value.classList.add("active");
        current_value.classList.remove("active");
      }
    );

    onUpdated(() => {
      if ("tab" in maritimeFileTab.value) {
        const overview = document.getElementById("overview");
        const tab = document.getElementById(maritimeFileTab.value.tab);
        tab.classList.add("active");
        overview.classList.remove("active");
        emit("changeTab", maritimeFileTab.value.tab);
      }
    });

    onMounted(() => {
      setTimeout(() => {
        if ("tab" in maritimeFileTab.value) {
          const overview = document.getElementById("overview");
          const tab = document.getElementById(maritimeFileTab.value.tab);
          tab.classList.add("active");
          overview.classList.remove("active");
          emit("changeTab", maritimeFileTab.value.tab);
        }
      }, 500);
    });

    return {
      props,
      showTabBooking,
      showTabBl,
      showTabDispatches,
      showTabEquipments,
      showTabBilling,
      showTabDocuments,
      maritimeFileId,
      maritimeFile,
      extraContainer,
      handleTabClick,
    };
  },
});
</script>
