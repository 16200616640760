
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "AudioPlayer",

  setup() {
    const store = useStore();
    const audioItem = computed(() => store.getters.AudioItem);

    const getUrl = () => {
      return audioItem.value.path;
    };

    return {
      getUrl,
    };
  },
};
