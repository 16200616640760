
import { useI18n } from "vue-i18n";
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import { ElTree } from "element-plus";
import TableDocuments from "@/components/tables/TableDocuments.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import ImportFileFactureDialog from "@/components/dialogs/ImportFileFactureDialog.vue";
import { useRoute } from "vue-router";

interface Tree {
  id: number;
  label: string;
  children?: Tree[];
}

export default {
  name: "Billing_documents",
  props: {
    showClass: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    billing: {
      type: Object,
    },
  },
  components: {
    TableDocuments,
    ImportFileFactureDialog
  },

  setup(props) {
    const { t } = useI18n();
    const search = ref("");
    const files = ref([]);
    const treeRef = ref<InstanceType<typeof ElTree>>();
    const activeIndex = ref("1");
    const seeFiles = ref(true);
    const store = useStore();
    const logs = computed(() => props.data);
    const fileStored = computed(() => store.getters.FileStoredD);
    const defaultProps = ref({
      children: "children",
      label: "label",
    });
    const data = ref([
      {
        id: 4,
        label: "General",
        children: [
          {
            id: 9,
            label: "Facturas",
          },
        ],
      },
    ]);
    const filter = ref(data.value);

    const handleSelect = (key: string, keyPath: string[]) => {
      // console.log(key, keyPath);
    };
    const onclickImport = (value) => {
      let payload = {
        show: true,
        option: value,
        data: [] as any,
      };
      store.commit("setImportFileDialog", payload);
    };
    const filterNode = (value: string, data: Tree) => {
      if (!value) return true;
      return data.label.includes(value);
    };

    const removeFile = (id) => {
      const index = logs.value.findIndex((x) => x.id === id);
      logs.value.splice(index, 1);
    };

    const nodeClick = (value) => {
      seeFiles.value = true;
    };

    watch(search, (val) => {
      if (val !== "") {
        filter.value = data.value.filter((item: Record<string, any>) => {
          return item.label.toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
      } else {
        filter.value = data.value;
      }
    });
    const route = useRoute();

    onMounted(() => {
      store.commit("clearFileStored");
      store.commit("setLoading", false);
      console.log(props);
      if (props.billing) {
        if (props.billing.all_data.edi_paths.length) {
          let dta = [] as any;
          props.billing.all_data.edi_paths.forEach((file) => {
            dta.push(file);
            const dtaFile = fileStored.value.find((f) => f.id === file.id);
            if (!dtaFile) {
              // store.commit("addFileStoredData", file);
            }
            store.commit("addFileStoredData", file);
          });
          // console.log(dta);
        }
      } 
      let payload = {
        show: false,
        option: [],
        data: [] as any,
      };
      store.commit("setImportFileDialog", payload);
    });

    onUpdated(() => {
      // console.clear();
    });

    const onSendEmail = (element: any) => {
    const customers_info = props.billing.all_data.customer_info.contacts;
    let emails = [] as any;

    // Mapear las solicitudes a promesas
    const promises = customers_info.map((customer: any) => {
      return ApiService.query("/api/contacts/", {
        params: {
          entity_id: [customer.pivot.entity_id],
          contact_type_id:
            element.category === "Factura" ? [5] :
            element.category === "Reporte" ? [5] :
            element.category === "Comercial" ? [2] : [],
        },
      })
        .then(({ data }) => {
          data.contacts.forEach((x: any) => {
            const communications = x.communications.filter(
              (y: any) => y.media.name === "Email"
            );
            communications.forEach((y: any) => {
              if (y.value && y.value !== "" && !emails.includes(y.value)) {
                emails.push(y.value);
              }
            });
          });
        })
        .catch(error => {
          console.error("Error fetching contacts:", error);
        });
    });

    // Esperar a que todas las promesas se resuelvan
    Promise.all(promises)
      .then(() => {
        console.log(emails);
        console.log(element);
        store.commit("setMails", emails);
        store.commit("setEmailItem", element);
        store.commit("setSendBillingDialog", true);
      })
      .catch(error => {
        console.error("Error processing promises:", error);
      });
  };
    return {
      search,
      fileStored,
      logs,
      files,
      filter,
      seeFiles,
      activeIndex,
      defaultProps,
      nodeClick,
      filterNode,
      removeFile,
      handleSelect,
      onclickImport,
      onSendEmail
    };
  },
};
