
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import TableBasic from "@/components/tables/TableBasic.vue";
import GoodsImoTE from "@/components/maritime-file/tables-editable/GoodsImoTE.vue";
import GoodsMeasuresImoTE from "@/components/maritime-file/tables-editable/GoodsMeasuresImoTE.vue";

export default defineComponent({
  name: "GoodsTE",
  components: { GoodsMeasuresImoTE, GoodsImoTE },
  props: {
    tableData: {
      type: Array as any,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    bl: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement", "updateElement"],

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: create") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: create"
          ) !== -1
        );
      }
    });
    const canEdit = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: update") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: update"
          ) !== -1
        );
      }
    });

    const activeNames = ref(["1"]);
    const billOfLandingTableData = computed(
      () => store.getters.BookingsBLTable
    );
    const packagingTypeData = computed(() => store.getters.PackagingType);
    const equipmentTypeData = computed(() => store.getters.EquipmentType);
    const goodBLData = computed(() => store.getters.GoodsBL);
    const goodData = computed(() => store.getters.Goods);
    const goodImoData = computed(() =>
      !props.bl ? store.getters.GoodsImo : store.getters.GoodsImoBL
    );
    const goodImoMeasureData = computed(() =>
      !props.bl
        ? store.getters.GoodsImoMeasures
        : store.getters.GoodsImoMeasuresBL
    );
    const dangerousGoodsTypeData = computed(
      () => store.getters.DangerousGoodsType
    );
    const customsTariffCodeData = computed(
      () => store.getters.CustomsTariffCode
    );
    let testGoodImoId = ref(0);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);
    const dialogImo = ref(false);
    const customsTariffCode = ref({
      loading: false,
      options: [],
      list: [],
    });
    const packagingType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const equipmentType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "", prop: "check" },
      { label: "id", prop: "id" },
      /*{ label: "iblid", prop: "bl_id" },*/
      { label: "icustomstariffcode", prop: "customs_tariff_code_id" },
      { label: "iPackagingType", prop: "packaging_type_id" },
      { label: "iDescription", prop: "description" },
      { label: "orden_partida_ifcsum", prop: "departure_order_export", size: 'small' },
      { label: "orden_partida_ifcsum2", prop: "departure_order_import", size: 'small' },
      { label: "iaditionalid", prop: "additional_id" },
      { label: "iimo", prop: "imo" },
      { label: "ishipmenttype", prop: "shipment_type" },
      { label: "icustomstatus", prop: "customs_status" },
      { label: "ibltypemmpp", prop: "bl_type_mmpp" },
    ]);
    const headerTable = ref(headers.value);
    const createImoMeasure = () => {
      store.commit(props.bl ? "setGoodImoMeasureBL" : "setGoodImoMeasure", {
        id: undefined,
        dangerous_goods_id: undefined,
        measure: "",
        measure_type_id: undefined,
        measure_unit_id: undefined,
        remarks: "",
      });
    };
    const removeImoMeasure = (index) => {
      goodImoMeasureData.value.splice(index, 1);
    };
    const removeImoGood = (index) => {
      goodImoData.value.splice(index, 1);
    };
    const createImoGood = () => {
      goodImoData.value.forEach(function (value) {
        testGoodImoId.value = value.id + 1;
      });
      store.commit("setImoGoodBL", {
        id: testGoodImoId.value,
        good_id: undefined,
        dangerous_goods_type_id: undefined,
        name: "",
        packaging_type_id: "",
        p_s_n: "",
        number_onu: "",
        class: "",
        packaged_group: "",
        emergency_tag: "",
        sea_pollutant: false,
        flash_point: "",
      });
    };
    const getCustomsTariffCode = () => {
      if (!customsTariffCodeData.value.length) {
        ApiService.get("/api/customs_tariff_code").then(({ data }) => {
          store.commit("setCustomsTariffCode", data.customs_tariff_codes);
          customsTariffCode.value.list = data.customs_tariff_codes;
          customsTariffCode.value.options = data.customs_tariff_codes;
        });
      } else {
        customsTariffCode.value.list = customsTariffCodeData.value;
        customsTariffCode.value.options = customsTariffCodeData.value;
      }
    };
    const selectCustomTariffCode = (query) => {
      if (query !== "") {
        customsTariffCode.value.loading = true;
        setTimeout(() => {
          customsTariffCode.value.loading = false;
          ApiService.query(`/api/customs_tariff_code/lists`, {
            params: { per_page: 10, name: query },
          }).then(({ data }) => {
            data.customs_tariff_code.forEach((element) => {
              store.commit("addCustomsTariffCode", element);
            });
            customsTariffCode.value.list = customsTariffCodeData.value;
            customsTariffCode.value.options = data.customs_tariff_code;
          });
        }, 200);
      } else {
        customsTariffCode.value.options = customsTariffCodeData.value;
      }
    };

    const onChangeCheck = () => {
      const checkElements = props.tableData.filter((x) => x.check === "1");
      const ids = checkElements.map((x) => x.id);
      if (!ids.length) {
        const reset_name = props.bl
          ? "resetGoodMeasuresBL"
          : "resetGoodMeasure";
        store.commit(`${reset_name}`);
      } else {
        const filter_name = props.bl
          ? "filterGoodMeasureBL"
          : "filterGoodMeasure";
        store.commit(`${filter_name}`, ids);
      }
    };

    const getPackagingType = () => {
      if (!packagingTypeData.value.length) {
        ApiService.get("/api/packaging_type").then(({ data }) => {
          store.commit("setPackagingType", data.packaging_types);
          packagingType.value.list = packagingTypeData.value;
          packagingType.value.options = packagingTypeData.value;
        });
      } else {
        packagingType.value.list = packagingTypeData.value;
        packagingType.value.options = packagingTypeData.value;
      }
    };
    const selectPackagingType = (query) => {
      if (query !== "") {
        packagingType.value.loading = true;
        setTimeout(() => {
          packagingType.value.loading = false;
          ApiService.query(`/api/packaging_type/lists`, {
            params: { per_page: 10, name: query },
          }).then(({ data }) => {
            data.packaging_type.forEach((element) => {
              store.commit("addPackagingType", element);
            });
            packagingType.value.list = packagingTypeData.value;
            packagingType.value.options = packagingTypeData.value;
          });
        }, 200);
      } else {
        packagingType.value.options = packagingTypeData.value;
      }
    };

    const getEquipmentType = () => {
      if (!equipmentTypeData.value.length) {
        ApiService.get("/api/equipment-types").then(({ data }) => {
          store.commit("setEquipmentType", data);
          equipmentType.value.list = data;
          equipmentType.value.options = data;
        });
      } else {
        equipmentType.value.list = equipmentTypeData.value;
        equipmentType.value.options = equipmentTypeData.value;
      }
    };
    const selectEquipmentType = (query) => {
      if (query !== "") {
        equipmentType.value.loading = true;
        setTimeout(() => {
          equipmentType.value.loading = false;
          ApiService.query(`/api/equipment-types/lists`, {
            params: { per_page: 10, registration_number: query },
          }).then(({ data }) => {
            data.registered_equipment.forEach((element) => {
              store.commit("addEquipmentType", element);
            });
            equipmentType.value.list = equipmentTypeData.value;
            equipmentType.value.options = data.registered_equipment;
          });
        }, 200);
      } else {
        equipmentType.value.options = equipmentTypeData.value;
      }
    };
    const activeImo = computed(function () {
      let result = true;
      if (props.bl) {
        goodBLData.value.forEach(function (value) {
          if (value.imo > 0) {
            result = false;
          }
        });
      } else {
        goodData.value.forEach(function (value) {
          if (value.imo > 0) {
            result = false;
          }
        });
      }
      return result;
    });

    onMounted(() => {
      if (props.mounted) {
        getCustomsTariffCode();
        getPackagingType();
        getEquipmentType();
      } else {
        customsTariffCode.value.list = customsTariffCodeData.value;
        customsTariffCode.value.options = customsTariffCodeData.value;
        packagingType.value.list = packagingTypeData.value;
        packagingType.value.options = packagingTypeData.value;
        equipmentType.value.list = equipmentTypeData.value;
        equipmentType.value.options = equipmentTypeData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      goodData,
      activeImo,
      packagingTypeData,
      goodImoData,
      goodImoMeasureData,
      tableButtons,
      dialogImo,
      activeNames,
      headers,
      headerTable,
      billOfLandingTableData,
      customsTariffCode,
      packagingType,
      equipmentType,
      onChangeCheck,
      selectCustomTariffCode,
      selectPackagingType,
      selectEquipmentType,
      createImoMeasure,
      removeImoMeasure,
      createImoGood,
      removeImoGood,
    };
  },
});
