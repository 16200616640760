
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import EquipmentInvolvedsTE from "@/components/maritime-file/tables-editable/EquipmentInvolvedsTE.vue";
import GoodsTE from "@/components/maritime-file/tables-editable/GoodsTE.vue";
import GoodsMeasuresTE from "@/components/maritime-file/tables-editable/GoodsMeasuresTE.vue";
import EquipmentTE from "@/components/maritime-file/tables-editable/EquipmentTE.vue";
import OverdimensionsTE from "@/components/maritime-file/tables-editable/OverdimensionsTE.vue";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import ApiService from "@/core/services/ApiService";
import TransportOrderStatusSelect from "@/components/catalogs-select/TransportOrderStatusSelect.vue";
import TransportOrderTypeSelect from "@/components/catalogs-select/TransportOrderTypeSelect.vue";
import OtToEquipmentTE from "@/components/maritime-file/tables-editable/OtToEquipmentTE.vue";
import store from "@/store";
import OtParticipantList from "@/components/maritime-file/tables-editable/OtParticipantList.vue";
import OtItinerariesList from "@/components/maritime-file/tables-editable/OtItinerariesList.vue";
import OtMeasuresTE from "@/components/maritime-file/tables-editable/OtMeasuresTE.vue";
import ImportFileTODialog from "@/components/dialogs/ImportFileTODialog.vue";
import moment from "moment";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";
import EventTE from "@/components/maritime-file/tables-editable/EventTE.vue";
export default defineComponent({
  name: "BillOfLandingOT",
  components: {
    EventTE,
    EquipmentInvolvedsTE,
    GoodsTE,
    GoodsMeasuresTE,
    EquipmentTE,
    OverdimensionsTE,
    ImportFileTODialog,
    // OtMeasuresTE,
    OtItinerariesList,
    OtParticipantList,
    // OtToEquipmentTE,
    TransportOrderTypeSelect,
    TransportOrderStatusSelect,
    TableBasicSelect,
  },
  props: {
    bl_id: {
      type: Number,
      default: null,
    },
    b_id: {
      type: Number,
      default: null,
    },
    is_bl: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const edi_file_model = ref(0);
    const addParticipant = ref(false);
    const addParticipantPropio = ref(false);
    const firstTime = ref(true);
    const firstTimePropio = ref(true);
    const api_url = process.env.VUE_APP_API_URL + "/storage/";
    const watchFlag = ref(false);
    const isLoading = ref(false);
    const ediFiles = ref([]) as any;
    const full_edi_download_path = ref("");
    const fileStored = computed(() => store.getters.FileStoredD);
    // const showTable = ref(true);
    const maritimeFileTab = computed(() => store.getters.maritimeFileTabItem);
    const file_item = computed(() => store.getters.maritimeFileItem);
    const showTable = computed(() => store.getters.actionModeStatus);
    const ParticipantList = computed(() => store.getters.ParticipantList);
    const EventList = computed(() => store.getters.EventList);
    const ItinerariesList = computed(() => store.getters.ItinerariesList);
    const EquipmentList = computed(() => store.getters.EquipmentList);
    const BookingsBLTable = computed(() => store.getters.BookingsBLTable);
    const MeasuresOT = computed(() => store.getters.MeasuresOT);
    const activeOT = ref(0);
    const equipmentsOT = computed(() => store.getters.EquipmentsOT);
    const overdimensionsOT = computed(() => store.getters.OverdimensionsOT);
    const shiploadOT = computed(() => store.getters.ShiploadOT);
    const goodMeasuresOT = computed(() => store.getters.GoodsMeasuresOT);
    const goodOT = computed(() => store.getters.GoodOT);
    const equipmentsToUpdate = ref([]) as any;
    const element_bl = computed(() => store.getters.BLElement);
    const element_booking = computed(() => store.getters.BookingElement);
    const itineraries_list = computed(() => store.getters.ItinerariesList);

    const shiploadTableData = computed(() => store.getters.ShiploadsBL);
    const overdimensionsTableData = computed(
      () => store.getters.OverdimensionsBL
    );
    const equipmentsTableData = computed(() => store.getters.EquipmentsBL);
    const goodTableData = computed(() => store.getters.GoodsBL);
    const measuresTableData = computed(() => store.getters.GoodsMeasuresBL);

    const header = ref([
      { column: "status_led", key: "status_led" },
      { column: "tiTransportOrderType", key: "type.name" },
      { column: "iDescription", key: "description" },
      { column: "taccouting_date", key: "created_at" },
      { column: "titransport_type", key: "transportation_type_data" },
      { column: "tdoc_status", key: "edipath_doc" },
      { column: "tot_shipping_date", key: "ot_shipping_date" },
    ]) as any;
    const dataObject = ref([]) as any;
    const contentTable = ref([]) as any;
    const dataContent = ref({
      description: "2*20DV",
      observations: "",
      type_id: undefined,
      status: 9,
      transportation_type: "P",
      transportation_mode: props.is_bl ? "CO" : "B",
      admission_status:
        !element_booking.value ||
        equipmentsOT.value.length <= 0 ||
        (equipmentsOT.value.length > 0 &&
          (equipmentsOT.value[0].is_empty === "1" ||
            equipmentsOT.value[0].is_empty === 1))
          ? "E"
          : element_booking.value.trip_type_id === 1
          ? "F"
          : element_booking.value.trip_type_id === 2
          ? "E"
          : equipmentsOT.value[0].is_empty === "0" ||
            equipmentsOT.value[0].is_empty === 0
          ? "F"
          : "E",
      reception_status:
        !element_booking.value ||
        equipmentsOT.value.length <= 0 ||
        (equipmentsOT.value.length > 0 &&
          (equipmentsOT.value[0].is_empty === "1" ||
            equipmentsOT.value[0].is_empty === 1))
          ? "E"
          : element_booking.value.trip_type_id === 1
          ? "E"
          : element_booking.value.trip_type_id === 2
          ? "F"
          : equipmentsOT.value[0].is_empty === "0" ||
            equipmentsOT.value[0].is_empty === 0
          ? "F"
          : "E",
      to_equipment: [],
      to_participants: [],
      to_events: [],
      to_itineraries: [],
    }) as any;
    const onclickImport = (value) => {
      let payload = {
        show: true,
        option: value,
        data: [] as any,
      };
      store.commit("setImportFileTODialog", payload);
    };
    const generate = async (mode) => {
      if (mode === "A") {
        ApiService.post(
          "/api/transportation_orders/" + activeOT.value + "/generate",
          {
            type: "COPARN",
          }
        );
      } else if (mode === "E") {
        ApiService.post(
          "/api/transportation_orders/" + activeOT.value + "/generate",
          {
            type: "IFTMIN",
          }
        );
      } else if (mode === "E_A") {
        let req = await ApiService.post(
          "/api/transportation_orders/" + activeOT.value + "/generate",
          {
            type: "COPARN",
          }
        );
        // const paths = [{ id: req.data.id }];
        await ApiService.post("/api/edifile/send", {
          edi_paths: [
            {
              id: req.data.id,
              edi_channels: [
                {
                  offices: [
                    {
                      id: file_item.value.office.id,
                    },
                  ],
                },
              ],
            },
          ],
        });
      }
    };
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Generar EDI" as never,
        type: "GENERATE_EDI" as never,
        color: "primary" as never,
      } as never,
      /*{
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,*/
    ]);
    const get_list = () => {
      store.commit("setLoadingStatus", true);
      ApiService.get(
        "/api/transportation_orders?&" +
          (props.bl_id ? "bls" : "bookings") +
          "%5B%5D=" +
          (props.bl_id ? props.bl_id : props.b_id) +
          "&with_trashed=false&page=1&per_page=10"
      ).then(function (response) {
        dataObject.value = response.data.transportation_orders;
        contentTable.value = response.data.transportation_orders;
        store.commit(
          "addTransportationOrders",
          response.data.transportation_orders
        );
        contentTable.value.forEach(function (val: any, key: any) {
          contentTable.value[key].status_led =
            contentTable.value[key].edi_paths.length === 0
              ? "none"
              : getColorTable(contentTable.value[key]);
          contentTable.value[key].edipath_doc =
            contentTable.value[key].edi_paths.length > 0
              ? contentTable.value[key].edi_paths[
                  contentTable.value[key].edi_paths.length - 1
                ].status_dictionary.outbound
              : "Fichero no Generado";
          contentTable.value[key].ot_shipping_date =
            contentTable.value[key].edi_paths.length > 0 &&
            contentTable.value[key].edi_paths[
              contentTable.value[key].edi_paths.length - 1
            ].status === 3
              ? formatDate(
                  contentTable.value[key].edi_paths[
                    contentTable.value[key].edi_paths.length - 1
                  ].created_at
                )
              : "-";
          contentTable.value[key].created_at = new Date(
            contentTable.value[key].created_at
          ).toLocaleDateString("ES");
          contentTable.value[key].admission_status =
            val.admission_status === "F"
              ? (contentTable.value[key].admission_status_data = "Lleno")
              : (contentTable.value[key].admission_status_data = "Vacio");
          contentTable.value[key].transportation_type =
            val.transportation_type === "P"
              ? (contentTable.value[key].transportation_type_data = "Propio")
              : (contentTable.value[key].transportation_type_data = "Cedido");
        });
        if (
          store.getters.blBoTabItem.item_id !== null &&
          store.getters.blBoTabItem.item_id !== undefined &&
          store.getters.blBoTabItem.item_id !== ""
        ) {
          actionsButton(store.getters.blBoTabItem.item_id, "EDIT", null);
          store.commit("removeBlBoTab");
        } else {
          store.commit("setLoadingStatus", false);
        }
      });
    };
    const downloadEdi = () => {
      //
    };
    const actionsButton = (param1, param2, param3) => {
      if (param2 === "GENERATE_EDI") {
        ApiService.post(
          "/api/transportation_orders/" + param1 + "/generate",
          {}
        );
      }
      if (param2 == "EDIT") {
        store.commit("setLoadingStatus", true);
        store.commit("setActionMode", true);
        store.commit("clearMeasureOT");
        store.commit("clearEquipmentList");

        store.commit("updateOverdimensionsOT", []);
        store.commit("updateEquipmentsOT", []);
        store.commit("updateShiploadOT", []);
        store.commit("updateGoodOT", []);
        store.commit("updateGoodMeasuresOT", []);
        activeOT.value = param1;
        dataObject.value.forEach(function (value: any) {
          if (param1 === value.id) {
            dataContent.value = value;
            ediFiles.value = value.edi_paths;
            if (ediFiles.value.length > 0) {
              changeEdiSelect(ediFiles.value[0].id);
              edi_file_model.value = ediFiles.value[0].id;
            }
            dataContent.value.to_shiploads.forEach(function (shiploads: any) {
              if (shiploads.shipload) {
                if (shiploads.shipload.goods) {
                  const payloadGood = {
                    id: shiploads.shipload.goods.id,
                    bl_id: shiploads.shipload.goods.bl_id,
                    customs_tariff_code_id:
                      shiploads.shipload.goods.customs_tariff_code_id,
                    packaging_type_id:
                      shiploads.shipload.goods.packaging_type_id,
                    description: shiploads.shipload.goods.description,
                    additional_id: shiploads.shipload.goods.additional_id,
                    imo: shiploads.shipload.goods.imo == 1 ? "1" : "0",
                    shipment_type: shiploads.shipload.goods.shipment_type,
                    customs_status: shiploads.shipload.goods.customs_status,
                    bl_type_mmpp: shiploads.shipload.goods.bl_type_mmpp,
                    new_item: false,
                  };
                  store.commit("setGoodOT", payloadGood);
                  shiploads.shipload.goods.goods_measures.forEach(function (
                    goods_measures
                  ) {
                    const payloadGoodMeasures = {
                      id: goods_measures.id,
                      good_id: goods_measures.goods_id,
                      measure_type_id: goods_measures.measure_type_id,
                      measure_unit_id: goods_measures.measure_unit_id,
                      measure: goods_measures.measure,
                      remarks: goods_measures.remarks,
                    };
                    store.commit("setGoodMeasuresOT", payloadGoodMeasures);
                  });
                }
                const payloadShipload = {
                  id: shiploads.shipload.id,
                  good_id: shiploads.shipload.good_id,
                  identification: shiploads.shipload.equipment_involved
                    .registered_equipment
                    ? shiploads.shipload.equipment_involved.registered_equipment
                        .registration_number
                    : null,
                  equipment_type:
                    shiploads.shipload.equipment_involved.equipment_id,
                  observations: shiploads.observations,
                  to_special_condition_id: shiploads.to_special_condition_id,
                };
                store.commit("setShiploadOT", payloadShipload);
                shiploads.shipload.equipment_involved.goods_measures.forEach(
                  function (goods_measures) {
                    const payloadGoodMeasures = {
                      id: goods_measures.id,
                      equipment_id: goods_measures.equipment_involved_id,
                      measure_type_id: goods_measures.measure_type_id,
                      measure_unit_id: goods_measures.measure_unit_id,
                      measure: goods_measures.measure,
                      remarks: goods_measures.remarks,
                      registration_number:
                        shiploads.shipload.equipment_involved
                          .registered_equipment !== undefined &&
                        shiploads.shipload.equipment_involved
                          .registered_equipment !== null
                          ? shiploads.shipload.equipment_involved
                              .registered_equipment.registration_number
                          : "-",
                    };
                    store.commit("setOverdimensionsOT", payloadGoodMeasures);
                  }
                );
              }
            });

            const shiploads = dataContent.value.to_shiploads[0];
            if (shiploads.shipload) {
              const payloadEquipment = {
                id: shiploads.shipload.equipment_involved.id,
                bl_id: shiploads.shipload.equipment_involved.bl_id,
                booking_id: shiploads.shipload.equipment_involved.booking_id,
                connected_in_station:
                  shiploads.shipload.equipment_involved.connected_in_station ==
                  1
                    ? "1"
                    : "0",
                connected_onboard:
                  shiploads.shipload.equipment_involved.connected_onboard == 1
                    ? "1"
                    : "0",
                connected_transport:
                  shiploads.shipload.equipment_involved.connected_transport == 1
                    ? "1"
                    : "0",
                pif: shiploads.shipload.equipment_involved.pif == 1 ? "1" : "0",
                customs:
                  shiploads.shipload.equipment_involved.customs == 1
                    ? "1"
                    : "0",
                under_cover:
                  shiploads.shipload.equipment_involved.under_cover == 1
                    ? "1"
                    : "0",
                weighers:
                  shiploads.shipload.equipment_involved.weighers == 1
                    ? "1"
                    : "0",
                dispatched: shiploads.shipload.equipment_involved.dispatched
                  ? "1"
                  : "0",
                is_shipper_owner: shiploads.shipload.equipment_involved
                  .is_shipper_owner
                  ? "1"
                  : "0",
                equipment_id:
                  shiploads.shipload.equipment_involved.equipment_id,
                equipment_name:
                  shiploads.shipload.equipment_involved.equipment.name,
                equipment_supplier:
                  shiploads.shipload.equipment_involved.equipment_supplier,
                is_empty:
                  shiploads.shipload.equipment_involved.is_empty == 1
                    ? "1"
                    : "0",
                registered_equipment_id:
                  shiploads.shipload.equipment_involved.registered_equipment_id,
                registration_number:
                  shiploads.shipload.equipment_involved.registered_equipment !==
                    undefined &&
                  shiploads.shipload.equipment_involved.registered_equipment !==
                    null
                    ? shiploads.shipload.equipment_involved.registered_equipment
                        .registration_number
                    : "-",
                seal_number: shiploads.shipload.equipment_involved.seal_number,
              };
              store.commit("setEquipmentsOT", payloadEquipment);
            }
            // store.commit("setEquipmentList", dataContent.value.to_shiploads);
            store.commit(
              "setParticipantList",
              dataContent.value.to_participants
            );
            store.commit("setEventList", dataContent.value.to_events);
            store.commit("setActionMode", false);
            store.commit(
              "setOtItinerariesList",
              dataContent.value.to_itineraries
            );
          }
        });
        // store.commit("setLoadingStatus", false);
      }
    };
    const createElement = () => {
      dataContent.value = {
        description: "2*20DV",
        observations: "",
        type_id: undefined,
        status: 9,
        transportation_type: "P",
        transportation_mode: props.is_bl ? "CO" : "B",
        admission_status: "F",
        reception_status: "F",
        to_equipment: [],
        to_participants: [],
        to_events: [],
        to_itineraries: [],
      };
      store.commit("setActionMode", false);
      activeOT.value = 0;
    };
    const createOtParticipantList = () => {
      if (ParticipantList.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        ParticipantList.value.forEach((item, index) => {
          if (
            !item.to_participant_type_id ||
            (!item.entity_id && item.ftx_entity === "")
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable) {
          store.commit("createParticipantList");
        } else {
          ElNotification({
            title: "Error",
            message: t("rpeditabletable"),
            type: "error",
          });
        }
      } else {
        store.commit("createParticipantList");
      }
    };
    const removeOtParticipantList = (i) => {
      ParticipantList.value.splice(i, 1);
    };
    const createOtEventList = () => {
      if (EventList.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        EventList.value.forEach((item, index) => {
          if (
            !item.event_type_id ||
            item.date_time === null ||
            item.date_time === ""
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable) {
          store.commit("createEventList");
        } else {
          ElNotification({
            title: "Error",
            message: t("reventeditabletable"),
            type: "error",
          });
        }
      } else {
        store.commit("createEventList");
      }
    };
    const removeOtEventList = (i) => {
      EventList.value.splice(i, 1);
    };
    const createEquipmentList = () => {
      store.commit("createEquipmentList");
    };
    const removeEquipmentList = (i) => {
      EquipmentList.value.splice(i, 1);
    };
    const createMeasuresTEList = () => {
      store.commit("createParticipantList");
    };
    const removeMeasuresTEList = (i) => {
      MeasuresOT.value.splice(i, 1);
    };
    const createOtItinerariesList = () => {
      if (ItinerariesList.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        ItinerariesList.value.forEach((item, index) => {
          if (
            !item.location_id ||
            item.type === "" ||
            item.start_date === "" ||
            item.start_date === null ||
            item.end_date === "" ||
            item.end_date === null ||
            item.gross_weight === ""
          ) {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable) {
          store.commit("createOtItinerariesList");
        } else {
          ElNotification({
            title: "Error",
            message: t("ritineeditabletable"),
            type: "error",
          });
        }
      } else {
        store.commit("createOtItinerariesList");
      }
    };
    const removeOtItinerariesList = (i) => {
      ItinerariesList.value.splice(i, 1);
    };
    const saveElement = () => {
      store.commit("setLoadingStatus", true);
      let flagContinue = true;

      const lastParticipant =
        ParticipantList.value[ParticipantList.value.length - 1];
      if (
        ParticipantList.value.length > 0 &&
        (!lastParticipant.to_participant_type_id ||
          (!lastParticipant.entity_id && lastParticipant.ftx_entity === ""))
      ) {
        ElNotification({
          title: "Error",
          message: t("rpeditabletable"),
          type: "error",
        });
        flagContinue = false;
        store.commit("setLoadingStatus", false);
      }

      if (
        dataContent.value.transportation_type === "Propio" ||
        dataContent.value.transportation_type === "P"
      ) {
        const lastItinerary =
          ItinerariesList.value[ItinerariesList.value.length - 1];
        if (
          ItinerariesList.value.length > 0 &&
          (!lastItinerary.location_id ||
            lastItinerary.type === "" ||
            lastItinerary.start_date === "" ||
            lastItinerary.start_date === null ||
            lastItinerary.end_date === "" ||
            lastItinerary.end_date === null ||
            lastItinerary.gross_weight === "")
        ) {
          ElNotification({
            title: "Error",
            message: t("ritineeditabletable"),
            type: "error",
          });
          flagContinue = false;
          store.commit("setLoadingStatus", false);
        }
      }

      const lastEvent = EventList.value[EventList.value.length - 1];
      if (
        EventList.value.length > 0 &&
        (!lastEvent.event_type_id ||
          lastEvent.date_time === null ||
          lastEvent.date_time === "")
      ) {
        ElNotification({
          title: "Error",
          message: t("reventeditabletable"),
          type: "error",
        });
        flagContinue = false;
        store.commit("setLoadingStatus", false);
      }

      shiploadOT.value.forEach(function (shVal, shKey) {
        if (!shVal.to_special_condition_id) {
          ElNotification({
            title: "Error",
            message: t("rshipsloadsgoods"),
            type: "error",
          });
          flagContinue = false;
          store.commit("setLoadingStatus", false);
        }
      });

      if (flagContinue) {
        const shiploadOt = [] as any;
        shiploadOT.value.forEach(function (shVal, shKey) {
          shiploadOt.push({
            shipload_id: shVal.id,
            to_special_condition_id: shVal.to_special_condition_id,
            observations: shVal.observations,
          });
        });
        const b_id = element_booking.value.id;
        const data = {
          booking_id:
            b_id !== undefined && b_id.booking_id
              ? b_id.booking_id
              : props.b_id,
          bl_id: props.bl_id
            ? props.bl_id
            : element_booking.value.bills_of_lading.length > 0
            ? element_booking.value.bills_of_lading[0].id
            : null,
          description: dataContent.value.description,
          type_id: dataContent.value.type_id,
          status: dataContent.value.status,
          transportation_type:
            dataContent.value.transportation_type === "Propio" ||
            dataContent.value.transportation_type === "P"
              ? "P"
              : "C",
          transportation_mode: dataContent.value.transportation_mode,
          reception_status:
            dataContent.value.reception_status === "Lleno" ||
            dataContent.value.reception_status === "Full" ||
            dataContent.value.reception_status === "F"
              ? "F"
              : "E",
          admission_status:
            dataContent.value.admission_status === "Lleno" ||
            dataContent.value.admission_status === "Full" ||
            dataContent.value.admission_status === "F"
              ? "F"
              : "E",
          observations: dataContent.value.observations,
          to_shiploads: shiploadOt,
          to_participants: ParticipantList.value,
          to_events: EventList.value,
          to_itineraries:
            dataContent.value.transportation_type === "Propio" ||
            dataContent.value.transportation_type === "P"
              ? ItinerariesList.value
              : [],
        };
        if (activeOT.value === 0) {
          if (dataContent.value.type_id) {
            dataContent.value.type_id.forEach(function (type_id) {
              data.type_id = type_id;
              ApiService.post("/api/transportation_orders", data).then(
                function () {
                  try {
                    get_list();
                    store.commit("setActionMode", true);
                    activeOT.value = 0;
                  } catch (e) {
                    store.commit("setLoadingStatus", false);
                  }
                }
              );
            });
          } else {
            store.commit("setLoadingStatus", false);
            ElNotification({
              title: "Error",
              message: t("rtransportordertype"),
              type: "error",
            });
          }
        } else {
          ApiService.put(
            "/api/transportation_orders/" + activeOT.value,
            data
          ).then(function () {
            get_list();
            store.commit("setActionMode", true);
            activeOT.value = 0;
          });
        }
      }
    };
    const changeEdiSelect = (id: any) => {
      full_edi_download_path.value =
        api_url + ediFiles.value.find((a: any) => a.id === id).path;
    };
    const cancelElement = () => {
      store.commit("setLoadingStatus", true);
      store.commit("removeBlBoTab");
      store.commit("removeMaritimeFileTab");
      store.commit("setActionMode", true);
      store.commit("clearEquipmentList");
      store.commit("clearOtItinerariesList");
      store.commit("clearParticipantList");
      store.commit("clearMeasureOT");
      activeOT.value = 0;
      activeNames.value = [];
      store.commit("setLoadingStatus", false);
    };
    const shiploadTableDataChargeData = () => {
      if (shiploadTableData.value.length <= 0) {
        element_bl.value.equipment_involveds.forEach((x) => {
          store.commit("setShiploadBL", {
            id: x.id,
            booking_id: x.booking_id,
            bl_id: x.bl_id,
            registered_equipment_id: x.registered_equipment_id,
            equipment_id: x.equipment_id,
            equipment_name: x.equipment.name,
            connected_onboard: x.connected_onboard === 1 ? "1" : "0",
            connected_in_station: x.connected_in_station === 1 ? "1" : "0",
            connected_transport: x.connected_transport === 1 ? "1" : "0",
            equipment_supplier: x.equipment_supplier,
            seal_number: x.seal_number,
            is_empty: x.is_empty === 1 ? "1" : "0",
            registration_number: x.registered_equipment
              ? x.registered_equipment.registration_number
              : "-",
          });
        });
      }
      return shiploadTableData.value;
    };

    const updateEquipmentsAndGoods = () => {
      store.commit("updateOverdimensionsOT", []);
      store.commit("updateEquipmentsOT", []);
      store.commit("updateShiploadOT", []);
      store.commit("updateGoodOT", []);
      store.commit("updateGoodMeasuresOT", []);
      shiploadTableData.value.forEach((item) => {
        if (equipmentsToUpdate.value.includes(item.id)) {
          store.commit("setEquipmentsOT", item);
          overdimensionsTableData.value.forEach((item1) => {
            if (item1.equipment_id == item.id) {
              store.commit("setOverdimensionsOT", item1);
            }
          });
          equipmentsTableData.value.forEach((item2) => {
            if (item2.identification == item.registration_number) {
              store.commit("setShiploadOT", item2);
              goodTableData.value.forEach((item3) => {
                if (
                  item2.good_id == item3.id &&
                  !goodOT.value.map((x) => x.id).includes(item3.id)
                ) {
                  store.commit("setGoodOT", item3);
                  measuresTableData.value.forEach((item4) => {
                    if (item4.good_id == item3.id) {
                      store.commit("setGoodMeasuresOT", item4);
                    }
                  });
                }
              });
            }
          });
        }
      });
    };

    onUnmounted(() => {
      store.commit("removeBlBoTab");
      store.commit("removeMaritimeFileTab");
      store.commit("setActionMode", true);
      store.commit("clearEquipmentList");
      store.commit("clearOtItinerariesList");
      store.commit("clearParticipantList");
      store.commit("clearMeasureOT");
      activeOT.value = 0;
      activeNames.value = [];
    });

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };
    const getColorTable = (ot) => {
      switch (ot.edi_paths[ot.edi_paths.length - 1].status) {
        case 1:
          return "info";
        case 2:
          return "warning";
        case 3:
          return "success";
        case 4:
          return "danger";
        case 5:
          return "danger";
        case 6:
          return "danger";
        case 7:
          return "danger";
        default:
          return "none";
      }
    };
    const activeNames = ref([]);
    watch(activeNames, (val) => {
      if (val.length > 0) {
        equipmentsToUpdate.value = [];
        equipmentsOT.value.forEach((x) => {
          equipmentsToUpdate.value.push(x.id);
        });
      }
    });

    watch(
      () => props.bl_id,
      (first) => {
        if (first && props.is_bl) {
          get_list();
        }
      }
    );

    watch(
      () => dataContent.value.transportation_type,
      (first) => {
        addParticipant.value = first === "C" && props.is_bl;
        /*if (first === "C" && props.is_bl && firstTime.value) {
          firstTime.value = false;
        }*/

        addParticipantPropio.value = first === "P" && props.is_bl;
        /*if (first === "P" && props.is_bl && firstTimePropio.value) {
          firstTimePropio.value = false;
        }*/
      }
    );

    onMounted(function () {
      if (
        "billOfLading" !== maritimeFileTab.value.tab ||
        contentTable.value.length <= 0
      ) {
        get_list();
      }
      if (activeNames.value.length > 0) {
        equipmentsToUpdate.value = [];
        equipmentsOT.value.forEach((x) => {
          equipmentsToUpdate.value.push(x.id);
        });
      }
      store.commit("setImportFileTODialog", {
        show: false,
        option: 0,
        data: [] as any,
      });

      let shiploadsArrayAux = [] as any;
      let shiploadAux = {} as any;
      shiploadOT.value.forEach((x) => {
        shiploadAux = x;
        if (!x.to_special_condition_id) {
          shiploadAux.to_special_condition_id = 5;
        }
        shiploadsArrayAux.push(shiploadAux);
      });
      store.commit("updateShiploadOT", shiploadsArrayAux);
    });
    const getBackgroundClass = (ot) => {
      if (ot.edi_paths && ot.edi_paths.length > 0) {
        switch (ot.edi_paths[ot.edi_paths.length - 1].status) {
          case 1:
            return "bg-primary";
          case 2:
            return "bg-warning";
          case 3:
            return "bg-success";
          case 4:
            return "bg-danger";
          case 5:
            return "bg-danger";
          case 6:
            return "bg-danger";
          case 7:
            return "bg-danger";
          default:
            return "bg-secondary text-black";
        }
      } else {
        return "bg-secondary text-black";
      }
    };
    return {
      changeEdiSelect,
      createOtParticipantList,
      removeOtParticipantList,
      createEquipmentList,
      removeEquipmentList,
      createMeasuresTEList,
      removeMeasuresTEList,
      createOtItinerariesList,
      removeOtItinerariesList,
      shiploadTableDataChargeData,
      updateEquipmentsAndGoods,
      getBackgroundClass,
      isLoading,
      showTable,
      header,
      dataObject,
      dataContent,
      tableButtons,
      ediFiles,
      api_url,
      actionsButton,
      createElement,
      saveElement,
      cancelElement,
      downloadEdi,
      onclickImport,
      generate,
      edi_file_model,
      full_edi_download_path,
      BookingsBLTable,
      activeOT,
      contentTable,
      formatDate,
      getColorTable,
      shiploadTableData,
      goodTableData,
      measuresTableData,
      equipmentsTableData,
      overdimensionsTableData,
      equipmentsOT,
      overdimensionsOT,
      shiploadOT,
      goodOT,
      goodMeasuresOT,
      equipmentsToUpdate,
      activeNames,
      createOtEventList,
      removeOtEventList,
      EventList,
      ParticipantList,
      ItinerariesList,
      element_booking,
      element_bl,
      addParticipant,
      addParticipantPropio,
    };
  },
});
