
import { defineComponent, ref, onMounted, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "MaritimeVoyageTE",
  props: {
    tableData: {
      type: Array,
    },
    shipowner_ids: {
      type: Array,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime trips: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime trips: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "maritime trips: delete"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const entityData = computed(() => store.getters.Entity);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    });
    const tripType = ref([
      { value: "I", label: "Import" },
      { value: "E", label: "Export" },
    ]);
    const headers = ref([
      { label: "id", prop: "id" },
      { label: "icode", prop: "code" },
      {
        label: "naviera",
        prop: "shipowner_id",
      },
      {
        label: "itripType",
        prop: "trip_type",
      },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const getElements = () => {
      ApiService.query(`/api/entities/lists`, {
        params: {
          per_page: 10,
          name: "",
          entity_type_ids: props.shipowner_ids,
        },
      }).then(({ data }) => {
        store.commit("setEntity", data.entities);
        elements.value.list = data.entities;
        elements.value.options = data.entities;
      });
    };
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        // setTimeout(() => {
        /*elements.value.loading = false;
        elements.value.options = elements.value.list.filter(
          (item: Record<string, any>) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          }
        );
        if (!elements.value.options.length) {*/
        ApiService.query(`/api/entities/lists`, {
          params: {
            per_page: 10,
            name: query,
            entity_type_id: props.shipowner_ids,
          },
        }).then(({ data }) => {
          data.entities.forEach((item) => {
            store.commit("addEntity", item);
          });
          elements.value.list = data.entities;
          elements.value.options = data.entities;
          elements.value.loading = false;
        });
        // }
        // }, 200);
      } else {
        elements.value.options = entityData.value;
      }
    };

    onMounted(() => {
      if (props.mounted) {
        getElements();
      }
      /* else {
        elements.value.list = entityData.value;
        elements.value.options = entityData.value;
      } */
    });

    return {
      canCreate,
      canEdit,
      canDelete,
      activeNames,
      tripType,
      headers,
      headerTable,
      elements,
      selectElements,
    };
  },
});
