
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import PrebillingAmountBLTE from "@/components/maritime-file/tables-editable/PrebillingAmountBLTE.vue";
import SPSelect from "@/components/catalogs-select/SPSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "PrebillingForm",
  components: {
    PrebillingAmountBLTE,
    SPSelect,
  },
  props: {
    origin: {
      type: String,
      default: "global",
    },
    prebilling_id: {
      type: String,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    propMaritimeFile: {
      type: Object,
      default: {} as any,
    },
    propMBL: {
      type: Object,
      default: {} as any,
    },
  },
  emits: ["backPrebilling"],
  setup: function (props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const isFirst = ref(true);
    const isBilled = ref(false);
    const showLines = ref(false);
    const billingConcepts = computed(() => store.getters.BillingConcepts);
    const currenciesData = computed(() => store.getters.Currencies);
    const permissions = computed(() => store.getters.permissions);
    const maritimeFiles = computed(() => store.getters.MaritimeFiles);
    const customerData = ref([] as any);
    const billingConceptData = ref([] as any);
    const providerData = ref([] as any);
    const shipOwnerData = ref([] as any);
    const officesData = ref([] as any);
    const submitButtonPB = ref<HTMLElement | null>(null);
    const prebillingTableData = ref([] as any);
    const maritime_files = ref([] as any);
    const listTaxes = ref([] as any);
    const listTaxesDetails = ref([] as any);
    const listCurrencies = ref([] as any);
    const dataBls = computed(() => store.getters.BLS);
    const dataBookings = ref([] as any);
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
      last_page: 1,
    });
    const prebillingSearch = ref<{
      cost_type_id: number[];
      offices_id: number[];
    }>({
      cost_type_id: [1],
      offices_id: [],
    });
    const taxesSearch = ref<{
      offices_id: number[];
    }>({
      offices_id: [],
    });
    const customerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [1, 13],
      offices_id: [],
    });
    const providerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [2],
      offices_id: [],
    });
    const shipOwnerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [7],
      offices_id: [],
    });
    const filters = ref({
      bl_id: undefined as any,
      cost_type_id: [],
      is_prebilling: false,
      show_maritme_prebillings: true,
      date: "",
      query: "",
      filterDates: null as any,
      filterMFDates: null as any,
    });
    const form = ref({
      id: undefined as any,
      bl_id: null as any,
      fusion_concept: null as any,
      billing_concept_id: undefined as any,
      has_been_billed: false,
      billing_concept: "",
      customer_id: undefined as any,
      currency_id: "",
      provider_id: undefined as any,
      maritime_file_id: [] as any,
      is_manifest: false,
      freight_payment_type_id: undefined as any,
      location_abroad_id: undefined as any,
      office_id: null as any,
      is_maritime_file: false,
      allows_billing: false,
      shipowner_id: undefined as any,
      tax_id: undefined as any,
      prebilling_amounts: [] as any,
      is_autimatic_prebilling: false,
    });
    const onSubmit = async (value) => {
      if (!prebillingTableData.value.length) {
        ElNotification({
          title: "Error",
          message: t("rblprebilling"),
          type: "error",
        });
        return;
      }
      if (!form.value.freight_payment_type_id) {
        ElNotification({
          title: "Error",
          message: t("rpaymenttype"),
          type: "error",
        });
        return;
      }
      if (!form.value.currency_id) {
        ElNotification({
          title: "Error",
          message: t("rcurrency"),
          type: "error",
        });
        return;
      }
      if (!form.value.billing_concept_id) {
        ElNotification({
          title: "Error",
          message: t("rbillingconcept"),
          type: "error",
        });
        return;
      }
      if (!form.value.tax_id) {
        ElNotification({
          title: "Error",
          message: t("rtaxes"),
          type: "error",
        });
        return;
      }
      if (submitButtonPB.value) {
        store.commit("setLoadingStatus", true);
        submitButtonPB.value.setAttribute("data-kt-indicator", "on");
        let payload = { ...form.value };
        payload.prebilling_amounts = prebillingTableData.value;
        payload.bl_id = form.value.bl_id;
        payload.maritime_file_id = form.value.maritime_file_id;
        payload.is_autimatic_prebilling = false;
        if (
          form.value.id === undefined ||
          form.value.id === null ||
          form.value.id === ""
        ) {
          try {
            const { data } = await ApiService.post("/api/prebillings", {
              list_prebillings: [payload],
            });
            submitButtonPB.value?.removeAttribute("data-kt-indicator");
            prebillingTableData.value = [];
            data[0].prebilling_amounts.forEach((x) => {
              prebillingTableData.value.push(convertPrebillingAmount(x));
            });
            form.value.id = data[0].id;
            store.commit("setLoadingStatus", false);
          } catch (e) {
            submitButtonPB.value?.removeAttribute("data-kt-indicator");
            store.commit("setLoadingStatus", false);
          }
        } else {
          try {
            const { data } = await ApiService.put(
              `/api/prebillings/${form.value.id}`,
              payload
            );
            submitButtonPB.value?.removeAttribute("data-kt-indicator");
            prebillingTableData.value = [];
            data.prebilling_amounts.forEach((x) => {
              prebillingTableData.value.push(convertPrebillingAmount(x));
            });
            store.commit("setLoadingStatus", false);
          } catch (e) {
            submitButtonPB.value?.removeAttribute("data-kt-indicator");
            store.commit("setLoadingStatus", false);
          }
        }
      }
    };

    const convertPrebillingAmount = (x) => {
      return {
        id: x.id,
        cost_type_id: x.cost_type_id,
        units: x.units,
        unit_amount: x.unit_amount,
        total_amount: x.total_amount,
        currency_id: x.currency_id,
        taxes_detail_id: x.taxes_detail_id,
        remarks: x.remarks,
        all_data: x,
      };
    };
    // const getBls = async () => {
    //   const { data } = await ApiService.query("/api/bills_of_lading", {
    //     params: {
    //       page: pagination.value.page,
    //       per_page: pagination.value.rowsPerPage,
    //     },
    //   });
    //   dataBls.value = [];
    //   data.bills_of_lading.forEach((x) => {
    //     dataBookings.value.push(x);
    //     x.bills_of_lading.forEach((y) => {
    //       y.name = `${y.id}-${y.bl_number}`;
    //       dataBls.value.push(y);
    //     });
    //   });
    // };
    const getMFs = async () => {
      const { data } = await ApiService.query("/api/maritimefiles", {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
        },
      });
      data.maritime_files.forEach((x) => {
        x.name = `${x.id}-${x.code}`;
        maritime_files.value.push(x);
      });
    };
    const clearData = (changeoffice = true) => {
      if (changeoffice) {
        store.commit("clearOffice");
        form.value.office_id = "";
      }
      store.commit("clearCustomer");
      store.commit("clearLocation");
      store.commit("clearBillingConcepts");
      store.commit("clearProvider");
      store.commit("clearShipowner");
      store.commit("clearTaxes");
      form.value.location_abroad_id = "";
      form.value.billing_concept_id = "";
      form.value.billing_concept = "";
      form.value.customer_id = "";
      form.value.provider_id = "";
      form.value.shipowner_id = "";
      form.value.tax_id = "";
    };
    const changeOffice = async (office_id) => {
      console.log("changeOffice val", office_id);
      form.value.office_id = office_id;
      clearData(false);
      if (office_id) {
        console.log("changeOffice val", office_id);
        setOfficeSearch(office_id);
      } else {
        prebillingSearch.value.offices_id = [];
        customerSearch.value.offices_id = [];
        providerSearch.value.offices_id = [];
        shipOwnerSearch.value.offices_id = [];
        taxesSearch.value.offices_id = [];
      }
      if (!isFirst.value) {
        await loadTaxes();
      } else {
        isFirst.value = false;
      }
    };
    const loadTaxes = async () => {
      store.commit("setLoading", false);
      const { data } = await ApiService.query(`/api/taxes`, {
        params: {
          page: 1,
          per_page: 100,
          offices: [form.value.office_id],
        },
      });
      listTaxes.value = data.taxes;
      form.value.tax_id = "";
      // store.dispatch("removeBodyClassName", "page-loading");
    };
    const loadTaxesDetails = async () => {
      console.log("loadTaxesDetails taxes", form.value.tax_id);
      const { data } = await ApiService.query(`/api/taxes_detail`, {
        params: {
          page: 1,
          per_page: 100,
          offices: [form.value.office_id],
          taxes: form.value.tax_id ? [form.value.tax_id] : [],
        },
      });
      listTaxesDetails.value = data.taxes_details;
    };
    const getCurencies = async () => {
      if (listCurrencies.value.length < 5) {
        const { data } = await ApiService.query(`/api/currencies`, {
          params: { per_page: 5 },
        });
        listCurrencies.value = data.currencies;
        store.commit("setCurrency", listCurrencies.value);
        for (const x of prebillingTableData.value) {
          const val = listCurrencies.value.find((y) => y.id === x.currency_id);
          if (val === undefined || !val) {
            store.commit("setLoading", true);
            const { data: currency } = await ApiService.get(
              `/api/currencies/${x.currency_id}`
            );
            listCurrencies.value.push(currency);
            store.commit("setCurrency", listCurrencies.value);
          }
        }
      }
    };

    const changeTaxe = async (taxe_id) => {
      console.log("changeTaxe", taxe_id);
      if (taxe_id) {
        form.value.tax_id = taxe_id;
        await loadTaxesDetails();
      } else {
        form.value.tax_id = "";
      }
    };

    const loadData = async (params_id = null as any) => {
      if (params_id) {
        await getPrebilling(params_id);
      }
    };
    const getPrebilling = async (params_id) => {
      const { data } = await ApiService.get("/api/prebillings/" + params_id);
      prebillingTableData.value = [];
      form.value.id = data.id;
      form.value.bl_id = data.bl_id;
      form.value.maritime_file_id = data.maritime_file_id;
      form.value.billing_concept_id = data.billing_concept_id
        ? data.billing_concept_id
        : data.prebilling_concept.id;
      store.commit("clearBillingConcepts");
      store.commit("addBillingConcepts", data.prebilling_concept);
      if (data.bills_of_lading) {
        data.bills_of_lading.bookings.forEach((x) => {
          dataBookings.value.push(x);
        });
      }
      if (data.bl_id) {
        store.commit("clearBls");
        store.commit("updateBls", data.bills_of_lading);
      } else if (data.maritime_file_id) {
        store.commit("clearMaritimeFiles");
        store.commit("updateMaritimeFiles", data.maritime_file);
      }
      if (data.customer) {
        form.value.customer_id = data.customer_id;
        if (form.value.customer_id) {
          store.commit("addCustomer", data.customer);
        }
      }
      if (data.provider) {
        store.commit("addProvider", data.provider);
      }
      if (data.office_id) {
        form.value.office_id = data.office_id;
        store.commit("clearOffice");
        store.commit("addOffice", data.office);
        setOfficeSearch(data.office_id);
      }
      if (data.shipowner) {
        store.commit("addShipowner", data.shipowner);
      }
      form.value.fusion_concept = data.fusion_concept;
      form.value.billing_concept = data.billing_concept;
      form.value.provider_id = data.provider_id;
      form.value.has_been_billed = data.has_been_billed;
      form.value.is_manifest = data.is_manifest !== 0;
      form.value.freight_payment_type_id = data.freight_payment_type_id;
      store.commit("addFreightPaymentType", data.freight_payment_type);
      form.value.location_abroad_id = data.location_abroad_id;
      if (data.location_abroad_id) {
        store.commit("addLocation", data.location_abroad);
      }
      form.value.allows_billing = data.allows_billing !== 0;
      form.value.shipowner_id = data.shipowner_id;
      form.value.currency_id =
        data.prebilling_amounts.length > 0
          ? data.prebilling_amounts[0].currency_id
          : 3;
      form.value.tax_id =
        data.prebilling_amounts.length > 0 &&
        data.prebilling_amounts[0].taxes_detail !== null &&
        data.prebilling_amounts[0].taxes_detail !== undefined
          ? data.prebilling_amounts[0].taxes_detail.tax_id
          : "";
      let count = 0;
      data.prebilling_amounts.forEach((x) => {
        store.commit("addCostType", x.cost_type);
        if (count == 0) {
          listCurrencies.value.push(x.currency);
          store.commit("clearCurrency");
          store.commit("addCurrency", x.currency);
        }
        if (x.taxes_detail !== null && x.taxes_detail !== undefined) {
          const findTaxes = listTaxes.value.find(
            (y) => y.id === x.taxes_detail.tax_id
          );
          if (findTaxes === undefined) {
            store.commit("addTaxes", x.taxes_detail.tax);
            listTaxes.value.push(x.taxes_detail.tax);
          }
          const findTaxesDetail = listTaxesDetails.value.find(
            (y) => y.id === x.taxes_detail_id
          );
          if (findTaxesDetail === undefined) {
            listTaxesDetails.value.push(x.taxes_detail);
          }
        }
        prebillingTableData.value.push(convertPrebillingAmount(x));
        count++;
      });
      console.log("prebillingTableData", prebillingTableData.value);
    };
    onMounted(async () => {
      try {
        console.log("props.origin", props.origin);
        console.log("props.prebilling_id", props.prebilling_id);
        console.log("props.isNew", props.isNew);
        console.log("props.propMaritimeFile", props.propMaritimeFile);
        if (props.isNew) {
          store.commit("setLoadingStatus", true);
          if (
            (props.origin === "maritime_file" ||
              props.origin === "prebillings_concept") &&
            props.propMaritimeFile
          ) {
            getDataFromMaritimeFile();
            showLines.value = true;
            store.commit("setLoadingStatus", false);
          }
          if (props.origin === "bl" && props.propMBL) {
            getDataFromBl();
            showLines.value = true;
            store.commit("setLoadingStatus", false);
          }
        } else {
          if (props.origin === "global") {
            store.commit("setLoadingStatus", true);
            setCurrentPageBreadcrumbs("prebillings", [
              { route: "/prebillings", label: "prebillings" },
            ]);
            const paramsRoute = route.params;
            await loadData(paramsRoute.id);
            showLines.value = true;
            store.commit("setLoadingStatus", false);
          } else {
            await getPrebilling(props.prebilling_id);
            showLines.value = true;
            store.commit("setLoadingStatus", false);
          }
        }
      } catch (e) {
        console.error(e);
        showLines.value = true;
        store.commit("setLoadingStatus", false);
      }
    });
    const getDataFromMaritimeFile = () => {
      console.log(
        "getDataFromMaritimeFile props.maritimeFile",
        props.propMaritimeFile
      );
      form.value.maritime_file_id = props.propMaritimeFile!.id;
      form.value.office_id = props.propMaritimeFile!.office_id;
      form.value.customer_id = props.propMaritimeFile!.customer_id;
      if (props.propMaritimeFile!.office.addresses.length) {
        form.value.location_abroad_id =
          props.propMaritimeFile!.office.addresses[0].location_id;
        store.commit("clearLocation");
        store.commit(
          "addLocation",
          props.propMaritimeFile!.office.addresses[0].location
        );
      }
      setOfficeSearch(form.value.office_id);
      store.commit("clearOffice");
      store.commit("addOffice", props.propMaritimeFile!.office);
      store.commit("clearCustomer");
      store.commit("addCustomer", props.propMaritimeFile!.customer);
    };
    const setOfficeSearch = (office_id) => {
      console.log("setOfficeSearch", office_id);
      prebillingSearch.value.offices_id = [];
      prebillingSearch.value.offices_id.push(parseInt(office_id));
      customerSearch.value.offices_id = [];
      customerSearch.value.offices_id.push(parseInt(office_id));
      providerSearch.value.offices_id = [];
      providerSearch.value.offices_id.push(parseInt(office_id));
      shipOwnerSearch.value.offices_id = [];
      shipOwnerSearch.value.offices_id.push(parseInt(office_id));
      taxesSearch.value.offices_id = [];
      taxesSearch.value.offices_id.push(parseInt(office_id));
    };
    const getDataFromBl = () => {
      console.log("getDataFromBl", props.propMBL);
      form.value.bl_id = props.propMBL!.id;
      form.value.office_id =
        props.propMBL!.bookings[0].maritime_voyage.maritime_file.office_id;
      form.value.customer_id = props.propMBL!.customer_id;
      if (
        props.propMBL!.bookings[0].maritime_voyage.maritime_file.office
          .addresses.length
      ) {
        form.value.location_abroad_id =
          props.propMBL!.bookings[0].maritime_voyage.maritime_file.office.addresses[0].location_id;
        store.commit("clearLocation");
        store.commit(
          "addLocation",
          props.propMBL!.bookings[0].maritime_voyage.maritime_file.office
            .addresses[0].location
        );
      }
      setOfficeSearch(form.value.office_id);
      store.commit("clearOffice");
      store.commit(
        "addOffice",
        props.propMBL!.bookings[0].maritime_voyage.maritime_file.office
      );
      store.commit("clearCustomer");
      if (props.propMBL!.customer) {
        store.commit("addCustomer", props.propMBL!.customer);
      }

    };

    const changeCurrency = (val) => {
      form.value.currency_id = val;
      prebillingTableData.value.forEach((x) => (x.currency_id = val));
    };
    const createPAElement = () => {
      if (prebillingTableData.value.length > 0) {
        let cont = 0;
        let invalidTable = false;
        prebillingTableData.value.forEach((item, index) => {
          if (item.cost_type_id === "") {
            invalidTable = true;
            cont += 1;
          }
        });
        if (!invalidTable && cont === 0) {
          addPALine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rpreditabletable"),
            type: "error",
          });
        }
      } else {
        addPALine();
      }
    };

    const addPALine = () => {
      prebillingTableData.value.push({
        id: null,
        cost_type_id: "",
        units: "",
        unit_amount: "",
        total_amount: "",
        currency_id: form.value.currency_id,
        taxes_detail_id: "",
        remarks: "",
      });
    };

    const removePAItem = (index) => {
      prebillingTableData.value.splice(index, 1);
    };

    const change1Mf = async (value) => {
      if (value === null || value === "") {
        form.value.office_id = "";
        form.value.customer_id = "";
      } else {
        const mf = maritime_files.value.find(
          (x) => x.id === form.value.maritime_file_id
        );
        listTaxes.value = [];
        form.value.customer_id = mf ? mf.customer_id : "";
        form.value.office_id = mf ? mf.office_id : "";
        const find_office = officesData.value.find(
          (x) => x.id === form.value.office_id
        );
        if (find_office === undefined) {
          officesData.value.push(mf.office);
        }
        const find_customer = customerData.value.find(
          (x) => x.id === form.value.customer_id
        );
        if (find_customer === undefined) {
          customerData.value.push(mf.customer);
        }
      }
    };
    const changeBL = async (value) => {
      console.log("changeBL value", value);
      store.commit("clearOffice");
      store.commit("clearCustomer");
      store.commit("clearLocation");
      store.commit("clearBillingConcepts");
      if (value) {
        const { data } = await ApiService.get("api/bills_of_lading/" + value);
        if (data.bookings.length) {
          form.value.bl_id = value;
          const booking = data.bookings[0];
          console.log("booking", booking);
          form.value.office_id =
            booking.maritime_voyage.maritime_file.office_id;
          prebillingSearch.value.offices_id = [
            booking.maritime_voyage.maritime_file.office_id,
          ];
          store.commit(
            "addOffice",
            booking.maritime_voyage.maritime_file.office
          );
          if (booking.maritime_voyage.maritime_file.office.addresses.length) {
            form.value.location_abroad_id =
              booking.maritime_voyage.maritime_file.office.addresses[0].location_id;
            store.commit(
              "addLocation",
              booking.maritime_voyage.maritime_file.office.addresses[0].location
            );
          }
        } else {
          form.value.bl_id = "";
          ElNotification({
            title: "Error",
            message: t("no_has_bookings"),
            type: "error",
            dangerouslyUseHTMLString: true,
          });
        }
      } else {
        form.value.bl_id = "";
        form.value.office_id = "";
        clearData();
      }
    };
    const onCancel = () => {
      if (props.origin === "global") {
        router.push({ name: "prebillings" });
      } else {
        emit("backPrebilling");
      }
    };
    onUnmounted(() => {
      showLines.value = false;
    });

    const getTax = async () => {
      if (listTaxes.value.length < 5) {
        const { data } = await ApiService.query("/api/taxes/lists", {
          params: {
            per_page: 5,
            offices: [form.value.office_id],
          },
        });
        listTaxes.value = data.taxes;
      }
    };

    const searchTax = async (query) => {
      if (query !== "") {
        setTimeout(() => {
          listTaxes.value = listTaxes.value.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          ApiService.query(`/api/taxes/lists`, {
            params: {
              name: query,
              offices: [form.value.office_id],
              per_page: 5,
            },
          }).then(({ data }) => {
            listTaxes.value = data.taxes;
          });
        }, 500);
      }
    };

    const changeBillingConcepts = (val) => {
      if (val) {
        form.value.billing_concept_id = val;
        const concept = billingConcepts.value.find((x) => x.id === val);
        form.value.billing_concept = concept.code;
      } else {
        form.value.billing_concept_id = "";
        form.value.billing_concept = "";
      }
    };

    const changeMaritimeFile = (value) => {
      console.log("changeMaritimeFile value", value);
      clearData();
      if (value) {
        form.value.maritime_file_id = value;
        console.log("changeMaritimeFile maritimeFiles", maritimeFiles.value);
        const mf = maritimeFiles.value.find((x) => x.id === value);
        console.log("changeMaritimeFile mf", mf);
        form.value.office_id = mf.office_id;
        prebillingSearch.value.offices_id = [mf.office_id];
        providerSearch.value.offices_id = [mf.office_id];
        customerSearch.value.offices_id = [mf.office_id];
        shipOwnerSearch.value.offices_id = [mf.office_id];
        taxesSearch.value.offices_id = [mf.office_id];
        store.commit("addOffice", mf.office);
        if (mf.office.addresses.length) {
          form.value.location_abroad_id = mf.office.addresses[0].location_id;
          store.commit("addLocation", mf.office.addresses[0].location);
        }
      } else {
        form.value.maritime_file_id = "";
        form.value.office_id = "";
      }
    };

    return {
      submitButtonPB,
      prebillingTableData,
      form,
      maritime_files,
      dataBls,
      listTaxes,
      listCurrencies,
      filters,
      customerData,
      providerData,
      billingConceptData,
      shipOwnerData,
      officesData,
      listTaxesDetails,
      showLines,
      customerSearch,
      providerSearch,
      shipOwnerSearch,
      prebillingSearch,
      taxesSearch,
      onSubmit,
      onCancel,
      changeOffice,
      changeTaxe,
      changeCurrency,
      createPAElement,
      removePAItem,
      getCurencies,
      change1Mf,
      changeBL,
      getTax,
      searchTax,
      changeBillingConcepts,
      changeMaritimeFile,
    };
  },
});
