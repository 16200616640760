
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "EquipmentInvolvedsTE",
  props: {
    tableData: {
      type: Array,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    bl: {
      type: Boolean,
      default: false,
    },
    in_ot: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement", "updateElement"],

  setup(props, { emit }) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: create") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: create"
          ) !== -1
        );
      }
    });
    const canEdit = computed(() => {
      if (!props.bl) {
        return (
          permissions.value.findIndex((x) => x.name === "bookings: update") !==
          -1
        );
      } else {
        return (
          permissions.value.findIndex(
            (x) => x.name === "bills of lading: update"
          ) !== -1
        );
      }
    });

    const activeNames = ref(["1"]);
    const select_equipment_id = ref("");
    const billOfLandingTableData = computed(
      () => store.getters.BookingsBLTable
    );
    const registerEquipmentData = computed(
      () => store.getters.RegisterEquipments
    );
    const equipmentData = computed(() => store.getters.EquipmentsMF);
    const requipments = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const equipments = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headers = ref([
      { label: "", prop: "check" },
      { label: "id", prop: "id" },
      /*{ label: "iblid", prop: "bl_id" },*/
      { label: "iequipmentid", prop: "equipment_id" },
      { label: "iregistercontainer", prop: "registered_equipment_id" },
      { label: "isealnumber", prop: "seal_number" },
      { label: "is_shipper_owner", prop: "is_shipper_owner" },
      { label: "iisempty", prop: "is_empty" },
      { label: "iconectedonborad", prop: "connected_onboard" },
      { label: "iconectedoninstation", prop: "connected_in_station" },
      { label: "iconectedontransport", prop: "connected_transport" },
      { label: "pif", prop: "pif" },
      { label: "customs", prop: "customs" },
      { label: "Despachos", prop: "dispatched" },
      { label: "weighers", prop: "weighers" },
      { label: "underCover", prop: "under_cover" },
    ]);
    const headerTable = ref(headers.value);

    const getREquipments = () => {
      if (!registerEquipmentData.value.length) {
        ApiService.get("/api/registered-equipment").then(({ data }) => {
          store.commit("setRegisterEquipment", data);
          requipments.value.list = data;
          requipments.value.options = data;
        });
      } else {
        requipments.value.list = registerEquipmentData.value;
        requipments.value.options = registerEquipmentData.value;
      }
    };
    const selectREquipment = (query) => {
      if (query !== "") {
        requipments.value.loading = true;
        requipments.value.loading = false;
        requipments.value.options = requipments.value.list.filter(
          (item: Record<string, any>) => {
            return (
              item.registration_number
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
          }
        );
        if (!requipments.value.options.length) {
          if (select_equipment_id.value !== "") {
            store.commit("setLoading", false);
            ApiService.query(`/api/registered-equipment/lists`, {
              params: {
                per_page: 25,
                registration_number: query,
                equipment_id: [select_equipment_id.value],
              },
            }).then(({ data }) => {
              store.commit("setRegisterEquipment", data.registered_equipment);
              requipments.value.list = data.registered_equipment;
              requipments.value.options = data.registered_equipment;
            });
          } else {
            store.commit("setLoading", false);
            ApiService.query(`/api/registered-equipment/lists`, {
              params: {
                per_page: 25,
                registration_number: query,
              },
            }).then(({ data }) => {
              store.commit("setRegisterEquipment", data.registered_equipment);
              requipments.value.list = data.registered_equipment;
              requipments.value.options = data.registered_equipment;
            });
          }
        } else {
          store.commit("setRegisterEquipment", requipments.value.options);
        }
      } else {
        requipments.value.options = registerEquipmentData.value;
      }
    };

    const getEquipments = () => {
      if (!equipmentData.value.length) {
        store.commit("setLoading", false);
        ApiService.query("/api/equipments/lists", {
          params: {
            per_page: 10,
          },
        }).then(({ data }) => {
          store.commit("setEquipment", data.equipments);
          equipments.value.list = data.equipments;
          equipments.value.options = data.equipments;
        });
      } else {
        equipments.value.list = equipmentData.value;
        equipments.value.options = equipmentData.value;
      }
    };
    const selectEquipment = (query) => {
      if (query !== "") {
        equipments.value.loading = true;
        equipments.value.loading = false;
        equipments.value.options = equipments.value.list.filter(
          (item: Record<string, any>) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          }
        );
        if (!equipments.value.options.length) {
          ApiService.query(`/api/equipments/lists`, {
            params: { per_page: 10, name: query },
          }).then(({ data }) => {
            data.equipments.forEach((element) => {
              store.commit("addEquipment", element);
            });
            equipments.value.list = equipmentData.value;
            equipments.value.options = data.equipments;
          });
        }
      } else {
        equipments.value.options = equipmentData.value;
      }
    };

    const getregistration = (item) => {
      if (!Array.isArray(registerEquipmentData.value)) {
        store.commit("setRegisterEquipment", [registerEquipmentData.value]);
      }
      const temp = registerEquipmentData.value.filter(
        (x) => x.equipment_id === item.equipment_id
      );
      if (temp.length > 0) {
        return temp;
      }
      const datas = registerEquipmentData.value.filter(
        (x) => x.id === item.registered_equipment_id
      );
      return datas;
    };
    const updateEquipment = (element) => {
      select_equipment_id.value = element.equipment_id;
      const item = registerEquipmentData.value.filter(
        (x) => x.equipment_id === element.equipment_id
      );
      element.registered_equipment_id = "";
      if (item > 0) {
        requipments.value.list = item;
        requipments.value.options = item;
      } else {
        ApiService.query(`/api/registered-equipment/lists`, {
          params: {
            per_page: 10,
            registration_number: "",
            equipment_id: [element.equipment_id],
          },
        }).then(({ data }) => {
          data.registered_equipment.forEach((element) => {
            store.commit("setRegisterEquipment", element);
          });
          requipments.value.list = data.registered_equipment;
          requipments.value.options = data.registered_equipment;
        });
      }
    };

    const canCreateOT = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: create"
        ) !== -1
      );
    });

    onMounted(() => {
      if (props.mounted) {
        getREquipments();
        getEquipments();
      } else {
        requipments.value.list = registerEquipmentData.value;
        requipments.value.options = registerEquipmentData.value;
        equipments.value.list = equipmentData.value;
        equipments.value.options = equipmentData.value;
      }
    });

    return {
      canCreate,
      canEdit,
      activeNames,
      headers,
      headerTable,
      billOfLandingTableData,
      registerEquipmentData,
      equipmentData,
      requipments,
      equipments,
      selectREquipment,
      selectEquipment,
      updateEquipment,
      getregistration,
      canCreateOT,
      select_equipment_id,
    };
  },
});
