import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white rounded shadow-sm p-5 p-lg-8 mx-auto tab-element" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  tabindex: "0"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prebillings = _resolveComponent("Prebillings")!
  const _component_PrebillingForm = _resolveComponent("PrebillingForm")!
  const _component_billing_register = _resolveComponent("billing-register")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Prebillings, {
        maritimeFileId: $setup.maritimeFile.id,
        propMaritimeFile: $setup.maritimeFile,
        refresh: $setup.refreshTablePrebilling,
        origin: "prebillings_concept",
        onOnEditPrebilling: $setup.onEditPrebilling,
        onOnNewPrebilling: $setup.onNewClick,
        onOnAddBillClick: $setup.onAddBill
      }, null, 8, ["maritimeFileId", "propMaritimeFile", "refresh", "onOnEditPrebilling", "onOnNewPrebilling", "onOnAddBillClick"])
    ], 512), [
      [_vShow, !$setup.editPrebilling && !$setup.billingShow]
    ]),
    ($setup.editPrebilling)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PrebillingForm, {
            origin: "prebillings_concept",
            isNew: $setup.isNew,
            prebilling_id: $setup.select_prebilling_id,
            onBackPrebilling: $setup.onCancel,
            propMaritimeFile: $setup.maritimeFile
          }, null, 8, ["isNew", "prebilling_id", "onBackPrebilling", "propMaritimeFile"])
        ]))
      : _createCommentVNode("", true),
    ($setup.billingShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_billing_register, {
            showBack: "true",
            maritime_file: $setup.maritimeFile,
            onActionBack: $setup.actionBackButon,
            origin: "maritime_file"
          }, null, 8, ["maritime_file", "onActionBack"])
        ]))
      : _createCommentVNode("", true)
  ]))
}